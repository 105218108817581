/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartnerInfo,
    PartnerInfoFromJSON,
    PartnerInfoFromJSONTyped,
    PartnerInfoToJSON,
} from './PartnerInfo';

/**
 * 
 * @export
 * @interface PartnerLoadListResult
 */
export interface PartnerLoadListResult {
    /**
     * 
     * @type {Array<PartnerInfo>}
     * @memberof PartnerLoadListResult
     */
    partners?: Array<PartnerInfo>;
}

export function PartnerLoadListResultFromJSON(json: any): PartnerLoadListResult {
    return PartnerLoadListResultFromJSONTyped(json, false);
}

export function PartnerLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partners': !exists(json, 'partners') ? undefined : ((json['partners'] as Array<any>).map(PartnerInfoFromJSON)),
    };
}

export function PartnerLoadListResultToJSON(value?: PartnerLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partners': value.partners === undefined ? undefined : ((value.partners as Array<any>).map(PartnerInfoToJSON)),
    };
}

