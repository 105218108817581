import { ChangeEvent, HTMLAttributes } from 'react';
import { TournamentResultsTabsValues } from '../../TournamentPage.interfaces';
import styles from './TournamentResultsTabs.module.css';

type TournamentResultsTabsProps= {
  activeTab: TournamentResultsTabsValues;
  selectTab: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
} & HTMLAttributes<HTMLElement>

const TournamentResultsTabs = ({ activeTab, selectTab, className, name, ...props }: TournamentResultsTabsProps) => {
  return (
    <nav className={`${className} ${styles['navbar']}`} {...props}>
      <label className={styles['navbar-item']}>
        <input checked={activeTab === 'results'} type='radio' name={name} style={{ display: 'none' }} value='results' onChange={selectTab}/>
        <span>Результаты</span>
      </label>
      <label className={styles['navbar-item']}>
        <input checked={activeTab === 'score'} type='radio' name={name} style={{ display: 'none' }} value='score' onChange={selectTab}/>
        <span>Счет</span>
      </label>
      <label className={styles['navbar-item']}>
        <input checked={activeTab === 'statistic'} type='radio' name={name} style={{ display: 'none' }} value='statistic' onChange={selectTab}/>
        <span>Статистика</span>
      </label>
    </nav>
  );
}
 
export default TournamentResultsTabs;
