const ModalBallIcon = () => {
  return (  
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6881_3814)">
        <path d="M28.7752 7.22892C33.6222 14.2839 31.8307 23.9314 24.7762 28.7739C17.7212 33.6209 8.07318 31.8304 3.22618 24.7739C-1.62082 17.7189 0.17068 8.07042 7.22618 3.22392C14.2812 -1.62108 23.9282 0.169924 28.7712 7.22392" fill="#C3D82E"/>
        <path d="M22.6684 29.998C22.6684 29.998 15.2684 13.509 23.1514 2.25098L23.8129 2.61598C23.8129 2.61598 15.5559 13.928 24.2369 29.154L22.6684 29.999M3.23045 24.7765C3.23045 24.7765 10.5219 13.947 8.51245 2.43498L7.61445 3.05248C7.61445 3.05248 11.5494 6.26548 2.66895 23.9585" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_6881_3814">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
 
export default ModalBallIcon;
