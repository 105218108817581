/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface AvailableCourtsLoadListParameters
 */
export interface AvailableCourtsLoadListParameters {
    /**
     * 
     * @type {Reference}
     * @memberof AvailableCourtsLoadListParameters
     */
    club?: Reference;
    /**
     * 
     * @type {LocalDate}
     * @memberof AvailableCourtsLoadListParameters
     */
    date?: LocalDate;
    /**
     * 
     * @type {number}
     * @memberof AvailableCourtsLoadListParameters
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableCourtsLoadListParameters
     */
    time?: string;
}

export function AvailableCourtsLoadListParametersFromJSON(json: any): AvailableCourtsLoadListParameters {
    return AvailableCourtsLoadListParametersFromJSONTyped(json, false);
}

export function AvailableCourtsLoadListParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableCourtsLoadListParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'date': !exists(json, 'date') ? undefined : LocalDateFromJSON(json['date']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'time': !exists(json, 'time') ? undefined : json['time'],
    };
}

export function AvailableCourtsLoadListParametersToJSON(value?: AvailableCourtsLoadListParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'club': ReferenceToJSON(value.club),
        'date': LocalDateToJSON(value.date),
        'duration': value.duration,
        'time': value.time,
    };
}

