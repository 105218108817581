import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import { useContext, useState } from "react";
import { GameResultsContext } from "../GameResults/utils";
import LineupScorePage from "../LineupScorePage/LineupScorePage";
import GameLineupsPage from "../GameLineupsPage/GameLineupsPage";
import LineupScoreContextWrapper from "../components/LineupScoresList/LineupScoreContextWrapper";
import TournamentResultsNewContextWrapper from "../../tournament/TournamentResultsNew/TournamentResultsNewContextWrapper";
import { castGameToMinitournament } from "../../tournament/utils";
import TournamentResultsNew from "../../tournament/TournamentResultsNew/TournamentResultsNew";

const GameResultsSteps = () => {
  const { step, game } = useContext(GameResultsContext);
  const [loading, setLoading] = useState(false);

  return ( 
    <BasePageWrapper>
      {game.gameTournamentKind ?
        <TournamentResultsNewContextWrapper
          tournament={castGameToMinitournament(game)}
          loading={loading}
          setLoading={setLoading}
          tournamentType="mini"
        >
          <TournamentResultsNew />
        </TournamentResultsNewContextWrapper> :
        <>
          {step === 'lineup' ? <GameLineupsPage /> : null}
          {step === 'score' ?
            <LineupScoreContextWrapper>
              <LineupScorePage /> 
            </LineupScoreContextWrapper> : null
          }
        </>
      }
    </BasePageWrapper>
  );
}
 
export default GameResultsSteps;
