/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameStatisticsLoadResult,
    GameStatisticsLoadResultFromJSON,
    GameStatisticsLoadResultFromJSONTyped,
    GameStatisticsLoadResultToJSON,
} from './GameStatisticsLoadResult';

/**
 * 
 * @export
 * @interface LoadGameTournamentStatisticsResponse
 */
export interface LoadGameTournamentStatisticsResponse {
    /**
     * 
     * @type {GameStatisticsLoadResult}
     * @memberof LoadGameTournamentStatisticsResponse
     */
    result?: GameStatisticsLoadResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadGameTournamentStatisticsResponse
     */
    messages?: Array<object>;
}

export function LoadGameTournamentStatisticsResponseFromJSON(json: any): LoadGameTournamentStatisticsResponse {
    return LoadGameTournamentStatisticsResponseFromJSONTyped(json, false);
}

export function LoadGameTournamentStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadGameTournamentStatisticsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : GameStatisticsLoadResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadGameTournamentStatisticsResponseToJSON(value?: LoadGameTournamentStatisticsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': GameStatisticsLoadResultToJSON(value.result),
        'messages': value.messages,
    };
}

