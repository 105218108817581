/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SetFavoriteClubsParameters,
    SetFavoriteClubsParametersFromJSON,
    SetFavoriteClubsParametersFromJSONTyped,
    SetFavoriteClubsParametersToJSON,
} from './SetFavoriteClubsParameters';

/**
 * 
 * @export
 * @interface SetFavoriteClubsRequest
 */
export interface SetFavoriteClubsRequest {
    /**
     * 
     * @type {SetFavoriteClubsParameters}
     * @memberof SetFavoriteClubsRequest
     */
    parameters?: SetFavoriteClubsParameters;
}

export function SetFavoriteClubsRequestFromJSON(json: any): SetFavoriteClubsRequest {
    return SetFavoriteClubsRequestFromJSONTyped(json, false);
}

export function SetFavoriteClubsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetFavoriteClubsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : SetFavoriteClubsParametersFromJSON(json['parameters']),
    };
}

export function SetFavoriteClubsRequestToJSON(value?: SetFavoriteClubsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': SetFavoriteClubsParametersToJSON(value.parameters),
    };
}

