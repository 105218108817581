import React from 'react';

function ProfileChatPage() {
  return (
    <div>
      ProfileChatPage
    </div>
  );
}

export default ProfileChatPage;