/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChatInfo,
    ChatInfoFromJSON,
    ChatInfoFromJSONTyped,
    ChatInfoToJSON,
} from './ChatInfo';

/**
 * 
 * @export
 * @interface ChatLoadListResult
 */
export interface ChatLoadListResult {
    /**
     * 
     * @type {Array<ChatInfo>}
     * @memberof ChatLoadListResult
     */
    infos?: Array<ChatInfo>;
}

export function ChatLoadListResultFromJSON(json: any): ChatLoadListResult {
    return ChatLoadListResultFromJSONTyped(json, false);
}

export function ChatLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(ChatInfoFromJSON)),
    };
}

export function ChatLoadListResultToJSON(value?: ChatLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(ChatInfoToJSON)),
    };
}

