import { GameTournamentKind, TournamentKind } from "@/integration-api/server-rest-lundapadelApi";

export const translateTournamentType = (type: TournamentKind | GameTournamentKind) => {
  switch(type) {
    case TournamentKind.AMERICANO:
      return 'Американо';
    case TournamentKind.MEXICANO:
      return 'Мексикано';
    case TournamentKind.ESCALERA:
      return 'Эскалера';
    case TournamentKind.CLASSIC:
      return 'Стандартный';
    default:
      return '';
  }
}
