import { Gender, TournamentStatisticElementView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentAwardCard.module.css'
import { Link } from "react-router-dom";
import TournamentAwardGold from '@/static/images/icons/TournamentAwardGold';
import TournamentAwardBronze from '@/static/images/icons/TournamentAwardBronze';
import TournamentAwardSilver from '@/static/images/icons/TournamentAwardSilver';
import VenokIcon from '@/static/images/icons/VenokIcon';

type TournamentAwardCardProps = {
  tournament: TournamentStatisticElementView;
}

const tournamentAward = (place?: number) => {
  switch(place) {
    case 1:
      return <TournamentAwardGold/>
    case 2:
      return <TournamentAwardSilver/>
    case 3:
      return <TournamentAwardBronze/>
    default:
      return (
        <div className={styles['venok']}>
          <div className={styles['place-wrapper']}>
            <span>{place}</span>
            <span>место</span>
          </div>
          <VenokIcon/>
        </div>
      )
  }
}

const tournamentGenderTyping = (gender: Array<Gender>) => {
  if(gender.length > 1) {
    return 'смешанного '
  }
  if(gender.includes(Gender.MAN)) {
    return 'мужского '
  }
  return 'женского '
}

const TournamentAwardCard = ({ tournament }: TournamentAwardCardProps) => {
  return (
    <Link to={`/tournament/${tournament.tournamentUid}`}>
      <div className={styles['wrapper']}>
        {tournamentAward(tournament.place)}
        <span className={styles['text']}>
          {tournament.place === 1 ? 'Победитель' : tournament.place! > 3 ? 'Участник' : 'Призер'}
          <br/>
          {tournamentGenderTyping(tournament.gender!)} турнира
          <br/>
          {tournament.displayDate}
        </span>
      </div>
    </Link>
  );
}
 
export default TournamentAwardCard;
