/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewsItemLoadListParameters,
    NewsItemLoadListParametersFromJSON,
    NewsItemLoadListParametersFromJSONTyped,
    NewsItemLoadListParametersToJSON,
} from './NewsItemLoadListParameters';

/**
 * 
 * @export
 * @interface NewsItemLoadListRequest
 */
export interface NewsItemLoadListRequest {
    /**
     * 
     * @type {NewsItemLoadListParameters}
     * @memberof NewsItemLoadListRequest
     */
    parameters?: NewsItemLoadListParameters;
}

export function NewsItemLoadListRequestFromJSON(json: any): NewsItemLoadListRequest {
    return NewsItemLoadListRequestFromJSONTyped(json, false);
}

export function NewsItemLoadListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemLoadListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : NewsItemLoadListParametersFromJSON(json['parameters']),
    };
}

export function NewsItemLoadListRequestToJSON(value?: NewsItemLoadListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': NewsItemLoadListParametersToJSON(value.parameters),
    };
}

