import InnerPadding from '@/components/InnerPadding/InnerPadding';
import styles from './TournamentQuickPlayersLimit.module.css';
import { quickPlayersLimitValues } from '../SelectTime/helper';

type TournamentQuickPlayersLimitProps = {
  handleClick: (value: string) => void;
}

const TournamentQuickPlayersLimit = ({ handleClick }: TournamentQuickPlayersLimitProps) => {
  return ( 
    <InnerPadding className={styles['wrapper']}>
      {quickPlayersLimitValues.map(val => (
        <div key={val} onClick={() => handleClick(val)} className={styles['value']}>
          {val}
        </div>
      ))}
    </InnerPadding>
  );
}
 
export default TournamentQuickPlayersLimit;
