import { createImgSrc } from "@/utils/utils";

export const fileToDataUrl = (file: Blob) => {
  return new Promise<string | ArrayBuffer | null | undefined>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result || '');
    };
    reader.readAsDataURL(file);
  });
}

export const cachedImage = (newsUid?: string, imgUid?: string) => {
  return imgUid ? `${createImgSrc.origin}/attachment?ownerType=NEWS&ownerUid=${newsUid}&imageUid=${imgUid}` : undefined;
}