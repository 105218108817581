import styles from './LineupVersus.module.css';
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import WarningIcon from "@/static/images/icons/WarningIcon";
import RatingBage from '../Badge/RatingBage/RatingBage';
import { GameLineupView, Role } from '@/integration-api/server-rest-lundapadelApi';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/hooks/hooks';
import ArrowSmallIcon from '@/static/images/icons/ArrowSmallIcon';

type LineupVersusProps = {
  lineup: GameLineupView;
  index?: number;
  listItem?: boolean;
  type?: 'empty' | 'edit'; 
  linksAllowed?: boolean;
}

const LineupVersus = ({ lineup, index, listItem, type, linksAllowed }: LineupVersusProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();

  return ( 
    <div className={styles['wrapper']}>
      {listItem ?       
        <>      
          {type === 'edit' ?
          <>
            <div className={styles['score-edit']}>
              <span>Выставить счет</span>
              <ArrowSmallIcon/>
            </div>
          </> :
          <>          
            <span className={styles['lineup-number']}>{index}</span>
            <div className={styles['score-empty']}>
              <WarningIcon />
              <span>Счет не выставлен</span>
            </div>
          </>
          }
        </> : null
      }
      <div className={styles['lineup-wrapper']}>
        <div className={styles['lineup-team']}>
          <ProfileCard 
            player={lineup.player1} 
            team='left'
            noPreferedSide
            fullName
            onClick={linksAllowed && (lineup.player1?.uid !== current.identity?.uid) && lineup.player1?.active ? () => navigate(`/profile/${lineup.player1?.uid}`) : undefined}
            bage={[{
              element: <RatingBage rating={lineup.player1?.displayRating ?? ''}/>,
              position: 'top-right'
            }, {
              element: lineup.player1?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
          <ProfileCard 
            player={lineup.player2} 
            team='left'
            noPreferedSide
            fullName
            onClick={linksAllowed && (lineup.player2?.uid !== current.identity?.uid) && lineup.player2?.active ? () => navigate(`/profile/${lineup.player2?.uid}`) : undefined}
            bage={[{
              element: <RatingBage rating={lineup.player2?.displayRating ?? ''}/>,
              position: 'top-right'
            }, {
              element: lineup.player2?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
        </div>
        <span className={styles['versus']}>vs</span>
        <div className={styles['lineup-team']}>
          <ProfileCard 
            player={lineup.player3} 
            team='right'
            noPreferedSide
            fullName
            onClick={linksAllowed && (lineup.player3?.uid !== current.identity?.uid) && lineup.player3?.active ? () => navigate(`/profile/${lineup.player3?.uid}`) : undefined}
            bage={[{
              element: <RatingBage rating={lineup.player3?.displayRating ?? ''} style={{ background: 'var(--blue)' }}/>,
              position: 'top-right'
            }, {
              element: lineup.player3?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
          <ProfileCard 
            player={lineup.player4} 
            team='right'
            noPreferedSide
            fullName
            onClick={linksAllowed && (lineup.player4?.uid !== current.identity?.uid) && lineup.player4?.active ? () => navigate(`/profile/${lineup.player4?.uid}`) : undefined}
            bage={[{
              element: <RatingBage rating={lineup.player4?.displayRating ?? ''} style={{ background: 'var(--blue)' }}/>,
              position: 'top-right'
            }, {
              element: lineup.player4?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
        </div>
      </div>
    </div>
  );
}
 
export default LineupVersus;
