import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './ClubCityBelonging.module.css';
import Headling from '@/components/Headling/Headling';
import FavoriteClubItem from '../FavoriteClubItem/FavoriteClubItem';
import InnerPadding from '@/components/InnerPadding/InnerPadding';

type ClubCityBelongingProps = {
  city?: string;
  clubs: Array<ClubView>;
  selectedClub?: string;
  favoriteClubSelection?: boolean
  handleClubSelect: (uid: string) => void;
  favoriteSelectedClubs?: Array<ClubView>;
}

const ClubCityBelonging = ({ clubs, city, selectedClub, favoriteClubSelection, favoriteSelectedClubs, handleClubSelect }: ClubCityBelongingProps) => {
  return ( 
    <div>
      <InnerPadding>
        <Headling appearence='big-normal'>
          {city}
        </Headling>
      </InnerPadding>
      <div className={styles['club-items']}>
        {clubs.map(club => (
          <FavoriteClubItem
            key={club.reference?.uid}
            club={club}
            selectedClub={selectedClub}
            onClick={() => handleClubSelect(club.reference?.uid ?? '')}
            favoriteClubSelection={favoriteClubSelection}
            favoriteSelectedClubs={favoriteSelectedClubs}
          />
        ))}
      </div>
    </div>
  );
}
 
export default ClubCityBelonging;
