import { FC } from 'react';
import styles from './SelectRating.module.css'
import RatingRange from '@/components/RatingRange/RatingRange';
import { useAppSelector } from '@/hooks/hooks';

export interface SelectRatingData {
  ratingName: string,
  isActive?: boolean,
}
export interface SelectRatingProps {
  title: string,
  value: [number, number],
  filter?: boolean;
  onChange: (value: [number, number]) => void,
}

const SelectRating: FC<SelectRatingProps> = (props) => {
  const { title, value, filter, onChange } = props;
  const { grades } = useAppSelector(state => state.auth.currentProfile);

  const sliderRange = grades ? [1, Object.keys(grades).length] : undefined;

  return grades && sliderRange ? (
    <div className={styles.wrapper}>
      <div className={styles.title} style={filter ? { paddingLeft: '0' } : undefined}>{title}</div>
        <div id='radio-group' className={styles.ratingWrapper}>

          <RatingRange  grades={grades} onChange={onChange} value={value} min={sliderRange[0]} max={sliderRange[1]}/>

        </div>
    </div>
  ) : null;
}

export default SelectRating;
