import HLWrapper from '@/components/HLWrapper/HLWrapper';
import styles from './RegisterStepThree.module.css';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import { useCallback, useEffect, useState } from 'react';
import { ClubView, PlayerProfileView } from '@/integration-api/server-rest-lundapadelApi';
import { clubLoadList } from '@/pages/application/home/components/ClubsSection/service';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import PhoneAddIcon from '@/components/Icons/PhoneAddIcon';
import { FetchErrorMessage, isEnum } from '@/types/types';
import greenInfo from '@/static/images/icons/info-icon-yellow.svg';
import LundaPadelLogo from '@/components/LundaPadelLogo/LundaPadelLogo';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

interface RegisterStepThreeProps {
  playerProfile?:PlayerProfileView;
}

function RegisterStepThree({playerProfile, ...props}: RegisterStepThreeProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [clubs, setClubs] = useState<Array<ClubView>>();

  const loadClubs = useCallback(async () => {
    setLoading(true)
    try {
      const { result } = await clubLoadList(true);
      if (result) {
        setClubs(result.views || []);
      }
    } catch (err: unknown) {
      if (err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }finally{
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    if (!clubs) {
      loadClubs();
    }
  }, [clubs, loadClubs]);

  return (
    <div className={`${styles.container}`}>
      {playerProfile ?
        <div className={styles['content-wrapper']}>
          <HLWrapper className={styles.lodoWrap}>
            <LundaPadelLogo />
          </HLWrapper>
          <HLWrapper>
            <div className={styles.rating}>
              <span className={styles.ratingLabel}>Ваш рейтинг в приложении:</span>
              <span className={styles.ratingSeparator} />
              <span className={styles.ratingValue}>
                {playerProfile.displayRating}
              </span>
            </div>
          </HLWrapper>
          <InnerPadding>
            <p className={styles.description}>
              Первичный рейтинг дается за прохождение опроса, для более точного определения внутриигрового рейтинга запишитесь на 10-ти минутную тренировку в наш клуб, это совершенно бесплатно!
            </p>
          </InnerPadding>
          <InnerPadding>
            <div className={styles.ratingInfo} onClick={() => navigate('/rating/info', { state: { from: 'register' } })}>
              <img src={greenInfo} alt="инфо о рейтинге" />
              <span>Подробнее о рейтинге</span>
            </div>
          </InnerPadding>
        </div> : null
      }


      {loading && <Skeleton count={2} />}
      <OversideWrapper className={styles.submitBtn}>
        <Button onClick={() => {
          navigate('/');
          sessionStorage.removeItem('profileUid');
        }} isBgEmpty>
          Начать играть!
        </Button>
        {clubs &&
          clubs.filter(club => club.lunda).map((club: ClubView) => {
            return (
              <a href={`tel:+${club.phone}`} key={club.name}>
                <div className={styles.buttonWithIcon}>
                  <PhoneAddIcon />
                  <div>{club.name}</div>
                </div>
              </a>
            );
          })}
      </OversideWrapper>
    </div>
  );
}

export default RegisterStepThree;
