/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Color,
    ColorFromJSON,
    ColorFromJSONTyped,
    ColorToJSON,
} from './Color';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface NewsItemInfo
 */
export interface NewsItemInfo {
    /**
     * 
     * @type {string}
     * @memberof NewsItemInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsItemInfo
     */
    title?: string;
    /**
     * 
     * @type {Color}
     * @memberof NewsItemInfo
     */
    color?: Color;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof NewsItemInfo
     */
    timestamp?: LocalDateTime;
}

export function NewsItemInfoFromJSON(json: any): NewsItemInfo {
    return NewsItemInfoFromJSONTyped(json, false);
}

export function NewsItemInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'color': !exists(json, 'color') ? undefined : ColorFromJSON(json['color']),
        'timestamp': !exists(json, 'timestamp') ? undefined : LocalDateTimeFromJSON(json['timestamp']),
    };
}

export function NewsItemInfoToJSON(value?: NewsItemInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'title': value.title,
        'color': ColorToJSON(value.color),
        'timestamp': LocalDateTimeToJSON(value.timestamp),
    };
}

