import styles from './LineupMatchesList.module.css';
import LineupScoresListItem from '../LineupScoresListItem/LineupScoresListItem';
import { ModifiedGameMatchView } from '../../GameResults/GameResultsPage.interfaces';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import TrashIcon from '@/static/images/icons/TrashIcon';
import { useCallback, useContext, useRef, useState } from 'react';
import { LineupScoreContext } from '../../GameResults/utils';
import { v4 } from 'uuid';
import GameResultsModal from '../GameResultsModal/GameResultsModal';
import { gameMatchStatus } from '../../service';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

type LineupMatchesListProps = {
  match: ModifiedGameMatchView;
  index: number;
}

const LineupMatchesList = ({ match, index }: LineupMatchesListProps) => {
  const { deleteScores, addMatchSet } = useContext(LineupScoreContext);
  const [modalVisible, setModalVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const addThirdSetHandler = useCallback(async () => {
    try {
      const { result } = await gameMatchStatus({ match });
      if(result?.valid) {
        setModalVisible(true);
      } else {
        addMatchSet?.({ matchIndex: index, set: { uid: v4(), score1: 0, score2: 0 } });
        scrollToEnd();
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    }
  }, [match, index, addMatchSet]);

  const renderMatchGameSets = () => {
    return match.gameSets?.map((set, i) => (
      <LineupScoresListItem
        type='match'
        key={set.uid}
        index={i}
        set={set}
        notDeletable={i < 2}
        matchIndex={index}
    />
    ));
  }
  
  const scrollToEnd = () => {
    setTimeout(() => {
      if(ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  };

  return (  
    <>
      <GameResultsModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        type='thirdSet'
      />    
      <div className={styles['wrapper']}>
        <InnerPadding className={styles['title']}>
          <span>{index + 1} матч</span>
          <div onClick={() => deleteScores?.({ type: 'match', matchIndex: index })}>
            <TrashIcon/> 
          </div>
        </InnerPadding>
        {renderMatchGameSets()}
        {match.gameSets?.length === 2 ?
          <InnerPadding 
            className={styles['third-set']}
            onClick={addThirdSetHandler}
          >
            Добавить третий сет
          </InnerPadding> : null
        }
        <div id={`${index}`} ref={ref} className={styles['divider']}></div>
      </div>
    </>
  );
}
 
export default LineupMatchesList;
