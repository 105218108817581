import { FC, InputHTMLAttributes } from 'react';
import styles from './ToggleSwitch.module.css';

export interface ToggleSwitchProps
  extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
}
const ToggleSwitch: FC<ToggleSwitchProps> = ({ id, ...inputProps }) => {
  return (
    <span className={styles.wrap}>
      <input type="checkbox" id={id} {...inputProps} className={styles.input} />
      <label htmlFor={id} className={styles.label} />
    </span>
  );
};

export default ToggleSwitch;
