/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayerProfileAvatarSaveParameters
 */
export interface PlayerProfileAvatarSaveParameters {
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileAvatarSaveParameters
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileAvatarSaveParameters
     */
    y?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileAvatarSaveParameters
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileAvatarSaveParameters
     */
    height?: number;
}

export function PlayerProfileAvatarSaveParametersFromJSON(json: any): PlayerProfileAvatarSaveParameters {
    return PlayerProfileAvatarSaveParametersFromJSONTyped(json, false);
}

export function PlayerProfileAvatarSaveParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileAvatarSaveParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function PlayerProfileAvatarSaveParametersToJSON(value?: PlayerProfileAvatarSaveParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
        'width': value.width,
        'height': value.height,
    };
}

