import dayjs, { Dayjs, isDayjs } from 'dayjs';

export type LocalDateTime =
  `${number}-${number}-${number}T${number}:${number}:${number}`;

export function LocalDateTimeFromJSON(json: any): LocalDateTime {
  return LocalDateTimeFromJSONTyped(json, false);
}

export function LocalDateTimeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LocalDateTime {
  if (json === undefined || json === null) {
    return json;
  }
  return json as LocalDateTime;
}

export function LocalDateTimeToJSON(value?: LocalDateTime | Date | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  if (isDayjs(value)) {
    return value.format('YYYY-MM-DDTHH:mm:ss');
  } else if (value instanceof Date) {
    return dayjs(value).format('YYYY-MM-DDTHH:mm:ss');
  }
  return value;
}

export type TimeString = `${number}:${number}:${number}`;

export function toLocalDateTime(
  sourceDate: Date | Dayjs | null | undefined,
  sourceTime?: TimeString
): LocalDateTime | undefined {
  if (!sourceDate || sourceDate === null) {
    return undefined;
  }

  let date: dayjs.Dayjs;

  if (!isDayjs(sourceDate)) {
    date = dayjs(sourceDate);
  } else {
    date = sourceDate;
  }

  if (!sourceTime) {
    return date.format('YYYY-MM-DDTHH:mm:ss') as LocalDateTime;
  }

  const timeParts: string[] = sourceTime.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);

  return date
    .set('hour', !isNaN(hour) ? hour : 0)
    .set('minutes', !isNaN(minutes) ? minutes : 0)
    .set('seconds', !isNaN(seconds) ? seconds : 0)
    .format('YYYY-MM-DDTHH:mm:ss') as LocalDateTime;
}
