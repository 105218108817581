import styles from './MatchDetails.module.css'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import CourtNumberModal from '../CourtNumberModal/CourtNumberModal';
import InfoPopup from '@/components/InfoPopup/InfoPopup';
import infoIcon from '@/static/images/icons/info-icon-orange.svg'
import TournamentInput from '../TournamentInput/TournamentInput';
import HLine from '@/components/HLine/HLine';
import { ClubView, Role } from '@/integration-api/server-rest-lundapadelApi';
import { useAppSelector } from '@/hooks/hooks';
import CoachWhistle from '@/static/images/icons/CoachWhistle';
import { GameFormValues } from '../../GameCreate/GameCreatePage.interfaces';

interface MatchDetailsInterface extends InputHTMLAttributes<HTMLInputElement> {
  formikValues: GameFormValues,
  setFieldValue?: any,
  clublist?: Array<ClubView>;
  date?: Date;
  scrollToClubSelection?: () => void;
}

function MatchDetails({ formikValues, setFieldValue, clublist, scrollToClubSelection, ...props }: MatchDetailsInterface) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPrivateMatchInfo, setShowPrivateMatchInfo] = useState<boolean>(false);
  const current = useAppSelector(state => state.auth.currentProfile);

  const onClose = () => {
    setShowModal(false)
  }

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    if(!formikValues?.club && e.target.name === 'courtBooked') {
      scrollToClubSelection?.();
    } else {
      setFieldValue(e.target.name, e.target.checked);
      if(e.target.name === 'courtBooked') {
        if(formikValues?.club?.yClientsIntegrated) {
          setFieldValue('duration', '2');
        }
        if(e.target.checked) {
          setShowModal(true);
        }
      }
    }
  }

  const closePopup = (e: MouseEvent) => {
    if ((e.target as HTMLDivElement).id !== 'privateMatchInfo') {
      setShowPrivateMatchInfo(false)
    }
  }

  useEffect(() => {
    if(formikValues?.courtBooked && !formikValues.courtNumber && !formikValues.club?.yClientsIntegrated) {
      setShowModal(true);
    }
  }, [formikValues?.club]);

  return (
    <>
      <div className={styles.wrapper} onClick={(e: any) => closePopup(e)}>
        <div className={styles.title}>Детали игры</div>
        <div className={styles.warning}>
          <img src={infoIcon} alt='info'/>
          <div className={styles.warningText}>Забронировать корт можно только на сайте или по телефону. В приложении бронь корта на данный момент недоступна.</div>
        </div>
        <div className={styles.switchBlock}>
          <div className={styles.switchItem}>
            <div className={styles.switchText}>Я забронировал корт</div>
            <ToggleSwitch
              checked={formikValues?.courtBooked}
              id='checkboxCourtBooked'
              name='courtBooked'
              onChange={handleToggle} />
          </div>
          <div className={styles.switchItem}>
            <div className={styles.privateMatch}>
              <div className={styles.switchText}>
                <span>Приватная игра</span>
                <InfoPopup
                  content='Игра не будет доступна для всех игроков. Вступить в нее можно будет только по приглашению  или qr-коду'
                  id='privateMatchInfo'
                  showInfo={showPrivateMatchInfo}
                  setShowInfo={setShowPrivateMatchInfo}
                  width={255}
                  right ={-115}
                  arrowOffset={118}
                />
              </div>
            </div>            
            <ToggleSwitch
              checked={formikValues?.privateGame}
              id='checkboxPrivateGame'
              name='privateGame'
              onChange={handleToggle}
            />
          </div>
          {current.roles?.includes(Role.COACH) || current.roles?.includes(Role.ADMIN) ?
            <div className={styles.switchItem}>
              <div className={styles.switchText}>
                <span style={{ paddingRight: '8px' }}>Рейтинговая игра</span>
                <CoachWhistle fill='var(--gray-blue)'/>
              </div>
              <ToggleSwitch
                checked={formikValues?.ranking}
                id='ranking'
                name='ranking'
                onChange={handleToggle} />
            </div> : null
          }
          <div className={styles.switchItem}>
            <div className={styles.switchText}>
              <span style={{ paddingRight: '8px' }}>Все участники выставляют результаты</span>
            </div>
            <ToggleSwitch
              checked={formikValues?.everyoneCanSetScore}
              id='everyoneCanSetScore'
              name='everyoneCanSetScore'
              onChange={handleToggle} />
          </div>
        </div>
        <HLine />
        <div className={styles.commentWrap}>
          <TournamentInput
            label='Комментарии к игре'
            placeholder='Введите комментарий'
            name='description'
            handleChange={(val: string) => setFieldValue('description', val)}
            values={formikValues as any}
            enterKeyHint='done'
          />
        </div>
        <HLine />
      </div>
      <CourtNumberModal visible={showModal} onClose={onClose} setFieldValue={setFieldValue} />
    </>
  );
}

export default MatchDetails;
