import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { CalculatedGameStatus } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChatPage = () => {
  const { chatUid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (chatUid) {
      RestClient.mainApi.chatMessageLoadList({
        chatUid,
        limit: 0,
        offset: 0,
      })
        .then(({ result }) => {
          if ([CalculatedGameStatus.PLANNED, CalculatedGameStatus.STARTED].includes(result?.relatedEntityStatus!)) {
            navigate(`/game/${result?.relatedEntityUid}/planned/chat?uid=${chatUid}`);
          } else {
            navigate('/');
            PopupMessage.open('Чат закрыт', 'error');
          }
        })
        .catch(() => navigate('/'));
    }
  }, [])

  return null;
}
 
export default ChatPage;
