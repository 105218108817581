type MaleIconProps = {
  fill?: string;
}

const MaleIcon = ({ fill }: MaleIconProps) => {
  return ( 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 7H10C8.9 7 8 7.9 8 9V15H10.5V22H13.5V15H16V9C16 7.9 15.1 7 14 7Z" fill={fill ?? "#C3D82E"}/>
      <path d="M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default MaleIcon;
