/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FirebaseSubscriptionParameters,
    FirebaseSubscriptionParametersFromJSON,
    FirebaseSubscriptionParametersFromJSONTyped,
    FirebaseSubscriptionParametersToJSON,
} from './FirebaseSubscriptionParameters';

/**
 * 
 * @export
 * @interface FirebaseNotificationsSubscribeRequest
 */
export interface FirebaseNotificationsSubscribeRequest {
    /**
     * 
     * @type {FirebaseSubscriptionParameters}
     * @memberof FirebaseNotificationsSubscribeRequest
     */
    parameters?: FirebaseSubscriptionParameters;
}

export function FirebaseNotificationsSubscribeRequestFromJSON(json: any): FirebaseNotificationsSubscribeRequest {
    return FirebaseNotificationsSubscribeRequestFromJSONTyped(json, false);
}

export function FirebaseNotificationsSubscribeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirebaseNotificationsSubscribeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : FirebaseSubscriptionParametersFromJSON(json['parameters']),
    };
}

export function FirebaseNotificationsSubscribeRequestToJSON(value?: FirebaseNotificationsSubscribeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': FirebaseSubscriptionParametersToJSON(value.parameters),
    };
}

