const SetWinnerArrowIcon = () => {
  return (  
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.66683 6.90872L2.74068 12L1.3335 10.5456L7.66683 4L14.0002 10.5456L12.592 12L7.66583 6.90872H7.66683Z" fill="#4EB857"/>
      <path d="M7.66683 12.9087L2.74068 18L1.3335 16.5456L7.66683 10L14.0002 16.5456L12.592 18L7.66583 12.9087H7.66683Z" fill="#4EB857"/>
    </svg>
  );
}
 
export default SetWinnerArrowIcon;
