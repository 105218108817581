function RatingLogo() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5573 12.7048L20.4 4M11.4488 12.8976L7 4M15.0008 12.2956L10.953 4M16.648 4L15.576 6.57692M8.03077 18.4308C8.03077 20.071 8.68236 21.6441 9.8422 22.804C11.002 23.9638 12.5751 24.6154 14.2154 24.6154C15.8556 24.6154 17.4287 23.9638 18.5886 22.804C19.7484 21.6441 20.4 20.071 20.4 18.4308C20.4 16.7905 19.7484 15.2174 18.5886 14.0576C17.4287 12.8977 15.8556 12.2462 14.2154 12.2462C12.5751 12.2462 11.002 12.8977 9.8422 14.0576C8.68236 15.2174 8.03077 16.7905 8.03077 18.4308Z" stroke="#B9B9B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.6689 17.3999L14.7305 15.8538V21.0076" stroke="#B9B9B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default RatingLogo;
