import { FC, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import styles from './Autosuggest.module.css'
import arrowInput from "@/static/images/icons/input-arrow.svg";
import closeIcon from '@/static/images/icons/input-close-icon.svg';

export interface AutoSuggestProps {
  values: Array<any>;
  onInputChange?: (value: string) => void;
  defaultSuggestValue?: string;
  placeholder: string;
}
const AutoSuggest: FC<AutoSuggestProps> = ({ values, placeholder, onInputChange, defaultSuggestValue }) => {
  const [value, setValue] = useState(defaultSuggestValue || '');
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const suggestionsElements = values.map(val => {
    return {
      id: val,
      name: val,
    };
  });

  function getSuggestions(value: string) {
    let filteredElements: typeof suggestionsElements;
    if (value === '') {
      filteredElements = suggestionsElements
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
      }).slice(0, 5);
    } else {
      filteredElements = suggestionsElements.filter(val => new RegExp(value, 'i').test(val.name));
    }

    return filteredElements.length ? filteredElements : [];
  }

  return (
    <div className={styles.autoSugWrap}>
      <Autosuggest
        suggestions={suggestions}
        focusInputOnSuggestionClick={false}
        onSuggestionSelected={(_, { suggestionValue }) => {
          onInputChange?.(suggestionValue);
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        // Будет запускать саджест при любых событиях, ограничьте, если требуется
        // https://github.com/moroshko/react-autosuggest?tab=readme-ov-file#should-render-suggestions-prop
        shouldRenderSuggestions={() => true}
        getSuggestionValue={suggestion => suggestion.name}
        inputProps={{
          className: `${styles.autosuggest}`,
          placeholder,
          value: value,
          onChange: (_, { newValue }) => {
            setValue(newValue);
            if(!newValue) {
              onInputChange?.(newValue);
            }
          },
         }}
        renderInputComponent={(inputProps: Omit<Autosuggest.RenderInputComponentProps, "ref">) =>
          <div>
            <input 
              {...inputProps} 
              enterKeyHint='done' 
              ref={inputRef}
              onKeyDown={e => {
                if(e.key === 'Enter') {
                  e.preventDefault();
                  (e.target as HTMLInputElement).blur();
                  setSuggestions([]);
                  const isValidValue = suggestions.find(sugg => sugg.name === (e.target as HTMLInputElement).value);
                  if(isValidValue) {
                    onInputChange?.(isValidValue.name);
                  }
                }
              }} 
            />
            {value ?
              <img src={closeIcon} onClick={() => {
                if(inputRef.current) {
                  inputRef.current?.focus();
                  onInputChange?.('');
                  setValue('');
                  setSuggestions(getSuggestions(''));
                }
              }} alt='стрелка ввести' />
              : <img src={arrowInput} alt='стрелка ввести' onClick={() => inputRef.current?.focus()} />
            }
          </div>
        }
        renderSuggestion={suggestion => <div className={styles.suggestion}>{suggestion.name}</div>}
        containerProps={{ className: `${styles.container}` }}
        renderSuggestionsContainer={({children, containerProps}) => <div {...containerProps} className={`${styles.suggestionsContainer} ${containerProps.className}}`}>{children}</div>}
        highlightFirstSuggestion={true}
      />
    </div>
  );
}

export default AutoSuggest;
