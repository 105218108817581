import styles from './GameMinitournamentCard.module.css';
import { HTMLAttributes } from 'react';
import ProfileCard from '../ProfileCard/ProfileCard';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import RatingBage from '../Badge/RatingBage/RatingBage';
import { GameElementInfo, Role } from '@/integration-api/server-rest-lundapadelApi';
import formatter from '@/utils/utils';
import { calculateEndTime } from '@/pages/application/create/components/MatchResultSettings/utils';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import { translateGameTournamentKind } from '../GameListCard/utils';
import GameCountBage from '../Badge/GameCountBage/GameCountBage';

type GameMinitournamentCardProps = {
  minitournament: GameElementInfo;
} & HTMLAttributes<HTMLDivElement>;

const GameMinitournamentCard = ({ minitournament, ...props }: GameMinitournamentCardProps) => {
  const shownPlayers = minitournament.members?.reduce<Array<JSX.Element>>((acc, current, index) => {
    if(index < 4) {
      acc.push(
        <ProfileCard
          key={current.uid}
          player={current}
          noPreferedSide
          fullName
          bage={[{
            element: index !== 3 ? <RatingBage rating={current.displayRating ?? ''}/> : <></>,
            position: 'top-right'
          }, {
            element: current.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
            position: 'top-left'
          }, {
            element: index === 3 ? <GameCountBage playersLimit={8}/> : <></>,
            position: 'middle-center'
          }]}
        />
      );
    }
    return acc;
  }, []);

  return (  
    <div {...props} className={styles['card']}>
      <div className={styles['lineup']}>
        <div className={styles['team']}>
          {shownPlayers?.slice(0, 2)}
        </div>
        <div className={styles['team']}>
          {shownPlayers?.slice(2, 4)}
        </div>
      </div>
      <div className={styles['details']}>
        <span>{formatter.formatDateToDayDateMonthWithParentheses(minitournament.plannedDate)}</span>
        <span>{formatter.formatDate(minitournament.plannedDate!, TIME_FORMAT_HH_MM)} {calculateEndTime(formatter.formatDate(minitournament.plannedDate!, TIME_FORMAT_HH_MM), minitournament.duration!)}</span>
        <span>{minitournament.clubName}</span>
        <span className={styles['kind']}>{translateGameTournamentKind(minitournament.tournamentKind!)}</span>
        <span className={styles['kind']}>{minitournament.place} место</span>
        <div className={styles['grades']}>
          {minitournament.displayMinGrade === minitournament.displayMaxGrade ?
            <span>{minitournament.displayMinGrade}</span> :
            <span>{minitournament.displayMinGrade}...{minitournament.displayMaxGrade}</span>
          }
        </div>
      </div>
    </div>
  );
}
 
export default GameMinitournamentCard;
