import { CSSBadgePositionType } from './UserAvatar.interfaces'

export const badgePosition: CSSBadgePositionType = {
  "top-left": {
    top: 0,
    left: 0,
    transform: 'translate(-25%, -25%)',
  },
  "top-center": {
    top: 0,
    left: 0,
    maxWidth: '100%',
    width: '100%',
    transform: 'translateY(-50%)',
  },
  "top-right": {
    top: 0,
    right: 0,
    transform: 'translate(25%, -25%)',
  },
  "middle-left": {
    top: 0,
    left: 0,
    maxHeight: '100%',
    height: '100%',
    transform: 'translateX(-50%)',
  },
  "middle-center": {
    top: 0,
    left: 0,
    maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    height: '100%'
  },
  "middle-right": {
    top: 0,
    right: 0,
    maxHeight: '100%',
    height: '100%',
    transform: 'translateX(50%)',
  },
  "bottom-left": {
    bottom: 0,
    left: 0,
    transform: 'translate(-25%, 25%)',
  },
  "bottom-center": {
    bottom: 0,
    left: 0,
    maxWidth: '100%',
    width: '100%',
    transform: 'translateY(50%)',
  },
  "bottom-right": {
    bottom: 0,
    right: 0,
    transform: 'translate(25%, 25%)',
  },
}

export const funnyAvatarHostname = ['10.0.3.19', 'app.lundapadel-dev.gridnine.com', 'localhost']
