/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentMemberView,
    TournamentMemberViewFromJSON,
    TournamentMemberViewFromJSONTyped,
    TournamentMemberViewToJSON,
} from './TournamentMemberView';

/**
 * 
 * @export
 * @interface TournamentClassicSaveTableParam
 */
export interface TournamentClassicSaveTableParam {
    /**
     * 
     * @type {string}
     * @memberof TournamentClassicSaveTableParam
     */
    tournamentUid?: string;
    /**
     * 
     * @type {Array<TournamentMemberView>}
     * @memberof TournamentClassicSaveTableParam
     */
    members?: Array<TournamentMemberView>;
}

export function TournamentClassicSaveTableParamFromJSON(json: any): TournamentClassicSaveTableParam {
    return TournamentClassicSaveTableParamFromJSONTyped(json, false);
}

export function TournamentClassicSaveTableParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentClassicSaveTableParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentUid': !exists(json, 'tournamentUid') ? undefined : json['tournamentUid'],
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(TournamentMemberViewFromJSON)),
    };
}

export function TournamentClassicSaveTableParamToJSON(value?: TournamentClassicSaveTableParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentUid': value.tournamentUid,
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(TournamentMemberViewToJSON)),
    };
}

