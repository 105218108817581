import styles from './CompletedContentFiltersModal.module.css';
import FullScreenModal from "@/components/FullScreenModal/FullScreenModal";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch";
import { useFormik } from "formik";
import { CompletedContentFilters } from '../CompletedContent/CompletedContent';

type CompletedContentFiltersModalProps = {
  modalVisible: boolean;
  handleSubmit: (values: CompletedContentFilters) => void;
}

const CompletedContentFiltersModal = ({ modalVisible, handleSubmit }: CompletedContentFiltersModalProps) => {
  const formik = useFormik<CompletedContentFilters>({
    initialValues: {
      showCanceled: false
    },
    onSubmit: submitForm
  });

  function submitForm(values: CompletedContentFilters) {
    handleSubmit(values);
  }

  return (  
    <FullScreenModal visible={modalVisible} onDone={formik.handleSubmit} title='Фильтр'>
      <form>
        <label className={styles['switcher-wrapper']}>
          <span className={styles['switcher-label']}>Показать отмененные игры</span>
          <ToggleSwitch
            id='showCanceled'
            name='showCanceled'
            onChange={formik.handleChange}
            checked={formik.values.showCanceled}
          />
        </label>
      </form>
    </FullScreenModal>
  );
}
 
export default CompletedContentFiltersModal;
