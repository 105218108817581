import { useCallback } from 'react';
import HomePageContent from './components/HomePageContent/HomePageContent';
import Notifications from './components/Notifications/Notifications';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import { useSearchParams } from 'react-router-dom';

function HomePage(q: React.PropsWithChildren): JSX.Element {
  const [searcParams, setSearchParams] = useSearchParams();

  const onOpenNotifications = useCallback(() => {
    setSearchParams(`notifications=true`)
  }, []);

  const onCloseNotifications = useCallback(() => {
    setSearchParams('');
  }, []);

  return (
    <BasePageWrapper showNavBar>
      {searcParams.get('notifications') === 'true' ? (
        <Notifications onCloseNotifications={onCloseNotifications} />
      ) : (
        <HomePageContent onOpenNotifications={onOpenNotifications} />
      )}
    </BasePageWrapper>
  );
}

export default HomePage;
