import { createContext } from "react";
import { IGameLineupsContext, IGameResultsContext, ILineupScoreContext } from "./GameResultsPage.interfaces";
import { GameLineupView } from "@/integration-api/server-rest-lundapadelApi";

export const gameResultsContextDefaultValue: IGameResultsContext = {
  step: 'lineup',
  playersPool: [],
  lineups: [],
  game: {}
}
export const GameResultsContext = createContext<IGameResultsContext>(gameResultsContextDefaultValue);

export const LineupScoreContext = createContext<ILineupScoreContext>({ matchScores: [], setScores: [] });

export const GameLineupsContext = createContext<IGameLineupsContext>({
  editableLineups: [],
  handleEditableLineups: () => {},
});

export const areLineupsFull = (lineups: Array<GameLineupView>) => {
  return !lineups.some(lp => !lp.player1 || !lp.player2 || !lp.player3 || !lp.player4);
}
