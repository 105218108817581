import React, {
  ChangeEvent,
  ClipboardEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import HLWrapper from '../HLWrapper/HLWrapper';
import Input from '../Input/Input';
import styles from './InputPhone.module.css';
import phoneLogo from '../../static/images/icons/phone-logo.svg';
import { COUNTRIES_CODES } from './utils';

export type InputPhoneValue = {
  countryCode: string;
  phone: string;
};

interface InputPhoneProps {
  initialValue?: InputPhoneValue;
  onChange?: (newValue: InputPhoneValue) => void;
  onEnterHandler?: () => void;
}

const InputPhone: ForwardRefRenderFunction<HTMLInputElement, InputPhoneProps> = (props, ref) => {
  const { initialValue, onChange, onEnterHandler } = props;

  const [innerCountryCode, setInnerCountryCode] = useState<string>('+7');
  const [innerPhone, setInnerPhone] = useState<string>('');

  useEffect(() => {
    if (initialValue) {
      const { countryCode = '+7', phone = '' } = initialValue;
      setInnerCountryCode(countryCode);
      setInnerPhone(phone);
    }
  }, [initialValue]);

  const changeCountryCode = useCallback(
    (countryCode: string) => {
      onChange &&
        onChange({
          countryCode,
          phone: innerPhone,
        });
    },
    [innerPhone, onChange]
  );

  const changePhoneNumber = useCallback(
    (phone: string) => {
      onChange &&
        onChange({
          countryCode: innerCountryCode,
          phone,
        });
    },
    [innerCountryCode, onChange]
  );

  const onEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event && event.key === 'Enter') {
        onEnterHandler && onEnterHandler();
      }
    },
    [onEnterHandler]
  );

  return (
    <HLWrapper>
      <div className={styles.phoneInput}>
        <label className={styles.phoneLabel}>
          <>
            <img
              src={COUNTRIES_CODES[innerCountryCode as keyof typeof COUNTRIES_CODES].img}
              alt="Coutry"
              className={styles.phoneSelectCoutry}
            />
            <select
              className={styles.phoneCodeSelect}
              value={innerCountryCode}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const countryCode = event.target.value;
                setInnerCountryCode(countryCode);
                changeCountryCode(countryCode);
              }}
            >
              {Object.keys(COUNTRIES_CODES).map((value, index) => (
                <option key={`${value}_${index}`}>{value}</option>
              ))}
            </select>
          </>
        </label>
        <Input
          icon={<img src={phoneLogo} alt="Phone" />}
          maxLength={10}
          type="tel"
          value={innerPhone}
          onKeyDown={onEnterPress}
          onPaste={(event: ClipboardEvent) => {
            const phoneNumber = (event.target as HTMLInputElement).value;
            setInnerPhone(phoneNumber);
            changePhoneNumber(phoneNumber);
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const phoneNumber = event.target.value;
            setInnerPhone(phoneNumber);
            changePhoneNumber(phoneNumber);
          }}
          enterKeyHint='next'
          ref={ref}
        />
      </div>
    </HLWrapper>
  );
};

export default forwardRef(InputPhone);
