/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfileStatisticsParam,
    ProfileStatisticsParamFromJSON,
    ProfileStatisticsParamFromJSONTyped,
    ProfileStatisticsParamToJSON,
} from './ProfileStatisticsParam';

/**
 * 
 * @export
 * @interface LoadProfileStatisticRequest
 */
export interface LoadProfileStatisticRequest {
    /**
     * 
     * @type {ProfileStatisticsParam}
     * @memberof LoadProfileStatisticRequest
     */
    parameters?: ProfileStatisticsParam;
}

export function LoadProfileStatisticRequestFromJSON(json: any): LoadProfileStatisticRequest {
    return LoadProfileStatisticRequestFromJSONTyped(json, false);
}

export function LoadProfileStatisticRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadProfileStatisticRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : ProfileStatisticsParamFromJSON(json['parameters']),
    };
}

export function LoadProfileStatisticRequestToJSON(value?: LoadProfileStatisticRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': ProfileStatisticsParamToJSON(value.parameters),
    };
}

