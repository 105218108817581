import { RootState } from "../models/store";
import { EnhancedStore } from "@reduxjs/toolkit/dist/configureStore";


export class StateProvider {

  static store: any = {
    dispatch: (action: any) => {
      throw new Error(`Store is not initialized`, action);
    },
  };

  static setStore(store: EnhancedStore<RootState>) {
    this.store = store;
  }

  static getStore() {
    return this.store;
  }
}
