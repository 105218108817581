/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentInviteView,
    TournamentInviteViewFromJSON,
    TournamentInviteViewFromJSONTyped,
    TournamentInviteViewToJSON,
} from './TournamentInviteView';

/**
 * 
 * @export
 * @interface CreateTournamentInviteListParam
 */
export interface CreateTournamentInviteListParam {
    /**
     * 
     * @type {string}
     * @memberof CreateTournamentInviteListParam
     */
    tournamentUid?: string;
    /**
     * 
     * @type {Array<TournamentInviteView>}
     * @memberof CreateTournamentInviteListParam
     */
    invitations?: Array<TournamentInviteView>;
}

export function CreateTournamentInviteListParamFromJSON(json: any): CreateTournamentInviteListParam {
    return CreateTournamentInviteListParamFromJSONTyped(json, false);
}

export function CreateTournamentInviteListParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTournamentInviteListParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentUid': !exists(json, 'tournamentUid') ? undefined : json['tournamentUid'],
        'invitations': !exists(json, 'invitations') ? undefined : ((json['invitations'] as Array<any>).map(TournamentInviteViewFromJSON)),
    };
}

export function CreateTournamentInviteListParamToJSON(value?: CreateTournamentInviteListParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentUid': value.tournamentUid,
        'invitations': value.invitations === undefined ? undefined : ((value.invitations as Array<any>).map(TournamentInviteViewToJSON)),
    };
}

