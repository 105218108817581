/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    InviteRule,
    InviteRuleFromJSON,
    InviteRuleFromJSONTyped,
    InviteRuleToJSON,
} from './InviteRule';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface PlayerProfileInfo
 */
export interface PlayerProfileInfo {
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    avatarUid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    displayRating?: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileInfo
     */
    rating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileInfo
     */
    subscribed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    nameInitials?: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileInfo
     */
    ratingPosition?: number;
    /**
     * 
     * @type {Grade}
     * @memberof PlayerProfileInfo
     */
    grade?: Grade;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileInfo
     */
    gradeVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    displayGrade?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileInfo
     */
    leftSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileInfo
     */
    rightSide?: boolean;
    /**
     * 
     * @type {InviteRule}
     * @memberof PlayerProfileInfo
     */
    inviteRule?: InviteRule;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileInfo
     */
    invitable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileInfo
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileInfo
     */
    ratingChangeForPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileInfo
     */
    displayRatingChangeForPeriod?: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof PlayerProfileInfo
     */
    roles?: Array<Role>;
}

export function PlayerProfileInfoFromJSON(json: any): PlayerProfileInfo {
    return PlayerProfileInfoFromJSONTyped(json, false);
}

export function PlayerProfileInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'avatarUid': !exists(json, 'avatarUid') ? undefined : json['avatarUid'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'displayRating': !exists(json, 'displayRating') ? undefined : json['displayRating'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'subscribed': !exists(json, 'subscribed') ? undefined : json['subscribed'],
        'nameInitials': !exists(json, 'nameInitials') ? undefined : json['nameInitials'],
        'ratingPosition': !exists(json, 'ratingPosition') ? undefined : json['ratingPosition'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'gradeVerified': !exists(json, 'gradeVerified') ? undefined : json['gradeVerified'],
        'displayGrade': !exists(json, 'displayGrade') ? undefined : json['displayGrade'],
        'leftSide': !exists(json, 'leftSide') ? undefined : json['leftSide'],
        'rightSide': !exists(json, 'rightSide') ? undefined : json['rightSide'],
        'inviteRule': !exists(json, 'inviteRule') ? undefined : InviteRuleFromJSON(json['inviteRule']),
        'invitable': !exists(json, 'invitable') ? undefined : json['invitable'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'ratingChangeForPeriod': !exists(json, 'ratingChangeForPeriod') ? undefined : json['ratingChangeForPeriod'],
        'displayRatingChangeForPeriod': !exists(json, 'displayRatingChangeForPeriod') ? undefined : json['displayRatingChangeForPeriod'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleFromJSON)),
    };
}

export function PlayerProfileInfoToJSON(value?: PlayerProfileInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'avatarUid': value.avatarUid,
        'displayName': value.displayName,
        'displayRating': value.displayRating,
        'rating': value.rating,
        'subscribed': value.subscribed,
        'nameInitials': value.nameInitials,
        'ratingPosition': value.ratingPosition,
        'grade': GradeToJSON(value.grade),
        'gradeVerified': value.gradeVerified,
        'displayGrade': value.displayGrade,
        'leftSide': value.leftSide,
        'rightSide': value.rightSide,
        'inviteRule': InviteRuleToJSON(value.inviteRule),
        'invitable': value.invitable,
        'active': value.active,
        'ratingChangeForPeriod': value.ratingChangeForPeriod,
        'displayRatingChangeForPeriod': value.displayRatingChangeForPeriod,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleToJSON)),
    };
}

