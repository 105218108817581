type WarningIconProps = {
  fill?: string
}

const WarningIcon = ({ fill }: WarningIconProps) => {
  return (  
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 8C14 4.6875 11.3125 2 8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8Z" stroke={fill ?? "#CE2020"} strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M7.8205 5.18946L7.99987 9.0004L8.17893 5.18946C8.18004 5.1651 8.17617 5.14077 8.16755 5.11795C8.15893 5.09514 8.14575 5.07432 8.12882 5.05677C8.11189 5.03922 8.09155 5.02531 8.06906 5.01589C8.04656 5.00646 8.02238 5.00172 7.998 5.00196C7.9739 5.00219 7.9501 5.00727 7.92801 5.0169C7.90591 5.02653 7.88599 5.04051 7.86942 5.05801C7.85286 5.07551 7.83999 5.09617 7.83158 5.11876C7.82317 5.14134 7.8194 5.16539 7.8205 5.18946Z" stroke={fill ?? "#CE2020"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 11.498C7.87639 11.498 7.75555 11.4614 7.65277 11.3927C7.54999 11.324 7.46988 11.2264 7.42258 11.1122C7.37527 10.998 7.36289 10.8724 7.38701 10.7511C7.41113 10.6299 7.47065 10.5185 7.55806 10.4311C7.64547 10.3437 7.75683 10.2842 7.87807 10.2601C7.99931 10.2359 8.12497 10.2483 8.23918 10.2956C8.35338 10.3429 8.45099 10.423 8.51967 10.5258C8.58834 10.6286 8.625 10.7494 8.625 10.873C8.625 11.0388 8.55915 11.1978 8.44194 11.315C8.32473 11.4322 8.16576 11.498 8 11.498Z" fill={fill ?? "#CE2020"}/>
    </svg>
  );
}
 
export default WarningIcon;
