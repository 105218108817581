const MedalIconSmall = () => {
  return (  
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.71594 0.596073C9.56406 0.731072 8.38281 3.07529 8.38281 3.07529L7.50391 6.62045L10.9239 6.30685C10.9239 6.30685 13.7687 1.01654 13.8264 0.894198C13.9291 0.67201 13.9839 0.580604 13.5916 0.580604C13.1992 0.579198 9.85797 0.46951 9.71594 0.596073Z" fill="#176CC7"/>
      <path d="M11.4871 6.08752C11.3169 5.99612 6.30504 5.91737 6.18692 6.08752C6.08004 6.24221 6.14051 7.05361 6.18129 7.15065C6.25442 7.3194 7.86457 7.9733 7.86457 7.9733V8.37971C7.86457 8.37971 7.97426 8.42471 8.91364 8.42471C9.85301 8.42471 10.04 8.3769 10.04 8.3769L10.0471 7.98596C10.0471 7.98596 11.4449 7.26596 11.518 7.16612C11.5827 7.08315 11.6572 6.17893 11.4871 6.08752ZM10.033 7.37283C10.033 7.37283 10.0527 7.17877 9.95567 7.13096C9.85864 7.08174 8.18098 7.09018 8.04879 7.09721C7.91661 7.10424 7.91661 7.34049 7.91661 7.34049L6.86614 6.78502L6.84786 6.5994L10.7769 6.62049L10.7854 6.8708L10.033 7.37283Z" fill="#FCC11A"/>
      <path d="M8.30234 7.29004C8.17016 7.29004 8.07031 7.4391 8.07031 7.67535C8.07031 7.89754 8.14625 8.08035 8.31359 8.07051C8.4725 8.06207 8.54844 7.86098 8.53719 7.67254C8.52312 7.45176 8.46969 7.29004 8.30234 7.29004Z" fill="#FDFFFF"/>
      <path d="M4.12109 12.9498C4.12109 16.3192 7.17406 17.7212 9.20047 17.6608C11.5616 17.5905 14.1013 15.7989 13.8917 12.5842C13.6948 9.5678 11.1228 8.1278 8.90375 8.14468C6.32469 8.16296 4.12109 10.0066 4.12109 12.9498Z" fill="#F8932A"/>
      <path d="M9.07306 17.0566C9.0379 17.0566 9.00134 17.0566 8.96619 17.0552C7.9115 17.02 6.83994 16.6221 6.07775 15.8838C5.26212 15.0935 4.75869 14.0936 4.75587 12.9447C4.74884 9.92691 7.33494 8.81738 8.99853 8.81738H9.01259C11.2668 8.82723 13.2145 10.5119 13.2651 12.8871C13.2876 13.9446 12.8545 15.0639 12.0051 15.8993C11.2134 16.6769 10.0448 17.0566 9.07306 17.0566Z" fill="#FCC11A"/>
      <path d="M3.58765 0.522817C3.49905 0.60438 6.88671 6.63719 6.88671 6.63719C6.88671 6.63719 7.45483 6.71032 8.72327 6.72157C9.99171 6.73282 10.6203 6.64844 10.6203 6.64844C10.6203 6.64844 7.98639 0.583286 7.82889 0.522817C7.75155 0.49188 6.70811 0.48063 5.68858 0.46938C4.66765 0.456724 3.67202 0.444067 3.58765 0.522817Z" fill="#2E9DF4"/>
      <path d="M7.35409 9.47135C7.14878 9.29557 6.10394 9.82572 5.36987 11.0393C4.87769 11.8535 4.8355 12.8956 5.14909 12.9124C5.46269 12.9293 5.45847 11.8985 6.1405 10.9971C6.96456 9.91291 7.65081 9.72588 7.35409 9.47135Z" fill="#FEFFFA"/>
      <path d="M9.05272 9.4082C8.7785 9.4082 8.351 11.2209 8.01491 11.4445C7.67881 11.668 5.93788 11.4543 5.91819 11.699C5.8985 11.9437 7.21053 12.9618 7.36381 13.3373C7.51709 13.7127 6.70147 15.3932 6.92647 15.5563C7.15006 15.7195 8.55491 14.548 9.02319 14.548C9.51116 14.548 10.7683 15.7982 10.9779 15.6266C11.1987 15.4466 10.4899 13.8041 10.601 13.4273C10.7135 13.0504 12.2393 12.246 12.1788 11.8804C12.1183 11.5134 10.3465 11.789 10.0202 11.4838C9.69397 11.1787 9.34803 9.4082 9.05272 9.4082Z" fill="#F8932A"/>
    </svg>
  );
}
 
export default MedalIconSmall;
