/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';
import {
    ViewObjectIdentity,
    ViewObjectIdentityFromJSON,
    ViewObjectIdentityFromJSONTyped,
    ViewObjectIdentityToJSON,
} from './ViewObjectIdentity';

/**
 * 
 * @export
 * @interface SetFavoriteClubsParameters
 */
export interface SetFavoriteClubsParameters {
    /**
     * 
     * @type {ViewObjectIdentity}
     * @memberof SetFavoriteClubsParameters
     */
    identity?: ViewObjectIdentity;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof SetFavoriteClubsParameters
     */
    clubs?: Array<Reference>;
}

export function SetFavoriteClubsParametersFromJSON(json: any): SetFavoriteClubsParameters {
    return SetFavoriteClubsParametersFromJSONTyped(json, false);
}

export function SetFavoriteClubsParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetFavoriteClubsParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': !exists(json, 'identity') ? undefined : ViewObjectIdentityFromJSON(json['identity']),
        'clubs': !exists(json, 'clubs') ? undefined : ((json['clubs'] as Array<any>).map(ReferenceFromJSON)),
    };
}

export function SetFavoriteClubsParametersToJSON(value?: SetFavoriteClubsParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': ViewObjectIdentityToJSON(value.identity),
        'clubs': value.clubs === undefined ? undefined : ((value.clubs as Array<any>).map(ReferenceToJSON)),
    };
}

