/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    PlayerProfileField,
    PlayerProfileFieldFromJSON,
    PlayerProfileFieldFromJSONTyped,
    PlayerProfileFieldToJSON,
} from './PlayerProfileField';

/**
 * 
 * @export
 * @interface PlayerProfileLoadListParameters
 */
export interface PlayerProfileLoadListParameters {
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileLoadListParameters
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileLoadListParameters
     */
    limit?: number;
    /**
     * 
     * @type {PlayerProfileField}
     * @memberof PlayerProfileLoadListParameters
     */
    sortBy?: PlayerProfileField;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileLoadListParameters
     */
    sortDesc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileLoadListParameters
     */
    textPattern?: string;
    /**
     * 
     * @type {Grade}
     * @memberof PlayerProfileLoadListParameters
     */
    lowerRatingFilter?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof PlayerProfileLoadListParameters
     */
    upperRatingFilter?: Grade;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileLoadListParameters
     */
    cityFilter?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileLoadListParameters
     */
    includeSelf?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileLoadListParameters
     */
    onlyPartners?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileLoadListParameters
     */
    onlyUnverified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileLoadListParameters
     */
    matchContext?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlayerProfileLoadListParameters
     */
    excludedUids?: Array<string>;
}

export function PlayerProfileLoadListParametersFromJSON(json: any): PlayerProfileLoadListParameters {
    return PlayerProfileLoadListParametersFromJSONTyped(json, false);
}

export function PlayerProfileLoadListParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileLoadListParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'sortBy': !exists(json, 'sortBy') ? undefined : PlayerProfileFieldFromJSON(json['sortBy']),
        'sortDesc': !exists(json, 'sortDesc') ? undefined : json['sortDesc'],
        'textPattern': !exists(json, 'textPattern') ? undefined : json['textPattern'],
        'lowerRatingFilter': !exists(json, 'lowerRatingFilter') ? undefined : GradeFromJSON(json['lowerRatingFilter']),
        'upperRatingFilter': !exists(json, 'upperRatingFilter') ? undefined : GradeFromJSON(json['upperRatingFilter']),
        'cityFilter': !exists(json, 'cityFilter') ? undefined : json['cityFilter'],
        'includeSelf': !exists(json, 'includeSelf') ? undefined : json['includeSelf'],
        'onlyPartners': !exists(json, 'onlyPartners') ? undefined : json['onlyPartners'],
        'onlyUnverified': !exists(json, 'onlyUnverified') ? undefined : json['onlyUnverified'],
        'matchContext': !exists(json, 'matchContext') ? undefined : json['matchContext'],
        'excludedUids': !exists(json, 'excludedUids') ? undefined : json['excludedUids'],
    };
}

export function PlayerProfileLoadListParametersToJSON(value?: PlayerProfileLoadListParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'sortBy': PlayerProfileFieldToJSON(value.sortBy),
        'sortDesc': value.sortDesc,
        'textPattern': value.textPattern,
        'lowerRatingFilter': GradeToJSON(value.lowerRatingFilter),
        'upperRatingFilter': GradeToJSON(value.upperRatingFilter),
        'cityFilter': value.cityFilter,
        'includeSelf': value.includeSelf,
        'onlyPartners': value.onlyPartners,
        'onlyUnverified': value.onlyUnverified,
        'matchContext': value.matchContext,
        'excludedUids': value.excludedUids,
    };
}

