import { CalculatedGameStatus, GameRole, GameView } from "@/integration-api/server-rest-lundapadelApi";

export const gameModifiableStatuses = [CalculatedGameStatus.PLANNED, CalculatedGameStatus.STARTED];
export const gameNonModifiableStatuses = [CalculatedGameStatus.AFTER_GAME, CalculatedGameStatus.FINISHED];

export function canUserInvite(game: Omit<GameView, 'tournamentStatus'>, current: string) {
  if(game?.owner?.uid === current) {
    return true;
  }
  if(game?.permissions?.inviteCreate?.includes?.(GameRole.PLAYER) && game?.members?.some?.(member => member?.player?.uid === current)) {
    return true;
  }
  return false;
}

export function canUserSetScore(game: GameView, current: string) {
  if(game?.owner?.uid === current) {
    return true;
  }
  if(game?.permissions?.scoringResult?.includes?.(GameRole.PLAYER) && game?.members?.some?.(member => member?.player?.uid === current)) {
    return true;
  }
  return false
}
