import { NavLink } from "react-router-dom"
import OversideWrapper from "@/components/OversideWrapper/OversideWrapper"
import Button from "@/components/Button/Button"
import createMatch from '@/static/images/icons/create-match-icon.svg'
import styles from "./CreateMatchBtn.module.css"
import { FC } from "react"
import { useAppSelector } from "@/hooks/hooks"
import { Role } from "@/integration-api/server-rest-lundapadelApi"

export interface CreateMatchBtnProps{
    navlink?:string,
}
const CreateMatchBtn:FC<CreateMatchBtnProps> = ({navlink}) => {
  const { roles } = useAppSelector(state => state.auth.currentProfile);
  return(
    <NavLink to={navlink ? navlink :'/create/game'}>
      <OversideWrapper>
        <Button className={styles.createMatchBtn}>
          <img src={createMatch} alt='плюс' className={styles.createMatchIcon} />
          {roles?.includes(Role.COACH) || roles?.includes(Role.ADMIN) ? 'Создать игру/турнир' : 'Создать игру'}
        </Button>
      </OversideWrapper>
    </NavLink>
  )
}

export default CreateMatchBtn
