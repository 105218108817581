import { FC, HTMLAttributes } from "react";
import styles from './UserAvatarVerified.module.css';
import verifiedIcon from '@/static/images/icons/verified-icon.svg'
;
interface UserAvatarVerifiedProps extends HTMLAttributes<HTMLDivElement>{

}
 
const UserAvatarVerified: FC<UserAvatarVerifiedProps> = ({className, ...props}) => {
  return ( 
    <img src={verifiedIcon} className={styles['rating']} alt="Verified" />
  );
}
 
export default UserAvatarVerified;
