import styles from './GamePlayersRadio.module.css';
import { gamePlayersOptions } from '../SelectTime/helper';
import { InputHTMLAttributes, useState } from 'react';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import LockLundaIcon from '@/static/images/icons/LockLundaIcon';
import LundaLockedModal from '../LundaLockedModal/LundaLockedModal';

type GamePlayersRadioProps = {
  playersLimit: number;
  lundaClub?: boolean;
} & InputHTMLAttributes<HTMLInputElement> 

const GamePlayersRadio = ({ playersLimit, lundaClub, ...props }: GamePlayersRadioProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (  
    <>    
      <InnerPadding className={styles['radio-wrapper']}>
        {gamePlayersOptions.map((option, index) => (
          <label 
            key={option} 
            htmlFor={`playersLimit-${option}`} 
            style={{ flex: 1 }}
            onClick={!lundaClub && (index === gamePlayersOptions.length - 1) ? () => setModalVisible(true) : undefined}
          >
            <input
              {...props}  
              type="radio" 
              id={`playersLimit-${option}`} 
              name='playersLimit' 
              value={option} 
              checked={playersLimit === option}
              disabled={!lundaClub && (index === gamePlayersOptions.length - 1)}
            />
            <div className={styles['radio-item']} style={!lundaClub && (index === gamePlayersOptions.length - 1) ? { background: 'rgba(44, 62, 79, 0.5)', color: 'var(--gray-light)' } : undefined}>
              {option}
            </div>
            {!lundaClub && (index === gamePlayersOptions.length - 1) ?
              <div className={styles['lock']}>
                <LockLundaIcon/>
              </div> : null
            }
          </label>
        ))}
      </InnerPadding>
      <LundaLockedModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
}
 
export default GamePlayersRadio;
