/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    NotificationRelatedEntityType,
    NotificationRelatedEntityTypeFromJSON,
    NotificationRelatedEntityTypeFromJSONTyped,
    NotificationRelatedEntityTypeToJSON,
} from './NotificationRelatedEntityType';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface NotificationInfo
 */
export interface NotificationInfo {
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    notificationUid?: string;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof NotificationInfo
     */
    sender?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof NotificationInfo
     */
    recipient?: PlayerProfileInfo;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationInfo
     */
    read?: boolean;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof NotificationInfo
     */
    timestamp?: LocalDateTime;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {NotificationRelatedEntityType}
     * @memberof NotificationInfo
     */
    relatedEntityType?: NotificationRelatedEntityType;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    matchClubName?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof NotificationInfo
     */
    matchPlannedDate?: LocalDateTime;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    clubName?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof NotificationInfo
     */
    plannedDate?: LocalDateTime;
}

export function NotificationInfoFromJSON(json: any): NotificationInfo {
    return NotificationInfoFromJSONTyped(json, false);
}

export function NotificationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationUid': !exists(json, 'notificationUid') ? undefined : json['notificationUid'],
        'sender': !exists(json, 'sender') ? undefined : PlayerProfileInfoFromJSON(json['sender']),
        'recipient': !exists(json, 'recipient') ? undefined : PlayerProfileInfoFromJSON(json['recipient']),
        'read': !exists(json, 'read') ? undefined : json['read'],
        'timestamp': !exists(json, 'timestamp') ? undefined : LocalDateTimeFromJSON(json['timestamp']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : NotificationRelatedEntityTypeFromJSON(json['relatedEntityType']),
        'matchClubName': !exists(json, 'matchClubName') ? undefined : json['matchClubName'],
        'matchPlannedDate': !exists(json, 'matchPlannedDate') ? undefined : LocalDateTimeFromJSON(json['matchPlannedDate']),
        'clubName': !exists(json, 'clubName') ? undefined : json['clubName'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
    };
}

export function NotificationInfoToJSON(value?: NotificationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationUid': value.notificationUid,
        'sender': PlayerProfileInfoToJSON(value.sender),
        'recipient': PlayerProfileInfoToJSON(value.recipient),
        'read': value.read,
        'timestamp': LocalDateTimeToJSON(value.timestamp),
        'description': value.description,
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': NotificationRelatedEntityTypeToJSON(value.relatedEntityType),
        'matchClubName': value.matchClubName,
        'matchPlannedDate': LocalDateTimeToJSON(value.matchPlannedDate),
        'clubName': value.clubName,
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
    };
}

