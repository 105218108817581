import {initializeApp} from "@firebase/app";
import {
  isSupported,
  getMessaging,
  getToken,
} from "@firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBDDOPjzPbvt2Mk3rn_wl8JLW-X1VEKaAE",
  authDomain: "lundapadel-3d402.firebaseapp.com",
  projectId: "lundapadel-3d402",
  storageBucket: "lundapadel-3d402.appspot.com",
  messagingSenderId: "383531207154",
  appId: "1:383531207154:web:d14f12e9dfd994f0dc836b",
  measurementId: "G-0X2QJM98RF"
};
const vapidKey =
  "BCPIB1eiXQxp1RXcF9tL7DUbjIoqzme5xah2awWjxU9DN-_g_0tgpa3ltM1AI8oUDN5sReHOKDliqNA-De3QYXM";

export class NotificationsService {
  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Don't crash an error if messaging not supported
      if (!isSupported()) {
        resolve();
        return;
      }

      try {
        Notification.requestPermission().then((value: any) => {
          if (value && value === "granted") {
            initFirebase(value);
          }
        });
      } catch (error) {
        // Safari doesn't return a promise for requestPermissions and it
        // throws a TypeError. It takes a callback as the first argument
        // instead.
        if (error instanceof TypeError) {
          Notification.requestPermission((value) => {
            initFirebase(value);
          });
        }
      }

      const initFirebase = (value: any) => {
        if (value && value === "granted") {

          const app = initializeApp(firebaseConfig);

          const messaging: any = getMessaging(app);

          getToken(messaging, {vapidKey}).then(token => {
            console.log("fb-web-token " + token)
            localStorage.setItem("fb-token", token);
          })
        }
      };
      resolve();
    });
  }
}
