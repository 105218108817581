import React, { ReactNode } from 'react';
import styles from './InputLabel.module.css';

interface InputLabelInterface extends React.PropsWithChildren {
  label?: string | ReactNode;
}

function InputLabel({ children, label }: InputLabelInterface) {
  return (
    <div className={styles.label}>
      <span className={styles.title}>{label}</span>
      {children}
    </div>
  );
}

export default InputLabel;
