/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailableSlot
 */
export interface AvailableSlot {
    /**
     * 
     * @type {string}
     * @memberof AvailableSlot
     */
    time?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableSlot
     */
    reserved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableSlot
     */
    gamePlanned?: boolean;
}

export function AvailableSlotFromJSON(json: any): AvailableSlot {
    return AvailableSlotFromJSONTyped(json, false);
}

export function AvailableSlotFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableSlot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : json['time'],
        'reserved': !exists(json, 'reserved') ? undefined : json['reserved'],
        'gamePlanned': !exists(json, 'gamePlanned') ? undefined : json['gamePlanned'],
    };
}

export function AvailableSlotToJSON(value?: AvailableSlot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'reserved': value.reserved,
        'gamePlanned': value.gamePlanned,
    };
}

