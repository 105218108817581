import styles from "./MobileCodeInput.module.css";
import { InitialCodeinputType } from "../helpers/types";
import ball from '@/static/images/icons/ball-icon.svg'

interface MobileCodeInputInterface {
  codeInput?: InitialCodeinputType;
}

function MobileCodeInput({ codeInput }: MobileCodeInputInterface) {

  return (
    <form className={styles.inputsWrapper}>
      <div className={styles.codeWrap}>
        {codeInput?.value[0] ?
          <div
            className={styles.codeNumber}>
            {codeInput.value[0]}
          </div>
          :
          <img src={ball} className={styles.ballIcon} />
        }
      </div>
      <div className={styles.codeWrap}>
        {codeInput?.value[1] ?
          <div
            className={styles.codeNumber}>
            {codeInput.value[1]}
          </div>
          :
          <img src={ball} className={styles.ballIcon} />
        }
      </div>
      <div className={styles.codeWrap}>
        {codeInput?.value[2] ?
          <div
            className={styles.codeNumber}>
            {codeInput.value[2]}
          </div>
          :
          <img src={ball} className={styles.ballIcon} />
        }
      </div>
      <div className={styles.codeWrap}>
        {codeInput?.value[3] ?
          <div
            className={styles.codeNumber}>
            {codeInput.value[3]}
          </div>
          :
          <img src={ball} className={styles.ballIcon} />
        }
      </div>
    </form>
  );
}

export default MobileCodeInput;
