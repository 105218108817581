import ru from './ru.png';
import by from './by.png';
import am from './am.png';
import de from './de.png';
import kz from './kz.png';
import nl from './nl.png';
import gb from './gb.png';
import fi from './fi.png';
import dk from './dk.png';
import se from './se.png';
import sj from './sj.png';
import mf from './mf.png';
import es from './es.png';
import pt from './pt.png';
import it from './it.png';
import hr from './hr.png';
import at from './at.png';
import si from './si.png';
import hu from './hu.png';
import rs from './rs.png';
import gr from './gr.png';
import sk from './sk.png';
import cz from './cz.png';
import pl from './pl.png';
import lt from './lt.png';
import lv from './lv.png';
import ee from './ee.png';
import tr from './tr.png';
import ge from './ge.png';
import az from './az.png';
import us from './us.png';
import qa from './qa.png';
import ae from './ae.png';
import il from './il.png';
import eg from './eg.png';
import sg from './sg.png';


export const countries = {
  am: {
    img: am,
    name: 'Армения',
  },
  by: {
    img: by,
    name: 'Беларусь',
  },
  de: {
    img: de,
    name: 'Германия',
  },
  kz:  {
    img: kz,
    name: 'Казахстан',
  },
  nl: {
    img: nl,
    name: 'Нидерланды',
  },
  ru: {
    img: ru,
    name: 'Россия',
  },
  gb: {
    img: gb,
    name: 'Великобритания',
  },
  fi: {
    img: fi,
    name: 'Финляндия',
  },
  dk: {
    img: dk,
    name: 'Дания',
  },
  se: {
    img: se,
    name: 'Швеция',
  },
  sj: {
    img: sj,
    name: 'Норвегия',
  },
  mf: {
    img: mf,
    name: 'Франция',
  },
  es: {
    img: es,
    name: 'Испания',
  },
  pt: {
    img: pt,
    name: 'Португалия',
  },
  it: {
    img: it,
    name: 'Италия',
  },
  hr: {
    img: hr,
    name: 'Хорватия',
  },
  at: {
    img: at,
    name: 'Австрия',
  },
  si: {
    img: si,
    name: 'Словения',
  },
  hu: {
    img: hu,
    name: 'Венгрия',
  },
  rs: {
    img: rs,
    name: 'Сербия',
  },
  gr: {
    img: gr,
    name: 'Греция',
  },
  sk: {
    img: sk,
    name: 'Словакия',
  },
  cz: {
    img: cz,
    name: 'Чехия',
  },
  pl: {
    img: pl,
    name: 'Польша',
  },
  lt: {
    img: lt,
    name: 'Литва',
  },
  lv: {
    img: lv,
    name: 'Латвия',
  },
  ee: {
    img: ee,
    name: 'Эстония',
  },
  tr: {
    img: tr,
    name: 'Турция',
  },
  ge: {
    img: ge,
    name: 'Грузия',
  },
  az: {
    img: az,
    name: 'Азербайджан',
  },
  us: {
    img: us,
    name: 'США',
  },
  qa: {
    img: qa,
    name: 'Катар',
  },
  ae: {
    img: ae,
    name: 'ОАЭ',
  },
  il: {
    img: il,
    name: 'Израиль',
  },
  eg: {
    img: eg,
    name: 'Египет',
  },
  sg: {
    img: sg,
    name: 'Сингапур',
  },
}
