/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';

/**
 * 
 * @export
 * @interface PlayerProfileDetailsView
 */
export interface PlayerProfileDetailsView {
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileDetailsView
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileDetailsView
     */
    lastName?: string;
    /**
     * 
     * @type {Gender}
     * @memberof PlayerProfileDetailsView
     */
    gender?: Gender;
    /**
     * 
     * @type {LocalDate}
     * @memberof PlayerProfileDetailsView
     */
    birthday?: LocalDate;
}

export function PlayerProfileDetailsViewFromJSON(json: any): PlayerProfileDetailsView {
    return PlayerProfileDetailsViewFromJSONTyped(json, false);
}

export function PlayerProfileDetailsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileDetailsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'birthday': !exists(json, 'birthday') ? undefined : LocalDateFromJSON(json['birthday']),
    };
}

export function PlayerProfileDetailsViewToJSON(value?: PlayerProfileDetailsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'gender': GenderToJSON(value.gender),
        'birthday': LocalDateToJSON(value.birthday),
    };
}

