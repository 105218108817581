/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FitnessLevel,
    FitnessLevelFromJSON,
    FitnessLevelFromJSONTyped,
    FitnessLevelToJSON,
} from './FitnessLevel';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    PlayedMatches,
    PlayedMatchesFromJSON,
    PlayedMatchesFromJSONTyped,
    PlayedMatchesToJSON,
} from './PlayedMatches';
import {
    RacketSportExperience,
    RacketSportExperienceFromJSON,
    RacketSportExperienceFromJSONTyped,
    RacketSportExperienceToJSON,
} from './RacketSportExperience';

/**
 * 
 * @export
 * @interface ProfileQuestionnaireView
 */
export interface ProfileQuestionnaireView {
    /**
     * 
     * @type {string}
     * @memberof ProfileQuestionnaireView
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileQuestionnaireView
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileQuestionnaireView
     */
    lastName?: string;
    /**
     * 
     * @type {Gender}
     * @memberof ProfileQuestionnaireView
     */
    gender?: Gender;
    /**
     * 
     * @type {LocalDate}
     * @memberof ProfileQuestionnaireView
     */
    birthday?: LocalDate;
    /**
     * 
     * @type {RacketSportExperience}
     * @memberof ProfileQuestionnaireView
     */
    experience?: RacketSportExperience;
    /**
     * 
     * @type {PlayedMatches}
     * @memberof ProfileQuestionnaireView
     */
    playedMatches?: PlayedMatches;
    /**
     * 
     * @type {FitnessLevel}
     * @memberof ProfileQuestionnaireView
     */
    fitnessLevel?: FitnessLevel;
    /**
     * 
     * @type {Grade}
     * @memberof ProfileQuestionnaireView
     */
    grade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof ProfileQuestionnaireView
     */
    tournamentParticipation?: Grade;
}

export function ProfileQuestionnaireViewFromJSON(json: any): ProfileQuestionnaireView {
    return ProfileQuestionnaireViewFromJSONTyped(json, false);
}

export function ProfileQuestionnaireViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileQuestionnaireView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'gender': !exists(json, 'gender') ? undefined : GenderFromJSON(json['gender']),
        'birthday': !exists(json, 'birthday') ? undefined : LocalDateFromJSON(json['birthday']),
        'experience': !exists(json, 'experience') ? undefined : RacketSportExperienceFromJSON(json['experience']),
        'playedMatches': !exists(json, 'playedMatches') ? undefined : PlayedMatchesFromJSON(json['playedMatches']),
        'fitnessLevel': !exists(json, 'fitnessLevel') ? undefined : FitnessLevelFromJSON(json['fitnessLevel']),
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'tournamentParticipation': !exists(json, 'tournamentParticipation') ? undefined : GradeFromJSON(json['tournamentParticipation']),
    };
}

export function ProfileQuestionnaireViewToJSON(value?: ProfileQuestionnaireView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'gender': GenderToJSON(value.gender),
        'birthday': LocalDateToJSON(value.birthday),
        'experience': RacketSportExperienceToJSON(value.experience),
        'playedMatches': PlayedMatchesToJSON(value.playedMatches),
        'fitnessLevel': FitnessLevelToJSON(value.fitnessLevel),
        'grade': GradeToJSON(value.grade),
        'tournamentParticipation': GradeToJSON(value.tournamentParticipation),
    };
}

