/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameInviteInfo,
    GameInviteInfoFromJSON,
    GameInviteInfoFromJSONTyped,
    GameInviteInfoToJSON,
} from './GameInviteInfo';

/**
 * 
 * @export
 * @interface GameInviteLoadListResult
 */
export interface GameInviteLoadListResult {
    /**
     * 
     * @type {number}
     * @memberof GameInviteLoadListResult
     */
    total?: number;
    /**
     * 
     * @type {Array<GameInviteInfo>}
     * @memberof GameInviteLoadListResult
     */
    infos?: Array<GameInviteInfo>;
}

export function GameInviteLoadListResultFromJSON(json: any): GameInviteLoadListResult {
    return GameInviteLoadListResultFromJSONTyped(json, false);
}

export function GameInviteLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameInviteLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(GameInviteInfoFromJSON)),
    };
}

export function GameInviteLoadListResultToJSON(value?: GameInviteLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(GameInviteInfoToJSON)),
    };
}

