/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatedGameStatus,
    CalculatedGameStatusFromJSON,
    CalculatedGameStatusFromJSONTyped,
    CalculatedGameStatusToJSON,
} from './CalculatedGameStatus';
import {
    GameInviteView,
    GameInviteViewFromJSON,
    GameInviteViewFromJSONTyped,
    GameInviteViewToJSON,
} from './GameInviteView';
import {
    GameTournamentKind,
    GameTournamentKindFromJSON,
    GameTournamentKindFromJSONTyped,
    GameTournamentKindToJSON,
} from './GameTournamentKind';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';
import {
    TourGameEntityType,
    TourGameEntityTypeFromJSON,
    TourGameEntityTypeFromJSONTyped,
    TourGameEntityTypeToJSON,
} from './TourGameEntityType';
import {
    TournamentKind,
    TournamentKindFromJSON,
    TournamentKindFromJSONTyped,
    TournamentKindToJSON,
} from './TournamentKind';
import {
    TournamentStatus,
    TournamentStatusFromJSON,
    TournamentStatusFromJSONTyped,
    TournamentStatusToJSON,
} from './TournamentStatus';

/**
 * 
 * @export
 * @interface TourGameInfo
 */
export interface TourGameInfo {
    /**
     * 
     * @type {TourGameEntityType}
     * @memberof TourGameInfo
     */
    type?: TourGameEntityType;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    uid?: string;
    /**
     * 
     * @type {Reference}
     * @memberof TourGameInfo
     */
    club?: Reference;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    city?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof TourGameInfo
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {Grade}
     * @memberof TourGameInfo
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof TourGameInfo
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    description?: string;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TourGameInfo
     */
    owner?: PlayerProfileInfo;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    displayMinGrade?: string;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    displayMaxGrade?: string;
    /**
     * 
     * @type {number}
     * @memberof TourGameInfo
     */
    playersLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TourGameInfo
     */
    privateTourGame?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    key?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TourGameInfo
     */
    courtBooked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    courtNumber?: string;
    /**
     * 
     * @type {CalculatedGameStatus}
     * @memberof TourGameInfo
     */
    gameStatus?: CalculatedGameStatus;
    /**
     * 
     * @type {boolean}
     * @memberof TourGameInfo
     */
    currentPlayerResponded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TourGameInfo
     */
    duration?: number;
    /**
     * 
     * @type {GameTournamentKind}
     * @memberof TourGameInfo
     */
    gameTournamentKind?: GameTournamentKind;
    /**
     * 
     * @type {string}
     * @memberof TourGameInfo
     */
    title?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof TourGameInfo
     */
    closingDate?: LocalDateTime;
    /**
     * 
     * @type {TournamentKind}
     * @memberof TourGameInfo
     */
    kind?: TournamentKind;
    /**
     * 
     * @type {number}
     * @memberof TourGameInfo
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof TourGameInfo
     */
    countPlayers?: number;
    /**
     * 
     * @type {TournamentStatus}
     * @memberof TourGameInfo
     */
    tournamentStatus?: TournamentStatus;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TourGameInfo
     */
    firstPlace?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TourGameInfo
     */
    secondPlace?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TourGameInfo
     */
    thirdPlace?: PlayerProfileInfo;
    /**
     * 
     * @type {Array<PlayerProfileInfo>}
     * @memberof TourGameInfo
     */
    players?: Array<PlayerProfileInfo>;
    /**
     * 
     * @type {Array<GameInviteView>}
     * @memberof TourGameInfo
     */
    invitations?: Array<GameInviteView>;
    /**
     * 
     * @type {Array<Gender>}
     * @memberof TourGameInfo
     */
    genders?: Array<Gender>;
}

export function TourGameInfoFromJSON(json: any): TourGameInfo {
    return TourGameInfoFromJSONTyped(json, false);
}

export function TourGameInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TourGameInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : TourGameEntityTypeFromJSON(json['type']),
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'city': !exists(json, 'city') ? undefined : json['city'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'owner': !exists(json, 'owner') ? undefined : PlayerProfileInfoFromJSON(json['owner']),
        'displayMinGrade': !exists(json, 'displayMinGrade') ? undefined : json['displayMinGrade'],
        'displayMaxGrade': !exists(json, 'displayMaxGrade') ? undefined : json['displayMaxGrade'],
        'playersLimit': !exists(json, 'playersLimit') ? undefined : json['playersLimit'],
        'privateTourGame': !exists(json, 'privateTourGame') ? undefined : json['privateTourGame'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'courtBooked': !exists(json, 'courtBooked') ? undefined : json['courtBooked'],
        'courtNumber': !exists(json, 'courtNumber') ? undefined : json['courtNumber'],
        'gameStatus': !exists(json, 'gameStatus') ? undefined : CalculatedGameStatusFromJSON(json['gameStatus']),
        'currentPlayerResponded': !exists(json, 'currentPlayerResponded') ? undefined : json['currentPlayerResponded'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'gameTournamentKind': !exists(json, 'gameTournamentKind') ? undefined : GameTournamentKindFromJSON(json['gameTournamentKind']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'closingDate': !exists(json, 'closingDate') ? undefined : LocalDateTimeFromJSON(json['closingDate']),
        'kind': !exists(json, 'kind') ? undefined : TournamentKindFromJSON(json['kind']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'countPlayers': !exists(json, 'countPlayers') ? undefined : json['countPlayers'],
        'tournamentStatus': !exists(json, 'tournamentStatus') ? undefined : TournamentStatusFromJSON(json['tournamentStatus']),
        'firstPlace': !exists(json, 'firstPlace') ? undefined : PlayerProfileInfoFromJSON(json['firstPlace']),
        'secondPlace': !exists(json, 'secondPlace') ? undefined : PlayerProfileInfoFromJSON(json['secondPlace']),
        'thirdPlace': !exists(json, 'thirdPlace') ? undefined : PlayerProfileInfoFromJSON(json['thirdPlace']),
        'players': !exists(json, 'players') ? undefined : ((json['players'] as Array<any>).map(PlayerProfileInfoFromJSON)),
        'invitations': !exists(json, 'invitations') ? undefined : ((json['invitations'] as Array<any>).map(GameInviteViewFromJSON)),
        'genders': !exists(json, 'genders') ? undefined : ((json['genders'] as Array<any>).map(GenderFromJSON)),
    };
}

export function TourGameInfoToJSON(value?: TourGameInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TourGameEntityTypeToJSON(value.type),
        'uid': value.uid,
        'club': ReferenceToJSON(value.club),
        'city': value.city,
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'description': value.description,
        'owner': PlayerProfileInfoToJSON(value.owner),
        'displayMinGrade': value.displayMinGrade,
        'displayMaxGrade': value.displayMaxGrade,
        'playersLimit': value.playersLimit,
        'privateTourGame': value.privateTourGame,
        'key': value.key,
        'courtBooked': value.courtBooked,
        'courtNumber': value.courtNumber,
        'gameStatus': CalculatedGameStatusToJSON(value.gameStatus),
        'currentPlayerResponded': value.currentPlayerResponded,
        'duration': value.duration,
        'gameTournamentKind': GameTournamentKindToJSON(value.gameTournamentKind),
        'title': value.title,
        'closingDate': LocalDateTimeToJSON(value.closingDate),
        'kind': TournamentKindToJSON(value.kind),
        'price': value.price,
        'countPlayers': value.countPlayers,
        'tournamentStatus': TournamentStatusToJSON(value.tournamentStatus),
        'firstPlace': PlayerProfileInfoToJSON(value.firstPlace),
        'secondPlace': PlayerProfileInfoToJSON(value.secondPlace),
        'thirdPlace': PlayerProfileInfoToJSON(value.thirdPlace),
        'players': value.players === undefined ? undefined : ((value.players as Array<any>).map(PlayerProfileInfoToJSON)),
        'invitations': value.invitations === undefined ? undefined : ((value.invitations as Array<any>).map(GameInviteViewToJSON)),
        'genders': value.genders === undefined ? undefined : ((value.genders as Array<any>).map(GenderToJSON)),
    };
}

