import { ExtedndedProfileCardProps, ProfileCardProps } from "./ProfileCard.interfaces";
import styles from './ProfileCard.module.css';
import { useDrag, useDrop } from "react-dnd";
import { useContext } from "react";
import { GameLineupsContext } from "@/pages/application/game/GameResults/utils";
import { ExtedndedLineupEditableItemProps, LineupEditableItemDropProps } from "@/pages/application/game/components/LineupListEditableItem/LineupListEditableItem.interfaces";
import UserAvatar from "@/components/UserAvatar/UserAvatar";
import { PrefferedSide } from "@/pages/application/tournament/utils";
import { isYourself } from "./utils";
import { createImgSrc } from "@/utils/utils";
import { useAppSelector } from "@/hooks/hooks";
import { GameLineupView } from "@/integration-api/server-rest-lundapadelApi";

const DraggableProfileCard = ({ player, bage, noPreferedSide, noYourself, fullName, ...props }: ProfileCardProps) => {
  const { editableLineups, handleEditableLineups } = useContext(GameLineupsContext);
  const currentUid = useAppSelector(state => state.auth.currentProfile.identity?.uid);

  const [dragCollect, dragRef] = useDrag<ExtedndedProfileCardProps, unknown, { isDragging: boolean }>({
    type: 'playerPoolItem',
    item: { player, type: 'playerPoolItem' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()  
    }),
    canDrag: !!player
  }, [player]);

  const [dropCollect, dropRef] = useDrop<ExtedndedLineupEditableItemProps, unknown, LineupEditableItemDropProps>({
    accept: 'lineupItem',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
    drop: (item) => {
      if(item.type === 'lineupItem') {
        const newPlayers: GameLineupView = { ...editableLineups[item.lineupIndex], [item.playerProp]: undefined } ;
        handleEditableLineups('edit', { index: item.lineupIndex, players: newPlayers });
      }
    }
  }, [player, editableLineups, handleEditableLineups]);

  return ( 
    <div ref={dropRef}>
      <div {...props} className={styles['player']} ref={dragRef}>
        <UserAvatar
          avatarUid={createImgSrc.playerProfile(player?.uid, player?.avatarUid)}
          badge={bage}
          nameInitials={player?.nameInitials}
          className={isYourself(currentUid, player?.uid) ? styles['yourself-border'] : ''}
        />
        {!noPreferedSide ? <span className={styles['preference']}>{PrefferedSide(player?.leftSide, player?.rightSide)}</span> : null}
        {!noYourself && isYourself(currentUid, player?.uid) ?
          <span className={styles['yourself']}>
            Вы
            <br></br>
            &nbsp;
          </span> :
          <>
            <span className={styles['name']}>
              {player?.displayName?.split?.(' ')?.[0]}
              <br></br>
              {fullName ? `${player?.displayName?.split?.(' ')?.[1]}` : ''}
            </span>
          </>
        }
      </div>
    </div>
  );
}
 
export default DraggableProfileCard;
