/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GradeInfoLoadListResult,
    GradeInfoLoadListResultFromJSON,
    GradeInfoLoadListResultFromJSONTyped,
    GradeInfoLoadListResultToJSON,
} from './GradeInfoLoadListResult';

/**
 * 
 * @export
 * @interface GradeInfoLoadListResponse
 */
export interface GradeInfoLoadListResponse {
    /**
     * 
     * @type {GradeInfoLoadListResult}
     * @memberof GradeInfoLoadListResponse
     */
    result?: GradeInfoLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof GradeInfoLoadListResponse
     */
    messages?: Array<object>;
}

export function GradeInfoLoadListResponseFromJSON(json: any): GradeInfoLoadListResponse {
    return GradeInfoLoadListResponseFromJSONTyped(json, false);
}

export function GradeInfoLoadListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeInfoLoadListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : GradeInfoLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function GradeInfoLoadListResponseToJSON(value?: GradeInfoLoadListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': GradeInfoLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

