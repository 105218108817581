/* tslint:disable */
/* eslint-disable */
/**
 * info
 * Service for compare entity from different development environments Операции, для которых требуется авторизация, могут возвращать HTTP код 403 (Forbidden). В этом случае нужно воспользваться операцией POST common/profile для прохождения процедуры авторизации и получения авторизационного токена, который передаётся в куки.
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CalculatedGameStatus {
    PLANNED = 'PLANNED',
    STARTED = 'STARTED',
    AFTER_GAME = 'AFTER_GAME',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    SCORE_UNDEFINED = 'SCORE_UNDEFINED'
}

export function CalculatedGameStatusFromJSON(json: any): CalculatedGameStatus {
    return CalculatedGameStatusFromJSONTyped(json, false);
}

export function CalculatedGameStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculatedGameStatus {
    return json as CalculatedGameStatus;
}

export function CalculatedGameStatusToJSON(value?: CalculatedGameStatus | null): any {
    return value as any;
}

