import UpdateRequiredPage from '@/components/AppVersionChecker/components/UpdateRequiredPage/UpdateRequiredPage';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import useVersionChecker from '@/hooks/useVersionChecker';
import { CheckVersionStatus } from '@/integration-api/server-rest-lundapadelApi';
import { Outlet } from 'react-router-dom';

export default function AuthPage() {
  const versionCheckResult = useVersionChecker();

  return (
    <>
      {versionCheckResult ? 
        versionCheckResult === CheckVersionStatus.SUPPORTED ?
          <Outlet/>: 
          <UpdateRequiredPage /> :
        <FullscreenLoader loading/>
      }
    </>
  )
}
