import styles from './UserPhotoBackground.module.css';

interface UserPhotoBackgroundProps {
  bgSrc?: string;
}

function UserPhotoBackground(props: UserPhotoBackgroundProps) {
  const { bgSrc } = props;

  const style = {
    background: `linear-gradient(transparent, var(--background-color)), url(${bgSrc}) top / cover`,
  }

  return <div style={style} className={styles.background} />;
}

export default UserPhotoBackground;
