const CrossBigIcon = () => {
  return (  
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5281_86106)">
        <path d="M15.0003 10.8317H10.8337V14.9984C10.8337 15.2194 10.7459 15.4313 10.5896 15.5876C10.4333 15.7439 10.2213 15.8317 10.0003 15.8317C9.77931 15.8317 9.56735 15.7439 9.41107 15.5876C9.25479 15.4313 9.16699 15.2194 9.16699 14.9984V10.8317H5.00033C4.77931 10.8317 4.56735 10.7439 4.41107 10.5876C4.25479 10.4313 4.16699 10.2194 4.16699 9.99837C4.16699 9.77736 4.25479 9.5654 4.41107 9.40912C4.56735 9.25284 4.77931 9.16504 5.00033 9.16504H9.16699V4.99837C9.16699 4.77736 9.25479 4.5654 9.41107 4.40912C9.56735 4.25284 9.77931 4.16504 10.0003 4.16504C10.2213 4.16504 10.4333 4.25284 10.5896 4.40912C10.7459 4.5654 10.8337 4.77736 10.8337 4.99837V9.16504H15.0003C36.5005 10.8317 15.4333 9.25284 15.5896 9.40912C15.7459 9.5654 15.8337 9.77736 15.8337 9.99837C15.8337 10.2194 15.7459 10.4313 15.5896 10.5876C15.4333 10.7439 15.2213 10.8317 15.0003 10.8317Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_5281_86106">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}
 
export default CrossBigIcon;
