import styles from './FullScreenModal.module.css';
import { FullScreenModalProps } from './FullScreenModal.interfaces';

function FullScreenModal(props: FullScreenModalProps) {
  const { visible, onDone, title, children } = props;

  return visible ? (
      <div className={styles['modal']}>
        <div className={styles['inner-wrapper']}>
          <div className={styles['header']}>
            <h3>{title}</h3>
            <button className={styles['submit-btn']} onClick={onDone}>Готово</button>
          </div>
          <div className={styles['content']}>
            {children}
          </div>
        </div>
      </div>
    ) : null
}

export default FullScreenModal;
