import { GameElementInfo, GameMatchResult } from "@/integration-api/server-rest-lundapadelApi";
import { MatchResults } from "./GameMatchCard.interfaces";

export function gameCardResultForProfile(match: GameElementInfo, profileUid: string): MatchResults {
  const isPlayer1 = match.player1?.uid === profileUid;
  const isPlayer2 = match.player2?.uid === profileUid;
  const isPlayer3 = match.player3?.uid === profileUid;
  const isPlayer4 = match.player4?.uid === profileUid;
  switch(match.matchResult) {
    case GameMatchResult.FIRST_TEAM: {
      return isPlayer1 || isPlayer2 ? 'win' : 'loss';
    }
    case GameMatchResult.SECOND_TEAM: {
      return isPlayer3 || isPlayer4 ? 'win' : 'loss';
    }
    default: {
      return 'tie';
    }
  }
}
