import ProfileCard from '@/components/ProfileCard/ProfileCard';
import { PlayersPoolProps } from './PlayerPool.interfaces';
import styles from './PlayersPool.module.css';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import { Role } from '@/integration-api/server-rest-lundapadelApi';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';

const PlayersPool = ({ playersPool }: PlayersPoolProps) => {
  return (
    <div className={styles['wrapper']}>
      {playersPool.map(player => (
        <ProfileCard
          key={player.uid}
          player={player}
          noPreferedSide
          fullName
          bage={[
            {
              element: <RatingBage rating={player.displayRating ?? '1.0'}/>,
              position: 'top-right'
            },
            {
              element: player.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }
          ]}
        />
      ))}
    </div>
  );
}
 
export default PlayersPool;
