import { PropsWithChildren, useCallback, useState } from "react";
import { LineupScoreContext } from "../../GameResults/utils";
import {DeleteScoresArgs, InitListScoresArgs, MatchSetActionArgs, ModifiedGameMatchView, ModifiedGameSetView, UpdateListScoresArgs } from "../../GameResults/GameResultsPage.interfaces";

const LineupScoreContextWrapper = ({ children, ...props }: PropsWithChildren) => {
  const [setScores, setSetScores] = useState<Array<ModifiedGameSetView>>([]);
  const [matchScores, setMatchScores] = useState<Array<ModifiedGameMatchView>>([]);

  const initScores = useCallback(({ matches, sets }: InitListScoresArgs) => {
    setSetScores(sets ?? []);
    setMatchScores(matches ?? []);
  }, []);

  const updateScores = useCallback(({ index, score, team, type, matchIndex }: UpdateListScoresArgs) => {
    if(type === 'set') {
      const newSetScores = setScores.slice();
      newSetScores[index ?? 0] = { ...setScores[index ?? 0], [team ?? 'score1']: score };
      setSetScores(newSetScores);
    } else {
      const newMatchScores = matchScores.slice();
      const newMatchSetScores = matchScores?.[matchIndex ?? 0]?.gameSets?.slice() ?? [];
      newMatchSetScores[index ?? 0] = { ...newMatchSetScores?.[index ?? 0], [team ?? 'score1'] : score };
      newMatchScores[matchIndex ?? 0] = { ...newMatchScores[matchIndex ?? 0], gameSets: newMatchSetScores };
      setMatchScores(newMatchScores);
    }
  }, [matchScores, setScores]);

  const addSetScore = useCallback((set: ModifiedGameSetView) => {
    setSetScores([...setScores, set]);
  }, [setScores]);

  const addMatchScore = useCallback((match: ModifiedGameMatchView) => {
    setMatchScores([...matchScores, match]);
  }, [matchScores]);

  const deleteScores = useCallback(({ type, index, matchIndex }: DeleteScoresArgs) => { 
    if(type === 'set') {
      const left = setScores.slice(0, index);
      const right = setScores.slice((index ?? 0) + 1);
      setSetScores(left.concat(right));
    } else {
      const left = matchScores.slice(0, matchIndex);
      const right = matchScores.slice((matchIndex ?? 0) + 1);
      setMatchScores(left.concat(right));
    }
  }, [setScores, matchScores]);

  const addMatchSet = useCallback(({ matchIndex, set }: MatchSetActionArgs) => {
    const requiredMatch = matchScores.slice()?.[matchIndex ?? 0];
    requiredMatch.gameSets?.push(set!);
    const newMatchScores = matchScores.slice();
    newMatchScores[matchIndex ?? 0] = requiredMatch;
    setMatchScores(newMatchScores);
  }, [matchScores]);

  const deleteMatchSet = useCallback(({ matchIndex }: MatchSetActionArgs) => {
    const requiredMatch = matchScores.slice()?.[matchIndex ?? 0];
    requiredMatch.gameSets?.pop();
    const newMatchScores = matchScores.slice();
    newMatchScores[matchIndex ?? 0] = requiredMatch;
    setMatchScores(newMatchScores);
  }, [matchScores]);

  return ( 
    <LineupScoreContext.Provider 
      {...props} 
      value={{ 
        matchScores, setScores, initScores, updateScores, addSetScore, addMatchScore, deleteScores, addMatchSet, deleteMatchSet
      }}>
      {children}
    </LineupScoreContext.Provider>
  );
}
 
export default LineupScoreContextWrapper;
