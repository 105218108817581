import styles from "./DialogItem.module.css"
import { FC } from "react"

export interface DialogItemProps {
  name?: string,
  message?: string,
  time?: string,
  incoming?: boolean,
}
const DialogItem: FC<DialogItemProps> = ({name, message, time, incoming}) => {

  return (
    <div className={incoming ? styles.wrapperIn : styles.wrapperOut}>
      <div className={styles.name}>{name}</div>
      <div className={incoming ? styles.messageIn : styles.messageOut}>{message}</div>
      <div className={incoming ? styles.timeIn : styles.timeOut}>{time}</div>
    </div>
  )
}

export default DialogItem
