/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';

/**
 * 
 * @export
 * @interface InformationMessage
 */
export interface InformationMessage {
    /**
     * 
     * @type {string}
     * @memberof InformationMessage
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof InformationMessage
     */
    code?: string;
    /**
     * 
     * @type {Severity}
     * @memberof InformationMessage
     */
    severity?: Severity;
}

export function InformationMessageFromJSON(json: any): InformationMessage {
    return InformationMessageFromJSONTyped(json, false);
}

export function InformationMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformationMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'severity': !exists(json, 'severity') ? undefined : SeverityFromJSON(json['severity']),
    };
}

export function InformationMessageToJSON(value?: InformationMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'code': value.code,
        'severity': SeverityToJSON(value.severity),
    };
}

