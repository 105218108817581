/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameRoundView,
    GameRoundViewFromJSON,
    GameRoundViewFromJSONTyped,
    GameRoundViewToJSON,
} from './GameRoundView';

/**
 * 
 * @export
 * @interface GameRoundsPreviewResult
 */
export interface GameRoundsPreviewResult {
    /**
     * 
     * @type {Array<GameRoundView>}
     * @memberof GameRoundsPreviewResult
     */
    rounds?: Array<GameRoundView>;
}

export function GameRoundsPreviewResultFromJSON(json: any): GameRoundsPreviewResult {
    return GameRoundsPreviewResultFromJSONTyped(json, false);
}

export function GameRoundsPreviewResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameRoundsPreviewResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rounds': !exists(json, 'rounds') ? undefined : ((json['rounds'] as Array<any>).map(GameRoundViewFromJSON)),
    };
}

export function GameRoundsPreviewResultToJSON(value?: GameRoundsPreviewResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rounds': value.rounds === undefined ? undefined : ((value.rounds as Array<any>).map(GameRoundViewToJSON)),
    };
}

