/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaptchaData
 */
export interface CaptchaData {
    /**
     * 
     * @type {string}
     * @memberof CaptchaData
     */
    ticket?: string;
    /**
     * 
     * @type {string}
     * @memberof CaptchaData
     */
    operation?: string;
    /**
     * 
     * @type {string}
     * @memberof CaptchaData
     */
    operand1?: string;
    /**
     * 
     * @type {string}
     * @memberof CaptchaData
     */
    operand2?: string;
}

export function CaptchaDataFromJSON(json: any): CaptchaData {
    return CaptchaDataFromJSONTyped(json, false);
}

export function CaptchaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaptchaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticket': !exists(json, 'ticket') ? undefined : json['ticket'],
        'operation': !exists(json, 'operation') ? undefined : json['operation'],
        'operand1': !exists(json, 'operand1') ? undefined : json['operand1'],
        'operand2': !exists(json, 'operand2') ? undefined : json['operand2'],
    };
}

export function CaptchaDataToJSON(value?: CaptchaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticket': value.ticket,
        'operation': value.operation,
        'operand1': value.operand1,
        'operand2': value.operand2,
    };
}

