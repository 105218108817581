const TournamentAwardSilver = () => {
  return ( 
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6424_6506)">
        <path d="M35.6245 2.1843C35.0676 2.6793 30.7363 11.2748 30.7363 11.2748L27.5137 24.2737L40.0537 23.1238C40.0537 23.1238 50.4848 3.72602 50.6962 3.27742C51.0726 2.46273 51.2737 2.12758 49.8351 2.12758C48.3965 2.12242 36.1452 1.72023 35.6245 2.1843Z" fill="#176CC7"/>
        <path d="M42.1167 22.3186C41.4928 21.9835 23.1159 21.6947 22.6828 22.3186C22.2909 22.8858 22.5126 25.861 22.6621 26.2168C22.9303 26.8355 28.8651 29.5322 28.8651 29.5322L28.7517 30.7594C28.7517 30.7594 29.2364 30.8832 32.6859 30.8832C36.1354 30.8832 36.816 30.7079 36.816 30.7079L36.8264 29.5993C36.8264 29.5993 42.1682 27.0005 42.4364 26.6396C42.6632 26.3405 42.7406 22.6538 42.1167 22.3186ZM36.6459 27.0211C36.6459 27.0211 36.816 26.2632 36.4603 26.0879C36.1045 25.9074 29.9995 25.9899 29.5148 26.0157C29.0301 26.0414 29.0301 26.9077 29.0301 26.9077L25.1114 24.5719V24.1904L39.7035 24.2935L39.7293 24.7266L36.6459 27.0211Z" fill="#CECDD2"/>
        <path d="M30.5561 26.3828C30.0714 26.3828 29.793 26.8881 29.793 27.7595C29.793 28.5742 30.0714 29.26 30.685 29.2084C31.1903 29.1672 31.3656 28.3938 31.3192 27.708C31.2728 26.8933 31.1697 26.3828 30.5561 26.3828Z" fill="#FDFFFF"/>
        <path d="M14.9375 47.3796C14.9375 59.734 26.307 64.9779 33.7372 64.7562C42.3945 64.4984 51.5314 57.9293 50.9384 46.1421C50.3764 34.9737 40.9044 30.0082 32.7678 30.0649C23.3113 30.1371 14.9375 36.5876 14.9375 47.3796Z" fill="#CECDD2"/>
        <path d="M33.2735 62.4583C33.1446 62.4583 33.0105 62.4583 32.8816 62.4531C29.0144 62.3242 25.1936 60.6948 22.3989 57.9878C19.4083 55.09 17.7686 51.1712 17.7893 46.9586C17.8357 36.6719 26.8282 31.9023 32.928 31.9023H32.9796C41.245 31.9384 48.0307 38.3631 48.2214 47.072C48.3039 50.9495 46.7158 55.0178 43.6014 58.0806C40.6933 60.932 36.8364 62.4583 33.2735 62.4583ZM32.9177 33.6142C27.4469 33.6142 19.3568 38.0898 19.3361 46.9586C19.3207 53.5895 24.4563 60.4061 33.0105 60.6948C36.2693 60.798 39.626 59.5502 42.3382 56.8844C45.1535 54.1155 46.7364 50.5473 46.6797 47.0927C46.5457 39.6006 40.42 33.64 32.9847 33.6039C32.9744 33.6039 32.9332 33.6142 32.9177 33.6142Z" fill="#9B9B9D"/>
        <path d="M29.9523 31.6951C29.5965 31.2052 25.951 31.3445 22.0271 34.7218C18.129 38.0785 17.5463 41.791 18.2476 41.987C19.0468 42.2087 20.0213 38.6199 23.3884 35.6035C26.4821 32.8449 30.6587 32.6696 29.9523 31.6951ZM45.3127 45.2302C44.0701 45.4056 45.3591 49.1284 42.4768 53.2946C39.976 56.9091 37.1401 58.2188 37.4701 59.0129C37.9135 60.0751 42.4562 57.4299 44.5599 53.1193C46.4626 49.2212 46.2357 45.1013 45.3127 45.2302Z" fill="#FEFFFA"/>
        <path d="M13.1527 1.91797C12.8279 2.21704 25.2493 24.3374 25.2493 24.3374C25.2493 24.3374 27.3324 24.6055 31.9834 24.6467C36.6343 24.688 38.9391 24.3786 38.9391 24.3786C38.9391 24.3786 29.2815 2.13969 28.704 1.91797C28.4204 1.80454 24.5945 1.76329 20.8562 1.72204C17.1127 1.67563 13.4621 1.62922 13.1527 1.91797Z" fill="#2E9DF4"/>
        <path d="M29.3125 44.5231C29.8487 44.5282 30.3283 43.8012 30.7717 43.3577C31.7153 42.409 32.7053 41.6562 33.8345 42.6771C36.6086 45.1985 31.0398 47.756 28.9258 51.252C27.348 53.861 27.0283 55.8668 27.4666 56.403C27.9048 56.9393 38.5731 56.9135 38.7175 56.6454C38.8619 56.3773 39.0114 52.804 38.7639 52.6338C38.5164 52.4637 33.2725 52.5616 33.2725 52.5616C33.2725 52.5616 33.7108 51.5407 35.5103 49.9835C37.4851 48.2768 39.0681 45.9307 38.6453 43.1566C37.7687 37.4229 31.9937 37.2115 29.493 39.207C27.0541 41.1509 27.58 44.5024 29.3125 44.5231Z" fill="#9B9B9D"/>
      </g>
      <defs>
        <clipPath id="clip0_6424_6506">
          <rect width="66" height="66" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
 
export default TournamentAwardSilver;
