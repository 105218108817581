type ArrowSmallIconProps = {
  fill?: string
}

const ArrowSmallIcon = ({ fill }: ArrowSmallIconProps) => {
  return (  
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.18205 6L4 2.88875L4.90897 2L9 6L4.90897 10L4 9.11062L7.18205 5.99937V6Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default ArrowSmallIcon;
