const TournamentAwardBronze = () => {
  return ( 
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6424_36058)">
        <path d="M35.6245 2.18625C35.0676 2.68125 30.7363 11.2767 30.7363 11.2767L27.5137 24.2756L40.0537 23.1258C40.0537 23.1258 50.4848 3.72797 50.6962 3.27938C51.0726 2.46469 51.2737 2.12953 49.8351 2.12953C48.3965 2.12438 36.1452 1.72219 35.6245 2.18625Z" fill="#176CC7"/>
        <path d="M42.1167 22.3206C41.4928 21.9854 23.1159 21.6967 22.6828 22.3206C22.2909 22.8878 22.5126 25.8629 22.6621 26.2187C22.9303 26.8375 28.8651 29.5342 28.8651 29.5342L28.7517 30.7614C28.7517 30.7614 29.2364 30.8851 32.6859 30.8851C36.1354 30.8851 36.816 30.7098 36.816 30.7098L36.8264 29.6012C36.8264 29.6012 42.1682 27.0025 42.4364 26.6415C42.6632 26.3425 42.7406 22.6557 42.1167 22.3206ZM36.6459 27.0231C36.6459 27.0231 36.816 26.2651 36.4603 26.0898C36.1045 25.9093 29.9995 25.9918 29.5148 26.0176C29.0301 26.0434 29.0301 26.9096 29.0301 26.9096L25.1835 24.9296L25.1114 24.1923L39.7035 24.2954L39.652 24.9915L36.6459 27.0231Z" fill="#F79429"/>
        <path d="M30.5561 26.7559C30.0714 26.7559 29.793 27.2612 29.793 28.1326C29.793 28.9473 30.0714 29.633 30.685 29.5815C31.1903 29.5402 31.3656 28.7668 31.3192 28.081C31.2728 27.2612 31.1697 26.7559 30.5561 26.7559Z" fill="#FDFFFF"/>
        <path d="M15.1133 47.4847C15.1133 59.8391 26.3075 64.9798 33.7377 64.7581C42.395 64.5003 51.7072 57.9312 50.9389 46.1441C50.217 35.0839 40.9048 30.0102 32.7683 30.0669C23.3117 30.1391 15.1133 36.6927 15.1133 47.4847Z" fill="#F79429"/>
        <path d="M33.3457 62.8899C33.2168 62.8899 33.0828 62.8899 32.9539 62.8848C29.0867 62.7559 25.2659 61.1265 22.4712 58.4195C19.4806 55.5216 17.8409 51.6029 17.8615 47.3902C17.9079 37.1035 26.9004 32.334 33.0003 32.334H33.0518C41.3173 32.3701 48.1029 38.7948 48.2937 47.5037C48.3762 51.3812 47.1387 55.238 44.0243 58.3009C41.1162 61.1471 36.9087 62.8899 33.3457 62.8899ZM32.99 34.0459C27.5192 34.0459 19.429 38.5215 19.4084 47.3902C19.3929 54.0212 24.5286 60.8377 33.0828 61.1265C36.3415 61.2296 40.0179 60.023 42.725 57.3624C45.5403 54.5935 46.8036 50.9841 46.7468 47.5295C46.6231 40.0374 40.4975 34.0768 33.0621 34.0459C33.0467 34.0355 33.0054 34.0459 32.99 34.0459Z" fill="#D25116"/>
        <path d="M29.9523 31.6951C29.5965 31.2052 25.951 31.3445 22.0271 34.7218C18.129 38.0785 17.5463 41.791 18.2476 41.987C19.0468 42.2087 20.0213 38.6199 23.3884 35.6035C26.4821 32.8449 30.6587 32.6696 29.9523 31.6951ZM45.6221 45.189C44.3795 45.3643 45.6685 49.0871 42.7862 53.2534C40.2854 56.8679 37.4495 58.1776 37.7795 58.9716C38.2229 60.0338 42.7655 57.3887 44.8693 53.0781C46.772 49.1748 46.5451 45.0549 45.6221 45.189Z" fill="#FEFFFA"/>
        <path d="M13.1527 1.91797C12.8279 2.21704 25.2493 24.3373 25.2493 24.3373C25.2493 24.3373 27.3324 24.6055 31.9834 24.6467C36.6343 24.688 38.9391 24.3786 38.9391 24.3786C38.9391 24.3786 29.2815 2.13969 28.704 1.91797C28.4204 1.80454 24.5945 1.76329 20.8562 1.72204C17.1127 1.67563 13.4621 1.62922 13.1527 1.91797Z" fill="#2E9DF4"/>
        <path d="M38.6464 43.1638C38.0482 37.9818 32.0154 37.5487 29.5146 39.539C27.0809 41.4777 27.3129 44.1796 29.0196 44.4941C30.3654 44.7416 30.716 43.6743 31.1079 43.1845C31.9948 42.0862 33.4076 41.983 34.3306 42.8648C35.3773 43.8702 34.7328 46.273 33.1601 46.371C31.9896 46.4432 31.3967 46.3813 31.2471 46.5824C31.0357 46.866 31.0667 49.0987 31.2832 49.3462C31.5307 49.6298 32.5207 49.4545 33.3715 49.4854C34.542 49.5318 35.7846 51.2643 34.9648 52.5637C34.114 53.9095 31.8504 53.3062 31.0357 52.4915C29.9581 51.4138 28.8804 52.0326 28.5195 52.4554C27.9523 53.1257 27.349 54.652 29.4424 56.137C31.5307 57.622 37.8678 57.9055 39.1465 53.3062C40.2603 49.2894 37.6615 47.6395 37.6615 47.6395C37.6615 47.6395 38.9867 46.1184 38.6464 43.1638Z" fill="#D25116"/>
      </g>
      <defs>
        <clipPath id="clip0_6424_36058">
          <rect width="66" height="66" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}
 
export default TournamentAwardBronze;
