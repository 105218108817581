import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { GameResultsContext } from "./utils";
import { GameResultStep } from "./GameResultsPage.interfaces";
import { extendGameLock, gameLoad, loadAndLockGame, unlockGame } from "../service";
import { App } from '@capacitor/app';
import { useParams } from "react-router-dom";
import { GameView } from "@/integration-api/server-rest-lundapadelApi";
import { FetchErrorMessage } from "@/types/types";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";
import { Capacitor } from "@capacitor/core";

const GameResultsPageContextWrapper = ({ children, ...props }: PropsWithChildren) => {
  const { id } = useParams();
  const [game, setGame] = useState<GameView>({});
  const [step, setStep] = useState<GameResultStep>('lineup');
  const [activeUid, setActiveUid] = useState<string>();
  const [lockToken, setLockToken] = useState<string>();
  const [ttlLock, setTtlLock] = useState<number>();

  const lockTokenInterval = useRef<NodeJS.Timeout | null>(null);

  const updateStep = useCallback((step: GameResultStep) => {
    setStep(step);
  }, []);

  const updateActiveUid = useCallback((uid?: string) => {
    setActiveUid(uid);
  }, []);

  const loadCurrentGame = useCallback(async () => {
    try {
      if(id) {
        if(!lockToken) {
          sessionStorage.removeItem('lockToken');
          const { result } = await loadAndLockGame({ gameUid: id });
          setGame(result?.view ?? {});
          sessionStorage.setItem('lockToken', result?.lockToken ?? '');
          setLockToken(result?.lockToken);
          setTtlLock(result?.ttlLock);
        } else {
          const { result } = await gameLoad({ uid: id });
          setGame(result ?? {});
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    }
  }, [id, lockToken]);
  
  const triggerUnlock = useCallback(() => {
    if(lockTokenInterval.current) {
      clearTimeout(lockTokenInterval.current);
    }
    const lt = sessionStorage.getItem('lockToken');
    if(lt) {
      unlockGame({ lockToken: lt })
        .then(() => {
          sessionStorage.removeItem('lockToken');
          setLockToken(undefined);
        })
        .catch(err => console.log(err));
    }
  }, []);

  useEffect(() => {
    loadCurrentGame();
  }, [id, step]);
  
  useEffect(() => {
    if(lockToken && id && ttlLock) {
      lockTokenInterval.current = setTimeout(() => {
        extendGameLock({ gameUid: id, lockToken })
          .then(({ result }) => {
            setLockToken(result?.lockToken);
            sessionStorage.setItem('lockToken', result?.lockToken ?? '');
          })
          .catch(err => {
            if(err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              })
            }
          })
      }, ttlLock * 1000);
    }
  }, [id, ttlLock, lockToken]);

  useEffect(() => {
    if(Capacitor.isNativePlatform() && id) {
      App.addListener('appStateChange', (state) => { 
          if(!state.isActive) {
            triggerUnlock()
          } else {
            sessionStorage.removeItem('lockToken');
            loadAndLockGame({ gameUid: id })
              .then(({ result }) => {
                setGame(result?.view ?? {});
                sessionStorage.setItem('lockToken', result?.lockToken ?? '');
                setLockToken(result?.lockToken);
                setTtlLock(result?.ttlLock);
              })
              .catch(err => {
                if(err instanceof Promise) {
                  err.then(err => {
                    const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                    PopupMessage.open(userErrorMessage ?? errorMessage);
                  })
                }
              })
          }
       }).catch(err => console.log(err))
        return () => {
          App.removeAllListeners().catch(err => console.log(err))
        } 
    }
  }, [id, triggerUnlock]);

  useEffect(() => {
    return () => triggerUnlock();
  }, [triggerUnlock]);

  return (
    <GameResultsContext.Provider 
      {...props} 
      value={{ 
        playersPool: game.members?.map(member => member.player ?? {}) ?? [],
        step, 
        lineups: game.lineups ?? [], 
        activeUid, 
        plannedDate: game.plannedDate,
        game,
        lockToken,
        updateActiveUid, 
        updateGame: loadCurrentGame, 
        updateStep 
      }}>
      {children}
    </GameResultsContext.Provider>
  );
}
 
export default GameResultsPageContextWrapper;
