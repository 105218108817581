/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartnerLoadListResult,
    PartnerLoadListResultFromJSON,
    PartnerLoadListResultFromJSONTyped,
    PartnerLoadListResultToJSON,
} from './PartnerLoadListResult';

/**
 * 
 * @export
 * @interface PartnerLoadListResponse
 */
export interface PartnerLoadListResponse {
    /**
     * 
     * @type {PartnerLoadListResult}
     * @memberof PartnerLoadListResponse
     */
    result?: PartnerLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof PartnerLoadListResponse
     */
    messages?: Array<object>;
}

export function PartnerLoadListResponseFromJSON(json: any): PartnerLoadListResponse {
    return PartnerLoadListResponseFromJSONTyped(json, false);
}

export function PartnerLoadListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerLoadListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : PartnerLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function PartnerLoadListResponseToJSON(value?: PartnerLoadListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': PartnerLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

