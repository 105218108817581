import TournamentResultsTabs from "@/pages/application/tournament/components/TournamentResultsTabs/TournamentResultsTabs"
import styles from './MinitournamentContent.module.css';
import { GamePlayerStatisticsInfo, GameRoundView, GameView } from "@/integration-api/server-rest-lundapadelApi";
import { useEffect, useState } from "react";
import { TournamentResultsTabsValues } from "@/pages/application/tournament/TournamentPage.interfaces";
import TournamentTableItem from "@/pages/application/tournament/components/TournamentTableItem/TournamentTableItem";
import TournamentRoundsPreview from "@/pages/application/tournament/components/TournamentRoundsPreview/TournamentRoundsPreview";
import TournamentStatistics from "@/pages/application/tournament/components/TournamentStatistics/TournamentStatistics";
import { mapRoundStatus } from "@/pages/application/tournament/utils";
import { useParams } from "react-router-dom";
import { loadGameRoundsPreview, loadGameTournamentStatistics } from "../../service";
import GameListCard from "@/components/GameListCard/GameListCard";

type MinitournamentContentProps = {
  tournament: GameView;
}

const MinitournamentContent = ({ tournament }: MinitournamentContentProps) => {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState<TournamentResultsTabsValues>('results');
  const [rounds, setRounds] = useState<Array<GameRoundView>>([]);
  const [tournamentStatistic, setTournamentStatistic] = useState<Array<GamePlayerStatisticsInfo>>([]);

  const renderTabContent = () => {
    switch(activeTab) {
      case 'results': {
        return (
          <div className={styles['table']}>
            {tournament?.members?.map?.((participant, index) => (
              <TournamentTableItem
                key={participant.player?.uid}
                participant={participant}
                placement={index + 1}
              />
            ))}
          </div>
        )
      }
      case 'score': {
        return (
          <div className={styles['rounds-wrapper']}>
            {rounds?.map((round, index) => (
              <TournamentRoundsPreview
                key={index}
                roundStickyTb={75}
                courtStickyTb={111}
                round={{ ...round, status: mapRoundStatus(round.status!) }}
                index={index}
              />
            ))}
          </div>
        )
      }
      case 'statistic': {
        return (
          <TournamentStatistics
            tournamentStatistic={tournamentStatistic}
          />
        )
      }
      default: {
        const _: never = activeTab;
        throw new Error('failed');
      }
    }
  }
  
  useEffect(() => {
    if(id) {
      Promise.all([loadGameRoundsPreview(id), loadGameTournamentStatistics(id)])
        .then(([{ result: rounds }, { result: stats }]) => {
          setRounds(rounds?.rounds ?? []);
          setTournamentStatistic(stats?.playersStatistics ?? []);
        })
        .catch(err => console.log(err));
    }
  }, [id]);

  return (  
    <div className={styles['wrapper']}>
      <GameListCard
        className={styles['card']}
        subtype="green"
        linkForbidden
        game={{ 
          ...tournament, 
          tournamentStatus: undefined, 
          gameStatus: tournament.status, 
          club: { caption: tournament.club?.displayName },
          players: tournament.members?.map(member => member.player ?? {})
        }}/>
      <TournamentResultsTabs
        name='tournament-tabs'
        activeTab={activeTab}
        selectTab={e => setActiveTab((e.target as HTMLInputElement).value as TournamentResultsTabsValues)}
        style={{ paddingBottom: activeTab === 'score' ? '28px' : '0', top: '-10px' }}
      /> 
      {renderTabContent()}
    </div>
  );
}
 
export default MinitournamentContent;
