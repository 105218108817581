import { useCountdown } from '@/hooks/useCountdown';
import dayjs from 'dayjs';
import { useMemo, useReducer } from 'react';
import styles from './CountdownTimer.module.css';

interface CountdownTimerProps {
  minutes: number;
  resendCode: () => Promise<void>;
}
const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
  const { resendCode, minutes: minutesCountdown } = props;
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const timer = useMemo(() => {
    return (!ignored || ignored) && dayjs().add(minutesCountdown, 'minutes');
  }, [minutesCountdown, ignored]);

  const times = useCountdown(timer);
  const minutes = times[2];
  const seconds = times[3];
  const totalMillisecond = times[times.length - 1];

  return (
    <span>
      {totalMillisecond > 0 ? (
        <>
          Отправить код повторно можно через{' '}
          {minutes > 9 ? minutes : `0${minutes}`}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </>
      ) : (
        <a
          className={styles.resend}
          href="/"
          onClick={async (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            await resendCode();
            forceUpdate();
          }}
        >
          Отправить код повторно
        </a>
      )}
    </span>
  );
};

export default CountdownTimer;
