/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FitnessLevel {
    LOW = 'LOW',
    GOOD = 'GOOD',
    GREAT = 'GREAT'
}

export function FitnessLevelFromJSON(json: any): FitnessLevel {
    return FitnessLevelFromJSONTyped(json, false);
}

export function FitnessLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FitnessLevel {
    return json as FitnessLevel;
}

export function FitnessLevelToJSON(value?: FitnessLevel | null): any {
    return value as any;
}

