/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface GameMemberView
 */
export interface GameMemberView {
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameMemberView
     */
    player?: PlayerProfileInfo;
    /**
     * 
     * @type {number}
     * @memberof GameMemberView
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof GameMemberView
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof GameMemberView
     */
    displayRating?: string;
    /**
     * 
     * @type {number}
     * @memberof GameMemberView
     */
    tournamentRatingChange?: number;
    /**
     * 
     * @type {string}
     * @memberof GameMemberView
     */
    tournamentDisplayRatingChange?: string;
}

export function GameMemberViewFromJSON(json: any): GameMemberView {
    return GameMemberViewFromJSONTyped(json, false);
}

export function GameMemberViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameMemberView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'player': !exists(json, 'player') ? undefined : PlayerProfileInfoFromJSON(json['player']),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'displayRating': !exists(json, 'displayRating') ? undefined : json['displayRating'],
        'tournamentRatingChange': !exists(json, 'tournamentRatingChange') ? undefined : json['tournamentRatingChange'],
        'tournamentDisplayRatingChange': !exists(json, 'tournamentDisplayRatingChange') ? undefined : json['tournamentDisplayRatingChange'],
    };
}

export function GameMemberViewToJSON(value?: GameMemberView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': PlayerProfileInfoToJSON(value.player),
        'score': value.score,
        'rating': value.rating,
        'displayRating': value.displayRating,
        'tournamentRatingChange': value.tournamentRatingChange,
        'tournamentDisplayRatingChange': value.tournamentDisplayRatingChange,
    };
}

