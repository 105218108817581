/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameRole,
    GameRoleFromJSON,
    GameRoleFromJSONTyped,
    GameRoleToJSON,
} from './GameRole';

/**
 * 
 * @export
 * @interface GamePermissionsView
 */
export interface GamePermissionsView {
    /**
     * 
     * @type {Array<GameRole>}
     * @memberof GamePermissionsView
     */
    inviteCreate?: Array<GameRole>;
    /**
     * 
     * @type {Array<GameRole>}
     * @memberof GamePermissionsView
     */
    scoringResult?: Array<GameRole>;
}

export function GamePermissionsViewFromJSON(json: any): GamePermissionsView {
    return GamePermissionsViewFromJSONTyped(json, false);
}

export function GamePermissionsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GamePermissionsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inviteCreate': !exists(json, 'inviteCreate') ? undefined : ((json['inviteCreate'] as Array<any>).map(GameRoleFromJSON)),
        'scoringResult': !exists(json, 'scoringResult') ? undefined : ((json['scoringResult'] as Array<any>).map(GameRoleFromJSON)),
    };
}

export function GamePermissionsViewToJSON(value?: GamePermissionsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inviteCreate': value.inviteCreate === undefined ? undefined : ((value.inviteCreate as Array<any>).map(GameRoleToJSON)),
        'scoringResult': value.scoringResult === undefined ? undefined : ((value.scoringResult as Array<any>).map(GameRoleToJSON)),
    };
}

