import { useEffect } from 'react'

export default function useDocumentVisibility(onVisible?: () => void, onHidden?: () => void) {
  useEffect(() => {
    function visibilityChangeHandler() {
      if (!document.hidden) {
        onVisible?.();
      } else {
        onHidden?.();
      }
    }

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    }
  }, [onHidden, onVisible])
}
