import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal";
import { ConfirmationModalProps } from "@/components/ConfirmationModal/ConfirmationModal.interfaces";
import { FC, useEffect, useState } from "react";
import styles from './CourtNumberModal.module.css'

export interface CourtNumberModalProps extends ConfirmationModalProps {
  setFieldValue?:any;
  toggleSwitch?: () => void;
}
const CourtNumberModal: FC<CourtNumberModalProps> = ({ visible, onClose, toggleSwitch, children, setFieldValue,...props }): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('')
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true)

  useEffect(() => {
    inputValue ? setDisabledBtn(false) : setDisabledBtn(true)
  }, [inputValue])

  const handleClick = async (e: any) => {
    e.preventDefault()
    if(setFieldValue){
      setFieldValue('courtNumber',inputValue) 
    }
    onClose()
    setInputValue('')
  }

  const handleClose = () => {
    setInputValue('');
    toggleSwitch?.();
    onClose()
  }
  return (
    <>
      <ConfirmationModal
        visible={visible}
        onClose={()=>{
          setFieldValue && setFieldValue('courtBooked', false)
          handleClose()
        }
        }
      >
        <div className={styles.wrap}>
          <div className={styles.text}>Укажите номер корта, который вы забронировали</div>
          <input
            type="text"
            name="courtNumber"
            min="0"
            maxLength={2}
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder="Например, 7"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            enterKeyHint="done"
          />
          <button onClick={handleClick} disabled={disabledBtn}
            className={`${styles.confirmBtn} ${!disabledBtn && styles.confirmBtnActive}`}>Подтвердить</button>
        </div>

      </ConfirmationModal>
    </>
  );
}

export default CourtNumberModal;
