import React from 'react';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import Header from '@/components/Header/Header';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import { Partners, SortBy } from './ProfileParthers.interface';
import styles from './ProfilePartners.module.css';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import tennisRacketImg from '@/static/images/icons/tennis-racket.svg';
import SelectIndicator from '@/components/SelectIndicator/SelectIndicator';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { getPartners, partnerDelete } from './service';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { createImgSrc } from '@/utils/utils';
import { hasSelectedPartners, sortByFunc } from './utils';
import { FetchErrorMessage } from '@/types/types';
import SelectOutlined from '@/static/images/icons/SelectOutlined';
import FilterOutlined from '@/static/images/icons/FilterOutlined';
import FullScreenModal from '@/components/FullScreenModal/FullScreenModal';
import RadioButton from '@/components/RadioButton/RadioButton';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import { useAppSelector } from '@/hooks/hooks';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const ProfilePartners: React.FC = () => {
  const currentUser = useOutletContext<any>()[0];
  const current = useAppSelector(state => state.auth.currentProfile);
  const { id } = useParams();
  const navigate = useNavigate();

  const [partners, setPartners] = useState<Partners>({});
  const [selectPartnersMode, setSelectPartnersMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partnerSortBy, setPartnerSortBy] = useState<SortBy>('matchTogether');
  const [filterModalVisible, setModalVisible] = useState(false);

  const selectedPartnersCount = Object.values(partners).filter(partner => partner?.selected).length;

  useEffect(() => {
    if (id && currentUser && currentUser.identity?.uid) {
      setLoading(true);

      getPartners(currentUser.identity?.uid)
        .then(({result}) => {
          if (result?.partners) {
            const partners: Partners = {};
            result.partners.forEach((partner) => {partners[partner?.uid!] = {...partner, selected: false}})
            setPartners(partners)
          }
          })
          .catch((err) => {
            if(err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              })
            }
          })
        .finally(() => setLoading(false));
    }
  }, [currentUser?.identity?.uid, id])

  const getSelectedPartners = () => Object.values(partners).filter((partner) => partner.selected);

  const handleSelectClick = () => {
    setSelectPartnersMode(!selectPartnersMode);
  }

  const handlePlayerItemClick = ({uid: playerUid, selected}: Partners[string]) => {
    if(selectPartnersMode) {
      const partnerWithChangedSelect = {...structuredClone(partners[playerUid!]), selected: !selected};
      if (partnerWithChangedSelect.invitable) {
        setPartners({...partners, [playerUid!]: partnerWithChangedSelect})
      }
    }
  }

  const handleCreateBtnClick = () => {
    localStorage.setItem('gameInvitations', JSON.stringify(getSelectedPartners()));

    navigate('/create/game?fromPartners');
  }

  const handleInviteBtnClick = (player: PlayerProfileInfo) => {
    localStorage.setItem('gameInvitations', JSON.stringify([player]));

    navigate('/create/game?fromPartners');
  }

  const handleDeletePartnersBtn = () => {
    setLoading(true);

    const selectedPartnersUids = getSelectedPartners().map((partner) => partner.uid!);
    
    partnerDelete(selectedPartnersUids)
      .then(() => {
        const partnersWithoutDeleted = {...partners};
  
        selectedPartnersUids.forEach((partnerUid) => {
          delete partnersWithoutDeleted[partnerUid];
        })
        setPartners(partnersWithoutDeleted);
        setSelectPartnersMode(false);
      })
      .catch(e => {
        if(e instanceof Promise) {
          e.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <BasePageWrapper contentWrapperClassName={styles['content-wrap']}>
      <Header className={styles['header']} additional={Object.keys(partners).length ? (
        <>
          <span  onClick={() => setModalVisible(true)}>
            <FilterOutlined />
          </span>
          <span className={styles['select-btn']} onClick={handleSelectClick}><SelectOutlined /></span>
        </>
      ) : undefined}
      >
        Напарники
      </Header>
      <div className={styles['content-wrapper']} style={{ paddingBottom: selectPartnersMode && hasSelectedPartners(partners) ? '148px' : '28px' }}>
        {Object.values(partners).sort(sortByFunc(partnerSortBy)).map((partner) => {
          return (
              <div className={styles['partner-card']} onClick={() => handlePlayerItemClick(partner)} key={partner.uid}>
                <div className={`${styles['select-indicator']} ${selectPartnersMode && partner.invitable ? styles['select-indicator_visible'] : ''}`}>
                  <SelectIndicator selected={partner.selected} />
                </div>
                <UserAvatar 
                  avatarUid={createImgSrc.playerProfile(partner.uid!, partner.avatarUid!)} 
                  nameInitials={partner.nameInitials} 
                  className={styles['avatar']}
                  badge={{position: 'top-right', element: <RatingBage rating={partner.displayRating?.slice(0, 3)!} />}}
                />
                <Link to={selectPartnersMode ? '' : `/profile/${partner.uid}`} className={styles['partner-link']}>
                  <div className={styles['partner-info']}>
                    <span className={styles['partner-name']}>{partner.displayName}</span>
                  </div>
                  <span className={styles['partner-match-count']}>Игр вместе: <b>{partner.playedGames}</b></span>
                </Link>
                {!selectPartnersMode && partner.invitable && <img className={styles['invite-btn']} src={tennisRacketImg} alt="Теннисная ракетка" onClick={() => handleInviteBtnClick(partner)}/>}
              </div>
            )
        })}
      </div>
      {selectPartnersMode && hasSelectedPartners(partners) && <OversideWrapper className={styles['actionBtnWrap']}>
        <Button style={{borderBottom: '10px'}} onClick={handleCreateBtnClick}>
          {selectedPartnersCount > 1 ? 'Создать игру с напарниками' : 'Создать игру с напарником'}
        </Button>
        {currentUser?.identity?.uid === current.identity?.uid ?
          <Button variant={ButtonVariants.CANCEL} bordered onClick={handleDeletePartnersBtn}>Удалить выбранных напарников</Button> : null
        }
      </OversideWrapper>}


      <FullscreenLoader loading={loading} />

      <FullScreenModal visible={filterModalVisible} title='Фильтр' onDone={() => setModalVisible(false)}>
        <div className={styles['radio']}>
          <h3>Сортировка</h3>

          <RadioButton
            name='sortBy'
            value="rating"
            label="По внутриигровому рейтингу"
            onClick={() => setPartnerSortBy('rating')}
            checked={partnerSortBy === 'rating'}
            readOnly
          />

          <RadioButton
            name='sortBy'
            value="matchTogether"
            label="По количеству игр вместе"
            onClick={() => setPartnerSortBy('matchTogether')}
            checked={partnerSortBy === 'matchTogether'}
            readOnly
          />
        </div>
      </FullScreenModal>
    </BasePageWrapper>
  );
};

export default React.memo(ProfilePartners);
