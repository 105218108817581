import { LundapadelProfileApiProfileQuestionnaireLoadRequest, PlayedMatches, ProfileQuestionnaireSaveResponse, ProfileQuestionnaireView, RegisterResponse } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export type SaveProfileType = 'Регистрация' | 'Анкета';

export function getQuestionaries(parameters: LundapadelProfileApiProfileQuestionnaireLoadRequest) {
  return RestClient.profileApi.profileQuestionnaireLoad(parameters);
}

export function saveProfile<T extends SaveProfileType>(view: ProfileQuestionnaireView, type: T): T extends 'Регистрация' ? Promise<RegisterResponse> : Promise<ProfileQuestionnaireSaveResponse>{
  const authData = sessionStorage.getItem('authData');
  if (authData) {
    try {
      const parsedData = JSON.parse(authData);
      if (type === 'Анкета') {
        return RestClient.profileApi.profileQuestionnaireSave({
          profileQuestionnaireSaveRequest: {
            parameters: {
              ...parsedData,
              view
            }
          }
        })
      } else if (type === 'Регистрация') {
        return RestClient.profileApi.register({
          registerRequest: {
            parameters: {
              ...parsedData,
              view
            }
          }
        })
      }

    } catch (err) { // сработает, если в JSON.parse передана невалидная строка 
      const message = err instanceof Error ? err.message : 'Ошибка при чтении JSON в sessionStorage'
      return Promise.reject(message);
    }
  }
  return Promise.reject('Нет данных аутентификации в sessionStorage');
}

export function registerUser() {
}

export function gradesLoad() {
  return RestClient.mainApi.gradeRatingLoad({
    reversed: false,
  })
}
