/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CheckVersionResult,
    CheckVersionResultFromJSON,
    CheckVersionResultFromJSONTyped,
    CheckVersionResultToJSON,
} from './CheckVersionResult';

/**
 * 
 * @export
 * @interface MinimumSupportedVersionCheckResponse
 */
export interface MinimumSupportedVersionCheckResponse {
    /**
     * 
     * @type {CheckVersionResult}
     * @memberof MinimumSupportedVersionCheckResponse
     */
    result?: CheckVersionResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof MinimumSupportedVersionCheckResponse
     */
    messages?: Array<object>;
}

export function MinimumSupportedVersionCheckResponseFromJSON(json: any): MinimumSupportedVersionCheckResponse {
    return MinimumSupportedVersionCheckResponseFromJSONTyped(json, false);
}

export function MinimumSupportedVersionCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinimumSupportedVersionCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : CheckVersionResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function MinimumSupportedVersionCheckResponseToJSON(value?: MinimumSupportedVersionCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': CheckVersionResultToJSON(value.result),
        'messages': value.messages,
    };
}

