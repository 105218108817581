import Headling from '@/components/Headling/Headling';
import styles from './ClubCardCityBelonging.module.css';
import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import ClubCard from '../ClubCard';

type ClubCardCityBelongingProps = {
  city?: string,
  clubs: Array<ClubView>;
}

const ClubCardCityBelonging = ({ clubs, city }: ClubCardCityBelongingProps) => {
  return ( 
    <div>
      <Headling appearence='big-normal' className={styles['headling']}>{city}</Headling>
      <div className={styles['clubs-wrapper']}>
        {clubs &&
          clubs.map((club: ClubView) => {
            return <ClubCard club={club} key={club.name} />;
          })}
      </div>
    </div>
  );
}
 
export default ClubCardCityBelonging;
