import styles from './RatingGrade.module.css';
import 'swiper/css';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import type SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAdditionalContentClass } from './RatingGradeUtils';
import type {
  RatingGradeProps,
} from './RatingGrade.interfaces';
import { gradeInfoLoadList, gradeRatingLoad } from './service';
import { GRADE_ID_PREFIX, uidRegExp } from '@/utils/constants';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const RatingGrade = ({ visibleGroups, isQuestionnaires, setCurrentIndex, activeIndexClassName }: RatingGradeProps): JSX.Element => {
  const [swiper, setSwiper] = useState<SwiperType>();
  const [grades, setGrades] = useState<Array<string | number> | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const onSlideClick = useCallback(
    (index: number) => {
      swiper?.slideToLoop(index);
      setCurrentIndex && setCurrentIndex(index)
    },
    [swiper]
  );

  const gradeRating = useCallback(async () => {
    try {
      const { result } = await gradeRatingLoad(false);
      if (result) {
        const gradeRatings = result?.gradeRatings || [];
        setGrades([...gradeRatings]);
      }
    } catch (err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const gradeInfoList = useCallback(async () => {
    try {
      const { result } = await gradeInfoLoadList();
      if (result) {
        const gradeRatings = result?.infos?.map(el=> el.rating?.toFixed(1) ?? '') || [];
        setGrades([...gradeRatings]);
        setCurrentIndex(0);
      }
    } catch (err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const slideTo = (group: string) => {
    if(swiper && grades) {
      const index = grades.findIndex(g => g === group);
      if(index > -1) {
        swiper.slideToLoop(index);
      }
    }
  };

  useEffect(() => {
    if(!window.location.hash.includes(`#${GRADE_ID_PREFIX}`) || window.location.hash.match(uidRegExp)?.[0]) {
      if(visibleGroups && grades?.length) {
        slideTo(visibleGroups[0])
      }
    }
  }, [visibleGroups, grades?.length, window.location.hash]);


  useEffect(() => {
    if(!isQuestionnaires){
      if (!grades) {
        gradeRating();
      }
    } else {
      if (!grades) {
        gradeInfoList()
      }
    }
  }, [grades, gradeRating]);

  const activeGradeClass = activeIndexClassName || styles['swiper-slide-content-quest--active']

  return (
    <>
      <div className={styles['rating-grade']}>
        {grades && (
          <Swiper
            slidesPerView={5}
            spaceBetween={30}
            onSwiper={setSwiper}
            onSlideChange={(swiper: SwiperType) => {
              if(!isQuestionnaires){
                if(window.location.hash.match(uidRegExp)?.[0]) {
                  if(timer.current) {
                    clearTimeout(timer.current);
                  }
                  timer.current = setTimeout(() => {
                    window.location.replace(`#`);
                  }, 500);
                } else {
                  if(grades.findIndex(g => g === visibleGroups[0]) !== swiper.realIndex) {
                    window.location.replace(`#${GRADE_ID_PREFIX}${grades[swiper.realIndex]}`);
                    if(timer.current) {
                      clearTimeout(timer.current);
                    }
                    timer.current = setTimeout(() => {
                      window.location.replace(`#`);
                    }, 500);
                  }
                }
              } else {
                setCurrentIndex(swiper.realIndex)
              }
            }}
            centeredSlides
            className={styles['swiper']}
            initialSlide={isQuestionnaires ? 0 : grades.findIndex(g => g === visibleGroups[0])}
          >
            {grades.map((value, index) => {
              return (
                <SwiperSlide
                  key={`${value}_${index}`}
                  className={styles['swiper-slide']}
                  onClick={() => onSlideClick(index)}
                >
                  {({
                    isActive,
                    isNext,
                    isPrev,
                  }: {
                    isActive: boolean;
                    isNext: boolean;
                    isPrev: boolean;
                  }) => {
                    return (
                      <div
                        className={`${
                          styles['swiper-slide-content']
                        } ${getAdditionalContentClass({
                          isActive,
                          isPrev,
                          isNext,
                        })} ${isActive && isQuestionnaires ? 
                          activeGradeClass :''}`
                          .trimEnd()}
                      >
                        {value}
                      </div>
                    );
                  }}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </>
  );
};

export default RatingGrade;
