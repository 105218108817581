/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TourGameLoadListParam,
    TourGameLoadListParamFromJSON,
    TourGameLoadListParamFromJSONTyped,
    TourGameLoadListParamToJSON,
} from './TourGameLoadListParam';

/**
 * 
 * @export
 * @interface LoadTourGameListRequest
 */
export interface LoadTourGameListRequest {
    /**
     * 
     * @type {TourGameLoadListParam}
     * @memberof LoadTourGameListRequest
     */
    parameters?: TourGameLoadListParam;
}

export function LoadTourGameListRequestFromJSON(json: any): LoadTourGameListRequest {
    return LoadTourGameListRequestFromJSONTyped(json, false);
}

export function LoadTourGameListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadTourGameListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TourGameLoadListParamFromJSON(json['parameters']),
    };
}

export function LoadTourGameListRequestToJSON(value?: LoadTourGameListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TourGameLoadListParamToJSON(value.parameters),
    };
}

