import { FC } from 'react';
import styles from './DateItem.module.css'
import dayjs from 'dayjs';
import { LocalDateTime } from '@/integration-api/server-rest-lundapadelApi';

export interface DateItemProps extends React.AllHTMLAttributes<HTMLInputElement> {
  day: string,
  date: number,
  month: string,
  value?: any,
  activeDate?: LocalDateTime,
}
const DateItem: FC<DateItemProps> = ({ value, day, date, month, activeDate, ...props }) => {

  const isActiveDate = dayjs(activeDate).isSame(value, 'date');

  return (
    <label className={styles.radioBtnLabel}>
      <input
        className={styles.radioInput}
        type="radio"
        name='plannedDate.date' 
        checked={isActiveDate ?? false}
        value={value}
        readOnly
        {...props}
      />
      <div className={styles.container}>
        <div className={styles.day}>{day}</div>
        <div className={styles.date}>{date}</div>
        <div className={styles.month}>{month}</div>
      </div>
    </label>

  );
}

export default DateItem;
