function BallLogo() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.3496 22.6504C8.40413 24.703 11.1607 25.9 14.0631 26C13.5295 22.8037 14.4839 19.4057 16.9448 16.9448C19.4068 14.4839 22.8037 13.5295 26 14.0631C25.894 11.2587 24.7863 8.48547 22.6504 6.3496C20.5145 4.21374 17.7401 3.10604 14.9369 3C15.4705 6.1963 14.5161 9.59432 12.0552 12.0552C9.59316 14.5161 6.1963 15.4705 3 14.9369C3.09893 17.8396 4.29612 20.5965 6.3496 22.6504Z" fill="#C9C9C9"/>
      <path d="M18.5749 18.5748C17.6368 19.5136 16.945 20.6695 16.5607 21.9399C16.1765 23.2102 16.1117 24.5558 16.3722 25.8572C18.7502 25.4753 20.9471 24.353 22.6501 22.6499C24.3531 20.9469 25.4755 18.75 25.8574 16.372C24.556 16.1119 23.2105 16.1768 21.9402 16.561C20.6699 16.9452 19.514 17.6369 18.5749 18.5748ZM10.4257 10.4255C11.3638 9.48666 12.0557 8.33077 12.4399 7.0604C12.8241 5.79003 12.8889 4.44446 12.6284 3.14307C10.2502 3.52474 8.05308 4.647 6.34983 6.35004C4.64658 8.05309 3.52405 10.2501 3.14209 12.6282C4.44367 12.8885 5.78935 12.8237 7.05987 12.4394C8.33038 12.0552 9.48648 11.3635 10.4257 10.4255Z" fill="#C9C9C9"/>
    </svg>
  );
}

export default BallLogo
