/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BookedCourtView,
    BookedCourtViewFromJSON,
    BookedCourtViewFromJSONTyped,
    BookedCourtViewToJSON,
} from './BookedCourtView';
import {
    CalculatedGameStatus,
    CalculatedGameStatusFromJSON,
    CalculatedGameStatusFromJSONTyped,
    CalculatedGameStatusToJSON,
} from './CalculatedGameStatus';
import {
    ClubInfo,
    ClubInfoFromJSON,
    ClubInfoFromJSONTyped,
    ClubInfoToJSON,
} from './ClubInfo';
import {
    GameCondition,
    GameConditionFromJSON,
    GameConditionFromJSONTyped,
    GameConditionToJSON,
} from './GameCondition';
import {
    GameInviteView,
    GameInviteViewFromJSON,
    GameInviteViewFromJSONTyped,
    GameInviteViewToJSON,
} from './GameInviteView';
import {
    GameLineupView,
    GameLineupViewFromJSON,
    GameLineupViewFromJSONTyped,
    GameLineupViewToJSON,
} from './GameLineupView';
import {
    GameMemberView,
    GameMemberViewFromJSON,
    GameMemberViewFromJSONTyped,
    GameMemberViewToJSON,
} from './GameMemberView';
import {
    GamePermissionsView,
    GamePermissionsViewFromJSON,
    GamePermissionsViewFromJSONTyped,
    GamePermissionsViewToJSON,
} from './GamePermissionsView';
import {
    GameTournamentKind,
    GameTournamentKindFromJSON,
    GameTournamentKindFromJSONTyped,
    GameTournamentKindToJSON,
} from './GameTournamentKind';
import {
    GameTournamentStatus,
    GameTournamentStatusFromJSON,
    GameTournamentStatusFromJSONTyped,
    GameTournamentStatusToJSON,
} from './GameTournamentStatus';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    InformationMessage,
    InformationMessageFromJSON,
    InformationMessageFromJSONTyped,
    InformationMessageToJSON,
} from './InformationMessage';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';
import {
    ViewObjectIdentity,
    ViewObjectIdentityFromJSON,
    ViewObjectIdentityFromJSONTyped,
    ViewObjectIdentityToJSON,
} from './ViewObjectIdentity';

/**
 * 
 * @export
 * @interface GameView
 */
export interface GameView {
    /**
     * 
     * @type {string}
     * @memberof GameView
     */
    key?: string;
    /**
     * 
     * @type {ViewObjectIdentity}
     * @memberof GameView
     */
    identity?: ViewObjectIdentity;
    /**
     * 
     * @type {ClubInfo}
     * @memberof GameView
     */
    club?: ClubInfo;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof GameView
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof GameView
     */
    closingDate?: LocalDateTime;
    /**
     * 
     * @type {Grade}
     * @memberof GameView
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof GameView
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof GameView
     */
    displayMinGrade?: string;
    /**
     * 
     * @type {string}
     * @memberof GameView
     */
    displayMaxGrade?: string;
    /**
     * 
     * @type {CalculatedGameStatus}
     * @memberof GameView
     */
    status?: CalculatedGameStatus;
    /**
     * 
     * @type {boolean}
     * @memberof GameView
     */
    courtBooked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GameView
     */
    courtNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GameView
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GameView
     */
    ranking?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GameView
     */
    privateGame?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GameView
     */
    duration?: number;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameView
     */
    owner?: PlayerProfileInfo;
    /**
     * 
     * @type {number}
     * @memberof GameView
     */
    playersLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GameView
     */
    isAuthUserParticipating?: boolean;
    /**
     * 
     * @type {GamePermissionsView}
     * @memberof GameView
     */
    permissions?: GamePermissionsView;
    /**
     * 
     * @type {GameTournamentKind}
     * @memberof GameView
     */
    gameTournamentKind?: GameTournamentKind;
    /**
     * 
     * @type {GameTournamentStatus}
     * @memberof GameView
     */
    tournamentStatus?: GameTournamentStatus;
    /**
     * 
     * @type {GameCondition}
     * @memberof GameView
     */
    condition?: GameCondition;
    /**
     * 
     * @type {Array<InformationMessage>}
     * @memberof GameView
     */
    messages?: Array<InformationMessage>;
    /**
     * 
     * @type {Array<GameMemberView>}
     * @memberof GameView
     */
    members?: Array<GameMemberView>;
    /**
     * 
     * @type {Array<GameLineupView>}
     * @memberof GameView
     */
    lineups?: Array<GameLineupView>;
    /**
     * 
     * @type {Array<BookedCourtView>}
     * @memberof GameView
     */
    bookedCourts?: Array<BookedCourtView>;
    /**
     * 
     * @type {Array<GameInviteView>}
     * @memberof GameView
     */
    invitations?: Array<GameInviteView>;
}

export function GameViewFromJSON(json: any): GameView {
    return GameViewFromJSONTyped(json, false);
}

export function GameViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'identity': !exists(json, 'identity') ? undefined : ViewObjectIdentityFromJSON(json['identity']),
        'club': !exists(json, 'club') ? undefined : ClubInfoFromJSON(json['club']),
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'closingDate': !exists(json, 'closingDate') ? undefined : LocalDateTimeFromJSON(json['closingDate']),
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'displayMinGrade': !exists(json, 'displayMinGrade') ? undefined : json['displayMinGrade'],
        'displayMaxGrade': !exists(json, 'displayMaxGrade') ? undefined : json['displayMaxGrade'],
        'status': !exists(json, 'status') ? undefined : CalculatedGameStatusFromJSON(json['status']),
        'courtBooked': !exists(json, 'courtBooked') ? undefined : json['courtBooked'],
        'courtNumber': !exists(json, 'courtNumber') ? undefined : json['courtNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ranking': !exists(json, 'ranking') ? undefined : json['ranking'],
        'privateGame': !exists(json, 'privateGame') ? undefined : json['privateGame'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'owner': !exists(json, 'owner') ? undefined : PlayerProfileInfoFromJSON(json['owner']),
        'playersLimit': !exists(json, 'playersLimit') ? undefined : json['playersLimit'],
        'isAuthUserParticipating': !exists(json, 'isAuthUserParticipating') ? undefined : json['isAuthUserParticipating'],
        'permissions': !exists(json, 'permissions') ? undefined : GamePermissionsViewFromJSON(json['permissions']),
        'gameTournamentKind': !exists(json, 'gameTournamentKind') ? undefined : GameTournamentKindFromJSON(json['gameTournamentKind']),
        'tournamentStatus': !exists(json, 'tournamentStatus') ? undefined : GameTournamentStatusFromJSON(json['tournamentStatus']),
        'condition': !exists(json, 'condition') ? undefined : GameConditionFromJSON(json['condition']),
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(InformationMessageFromJSON)),
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(GameMemberViewFromJSON)),
        'lineups': !exists(json, 'lineups') ? undefined : ((json['lineups'] as Array<any>).map(GameLineupViewFromJSON)),
        'bookedCourts': !exists(json, 'bookedCourts') ? undefined : ((json['bookedCourts'] as Array<any>).map(BookedCourtViewFromJSON)),
        'invitations': !exists(json, 'invitations') ? undefined : ((json['invitations'] as Array<any>).map(GameInviteViewFromJSON)),
    };
}

export function GameViewToJSON(value?: GameView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'identity': ViewObjectIdentityToJSON(value.identity),
        'club': ClubInfoToJSON(value.club),
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'closingDate': LocalDateTimeToJSON(value.closingDate),
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'displayMinGrade': value.displayMinGrade,
        'displayMaxGrade': value.displayMaxGrade,
        'status': CalculatedGameStatusToJSON(value.status),
        'courtBooked': value.courtBooked,
        'courtNumber': value.courtNumber,
        'description': value.description,
        'ranking': value.ranking,
        'privateGame': value.privateGame,
        'duration': value.duration,
        'owner': PlayerProfileInfoToJSON(value.owner),
        'playersLimit': value.playersLimit,
        'isAuthUserParticipating': value.isAuthUserParticipating,
        'permissions': GamePermissionsViewToJSON(value.permissions),
        'gameTournamentKind': GameTournamentKindToJSON(value.gameTournamentKind),
        'tournamentStatus': GameTournamentStatusToJSON(value.tournamentStatus),
        'condition': GameConditionToJSON(value.condition),
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(InformationMessageToJSON)),
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(GameMemberViewToJSON)),
        'lineups': value.lineups === undefined ? undefined : ((value.lineups as Array<any>).map(GameLineupViewToJSON)),
        'bookedCourts': value.bookedCourts === undefined ? undefined : ((value.bookedCourts as Array<any>).map(BookedCourtViewToJSON)),
        'invitations': value.invitations === undefined ? undefined : ((value.invitations as Array<any>).map(GameInviteViewToJSON)),
    };
}

