/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface BookingParameters
 */
export interface BookingParameters {
    /**
     * 
     * @type {Reference}
     * @memberof BookingParameters
     */
    club?: Reference;
    /**
     * 
     * @type {Reference}
     * @memberof BookingParameters
     */
    court?: Reference;
    /**
     * 
     * @type {number}
     * @memberof BookingParameters
     */
    tariffId?: number;
    /**
     * 
     * @type {LocalDate}
     * @memberof BookingParameters
     */
    date?: LocalDate;
    /**
     * 
     * @type {string}
     * @memberof BookingParameters
     */
    time?: string;
}

export function BookingParametersFromJSON(json: any): BookingParameters {
    return BookingParametersFromJSONTyped(json, false);
}

export function BookingParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'court': !exists(json, 'court') ? undefined : ReferenceFromJSON(json['court']),
        'tariffId': !exists(json, 'tariffId') ? undefined : json['tariffId'],
        'date': !exists(json, 'date') ? undefined : LocalDateFromJSON(json['date']),
        'time': !exists(json, 'time') ? undefined : json['time'],
    };
}

export function BookingParametersToJSON(value?: BookingParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'club': ReferenceToJSON(value.club),
        'court': ReferenceToJSON(value.court),
        'tariffId': value.tariffId,
        'date': LocalDateToJSON(value.date),
        'time': value.time,
    };
}

