import dayjs, { Dayjs, isDayjs } from 'dayjs';

export type LocalDate = `${number}-${number}-${number}`;

export function LocalDateFromJSON(json: any): LocalDate {
  return LocalDateFromJSONTyped(json, false);
}

export function LocalDateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LocalDate {
  if (json === undefined || json === null) {
    return json;
  }
  return json as LocalDate;
}

export function LocalDateToJSON(value?: LocalDate | Date | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  if (isDayjs(value)) {
    return value.format('YYYY-MM-DD');
  } else if (value instanceof Date) {
    return dayjs(value).set('hour', 12).set('minutes', 30).format('YYYY-MM-DD');
  }
  return value;
}

export function toLocalDate(
  sourceDate: Date | Dayjs | null | undefined
): LocalDate | undefined {
  if (!sourceDate || sourceDate === null) {
    return undefined;
  }

  let date: dayjs.Dayjs;

  if (!isDayjs(sourceDate)) {
    date = dayjs(sourceDate);
  } else {
    date = sourceDate;
  }

  return date.format('YYYY-MM-DD') as LocalDate;
}
