import styles from '../GamePage.module.css';
import Header from "@/components/Header/Header";
import EditGameIcon from "@/static/images/icons/EditGameIcon";
import EntityCalendarIcon from "@/static/images/icons/EntityCalendarIcon";
import ShareIcon from "@/static/images/icons/ShareIcon";
import { useCallback } from "react";
import { CalculatedGameStatus } from "@/integration-api/server-rest-lundapadelApi";
import { useAppSelector } from '@/hooks/hooks';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import formatter, { createImgSrc } from '@/utils/utils';
import { Share } from '@capacitor/share';
import PageBackground from '@/components/PageBackground/PageBackground';
import bgImage from '@/static/images/match-page-background.jpeg';
import PlannedGameContent from '../GameStatusContent/PlannedGameContent';
import { PuffLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Browser } from '@capacitor/browser';
import { IGamePageContext } from '../GamePage.interfaces';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import { gameModifiableStatuses } from '../utils';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const PlannedGamePage = () => {
  const { game, goBackHandler, initialLoading } = useOutletContext<IGamePageContext>();
  const { id } = useParams();
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();

  const shareGameHandler = useCallback(async () => {
    const { value } = await Share.canShare()
    if (value) {
      await Share.share({
        title: 'Присоединяйся к матчу',
        text: `Корт: ${game?.club?.displayName ?? ''}, Дата: ${formatter.formatDateToDayDateMonth(game?.plannedDate)}`,
        url: `https://app.lundapadel.ru/share/game/${id}`
      });

    } else {
      if (navigator.share !== undefined) {
        const shareData: ShareData = {
          title: 'Присоединяйся к матчу',
          text: `Корт: ${game?.club?.displayName || ''}, Дата: ${formatter.formatDateToDayDateMonth(game?.plannedDate)}`,
          url: `${window.location.origin}/share${window.location.pathname}`,
        };
        await navigator.share(shareData);
      } else {
        PopupMessage.open('Функционал не поддерживается');
      }
    }
  }, [game?.plannedDate, game?.club?.displayName, id]);

  const renderCalendarButton = () => {
    if(Capacitor.isNativePlatform()){
      return (
        <div className={styles['header-button']} onClick={async () => {
          try {
            if(Capacitor.getPlatform() === 'ios') {
              await Browser.open({
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=GAME&type=&inline=true`,
                presentationStyle: 'popover'
              });
            } else {
              const { path } = await Filesystem.downloadFile({
                path: 'calendar.ics',
                directory: Directory.Data,
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=GAME&type=&inline=true`,
              });
              if(path) {
                await FileOpener.open({
                  filePath: path
                });
              }
            }
          } catch(err) {
            PopupMessage.open('Произошла ошибка')
          }
        }}>
          <EntityCalendarIcon />
        </div>
      )
    } else {
      return (
        <div>
          <a className={styles['header-button']} href={`${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=GAME&type=&inline=true`} download>
            <EntityCalendarIcon />
          </a> 
        </div>
      )
    }
  }

  if(game?.status && !gameModifiableStatuses.includes(game?.status)) {
    if(game.status === CalculatedGameStatus.CANCELED || game.status === CalculatedGameStatus.SCORE_UNDEFINED) {
      return <Navigate to={`/my-games?tab=completed`} replace={true}/>
    } else {
      return <Navigate to={`/game/${id}/completed`} replace={true} />
    }
  }

  return (  
    <BasePageWrapper showNavBar>
      <Header
        className={styles['header']}
        additional={
          <div className={styles['header-buttons']}>
            {game.status === CalculatedGameStatus.PLANNED ?
              <>
                {renderCalendarButton()}
              </> : null
            }
            {game?.owner?.uid === current.identity?.uid && game?.status === CalculatedGameStatus.PLANNED ?
              <div className={styles['header-button']} onClick={() => navigate(`/game/${id}/settings`)}>
                <EditGameIcon/>
              </div> : null
            }
            <div className={styles['header-button']} onClick={shareGameHandler}>
              <ShareIcon />
            </div>
          </div>
        }
        handleClick={goBackHandler()}
      >
        Игра
      </Header>
      <PageBackground imgSrc={bgImage}/>
      {initialLoading ?
        <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} /> :      
        <PlannedGameContent />
      }
    </BasePageWrapper>
  );
}
 
export default PlannedGamePage;
