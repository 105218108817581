/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackLoadListMessagesResult,
    FeedbackLoadListMessagesResultFromJSON,
    FeedbackLoadListMessagesResultFromJSONTyped,
    FeedbackLoadListMessagesResultToJSON,
} from './FeedbackLoadListMessagesResult';

/**
 * 
 * @export
 * @interface FeedbackLoadListMessageResponse
 */
export interface FeedbackLoadListMessageResponse {
    /**
     * 
     * @type {FeedbackLoadListMessagesResult}
     * @memberof FeedbackLoadListMessageResponse
     */
    result?: FeedbackLoadListMessagesResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof FeedbackLoadListMessageResponse
     */
    messages?: Array<object>;
}

export function FeedbackLoadListMessageResponseFromJSON(json: any): FeedbackLoadListMessageResponse {
    return FeedbackLoadListMessageResponseFromJSONTyped(json, false);
}

export function FeedbackLoadListMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackLoadListMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : FeedbackLoadListMessagesResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function FeedbackLoadListMessageResponseToJSON(value?: FeedbackLoadListMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': FeedbackLoadListMessagesResultToJSON(value.result),
        'messages': value.messages,
    };
}

