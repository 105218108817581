import styles from './UserChat.module.css';
import Messages from './components/messages/Messages';
import MessageControl from './components/messageControl/MessageControl';
import { useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

interface UserChatProps {
}

const UserChat: React.FC<UserChatProps> = () => {
  const controlRef = useRef<HTMLDivElement>(null);
  
  if(Capacitor.isPluginAvailable('Keyboard')) {
    Keyboard.addListener('keyboardWillShow', info => {
      if(controlRef.current) {
        controlRef.current.classList.add(styles['keyboard-shown']);
        controlRef.current.style.height = `calc(100% - ${info.keyboardHeight}px)`;
      }
    })
  
    Keyboard.addListener('keyboardWillHide', () => {
      if(controlRef.current) {
        controlRef.current.classList.remove(styles['keyboard-shown']);
        controlRef.current.style.height = '100%';
      }
    })
  }

  useEffect(() => {
    return () => {
      if(Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.removeAllListeners().catch(err => console.log(err))
      }
    }
  }, []);

  return (
    <div className={styles.container} ref={controlRef}>
      <Messages />

      <div className={styles.control}>
        <MessageControl />
      </div>
    </div>
  );
}
 
export default UserChat;
