import { Role, TournamentMemberView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentTableItem.module.css';
import { renderPlacement } from './utils';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import { createImgSrc } from '@/utils/utils';
import { EMPTY_STRING } from '@/utils/constants';
import { useAppSelector } from '@/hooks/hooks';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { useNavigate } from 'react-router-dom';
import ChangeVector from '@/pages/application/rating/components/PlayerRating/svg/ChangeVector';

type TournamentTableItemProps = {
  participant: TournamentMemberView;
  placement: number;
}

const TournamentTableItem = ({ participant, placement }: TournamentTableItemProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();

  return ( 
    <div className={`${styles['wrapper']} ${placement === 1 ? styles['no-top-border']: EMPTY_STRING} ${current.identity?.uid === participant.player?.uid ? styles['yourself'] : EMPTY_STRING}`}>
      <div className={styles['main-content']}>
        {renderPlacement(placement)}
        <UserAvatar
          avatarUid={createImgSrc.playerProfile(participant.player?.uid, participant.player?.avatarUid)}
          nameInitials={participant.player?.nameInitials}
          badge={{
            element: participant.player?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
            position: 'top-left'
          }}
          onClick={participant.player?.active ? () => navigate(`/profile/${current.identity?.uid === participant.player?.uid ? 'me' : participant.player?.uid}`) : undefined}
        />
        <div className={styles['info']}>
          <span 
            className={styles['name']}
            onClick={participant.player?.active ? () => navigate(`/profile/${current.identity?.uid === participant.player?.uid ? 'me' : participant.player?.uid}`) : undefined}
          >
            {participant.player?.displayName?.substring(0, 25) || '-'}
          </span>
          <div className={styles['rating-value']}>
            {Number(participant.tournamentDisplayRatingChange) > 0 && (
              <span className={styles.positive}><ChangeVector vector="up" />{participant?.tournamentDisplayRatingChange}</span>
            )}
            {Number(participant.tournamentDisplayRatingChange) < 0 && (
              <span className={styles.negative}><ChangeVector vector="down" />{participant?.tournamentDisplayRatingChange}</span>
            )}
            <span>{participant.displayRating}</span>
          </div>
        </div>
      </div>
    </div>     
  );
}
 
export default TournamentTableItem;
