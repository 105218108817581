import React from 'react';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import styles from './ErrorPage.module.css';
import Button from '@/components/Button/Button';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import page404Illustration from '@/static/images/page404.svg';
import { Link } from 'react-router-dom';

function ErrorPage() {
  return (
    <BasePageWrapper contentWrapperClassName={styles['page-wrap']}>
      <img src={page404Illustration} alt="404 illustration" className={styles['illustration']} />

      <OversideWrapper className={styles['button-wrap']}>
        <Link to="/">
          <Button>На главную</Button>
        </Link>
      </OversideWrapper>
    </BasePageWrapper>
  );
}

export default ErrorPage;
