/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdvertisementPlaceType,
    AdvertisementPlaceTypeFromJSON,
    AdvertisementPlaceTypeToJSON,
    AttachmentUploadResponse,
    AttachmentUploadResponseFromJSON,
    AttachmentUploadResponseToJSON,
    ChatLoadListResponse,
    ChatLoadListResponseFromJSON,
    ChatLoadListResponseToJSON,
    ChatMessageLoadListResponse,
    ChatMessageLoadListResponseFromJSON,
    ChatMessageLoadListResponseToJSON,
    ChatMessageSendRequest,
    ChatMessageSendRequestFromJSON,
    ChatMessageSendRequestToJSON,
    ChatMessageSendResponse,
    ChatMessageSendResponseFromJSON,
    ChatMessageSendResponseToJSON,
    ChatSilentModeRequest,
    ChatSilentModeRequestFromJSON,
    ChatSilentModeRequestToJSON,
    ChatType,
    ChatTypeFromJSON,
    ChatTypeToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    EventExportType,
    EventExportTypeFromJSON,
    EventExportTypeToJSON,
    ExistNotificationResponse,
    ExistNotificationResponseFromJSON,
    ExistNotificationResponseToJSON,
    FeedbackLoadListMessageResponse,
    FeedbackLoadListMessageResponseFromJSON,
    FeedbackLoadListMessageResponseToJSON,
    FeedbackReadResponse,
    FeedbackReadResponseFromJSON,
    FeedbackReadResponseToJSON,
    FeedbackReplyExistResponse,
    FeedbackReplyExistResponseFromJSON,
    FeedbackReplyExistResponseToJSON,
    FeedbackSendMessageRequest,
    FeedbackSendMessageRequestFromJSON,
    FeedbackSendMessageRequestToJSON,
    FeedbackSendRequest,
    FeedbackSendRequestFromJSON,
    FeedbackSendRequestToJSON,
    FirebaseNotificationsSubscribeRequest,
    FirebaseNotificationsSubscribeRequestFromJSON,
    FirebaseNotificationsSubscribeRequestToJSON,
    FirebaseNotificationsUnsubscribeRequest,
    FirebaseNotificationsUnsubscribeRequestFromJSON,
    FirebaseNotificationsUnsubscribeRequestToJSON,
    GradeInfoLoadListResponse,
    GradeInfoLoadListResponseFromJSON,
    GradeInfoLoadListResponseToJSON,
    GradeRatingLoadResponse,
    GradeRatingLoadResponseFromJSON,
    GradeRatingLoadResponseToJSON,
    LoadAdvertisementResponse,
    LoadAdvertisementResponseFromJSON,
    LoadAdvertisementResponseToJSON,
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeToJSON,
    MinimumSupportedVersionCheckResponse,
    MinimumSupportedVersionCheckResponseFromJSON,
    MinimumSupportedVersionCheckResponseToJSON,
    NewsItemLoadListRequest,
    NewsItemLoadListRequestFromJSON,
    NewsItemLoadListRequestToJSON,
    NewsItemLoadListResponse,
    NewsItemLoadListResponseFromJSON,
    NewsItemLoadListResponseToJSON,
    NewsItemLoadRequest,
    NewsItemLoadRequestFromJSON,
    NewsItemLoadRequestToJSON,
    NewsItemLoadResponse,
    NewsItemLoadResponseFromJSON,
    NewsItemLoadResponseToJSON,
    NotificationLoadListResponse,
    NotificationLoadListResponseFromJSON,
    NotificationLoadListResponseToJSON,
    NotificationsSubscribeRequest,
    NotificationsSubscribeRequestFromJSON,
    NotificationsSubscribeRequestToJSON,
    NotificationsUnsubscribeRequest,
    NotificationsUnsubscribeRequestFromJSON,
    NotificationsUnsubscribeRequestToJSON,
    PublicKeyLoadResponse,
    PublicKeyLoadResponseFromJSON,
    PublicKeyLoadResponseToJSON,
    ReadAllNotificationRequest,
    ReadAllNotificationRequestFromJSON,
    ReadAllNotificationRequestToJSON,
    ReadNotificationRequest,
    ReadNotificationRequestFromJSON,
    ReadNotificationRequestToJSON,
    TelegramCheckAvailableResponse,
    TelegramCheckAvailableResponseFromJSON,
    TelegramCheckAvailableResponseToJSON,
    TicketType,
    TicketTypeFromJSON,
    TicketTypeToJSON,
    WebsocketTicketRegisterResponse,
    WebsocketTicketRegisterResponseFromJSON,
    WebsocketTicketRegisterResponseToJSON,
} from '../models';

export interface LundapadelMainApiAttachmentUploadRequest {
    file?: Blob;
    lastModifiedDate?: LocalDateTime | null;
}

export interface LundapadelMainApiCalendarEventExportRequest {
    entityUid: string;
    entityType: EventExportType;
    type: string;
    inline: boolean;
}

export interface LundapadelMainApiChatLoadListRequest {
    relatedEntityUid: string;
    type: ChatType;
}

export interface LundapadelMainApiChatMessageLoadListRequest {
    chatUid: string;
    offset: number;
    limit: number;
    lastMessageDate?: LocalDateTime;
}

export interface LundapadelMainApiChatMessageSendOperationRequest {
    chatMessageSendRequest: ChatMessageSendRequest;
}

export interface LundapadelMainApiChatSilentModeOperationRequest {
    chatSilentModeRequest: ChatSilentModeRequest;
}

export interface LundapadelMainApiFeedbackLoadListMessageRequest {
    offset: number;
    limit: number;
}

export interface LundapadelMainApiFeedbackReadRequest {
    lastFeedbackMessageDate: LocalDateTime;
}

export interface LundapadelMainApiFeedbackSendOperationRequest {
    feedbackSendRequest: FeedbackSendRequest;
}

export interface LundapadelMainApiFeedbackSendMessageOperationRequest {
    feedbackSendMessageRequest: FeedbackSendMessageRequest;
}

export interface LundapadelMainApiFirebaseNotificationsSubscribeOperationRequest {
    firebaseNotificationsSubscribeRequest: FirebaseNotificationsSubscribeRequest;
}

export interface LundapadelMainApiFirebaseNotificationsUnsubscribeOperationRequest {
    firebaseNotificationsUnsubscribeRequest: FirebaseNotificationsUnsubscribeRequest;
}

export interface LundapadelMainApiGradeRatingLoadRequest {
    reversed?: boolean;
    available?: boolean;
}

export interface LundapadelMainApiLoadAdvertisementRequest {
    type: AdvertisementPlaceType;
}

export interface LundapadelMainApiMinimumSupportedVersionCheckRequest {
    version: string;
}

export interface LundapadelMainApiNewsItemLoadOperationRequest {
    newsItemLoadRequest: NewsItemLoadRequest;
}

export interface LundapadelMainApiNewsItemLoadListOperationRequest {
    newsItemLoadListRequest: NewsItemLoadListRequest;
}

export interface LundapadelMainApiNotificationLoadListRequest {
    offset: number;
    limit: number;
    unread?: boolean;
}

export interface LundapadelMainApiNotificationsSubscribeOperationRequest {
    notificationsSubscribeRequest: NotificationsSubscribeRequest;
}

export interface LundapadelMainApiNotificationsUnsubscribeOperationRequest {
    notificationsUnsubscribeRequest: NotificationsUnsubscribeRequest;
}

export interface LundapadelMainApiReadAllNotificationOperationRequest {
    readAllNotificationRequest: ReadAllNotificationRequest;
}

export interface LundapadelMainApiReadNotificationOperationRequest {
    readNotificationRequest: ReadNotificationRequest;
}

export interface LundapadelMainApiWebsocketTicketRegisterRequest {
    ticketType: TicketType;
    relatedEntityUid: string;
}

/**
 * 
 */
export class LundapadelMainApi extends runtime.BaseAPI {

    /**
     * Attachment upload
     */
    async attachmentUploadRaw(requestParameters: LundapadelMainApiAttachmentUploadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AttachmentUploadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.lastModifiedDate !== undefined) {
            formParams.append('lastModifiedDate', requestParameters.lastModifiedDate as any);
        }

        const response = await this.request({
            path: `/attachment/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentUploadResponseFromJSON(jsonValue));
    }

    /**
     * Attachment upload
     */
    async attachmentUpload(requestParameters: LundapadelMainApiAttachmentUploadRequest = {}, initOverrides?: RequestInit): Promise<AttachmentUploadResponse> {
        const response = await this.attachmentUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports calendar event to iCalendar format
     */
    async calendarEventExportRaw(requestParameters: LundapadelMainApiCalendarEventExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entityUid === null || requestParameters.entityUid === undefined) {
            throw new runtime.RequiredError('entityUid','Required parameter requestParameters.entityUid was null or undefined when calling calendarEventExport.');
        }

        if (requestParameters.entityType === null || requestParameters.entityType === undefined) {
            throw new runtime.RequiredError('entityType','Required parameter requestParameters.entityType was null or undefined when calling calendarEventExport.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling calendarEventExport.');
        }

        if (requestParameters.inline === null || requestParameters.inline === undefined) {
            throw new runtime.RequiredError('inline','Required parameter requestParameters.inline was null or undefined when calling calendarEventExport.');
        }

        const queryParameters: any = {};

        if (requestParameters.entityUid !== undefined) {
            queryParameters['entityUid'] = requestParameters.entityUid;
        }

        if (requestParameters.entityType !== undefined) {
            queryParameters['entityType'] = requestParameters.entityType;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.inline !== undefined) {
            queryParameters['inline'] = requestParameters.inline;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/event/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Exports calendar event to iCalendar format
     */
    async calendarEventExport(requestParameters: LundapadelMainApiCalendarEventExportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.calendarEventExportRaw(requestParameters, initOverrides);
    }

    /**
     * Load list of chats by related entity uid and type
     */
    async chatLoadListRaw(requestParameters: LundapadelMainApiChatLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChatLoadListResponse>> {
        if (requestParameters.relatedEntityUid === null || requestParameters.relatedEntityUid === undefined) {
            throw new runtime.RequiredError('relatedEntityUid','Required parameter requestParameters.relatedEntityUid was null or undefined when calling chatLoadList.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling chatLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.relatedEntityUid !== undefined) {
            queryParameters['relatedEntityUid'] = requestParameters.relatedEntityUid;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/chat/load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of chats by related entity uid and type
     */
    async chatLoadList(requestParameters: LundapadelMainApiChatLoadListRequest, initOverrides?: RequestInit): Promise<ChatLoadListResponse> {
        const response = await this.chatLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list of chat messages
     */
    async chatMessageLoadListRaw(requestParameters: LundapadelMainApiChatMessageLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChatMessageLoadListResponse>> {
        if (requestParameters.chatUid === null || requestParameters.chatUid === undefined) {
            throw new runtime.RequiredError('chatUid','Required parameter requestParameters.chatUid was null or undefined when calling chatMessageLoadList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling chatMessageLoadList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling chatMessageLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.chatUid !== undefined) {
            queryParameters['chatUid'] = requestParameters.chatUid;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.lastMessageDate !== undefined) {
            queryParameters['lastMessageDate'] = requestParameters.lastMessageDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/chat/messages/load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of chat messages
     */
    async chatMessageLoadList(requestParameters: LundapadelMainApiChatMessageLoadListRequest, initOverrides?: RequestInit): Promise<ChatMessageLoadListResponse> {
        const response = await this.chatMessageLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends the message to the chat
     */
    async chatMessageSendRaw(requestParameters: LundapadelMainApiChatMessageSendOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChatMessageSendResponse>> {
        if (requestParameters.chatMessageSendRequest === null || requestParameters.chatMessageSendRequest === undefined) {
            throw new runtime.RequiredError('chatMessageSendRequest','Required parameter requestParameters.chatMessageSendRequest was null or undefined when calling chatMessageSend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/chat/messages/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageSendRequestToJSON(requestParameters.chatMessageSendRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageSendResponseFromJSON(jsonValue));
    }

    /**
     * Sends the message to the chat
     */
    async chatMessageSend(requestParameters: LundapadelMainApiChatMessageSendOperationRequest, initOverrides?: RequestInit): Promise<ChatMessageSendResponse> {
        const response = await this.chatMessageSendRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set chat silent mode
     */
    async chatSilentModeRaw(requestParameters: LundapadelMainApiChatSilentModeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.chatSilentModeRequest === null || requestParameters.chatSilentModeRequest === undefined) {
            throw new runtime.RequiredError('chatSilentModeRequest','Required parameter requestParameters.chatSilentModeRequest was null or undefined when calling chatSilentMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/chat/silent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatSilentModeRequestToJSON(requestParameters.chatSilentModeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set chat silent mode
     */
    async chatSilentMode(requestParameters: LundapadelMainApiChatSilentModeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.chatSilentModeRaw(requestParameters, initOverrides);
    }

    /**
     * Checks if notifications exist
     */
    async existNotificationRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExistNotificationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notification/exist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExistNotificationResponseFromJSON(jsonValue));
    }

    /**
     * Checks if notifications exist
     */
    async existNotification(initOverrides?: RequestInit): Promise<ExistNotificationResponse> {
        const response = await this.existNotificationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Load list message for player
     */
    async feedbackLoadListMessageRaw(requestParameters: LundapadelMainApiFeedbackLoadListMessageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeedbackLoadListMessageResponse>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling feedbackLoadListMessage.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling feedbackLoadListMessage.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedback/load-list-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackLoadListMessageResponseFromJSON(jsonValue));
    }

    /**
     * Load list message for player
     */
    async feedbackLoadListMessage(requestParameters: LundapadelMainApiFeedbackLoadListMessageRequest, initOverrides?: RequestInit): Promise<FeedbackLoadListMessageResponse> {
        const response = await this.feedbackLoadListMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Read all feedback messages
     */
    async feedbackReadRaw(requestParameters: LundapadelMainApiFeedbackReadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeedbackReadResponse>> {
        if (requestParameters.lastFeedbackMessageDate === null || requestParameters.lastFeedbackMessageDate === undefined) {
            throw new runtime.RequiredError('lastFeedbackMessageDate','Required parameter requestParameters.lastFeedbackMessageDate was null or undefined when calling feedbackRead.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastFeedbackMessageDate !== undefined) {
            queryParameters['lastFeedbackMessageDate'] = requestParameters.lastFeedbackMessageDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedback/read`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackReadResponseFromJSON(jsonValue));
    }

    /**
     * Read all feedback messages
     */
    async feedbackRead(requestParameters: LundapadelMainApiFeedbackReadRequest, initOverrides?: RequestInit): Promise<FeedbackReadResponse> {
        const response = await this.feedbackReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if feedback reply exist
     */
    async feedbackReplyExistRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeedbackReplyExistResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/feedback/exist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackReplyExistResponseFromJSON(jsonValue));
    }

    /**
     * Check if feedback reply exist
     */
    async feedbackReplyExist(initOverrides?: RequestInit): Promise<FeedbackReplyExistResponse> {
        const response = await this.feedbackReplyExistRaw(initOverrides);
        return await response.value();
    }

    /**
     * Send feedback
     */
    async feedbackSendRaw(requestParameters: LundapadelMainApiFeedbackSendOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.feedbackSendRequest === null || requestParameters.feedbackSendRequest === undefined) {
            throw new runtime.RequiredError('feedbackSendRequest','Required parameter requestParameters.feedbackSendRequest was null or undefined when calling feedbackSend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mail/feedback/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackSendRequestToJSON(requestParameters.feedbackSendRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send feedback
     */
    async feedbackSend(requestParameters: LundapadelMainApiFeedbackSendOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.feedbackSendRaw(requestParameters, initOverrides);
    }

    /**
     * Player send message to admin
     */
    async feedbackSendMessageRaw(requestParameters: LundapadelMainApiFeedbackSendMessageOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.feedbackSendMessageRequest === null || requestParameters.feedbackSendMessageRequest === undefined) {
            throw new runtime.RequiredError('feedbackSendMessageRequest','Required parameter requestParameters.feedbackSendMessageRequest was null or undefined when calling feedbackSendMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/feedback/send-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackSendMessageRequestToJSON(requestParameters.feedbackSendMessageRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Player send message to admin
     */
    async feedbackSendMessage(requestParameters: LundapadelMainApiFeedbackSendMessageOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.feedbackSendMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Subscribe firebase notifications
     */
    async firebaseNotificationsSubscribeRaw(requestParameters: LundapadelMainApiFirebaseNotificationsSubscribeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firebaseNotificationsSubscribeRequest === null || requestParameters.firebaseNotificationsSubscribeRequest === undefined) {
            throw new runtime.RequiredError('firebaseNotificationsSubscribeRequest','Required parameter requestParameters.firebaseNotificationsSubscribeRequest was null or undefined when calling firebaseNotificationsSubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notification/subscribe-fb`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FirebaseNotificationsSubscribeRequestToJSON(requestParameters.firebaseNotificationsSubscribeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Subscribe firebase notifications
     */
    async firebaseNotificationsSubscribe(requestParameters: LundapadelMainApiFirebaseNotificationsSubscribeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firebaseNotificationsSubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribe firebase notifications
     */
    async firebaseNotificationsUnsubscribeRaw(requestParameters: LundapadelMainApiFirebaseNotificationsUnsubscribeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firebaseNotificationsUnsubscribeRequest === null || requestParameters.firebaseNotificationsUnsubscribeRequest === undefined) {
            throw new runtime.RequiredError('firebaseNotificationsUnsubscribeRequest','Required parameter requestParameters.firebaseNotificationsUnsubscribeRequest was null or undefined when calling firebaseNotificationsUnsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notification/unsubscribe-fb`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FirebaseNotificationsUnsubscribeRequestToJSON(requestParameters.firebaseNotificationsUnsubscribeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe firebase notifications
     */
    async firebaseNotificationsUnsubscribe(requestParameters: LundapadelMainApiFirebaseNotificationsUnsubscribeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firebaseNotificationsUnsubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Load list grade infos
     */
    async gradeInfoLoadListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GradeInfoLoadListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/grade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeInfoLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list grade infos
     */
    async gradeInfoLoadList(initOverrides?: RequestInit): Promise<GradeInfoLoadListResponse> {
        const response = await this.gradeInfoLoadListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Load grade ratings
     */
    async gradeRatingLoadRaw(requestParameters: LundapadelMainApiGradeRatingLoadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GradeRatingLoadResponse>> {
        const queryParameters: any = {};

        if (requestParameters.reversed !== undefined) {
            queryParameters['reversed'] = requestParameters.reversed;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rating/load-grade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GradeRatingLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load grade ratings
     */
    async gradeRatingLoad(requestParameters: LundapadelMainApiGradeRatingLoadRequest = {}, initOverrides?: RequestInit): Promise<GradeRatingLoadResponse> {
        const response = await this.gradeRatingLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load advertisement item
     */
    async loadAdvertisementRaw(requestParameters: LundapadelMainApiLoadAdvertisementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoadAdvertisementResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling loadAdvertisement.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/advertisement/load`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadAdvertisementResponseFromJSON(jsonValue));
    }

    /**
     * Load advertisement item
     */
    async loadAdvertisement(requestParameters: LundapadelMainApiLoadAdvertisementRequest, initOverrides?: RequestInit): Promise<LoadAdvertisementResponse> {
        const response = await this.loadAdvertisementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check minimum supported app version
     */
    async minimumSupportedVersionCheckRaw(requestParameters: LundapadelMainApiMinimumSupportedVersionCheckRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MinimumSupportedVersionCheckResponse>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling minimumSupportedVersionCheck.');
        }

        const queryParameters: any = {};

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/version/check-minimum-supported`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MinimumSupportedVersionCheckResponseFromJSON(jsonValue));
    }

    /**
     * Check minimum supported app version
     */
    async minimumSupportedVersionCheck(requestParameters: LundapadelMainApiMinimumSupportedVersionCheckRequest, initOverrides?: RequestInit): Promise<MinimumSupportedVersionCheckResponse> {
        const response = await this.minimumSupportedVersionCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load news item
     */
    async newsItemLoadRaw(requestParameters: LundapadelMainApiNewsItemLoadOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NewsItemLoadResponse>> {
        if (requestParameters.newsItemLoadRequest === null || requestParameters.newsItemLoadRequest === undefined) {
            throw new runtime.RequiredError('newsItemLoadRequest','Required parameter requestParameters.newsItemLoadRequest was null or undefined when calling newsItemLoad.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/news/load`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewsItemLoadRequestToJSON(requestParameters.newsItemLoadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsItemLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load news item
     */
    async newsItemLoad(requestParameters: LundapadelMainApiNewsItemLoadOperationRequest, initOverrides?: RequestInit): Promise<NewsItemLoadResponse> {
        const response = await this.newsItemLoadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list of news items
     */
    async newsItemLoadListRaw(requestParameters: LundapadelMainApiNewsItemLoadListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NewsItemLoadListResponse>> {
        if (requestParameters.newsItemLoadListRequest === null || requestParameters.newsItemLoadListRequest === undefined) {
            throw new runtime.RequiredError('newsItemLoadListRequest','Required parameter requestParameters.newsItemLoadListRequest was null or undefined when calling newsItemLoadList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/news/load-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewsItemLoadListRequestToJSON(requestParameters.newsItemLoadListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsItemLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list of news items
     */
    async newsItemLoadList(requestParameters: LundapadelMainApiNewsItemLoadListOperationRequest, initOverrides?: RequestInit): Promise<NewsItemLoadListResponse> {
        const response = await this.newsItemLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Load list notifications
     */
    async notificationLoadListRaw(requestParameters: LundapadelMainApiNotificationLoadListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationLoadListResponse>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling notificationLoadList.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling notificationLoadList.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.unread !== undefined) {
            queryParameters['unread'] = requestParameters.unread;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notification/load-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationLoadListResponseFromJSON(jsonValue));
    }

    /**
     * Load list notifications
     */
    async notificationLoadList(requestParameters: LundapadelMainApiNotificationLoadListRequest, initOverrides?: RequestInit): Promise<NotificationLoadListResponse> {
        const response = await this.notificationLoadListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Subscribe notification
     */
    async notificationsSubscribeRaw(requestParameters: LundapadelMainApiNotificationsSubscribeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationsSubscribeRequest === null || requestParameters.notificationsSubscribeRequest === undefined) {
            throw new runtime.RequiredError('notificationsSubscribeRequest','Required parameter requestParameters.notificationsSubscribeRequest was null or undefined when calling notificationsSubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notification/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationsSubscribeRequestToJSON(requestParameters.notificationsSubscribeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Subscribe notification
     */
    async notificationsSubscribe(requestParameters: LundapadelMainApiNotificationsSubscribeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationsSubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribe notification
     */
    async notificationsUnsubscribeRaw(requestParameters: LundapadelMainApiNotificationsUnsubscribeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationsUnsubscribeRequest === null || requestParameters.notificationsUnsubscribeRequest === undefined) {
            throw new runtime.RequiredError('notificationsUnsubscribeRequest','Required parameter requestParameters.notificationsUnsubscribeRequest was null or undefined when calling notificationsUnsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notification/unsubscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationsUnsubscribeRequestToJSON(requestParameters.notificationsUnsubscribeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribe notification
     */
    async notificationsUnsubscribe(requestParameters: LundapadelMainApiNotificationsUnsubscribeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.notificationsUnsubscribeRaw(requestParameters, initOverrides);
    }

    /**
     * Load public key
     */
    async publicKeyLoadRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PublicKeyLoadResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notification/public_key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicKeyLoadResponseFromJSON(jsonValue));
    }

    /**
     * Load public key
     */
    async publicKeyLoad(initOverrides?: RequestInit): Promise<PublicKeyLoadResponse> {
        const response = await this.publicKeyLoadRaw(initOverrides);
        return await response.value();
    }

    /**
     * Read all notification for player
     */
    async readAllNotificationRaw(requestParameters: LundapadelMainApiReadAllNotificationOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.readAllNotificationRequest === null || requestParameters.readAllNotificationRequest === undefined) {
            throw new runtime.RequiredError('readAllNotificationRequest','Required parameter requestParameters.readAllNotificationRequest was null or undefined when calling readAllNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notification/read-all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReadAllNotificationRequestToJSON(requestParameters.readAllNotificationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Read all notification for player
     */
    async readAllNotification(requestParameters: LundapadelMainApiReadAllNotificationOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.readAllNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Read notification
     */
    async readNotificationRaw(requestParameters: LundapadelMainApiReadNotificationOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.readNotificationRequest === null || requestParameters.readNotificationRequest === undefined) {
            throw new runtime.RequiredError('readNotificationRequest','Required parameter requestParameters.readNotificationRequest was null or undefined when calling readNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notification/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReadNotificationRequestToJSON(requestParameters.readNotificationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Read notification
     */
    async readNotification(requestParameters: LundapadelMainApiReadNotificationOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.readNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Checks telegram availability
     */
    async telegramCheckAvailableRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TelegramCheckAvailableResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/telegram/check-available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TelegramCheckAvailableResponseFromJSON(jsonValue));
    }

    /**
     * Checks telegram availability
     */
    async telegramCheckAvailable(initOverrides?: RequestInit): Promise<TelegramCheckAvailableResponse> {
        const response = await this.telegramCheckAvailableRaw(initOverrides);
        return await response.value();
    }

    /**
     * Register websocket ticket
     */
    async websocketTicketRegisterRaw(requestParameters: LundapadelMainApiWebsocketTicketRegisterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WebsocketTicketRegisterResponse>> {
        if (requestParameters.ticketType === null || requestParameters.ticketType === undefined) {
            throw new runtime.RequiredError('ticketType','Required parameter requestParameters.ticketType was null or undefined when calling websocketTicketRegister.');
        }

        if (requestParameters.relatedEntityUid === null || requestParameters.relatedEntityUid === undefined) {
            throw new runtime.RequiredError('relatedEntityUid','Required parameter requestParameters.relatedEntityUid was null or undefined when calling websocketTicketRegister.');
        }

        const queryParameters: any = {};

        if (requestParameters.ticketType !== undefined) {
            queryParameters['ticketType'] = requestParameters.ticketType;
        }

        if (requestParameters.relatedEntityUid !== undefined) {
            queryParameters['relatedEntityUid'] = requestParameters.relatedEntityUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/websocket/ticket/register`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebsocketTicketRegisterResponseFromJSON(jsonValue));
    }

    /**
     * Register websocket ticket
     */
    async websocketTicketRegister(requestParameters: LundapadelMainApiWebsocketTicketRegisterRequest, initOverrides?: RequestInit): Promise<WebsocketTicketRegisterResponse> {
        const response = await this.websocketTicketRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
