import { TournamentRoundView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentRoundsPreview.module.css';
import { Fragment, useState } from 'react';
import LineupCard from '@/components/LineupCard/LineupCard';
import ArrowIcon from '@/static/images/icons/ArrowIcon';

type TournamentRoundsPreviewProps = {
  round: TournamentRoundView;
  index: number;
  roundStickyTb?: number;
  courtStickyTb?: number;
}

const TournamentRoundsPreview = ({ round, index, roundStickyTb, courtStickyTb }: TournamentRoundsPreviewProps) => {
  const [open, setOpen] = useState(false);

  const renderLineups = (round: TournamentRoundView) => {
    let actualIndex = 0;
    return round?.lineups?.map((lineup, ind) => {
      return (
        <Fragment key={ind}>
          {open ? <div className={styles['court-number']} style={{ top: courtStickyTb ? `${courtStickyTb}px` : '68px'}}>{ind + 1} корт</div> : null}
          {lineup.games?.map((game, i, arr) => {
            actualIndex++;
            return (
              <div key={`${ind}_${i}`} className={`${styles['court']} ${open ? styles['court-active'] : ''}`}>
                <LineupCard
                  type={!game.score1 && !game.score2 ? 'empty' : 'tournament'}
                  stickyBorderTop={courtStickyTb ? `${courtStickyTb + (roundStickyTb ?? 0) - 36}px` : '107px'}
                  index={actualIndex}
                  lineup={{ 
                    player1: game.player1,
                    player2: game.player2,
                    player3: game.player3,
                    player4: game.player4,
                    score1: game.score1,
                    score2: game.score2
                  }}
                  style={{ marginBottom: i === arr.length - 1 ? '16px' :'8px' }}
                />
              </div>
            );
          })}
        </Fragment>
      );
    });
  }

  return ( 
    <div>
      <div className={styles['round']} style={{ top: roundStickyTb ? `${roundStickyTb}px` : '28px', paddingBottom: open ? '16px' : '0' }} onClick={() => setOpen(!open)}>
        <span>{index + 1} Раунд</span>
        <div 
          className={`${styles['collapse-icon']} ${open ? styles['collapse-icon-opened'] : ''}`}
        >
          <ArrowIcon stroke='var(--green-yellow)'/>
        </div>
      </div>
      {renderLineups(round)}
    </div>
);
}
 
export default TournamentRoundsPreview;
