import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import MessageBlock from './MessageBlock/MessageBlock';
import DialogBlock from './DialogBlock/DialogBlock';
import Header from '@/components/Header/Header';
import styles from './ChatPage.module.css'
import HLine from '@/components/HLine/HLine';
import { getMessagesDateBlocks } from './utils';
import { ChatPageProps } from './ChatPage.intefaces';
import { ChatContextWrapper } from './ChatContext';
import { FeedbackMessageView } from '@/integration-api/server-rest-lundapadelApi';

function ChatPage<T extends FeedbackMessageView>({ header, messageList, handleSendMessage }: ChatPageProps<T>) {
  return (
    <ChatContextWrapper>
      <BasePageWrapper>
        <Header className={styles['header']}>
          {header}
          {/* <div className={styles.wrap}>
            <div className={styles.time}>Пн 23 декабря | 20:00 - 20:30 </div>
            <div className={styles.address}>Lunda Padel Речной</div>
          </div> */}
        </Header>
        <DialogBlock 
          messagesBlocks={getMessagesDateBlocks(messageList)}
        />
        <HLine />
        <MessageBlock 
          handleSendMessage={handleSendMessage}
        />
        <HLine />
      </BasePageWrapper>
    </ChatContextWrapper>
  );
}

export default ChatPage;
