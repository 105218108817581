import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import { AcceptRulesModalProps } from './AcceptRulesModal.interface';
import styles from './AcceptRulesModal.module.css';
import ballIcon from '@/static/images/icons/ball-icon.svg';
import arrowIcon from '@/static/images/icons/right-arrow-green-icon.svg';

export default function AcceptRulesModal({ visible, onClose, onPolicyOpen, onTosOpen, handleAcceptRules }: AcceptRulesModalProps) {
  return (
    <ConfirmationModal visible={visible} onClose={onClose}>
      <h2 className={styles['modal-header']}>Регистрация в приложении</h2>
      <p className={styles['modal-content']}>
        Вы должны ознакомиться и принять
        <span onClick={onPolicyOpen}>политику конфиденциальности</span>&nbsp;и&nbsp;
        <span onClick={onTosOpen}>пользовательское соглашение</span>&nbsp;
        перед использованием приложения
      </p>

      <button className={styles['button']} onTouchEnd={handleAcceptRules}>
      <div className={styles['button-inner']}>
        <img src={ballIcon} alt="иконка мячика" />
        <span>Принять</span>
        <img src={arrowIcon} alt='иконка стрелки вправо'/>
      </div>
    </button>
    </ConfirmationModal>
  )
}
