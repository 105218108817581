/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    InvitationStatus,
    InvitationStatusFromJSON,
    InvitationStatusFromJSONTyped,
    InvitationStatusToJSON,
} from './InvitationStatus';
import {
    InviteRelatedEntityType,
    InviteRelatedEntityTypeFromJSON,
    InviteRelatedEntityTypeFromJSONTyped,
    InviteRelatedEntityTypeToJSON,
} from './InviteRelatedEntityType';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface InviteInfo
 */
export interface InviteInfo {
    /**
     * 
     * @type {string}
     * @memberof InviteInfo
     */
    relatedEntityUid?: string;
    /**
     * 
     * @type {InviteRelatedEntityType}
     * @memberof InviteInfo
     */
    relatedEntityType?: InviteRelatedEntityType;
    /**
     * 
     * @type {string}
     * @memberof InviteInfo
     */
    clubName?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof InviteInfo
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {boolean}
     * @memberof InviteInfo
     */
    courtBooked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InviteInfo
     */
    courtNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InviteInfo
     */
    privateEntity?: boolean;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof InviteInfo
     */
    sender?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof InviteInfo
     */
    player?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof InviteInfo
     */
    owner?: PlayerProfileInfo;
    /**
     * 
     * @type {Grade}
     * @memberof InviteInfo
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof InviteInfo
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof InviteInfo
     */
    displayMinGrade?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteInfo
     */
    displayMaxGrade?: string;
    /**
     * 
     * @type {InvitationStatus}
     * @memberof InviteInfo
     */
    invitationStatus?: InvitationStatus;
    /**
     * 
     * @type {string}
     * @memberof InviteInfo
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InviteInfo
     */
    playersLimit?: number;
    /**
     * 
     * @type {Array<PlayerProfileInfo>}
     * @memberof InviteInfo
     */
    players?: Array<PlayerProfileInfo>;
}

export function InviteInfoFromJSON(json: any): InviteInfo {
    return InviteInfoFromJSONTyped(json, false);
}

export function InviteInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedEntityUid': !exists(json, 'relatedEntityUid') ? undefined : json['relatedEntityUid'],
        'relatedEntityType': !exists(json, 'relatedEntityType') ? undefined : InviteRelatedEntityTypeFromJSON(json['relatedEntityType']),
        'clubName': !exists(json, 'clubName') ? undefined : json['clubName'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'courtBooked': !exists(json, 'courtBooked') ? undefined : json['courtBooked'],
        'courtNumber': !exists(json, 'courtNumber') ? undefined : json['courtNumber'],
        'privateEntity': !exists(json, 'privateEntity') ? undefined : json['privateEntity'],
        'sender': !exists(json, 'sender') ? undefined : PlayerProfileInfoFromJSON(json['sender']),
        'player': !exists(json, 'player') ? undefined : PlayerProfileInfoFromJSON(json['player']),
        'owner': !exists(json, 'owner') ? undefined : PlayerProfileInfoFromJSON(json['owner']),
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'displayMinGrade': !exists(json, 'displayMinGrade') ? undefined : json['displayMinGrade'],
        'displayMaxGrade': !exists(json, 'displayMaxGrade') ? undefined : json['displayMaxGrade'],
        'invitationStatus': !exists(json, 'invitationStatus') ? undefined : InvitationStatusFromJSON(json['invitationStatus']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'playersLimit': !exists(json, 'playersLimit') ? undefined : json['playersLimit'],
        'players': !exists(json, 'players') ? undefined : ((json['players'] as Array<any>).map(PlayerProfileInfoFromJSON)),
    };
}

export function InviteInfoToJSON(value?: InviteInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedEntityUid': value.relatedEntityUid,
        'relatedEntityType': InviteRelatedEntityTypeToJSON(value.relatedEntityType),
        'clubName': value.clubName,
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'courtBooked': value.courtBooked,
        'courtNumber': value.courtNumber,
        'privateEntity': value.privateEntity,
        'sender': PlayerProfileInfoToJSON(value.sender),
        'player': PlayerProfileInfoToJSON(value.player),
        'owner': PlayerProfileInfoToJSON(value.owner),
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'displayMinGrade': value.displayMinGrade,
        'displayMaxGrade': value.displayMaxGrade,
        'invitationStatus': InvitationStatusToJSON(value.invitationStatus),
        'description': value.description,
        'playersLimit': value.playersLimit,
        'players': value.players === undefined ? undefined : ((value.players as Array<any>).map(PlayerProfileInfoToJSON)),
    };
}

