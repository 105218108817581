/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PlayedMatches {
    LOW_NUMBER = 'LOW_NUMBER',
    AVERAGE_NUMBER = 'AVERAGE_NUMBER',
    HIGH_NUMBER = 'HIGH_NUMBER'
}

export function PlayedMatchesFromJSON(json: any): PlayedMatches {
    return PlayedMatchesFromJSONTyped(json, false);
}

export function PlayedMatchesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayedMatches {
    return json as PlayedMatches;
}

export function PlayedMatchesToJSON(value?: PlayedMatches | null): any {
    return value as any;
}

