const ParticipantsIcon = () => {
  return ( 
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.83688" cy="2.83688" r="2.83688" transform="matrix(-1 0 0 1 13.3472 4)" stroke="#C3D82E" strokeWidth="1.5"/>
      <path d="M5.5459 13.8823C5.5459 13.2722 5.92948 12.7279 6.50412 12.5226V12.5226C9.09486 11.5974 11.926 11.5974 14.5168 12.5226V12.5226C15.0914 12.7279 15.475 13.2722 15.475 13.8823V14.8153C15.475 15.6575 14.7291 16.3044 13.8954 16.1853L13.6174 16.1456C11.5566 15.8512 9.46431 15.8512 7.40344 16.1456L7.12548 16.1853C6.29179 16.3044 5.5459 15.6575 5.5459 14.8153V13.8823Z" stroke="#C3D82E" strokeWidth="1.5"/>
      <path d="M14.7661 9.75288C15.9972 9.75288 16.9951 8.75493 16.9951 7.5239C16.9951 6.29287 15.9972 5.29492 14.7661 5.29492" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M17.2072 14.8376L17.4256 14.8688C18.0807 14.9624 18.6667 14.4541 18.6667 13.7924V13.0594C18.6667 12.5799 18.3653 12.1523 17.9138 11.991C17.4635 11.8302 17.0038 11.7049 16.5391 11.6152" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M5.90085 9.75288C4.66982 9.75288 3.67188 8.75493 3.67188 7.5239C3.67188 6.29287 4.66982 5.29492 5.90085 5.29492" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M3.45977 14.8376L3.24137 14.8688C2.58633 14.9624 2.00027 14.4541 2.00027 13.7924V13.0594C2.00027 12.5799 2.30166 12.1523 2.75316 11.991C3.20353 11.8302 3.66315 11.7049 4.12793 11.6152" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
}
 
export default ParticipantsIcon;
