import styles from './PlayersPool.module.css';
import { PlayersPoolProps } from "./PlayerPool.interfaces";
import DraggableProfileCard from '@/components/ProfileCard/DraggableProfileCard';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { Role } from '@/integration-api/server-rest-lundapadelApi';

const DraggablePlayersPool = ({ playersPool }: PlayersPoolProps) => {
  return ( 
    <div className={styles['wrapper']}>
      {playersPool.map(player => (
        <DraggableProfileCard
          key={player.uid}
          player={player}
          fullName
          noPreferedSide
          bage={[
            {
              element: <RatingBage rating={player.displayRating ?? '1.0'}/>,
              position: 'top-right'
            },
            {
              element: player.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }
          ]}
        />
      ))}
    </div>
  );
}
 
export default DraggablePlayersPool;
