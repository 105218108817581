import { Role, TourGameInfo, TournamentKind, TournamentStatus } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentFinishedCard.module.css';
import formatter, { createImgSrc } from "@/utils/utils";
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import houseCityIcon from '@/static/images/icons/house-city-icon.svg';
import calendarIcon from '@/static/images/icons/calendar-icon.svg';
import goldMedal from '@/static/images/icons/tournament-result-medal-gold.svg';
import silverMedal from '@/static/images/icons/tournament-result-medal-silver.svg';
import bronzeMedal from '@/static/images/icons/tournament-result-medal-bronze.svg';
import UserAvatar from '../UserAvatar/UserAvatar';
import { tournamentType } from '../TournamentCard/utils';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { isYourself } from '../ProfileCard/utils';
import { useAppSelector } from '@/hooks/hooks';
import medalIcon from '@/static/images/icons/medal-icon.svg';
import trophyImage from '@/static/images/trophy.svg';
import { translateTournamentType } from '@/pages/application/create/components/SelectTournamentType/utils';
import tourneyIcon from '@/static/images/icons/tournament-type-icon.svg';

interface TournamentFinishedCardProps {
  tournament: TourGameInfo;
}

const TournamentFinishedCard = ({ tournament }: TournamentFinishedCardProps) => {
  const currentProfileUid = useAppSelector(state => state.auth.currentProfile.identity?.uid);

  if(tournament?.tournamentStatus === TournamentStatus.CANCEL) {
    return (
      <div className={styles['card-wrapper-canceled']}>
        <div className={styles['headling-canceled']}>
          <span className={styles['canceled-text']}>Отменен</span>
          <span className={styles['canceled-time']}>{formatter.relativeDate(tournament?.plannedDate ?? '')}</span>
        </div>
        <div className={styles['headling']}>{tournamentType(tournament?.genders)}  «{tournament?.title}»</div>
        <div className={styles['info']}>
          <div className={styles['info-item']}>
            <img src={calendarIcon} alt="икона календаря" />
            <span>
              {`${formatter.formatDateToDayDateMonth(tournament?.plannedDate)}, 
              ${formatter.formatDate(tournament?.plannedDate ?? new Date(), TIME_FORMAT_HH_MM)}`}
            </span>
          </div>
          <div className={styles['info-item']}>
            <img src={houseCityIcon} alt="икона клуба" />
            <span>{tournament?.club?.caption}</span>
          </div>
          <div className={styles['info-item']}>
            <img src={medalIcon} alt="икона медали" />
            {tournament?.displayMinGrade === tournament?.displayMaxGrade ?
              <span>{tournament?.displayMaxGrade}</span> :
              <span>{tournament?.displayMinGrade}...{tournament?.displayMaxGrade}</span>
            }
          </div>
          <div className={styles['info-item']}>
            <img src={tourneyIcon} alt="икона мяча" />
            <span>{translateTournamentType(tournament?.kind ?? TournamentKind.CLASSIC)}</span>
          </div>
        </div>
        <img className={styles['trophy']} src={trophyImage} alt="кубок" />
      </div>
    );
  }

  return (
    <div className={styles['card-wrapper']}>
      <div className={styles['headling']}>{tournamentType(tournament?.genders)}  «{tournament?.title}»</div>
      <div className={styles['info']}>
        <div className={styles['info-item']}>
          <img src={calendarIcon} alt="икона календаря" />
          <span>
            {`${formatter.formatDateToDayDateMonth(tournament?.plannedDate)}, 
            ${formatter.formatDate(tournament?.plannedDate ?? new Date(), TIME_FORMAT_HH_MM)}`}
          </span>
        </div>
        <div className={styles['info-item']}>
          <img src={houseCityIcon} alt="икона клуба" />
          <span>{tournament?.club?.caption}</span>
        </div>
      </div>
      <div className={styles['winners']}>
        <div className={styles['winners-item']}>
          <img src={goldMedal} alt="золото" className={styles['medal']}/>
          <UserAvatar
            avatarUid={createImgSrc.playerProfile(tournament?.firstPlace?.uid, tournament?.firstPlace?.avatarUid)}
            nameInitials={tournament?.firstPlace?.nameInitials}
            className={isYourself(currentProfileUid, tournament?.firstPlace?.uid) ? styles['yourself-border'] : ''}
            badge={
              {
                element: tournament?.firstPlace?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                position: 'top-left'
              }
            }
          />
          <span className={isYourself(currentProfileUid, tournament?.firstPlace?.uid) ? styles['name-yourself'] : styles['name']}>
            {isYourself(currentProfileUid, tournament?.firstPlace?.uid) ? 'Вы' : tournament?.firstPlace?.displayName?.substring(0, 25) || '-'}
          </span>
        </div>
        <div className={styles['winners-item']}>
          <img src={silverMedal} alt="серебро" className={styles['medal']}/>
          <UserAvatar
            avatarUid={createImgSrc.playerProfile(tournament?.secondPlace?.uid, tournament?.secondPlace?.avatarUid)}
            nameInitials={tournament?.secondPlace?.nameInitials}
            className={isYourself(currentProfileUid, tournament?.secondPlace?.uid) ? styles['yourself-border'] : ''}
            badge={
              {
                element: tournament?.secondPlace?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                position: 'top-left'
              }
            }
          />
          <span className={isYourself(currentProfileUid, tournament?.secondPlace?.uid) ? styles['name-yourself'] : styles['name']}>
            {isYourself(currentProfileUid, tournament?.secondPlace?.uid) ? 'Вы' : tournament?.secondPlace?.displayName?.substring(0, 25) || '-'}
          </span>
        </div>
        <div className={styles['winners-item']}>
          <img src={bronzeMedal} alt="бронза" className={styles['medal']}/>
          <UserAvatar
            avatarUid={createImgSrc.playerProfile(tournament?.thirdPlace?.uid, tournament?.thirdPlace?.avatarUid)}
            nameInitials={tournament?.thirdPlace?.nameInitials}
            className={isYourself(currentProfileUid, tournament?.thirdPlace?.uid) ? styles['yourself-border'] : ''}
            badge={
              {
                element: tournament?.thirdPlace?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                position: 'top-left'
              }
            }
          />
          <span className={isYourself(currentProfileUid, tournament?.thirdPlace?.uid) ? styles['name-yourself'] : styles['name']}>
            {isYourself(currentProfileUid, tournament?.thirdPlace?.uid) ? 'Вы' : tournament?.thirdPlace?.displayName?.substring(0, 25) || '-'}
          </span>
        </div>
      </div>
    </div>
  );
}
 
export default TournamentFinishedCard;
