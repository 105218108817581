import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import styles from './PlayersCaterpillar.module.css';
import UserAvatar from '@/components/UserAvatar/UserAvatar';

type PlayersCaterpillarProps = {
  players: Array<PlayerProfileInfo | undefined>;
  onInviteClick: VoidFunction;
}

const PlayersCaterpillar = ({ players, onInviteClick }: PlayersCaterpillarProps) => {
  return (  
    <div className={styles['wrapper']}>
      <div className={styles['players']}>
        {players.map((_ , index) => (
          <UserAvatar
            key={index}
            isInvitable
            onClick={onInviteClick}
          />
        ))}
      </div>
      <span className={styles['invite']}>Пригласить</span>
    </div>
  );
}
 
export default PlayersCaterpillar;
