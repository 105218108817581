import { CSSProperties, createContext } from "react"
import { ITournamentResultsClassisContext, MinitournamentView } from "./TournamentPage.interfaces"
import { ITournamentResultsNewContext } from "./TournamentResultsNew/TournamentResultsNew.interfaces"
import dayjs from "dayjs";
import { GameRoundStatus, GameRoundView, GameTournamentKind, GameTournamentStatus, GameView, RoundStatus, TournamentKind, TournamentRoundView, TournamentStatus, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import { CalculatedGameStatus } from "@/integration-api/server-rest-get-info";

export const TournamentResultClassicContext = createContext<ITournamentResultsClassisContext>({
  participants: [],
  isOwner: false,
  updateList: () => {},
})

export const TournamentResultsNewContext = createContext<ITournamentResultsNewContext>({
  tournament: {},
  step: 'lineups',
  roundNumber: 0,
  roundCounter: 0,
  loading: false,
  tournamentType: 'full',
  setLoading: () => {}
});

export function PrefferedSide(leftside?: boolean, rightSide?: boolean) {
  if((leftside && rightSide) || (!leftside && !rightSide)) {
    return 'Оба'
  }
  if(leftside) {
    return 'Левый'
  }
  return 'Правый'
}

export function applyTableItemStyle(dragTarget: number, dropTarget: number, placement: number): CSSProperties | undefined {
  if(dragTarget < dropTarget && dropTarget >= placement && dragTarget <= placement) {
    return { position: 'absolute', top: '-57px', width: '100%' }
  }
  if(dragTarget > dropTarget && dropTarget <= placement && dragTarget >= placement) {
    return { position: 'absolute', top: '57px', width: '100%' }
  }
}
//ohuenniy managment dat)))
export function dateToUTC(date: Date) {
  return new Date(`${dayjs(new Date(date ?? '')).format().slice(0, 19)}Z`);
}

function mapTournamentKind(kind: GameTournamentKind) {
  switch(kind) {
    case GameTournamentKind.AMERICANO:
      return TournamentKind.AMERICANO;
    case GameTournamentKind.MEXICANO:
      return TournamentKind.MEXICANO;
    default:
      return TournamentKind.ESCALERA;
  }
}

export function mapTournamentStatus(status: CalculatedGameStatus) {
  switch(status) {
    case CalculatedGameStatus.STARTED: 
      return TournamentStatus.START;
    case CalculatedGameStatus.AFTER_GAME:
      return TournamentStatus.AFTER_TOURNAMENT;
    default:
      return TournamentStatus.EDITABLE;
  }
}

export function mapRoundStatus(status: GameRoundStatus): RoundStatus {
  switch(status) {
    case GameRoundStatus.STARTED:
      return RoundStatus.STARTED;
    case GameRoundStatus.FINISHED:
      return RoundStatus.FINISHED;
    default:
      const _ = status;
      throw new Error('not every option is handeled');
  }
}

export function mapGameRoundStatus(status: RoundStatus): GameRoundStatus {
  switch(status) {
    case RoundStatus.STARTED:
      return GameRoundStatus.STARTED;
    default:
      return GameRoundStatus.FINISHED;
  }
}

export function gameTournamentStatusToTournamentStatus(status: GameTournamentStatus): TournamentStatus {
  switch(status) {
    case GameTournamentStatus.PLANNED:
      return TournamentStatus.PLANNED;
    case GameTournamentStatus.STARTED:
      return TournamentStatus.START;
    case GameTournamentStatus.EDITABLE:
      return TournamentStatus.EDITABLE;
    case GameTournamentStatus.AFTER_TOURNAMENT:
      return TournamentStatus.AFTER_TOURNAMENT;
    case GameTournamentStatus.FINISH:
      return TournamentStatus.FINISH;
    case GameTournamentStatus.CANCEL:
      return TournamentStatus.CANCEL;
    default: 
      const _ = status;
      throw new Error('not every option is handled');
  }
}

export function castGameToMinitournament(game: GameView): MinitournamentView {
  const kind = mapTournamentKind(game.gameTournamentKind!);
  const status = gameTournamentStatusToTournamentStatus(game.tournamentStatus!)
  return {
    ...game,
    kind,
    roundsLimit: GameTournamentKind.AMERICANO ? 7 : 0,
    status
  };
}

export function castGameRoundToTournamentRound(round: GameRoundView): TournamentRoundView {
  return {
    ...round,
    tournamentUid: round.gameUid,
    status: mapRoundStatus(round.status!),
  };
}
