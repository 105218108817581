/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedbackSendParameters
 */
export interface FeedbackSendParameters {
    /**
     * 
     * @type {string}
     * @memberof FeedbackSendParameters
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackSendParameters
     */
    text?: string;
}

export function FeedbackSendParametersFromJSON(json: any): FeedbackSendParameters {
    return FeedbackSendParametersFromJSONTyped(json, false);
}

export function FeedbackSendParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackSendParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function FeedbackSendParametersToJSON(value?: FeedbackSendParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'text': value.text,
    };
}

