/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileAvatarSaveParameters,
    PlayerProfileAvatarSaveParametersFromJSON,
    PlayerProfileAvatarSaveParametersFromJSONTyped,
    PlayerProfileAvatarSaveParametersToJSON,
} from './PlayerProfileAvatarSaveParameters';
import {
    PlayerProfileView,
    PlayerProfileViewFromJSON,
    PlayerProfileViewFromJSONTyped,
    PlayerProfileViewToJSON,
} from './PlayerProfileView';

/**
 * 
 * @export
 * @interface PlayerProfileImageSaveParameters
 */
export interface PlayerProfileImageSaveParameters {
    /**
     * 
     * @type {PlayerProfileView}
     * @memberof PlayerProfileImageSaveParameters
     */
    view?: PlayerProfileView;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileImageSaveParameters
     */
    temporalProfileImageUid?: string;
    /**
     * 
     * @type {PlayerProfileAvatarSaveParameters}
     * @memberof PlayerProfileImageSaveParameters
     */
    avatarSaveParameters?: PlayerProfileAvatarSaveParameters;
}

export function PlayerProfileImageSaveParametersFromJSON(json: any): PlayerProfileImageSaveParameters {
    return PlayerProfileImageSaveParametersFromJSONTyped(json, false);
}

export function PlayerProfileImageSaveParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileImageSaveParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'view': !exists(json, 'view') ? undefined : PlayerProfileViewFromJSON(json['view']),
        'temporalProfileImageUid': !exists(json, 'temporalProfileImageUid') ? undefined : json['temporalProfileImageUid'],
        'avatarSaveParameters': !exists(json, 'avatarSaveParameters') ? undefined : PlayerProfileAvatarSaveParametersFromJSON(json['avatarSaveParameters']),
    };
}

export function PlayerProfileImageSaveParametersToJSON(value?: PlayerProfileImageSaveParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'view': PlayerProfileViewToJSON(value.view),
        'temporalProfileImageUid': value.temporalProfileImageUid,
        'avatarSaveParameters': PlayerProfileAvatarSaveParametersToJSON(value.avatarSaveParameters),
    };
}

