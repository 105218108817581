/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileView,
    PlayerProfileViewFromJSON,
    PlayerProfileViewFromJSONTyped,
    PlayerProfileViewToJSON,
} from './PlayerProfileView';

/**
 * 
 * @export
 * @interface PlayerImageSaveResponse
 */
export interface PlayerImageSaveResponse {
    /**
     * 
     * @type {PlayerProfileView}
     * @memberof PlayerImageSaveResponse
     */
    result?: PlayerProfileView;
    /**
     * 
     * @type {Array<object>}
     * @memberof PlayerImageSaveResponse
     */
    messages?: Array<object>;
}

export function PlayerImageSaveResponseFromJSON(json: any): PlayerImageSaveResponse {
    return PlayerImageSaveResponseFromJSONTyped(json, false);
}

export function PlayerImageSaveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerImageSaveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : PlayerProfileViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function PlayerImageSaveResponseToJSON(value?: PlayerImageSaveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': PlayerProfileViewToJSON(value.result),
        'messages': value.messages,
    };
}

