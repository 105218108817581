import { LocalDateTime } from "@/integration-api/server-rest-lundapadelApi";
import { shortNameDayOfWeekArray, shortNameMonthArray } from "@/utils/constants";
import dayjs from "dayjs";

export interface DateParams {
  day: string,
  month: string,
  date: number,
  value: Date,
}

export const getDateArray = (startDate:LocalDateTime, arrayLength = 10) =>{
  const dateArray: DateParams[] = []

  for (let index = 0; index < arrayLength; index++) {
    const currentDate = dayjs(startDate).add(index, 'day').toDate()
  
    dateArray.push({
      value: currentDate,
      date: currentDate.getDate(),
      month: shortNameMonthArray[currentDate.getMonth()],
      day: shortNameDayOfWeekArray[currentDate.getDay()]
    })
  }
  
  return dateArray
}
