import styles from './GameInvitationPage.module.css';
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import Header from "@/components/Header/Header";
import { useCallback, useEffect, useState } from "react";
import { createInviteListForGame, gameLoad, participantCreateGameInvite, playerLoadList } from "../service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GameInviteView, GameLoadResponse, GameView, Grade, GradeRatingLoadResponse, InvitationStatus, LoadTournamentResponse, PlayerProfileField, PlayerProfileInfo, toLocalDateTime, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import { FetchErrorMessage } from "@/types/types";
import PuffLoader from "react-spinners/PuffLoader";
import HLWrapper from "@/components/HLWrapper/HLWrapper";
import Search from "@/components/Search/Search";
import SelectRating from "../../create/components/SelectRating/SelectRating";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch";
import SearchRecent from "../../../../components/SearchRecent/SearchRecent";
import OversideWrapper from "@/components/OversideWrapper/OversideWrapper";
import Button from "@/components/Button/Button";
import { useFormik } from "formik";
import { gradeRatingLoad } from "../../rating/components/RatingGrade/service";
import useDebounce from '@/hooks/useDebounce';
import { useAppSelector } from '@/hooks/hooks';
import { canUserInvite } from '../utils';
import { loadTournament } from '../../tournament/service';
import { createInviteListForTournament } from '../../create/service';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

type InvitationForm = {
  minGrade: number;
  maxGrade: number;
  onlyPartners: boolean;
}

type EntityView = GameView | TournamentView;

const GameInvitaionPage = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [textPattern, setTextPattern] = useState<string>('');
  const [entity, setEntity] = useState<EntityView>({});
  const [playerList, setPlayerList] = useState<Array<PlayerProfileInfo>>([]);
  const [invitedPlayers, setInvitedPlayers] = useState<Array<PlayerProfileInfo>>([]);

  const formik = useFormik<InvitationForm>({
    initialValues: {
      minGrade: 1,
      maxGrade: 2,
      onlyPartners: false
    },
    onSubmit: submitForm
  });

  const handleRangeChange = useCallback((value: [number, number]) => {
    formik.setFieldValue('minGrade', value[0]);
    formik.setFieldValue('maxGrade', value[1]);
  }, []);

  const filterInvitedPlayers = useCallback((player: PlayerProfileInfo) => {
    return !entity?.invitations?.find(invite => invite.player?.uid === player.uid && (invite.invitationStatus === InvitationStatus.SENT || invite.invitationStatus === InvitationStatus.ACCEPTED));
  }, [entity?.invitations]);

  const addPlayer = useCallback((player: PlayerProfileInfo) => {
    if(invitedPlayers.find(invited => player.uid === invited.uid)) {
      setInvitedPlayers(invitedPlayers.filter(invited => player.uid !== invited.uid))
    } else {
      setInvitedPlayers([...invitedPlayers, player]);
    }
  }, [invitedPlayers]);

  async function submitForm(values: InvitationForm) {
    try {
      const invitations: Array<GameInviteView> = invitedPlayers.map(player => ({
        player,
        decisionDate: toLocalDateTime(new Date()),
        sendDate: toLocalDateTime(new Date()),
        invitationStatus: InvitationStatus.SENT,
        sender: { ...current, uid: current?.identity?.uid }
      }));
      if(pathname.includes('/game')) {
        if(canUserInvite(entity as GameView, current.identity?.uid ?? '') && current.identity?.uid !== entity.owner?.uid) {
          await participantCreateGameInvite({
            gameUid: id,
            invitations
          });
        } else {
          await createInviteListForGame({
            gameUid: id,
            invitations
          });
        }
      } else {
        await createInviteListForTournament({
          tournamentUid: id,
          invitations
        });
      }
      navigate(-1);
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }

  function loadPlayersList() {
    setLoading(true); 
    playerLoadList({
      lowerRatingFilter: Grade[`_${formik.values.minGrade}` as keyof typeof Grade],
      upperRatingFilter: Grade[`_${formik.values.maxGrade}` as keyof typeof Grade],
      textPattern,
      onlyPartners: formik.values.onlyPartners,
      sortBy: formik.values.onlyPartners ? PlayerProfileField.COUNT_MATCHES_WITH_PARTNER : PlayerProfileField.RATING,
      matchContext: true,
      excludedUids: entity.members?.map(member => member?.player?.uid ?? '')
    })
      .then(({ result }) => {
        setPlayerList(result?.infos ?? []);
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
      .finally(() => setLoading(false));
  }

  const debouncedPlayerLoadList = useDebounce(loadPlayersList, 500);
 
  useEffect(() => {
    debouncedPlayerLoadList();
  }, [formik.values.maxGrade, formik.values.minGrade, formik.values.onlyPartners, entity.members, textPattern]);

  useEffect(() => {
    if(id) {
      setLoading(true)
      const requests = pathname.includes('/game') ? [
        gameLoad({ uid: id }),
        gradeRatingLoad()
      ] as [Promise<GameLoadResponse>, Promise<GradeRatingLoadResponse>] : [
        loadTournament(id),
        gradeRatingLoad()
      ] as [Promise<LoadTournamentResponse>, Promise<GradeRatingLoadResponse>]
      Promise.all(requests)
        .then(([{ result }, { result: gradesResult }]) => {
          setEntity(result ?? {});
          const minGrade = gradesResult?.gradeRatings?.findIndex(res => res === result?.displayMinGrade) ?? 1;
          const maxGrade = gradesResult?.gradeRatings?.findIndex(res => res === result?.displayMaxGrade) ?? 2;
          formik.setFieldValue('minGrade', minGrade + 1);
          formik.setFieldValue('maxGrade', maxGrade + 1);
        })
        .catch(err => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        });
    }
  }, [id]);

  return (  
    <BasePageWrapper>
      <Header>Пригласить игроков</Header>
      <form onSubmit={formik.handleSubmit}>
        <HLWrapper>
          <Search
            searchHandler={e => setTextPattern(e.target.value)}
            placeholder='Найди игрока по имени или номеру'
          />
        </HLWrapper>
        <SelectRating 
          title="Все пользователи"
          onChange={handleRangeChange}
          value={[formik.values.minGrade, formik.values.maxGrade]}              
        />
        <div className={styles['switcher']}>
          <span>Только мои напарники</span>
          <ToggleSwitch 
            id='onlyPartners'
            name='onlyPartners'
            disabled={loading}
            checked={!!formik.values.onlyPartners}
            onChange={() => formik.setFieldValue('onlyPartners', !formik.values.onlyPartners)}
          /> 
        </div>
        {loading ?
          <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} /> :          
          <SearchRecent 
            suggestions={playerList?.filter(filterInvitedPlayers).reverse()} 
            isInvitation={true} 
            addPlayer={addPlayer} 
            invitedPlayers={invitedPlayers}
            invitationsList={entity?.invitations} 
          />
        }
        <OversideWrapper>
          <Button className={styles['button']} disabled={!invitedPlayers.length}>Пригласить</Button>
        </OversideWrapper>
      </form> 
    </BasePageWrapper>
  );
}
 
export default GameInvitaionPage;
