import InfoPopup from '@/components/InfoPopup/InfoPopup';
import { PlayerStatProps } from './PlayerStat.interfaces';
import styles from './PlayerStat.module.css';
import PercentRing from '@/components/PercentRing/PercentRing';
import { useState } from 'react';

function PlayerStat(props: PlayerStatProps) {
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const { playerStat } = props;

  function returnTenOrLess(value: number): number {
    return value < 10 ? value : 10;
  }

  function calculateEfficiency(): number {
    const percent = Number(playerStat?.winsLastMatchCount) / (Number(playerStat?.correctMatchesCount));
    return Math.round(percent * 100);
  }

  const closePopup = (e: MouseEvent) => {
    if ((e.target as HTMLDivElement).id !== 'statistic') {
      setShowInfo(false)
    }
  }

  return (
    <article className={styles.container} onClick={(e:any)=>closePopup(e)}>
      <div className={styles.valuesWrapper}>
        <h3 className={styles.header}>
          <span>Статистика</span>
          <InfoPopup
            content='Статистика по выигранным матчам'
            id='statistic'
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            width={207}
            right={-95}
            arrowOffset={90}
          />
        </h3>

        <div className={styles.values}>
          <div className={styles.valueWraper}>
            <div className={styles.value}>{playerStat?.correctMatchesCount}</div>
            <div className={styles.valueDesc}>Всего</div>
          </div>
          <div className={`${styles.valueWraper} ${styles.colorGreenyellow}`}>
            <div className={styles.value}>{playerStat?.winsCount}</div>
            <div className={styles.valueDesc}>Выиграно</div>
          </div>
        </div>

        <div className={styles.values}>
          <div className={styles.valueWraper}>
            <div className={styles.value}>{returnTenOrLess(playerStat?.correctMatchesCount || 0)}</div>
            <div className={styles.valueDesc}>Последних</div>
          </div>
          <div className={`${styles.valueWraper} ${styles.colorGreenyellow}`}>
            <div className={styles.value}>{playerStat?.winsLastMatchCount}</div>
            <div className={styles.valueDesc}>Выиграно</div>
          </div>
        </div>
      </div>
      <div className={styles.percentRingWrapper}>
        <PercentRing value={playerStat?.efficiency ?? 0} />
      </div>
    </article>
  );
}

export default PlayerStat;
