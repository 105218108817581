import { FormikErrors, useFormik } from 'formik';
import styles from './TournamentCreatePage.module.css';
import { AdditionalGender, TournamentFormValues } from './TournamentCreatePage.interfaces';
import SelectDate from '../components/SelectDate/SelectDate';
import HLine from '@/components/HLine/HLine';
import SelectTime from '../components/SelectTime/SelectTime';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ClubLoadListParameters, ClubView, Gender, Grade, LocalDateTime, TournamentKind, TournamentStatus, TournamentView, toLocalDate, toLocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import TournamentInput from '../components/TournamentInput/TournamentInput';
import MatchResultSettings from '../components/MatchResultSettings/MatchResultSettings';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import createMatch from '@/static/images/icons/create-match-icon.svg'
import { loadClubs } from '../../services';
import { useAppSelector } from '@/hooks/hooks';
import { getPlannedDate } from '../utils';
import { createInviteListForTournament, gameBookedListTime, gameTimeBooked, saveTournament } from '../service';
import { useNavigate, useParams } from 'react-router-dom';
import SelectRating from '../components/SelectRating/SelectRating';
import Header from '@/components/Header/Header';
import CreateTabs from '../components/CreateTabs/CreateTabs';
import { FetchErrorMessage } from '@/types/types';
import SelectClubSection from '../components/SelectClubSection/SelectClubSection';
import ClubSelectionPage from '../ClubSelectionPage/ClubSelectionPage';
import SelectTournamentType from '../components/SelectTournamentType/SelectTournamentType';
import SelectTournamentGender from '../components/SelectTournamentGender/SelectTournamentGender';
import TournamentSetScores from '../components/TournamentSetScores/TournamentSetScores';
import TournamentQuickPlayersLimit from '../components/TournamentQuickPlayersLimit/TournamentQuickPlayersLimit';
import TimeWarningModal from '../components/TimeWarningModal/TimeWarningModal';
import PrivateTournamentDetails from '../components/PrivateTournamentDetails/PrivateTournamentDetails';
import { loadTournament } from '../../tournament/service';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import MatchSearchPage from '../../match/MatchSearchPage/MatchSearchPage';
import { InvitationStatus, TournamentInviteView } from '@/integration-api/server-rest-get-info';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const TournamentCreatePage = () => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const { id } = useParams();

  const [clubList, setClubList] = useState<Array<ClubView>>();
  const [errorField, setErrorField] = useState<string>();
  const [clubsPageVisible, setClubsPageVisisble] = useState(false);
  const [invitePageVisible, setInvitePageVisible] = useState(false);
  const [bookedTimes, setBookedTimes] = useState<Array<LocalDateTime>>([]);
  const [tournament, setTournament] = useState<TournamentView>({});
  const [loading, setLoading] = useState(true);
  const [waringModal, setWarningModal] = useState(false);
  const [excludedUids, setExcludedUids] = useState<Array<string>>([]);

  const clubRef = useRef<HTMLDivElement | null>(null);
  const timeRef = useRef<HTMLDivElement | null>(null);
  const kindRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const priceRef = useRef<HTMLDivElement | null>(null);
  const maxScoreRef = useRef<HTMLDivElement | null>(null)
  const playersLimitRef = useRef<HTMLDivElement | null>(null);

  const scrollToError = (ref: any) => {
    const centerScreen = ref.current.offsetTop - (window.window.screen.height - ref.current.clientHeight) / 2
    window.scrollTo({
      left: 0,
      top: centerScreen,
      behavior: 'smooth'
    })
  }

  const loopThourghErrors = useCallback((errors: Record<string, any>) => {
    for (let key in errors) {
      if(typeof errors[key] === 'object') {
        loopThourghErrors(errors[key]);
      } else {
        PopupMessage.open(errors[key]);
      }
    }
  }, []);

  const formik = useFormik<TournamentFormValues>({
    initialValues: {
      plannedDate: {
        date: new Date(),
      },
      minGrade: 1,
      maxGrade: Object.keys(current.grades ?? {}).length,
      title: '',
      description: '',
      price: '',
      playersLimit: '',
      gender: AdditionalGender.MULTIPLE,
      reverseSortFirstRound: false,
      privateTournament: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: values => {
      const errors: FormikErrors<TournamentFormValues> = {};
      if (!values.plannedDate.time) {
        scrollToError(timeRef)
        errors.plannedDate = {
          time: 'Выберите время!'
        }
        setErrorField('time');
      }
      if(!values.club) {
        scrollToError(clubRef);
        errors.club = 'Выберите клуб!';
        setErrorField('club');
      }
      if(!values.kind) {
        scrollToError(kindRef);
        errors.gender = 'Выберите тип турнира!';
        setErrorField('kind');
      }
      if(!values.title) {
        scrollToError(titleRef);
        errors.title = 'Назовите турнир!';
        setErrorField('title');
      }
      if(Number(values.price) < 0 || Number(values.price) > 1000000000) {
        scrollToError(priceRef);
        errors.price = 'Невалидное значение стоимости!';
        setErrorField('price');
      }
      if(!values.maxScore) {
        scrollToError(maxScoreRef);
        errors.price = 'Выберите количество очков в сете!';
        setErrorField('maxScore');
      }
      if(Number(values.playersLimit) < 4 || Number(values.playersLimit) % 4) {
        scrollToError(playersLimitRef);
        errors.price = 'Невалидное число игроков!';
        setErrorField('playersLimit');
      } 
      loopThourghErrors(errors);
      return errors;
    },
    onSubmit: handleSubmitForm,
  });

  async function submitForm(values: TournamentFormValues) {
    try {
      const request: TournamentView = {
        ...tournament,
        ...values,
        club: { address: values.club?.mapAddress, displayName: values.club?.name, uid: values.club?.reference?.uid  },
        minGrade: Grade[`_${values.minGrade}` as keyof typeof Grade],
        maxGrade: Grade[`_${values.maxGrade}` as keyof typeof Grade],
        owner: { ...current, uid: current.identity?.uid },
        plannedDate: getPlannedDate(formik),
        status: TournamentStatus.PLANNED,
        genders: values.gender === AdditionalGender.MULTIPLE ? [Gender.MAN, Gender.WOMAN] : [values.gender ?? Gender.MAN],
        maxScore: Number(values.maxScore),
        price: Number(values.price),
        playersLimit: Number(values.playersLimit),
        reverseSortFirstRound: values.kind === TournamentKind.ESCALERA ? values.reverseSortFirstRound : false,
      }
      const { result } = await saveTournament(request);
      if(result) {
        if(values.privateTournament && values.tournamentInvitations?.length) {
          const invitations: Array<TournamentInviteView> = values.tournamentInvitations?.map(inv => ({
            player: inv,
            invitationStatus: InvitationStatus.SENT,
            sender: current
          })) ?? [];
          await createInviteListForTournament({
            tournamentUid: result.identity?.uid ?? '',
            invitations
          })
        }
        navigate(`/tournament/${result.identity?.uid}?afterCreate`);
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }

  async function handleSubmitForm(values: TournamentFormValues) {
    gameTimeBooked({
      plannedDate: getPlannedDate(formik)
    })
      .then(({ result }) => {
        if(result?.booked) {
          if((dayjs(tournament?.plannedDate).format('H:mm') === formik.values.plannedDate?.time) && 
            (dayjs(tournament?.plannedDate).format('YYYY-MM-DD') === dayjs(formik.values.plannedDate?.date).format('YYYY-MM-DD'))) {
              submitForm(values);
          } else {
            setWarningModal(true);
          }
        } else {
          submitForm(values);
        }
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      });
  }

  const handleDateChange = useCallback((date: LocalDateTime) => {
    formik.setFieldValue('plannedDate.date', date);
  }, [formik]);

  const handleClubSelection = (uid: string) => {
    const club = clubList?.find(c => c.reference?.uid === uid)
    formik.setFieldValue('club', club);
    setClubsPageVisisble(false);
  };

  useEffect(() => {
    if(formik.values.plannedDate?.date) {
      gameBookedListTime({plannedDate: toLocalDate(formik.values.plannedDate.date)})
        .then(({ result }) => {
          setBookedTimes(result?.times ?? []);
        })
        .catch(err => console.log(err));
    }
  }, [formik.values?.plannedDate?.date]);

  useEffect(() => {
    if(current.grades) {
      setLoading(true);
      if(id) {
        Promise.all([
          loadTournament(id),
          loadClubs()
        ])
          .then(([{ result }, { result: result2 }]) => {
            if(result && result2) {
              const minGrade = Object.entries(current.grades ?? {}).find(entry => `${entry[0]}` === result.minGrade);
              const maxGrade = Object.entries(current.grades ?? {}).find(entry => `${entry[0]}` === result.maxGrade);
              setClubList(result2.views);
              setTournament(result);
              setExcludedUids(result.invitations?.map(inv => inv.player?.uid ?? '') ?? []);
              formik.setValues({
                description: result.description ?? '',
                gender: result.genders?.length! > 1 ? AdditionalGender.MULTIPLE : result.genders?.includes(Gender.MAN) ? Gender.MAN : Gender.WOMAN,
                plannedDate: {
                  date: dayjs(result.plannedDate).toDate(),
                  time: dayjs(result.plannedDate).format('H:mm'),
                },
                price: `${result.price ?? 0}`,
                title: result.title ?? '',
                kind: result.kind,
                club: result2.views?.find(cl => cl.reference?.uid === result.club?.uid),
                playersLimit: `${result.playersLimit ?? 0}`,
                maxScore: `${result.maxScore ?? 0}`,
                minGrade: parseInt(minGrade?.[0].split('_')?.[1] ?? '1'),
                maxGrade: parseInt(maxGrade?.[0].split('_')?.[1] ?? '1'),
                reverseSortFirstRound: !!result.reverseSortFirstRound,
                privateTournament: !!result.privateTournament,
              })
            }
          })
          .catch(err => {
            if (err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              });
            }
          })
          .finally(() => setLoading(false));
      } else {
        formik.setFieldValue('maxGrade', Object.keys(current.grades).length);
        loadClubs()
          .then(({ result }) => {
            setClubList(result?.views ?? []);
          })
          .catch(err => {
            if (err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              });
            }
          })
          .finally(() => setLoading(false));
      }
    }
  }, [current.grades]);

  return ( 
    <>
      <TimeWarningModal<TournamentFormValues>
        visible={waringModal}
        onClose={() => setWarningModal(false)}
        submitForm={submitForm}
        formValues={formik.values}
      />
      <div hidden={clubsPageVisible || invitePageVisible}>
        <Header className={styles['header']} handleClick={() => id ? navigate(-1) : navigate('/play')} style={{ marginBottom: id ? '10px' : '30px' }}>
          {id ? 'Редактирование турнира' : 'Создание турнира'}
        </Header>
        {!id ? 
          <CreateTabs
            activeTab='tournament'
          /> : null
        }
        {loading ? 
          <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} /> :          
          <form onSubmit={formik.handleSubmit}>
            <SelectDate
              onActiveDateChange={handleDateChange}
              initialStartDate={toLocalDateTime(formik.values.plannedDate?.date)}
              setErrorField={(field: string) => setErrorField(field)}
            />
            <HLine fieldsErrors={errorField === 'time'} calssName={styles['hline']}/>
            <div ref={timeRef}>
              <SelectTime
                onChange={formik.handleChange} 
                bookedTimes={bookedTimes}
                formikValues={formik.values}
              />
            </div>
            <HLine fieldsErrors={errorField === 'time' || errorField === 'club'} calssName={styles['hline']}/>
            <div ref={clubRef}>
              <SelectClubSection
                clubList={clubList}
                selectedClub={formik.getFieldProps('club').value}
                handleClubSelection={handleClubSelection}
                onClick={() => clubList ? setClubsPageVisisble(true) : undefined}
              />
            </div>
            <HLine fieldsErrors={errorField === 'club' || errorField === 'kind'} calssName={styles['hline']}/>
            <div ref={kindRef}>
              <SelectTournamentType
                value={formik.values.kind}
                handleChange={value => {
                  if(value === TournamentKind.ESCALERA) {
                    formik.setFieldValue('maxScore', '15');
                  } else {
                    formik.setFieldValue('maxScore', undefined);
                  }
                  formik.setFieldValue('kind', value);
                }}
                reverseSortFirstRound={formik.values.reverseSortFirstRound}
                handleReverseSortFirstRound={val => formik.setFieldValue('reverseSortFirstRound', val)}
              />
            </div>
            <HLine fieldsErrors={errorField === 'kind'} calssName={styles['hline']}/>
            <SelectTournamentGender value={formik.values.gender} handleChange={value => formik.setFieldValue('gender', value)}/>
            <HLine fieldsErrors={errorField === 'title'} calssName={styles['hline']}/>
            <div ref={titleRef}>
              <TournamentInput 
                label='Название турнира'
                placeholder='Введите название турнира'
                name='title'
                values={formik.values}
                maxLength={60}
                handleChange={(val: string) => formik.setFieldValue('title', val)}
              />
            </div>
            <HLine fieldsErrors={errorField === 'title' || errorField === 'price'} calssName={styles['hline']}/>
            <div ref={priceRef}>
              <TournamentInput 
                label='Стоимость участия'
                placeholder='Введите стоимость участия в рублях'
                name='price'
                values={formik.values}
                handleChange={(val: string) => formik.setFieldValue('price', val)}
              />
            </div>
            <HLine fieldsErrors={errorField === 'price' || errorField === 'maxScore'} calssName={styles['hline']}/>
            <div ref={maxScoreRef}>
              <TournamentSetScores
                kind={formik.values.kind}
                maxScore={formik.values.maxScore}
                onChange={formik.handleChange}
              />
            </div>
            <HLine fieldsErrors={errorField === 'maxScore' || errorField === 'playersLimit'} calssName={styles['hline']}/>
            <div ref={playersLimitRef}>
              <TournamentInput 
                label='Количество игроков'
                placeholder='Введите количество игроков в турнире'
                name='playersLimit'
                values={formik.values}
                handleChange={(val: string) => formik.setFieldValue('playersLimit', val)}
              />
              <TournamentQuickPlayersLimit handleClick={value => formik.setFieldValue('playersLimit', value)}/>
            </div>
            <HLine fieldsErrors={errorField === 'playersLimit'} calssName={styles['hline']}/>
            <SelectRating
              title='Выберите рейтинг игроков'
              onChange={(value) => {
                formik.setFieldValue('minGrade',value[0]);
                formik.setFieldValue('maxGrade', value[1]);
              }}
              value={[formik.values.minGrade, formik.values.maxGrade]}
            />
            <HLine calssName={styles['hline']}/>
            <PrivateTournamentDetails
              privateTournament={formik.values.privateTournament}
              setPrivateTournament={formik.handleChange}
              invitations={formik.values.tournamentInvitations}
              openInvitePage={() => setInvitePageVisible(true)}
            />
            <HLine calssName={styles['hline']}/>
            <TournamentInput 
              label='Комментарии к турниру'
              placeholder='Введите комментарий'
              name='description'
              values={formik.values}
              handleChange={(val: string) => formik.setFieldValue('description', val)}
              maxLength={200}
            />
            <MatchResultSettings getFieldValue={formik.getFieldProps}/>
            <OversideWrapper>
              <Button className={styles.createMatchBtn} type='submit'>
                {id ?
                  <span>Сохранить турнир</span> :
                  <>
                    <img src={createMatch} alt='плюс' className={styles.createMatchIcon} />
                    Создать турнир
                  </>
                }
              </Button>
            </OversideWrapper>
          </form>
        }
      </div>
      {clubsPageVisible && clubList ?
        <ClubSelectionPage
          selectedClub={formik.getFieldProps('club').value}
          onClose={() => setClubsPageVisisble(false)}
          handleClubSelect={handleClubSelection}
        /> : null
      }
      {invitePageVisible && current.grades ?
        <MatchSearchPage
          onSubmit={(invitedPlayers) => {
            formik.setFieldValue('tournamentInvitations', invitedPlayers);
            setInvitePageVisible(false);
          }}
          ratingRange={[formik.values.minGrade, formik.values.maxGrade]}
          initialSlectedPlayers={formik.values.tournamentInvitations}
          onClose={() => setInvitePageVisible(false)}
          excludedUids={excludedUids}
        /> : null
      }
    </>
  );
}
 
export default TournamentCreatePage;
