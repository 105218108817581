/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GamePrivateParam
 */
export interface GamePrivateParam {
    /**
     * 
     * @type {string}
     * @memberof GamePrivateParam
     */
    gameUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GamePrivateParam
     */
    privateGame?: boolean;
}

export function GamePrivateParamFromJSON(json: any): GamePrivateParam {
    return GamePrivateParamFromJSONTyped(json, false);
}

export function GamePrivateParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): GamePrivateParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'privateGame': !exists(json, 'privateGame') ? undefined : json['privateGame'],
    };
}

export function GamePrivateParamToJSON(value?: GamePrivateParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'privateGame': value.privateGame,
    };
}

