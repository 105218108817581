import { TournamentPlayerStatisticsInfo } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentStatistics.module.css';
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import { useAppSelector } from '@/hooks/hooks';

type TournamentStatisticsItemProps = {
  statistic?: TournamentPlayerStatisticsInfo;
}

const TournamentStatisticsItem = ({ statistic }: TournamentStatisticsItemProps) => {
  const currentUid = useAppSelector(state => state.auth.currentProfile.identity?.uid);

  return ( 
    <div className={`${styles['grid-item']} ${currentUid === statistic?.playerInfo?.uid ? styles['blue'] : ''} `}>
      <ProfileCard
        player={statistic?.playerInfo}
        fullName
        noPreferedSide
        noYourself
      />
      <span>{statistic?.wonMatches} - <span style={{ color: 'var(--gray-dark)' }}>{statistic?.drawMatches}</span> - {statistic?.lostMatches}</span>
      <span>{statistic?.pointsScored} - {statistic?.pointsConceded}</span>
      <span className={`${statistic?.pointDelta! > 0 ? styles['positive'] : ''} ${statistic?.pointDelta! < 0 ? styles['negative'] : ''}`}>{statistic?.pointDelta}</span>
    </div>
  );
}
 
export default TournamentStatisticsItem;
