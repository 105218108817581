import { SlideData } from './LineupScoreSwiper.interfaces';
import styles from './LineupScoreSwiper.module.css';

export const applySlideClass = (slide: SlideData) => {
  if(slide.isNext) {
    return styles['slide-next'];
  }
  if(!slide.isActive && slide.isVisible) {
    return styles['slide-visible'];
  }
  if(slide.isActive) {
    return styles['slide-active'];
  }
  return ''
}
