import styles from './ProfileCard.module.css';
import { ProfileCardProps } from './ProfileCard.interfaces';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import { createImgSrc } from '@/utils/utils';
import { PrefferedSide } from '@/pages/application/tournament/utils';
import { useAppSelector } from '@/hooks/hooks';
import { isYourself } from './utils';

const ProfileCard = ({ player, team, bage, fullName, noPreferedSide, orange, onClick, noYourself, ...props }: ProfileCardProps) => {
  const currentUid = useAppSelector(state => state.auth.currentProfile.identity?.uid);
  const backgroundColor = team === 'left' ? 'var(--green-lunda)' : team === 'right' ? 'var(--blue)' : undefined;
  return (  
    <div {...props} className={`${styles['player']} ${orange ? styles['orange'] : ''}`.trim()}>
      <UserAvatar
        avatarUid={createImgSrc.playerProfile(player?.uid, player?.avatarUid)}
        badge={bage ?? {
          element: <RatingBage rating={player?.displayRating ?? ''} style={{ backgroundColor }}/>,
          position: 'top-right'
        }}
        nameInitials={player?.nameInitials}
        className={isYourself(currentUid, player?.uid) ? styles['yourself-border'] : ''}
        onClick={onClick}
      />
      {!noPreferedSide ? <span className={styles['preference']}>{PrefferedSide(player?.leftSide, player?.rightSide)}</span> : null}
      {!noYourself && isYourself(currentUid, player?.uid) ?
        <span className={styles['yourself']}>
          Вы
          <br></br>
          &nbsp;
        </span> :
        <>
          <span className={styles['name']}>
            {player?.displayName?.split?.(' ')?.[0]}
            <br></br>
            {fullName ? `${player?.displayName?.split?.(' ')?.[1]}` : ''}
          </span>
        </>
      }
    </div>
  );
}
 
export default ProfileCard;
