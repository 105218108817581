import { CheckVersionStatus } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";
import { version } from '@/components/AppVersionChecker/app-version';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useVersionChecker() {
  const [versionCheckResult, setVersionCheckResult] = useState<CheckVersionStatus>();
  const { pathname } = useLocation();

  useEffect(() => {
    RestClient.mainApi.minimumSupportedVersionCheck({ version })
      .then(({result}) => {setVersionCheckResult(result?.status)})
      .catch(e => {
        setVersionCheckResult(CheckVersionStatus.SUPPORTED);
        console.log(e);
      })
  }, [pathname]);

  return versionCheckResult;
}
