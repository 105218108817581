/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UIRatingSettingsView,
    UIRatingSettingsViewFromJSON,
    UIRatingSettingsViewFromJSONTyped,
    UIRatingSettingsViewToJSON,
} from './UIRatingSettingsView';

/**
 * 
 * @export
 * @interface GetUIRatingSettingsResponse
 */
export interface GetUIRatingSettingsResponse {
    /**
     * 
     * @type {UIRatingSettingsView}
     * @memberof GetUIRatingSettingsResponse
     */
    result?: UIRatingSettingsView;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetUIRatingSettingsResponse
     */
    messages?: Array<object>;
}

export function GetUIRatingSettingsResponseFromJSON(json: any): GetUIRatingSettingsResponse {
    return GetUIRatingSettingsResponseFromJSONTyped(json, false);
}

export function GetUIRatingSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUIRatingSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : UIRatingSettingsViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function GetUIRatingSettingsResponseToJSON(value?: GetUIRatingSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': UIRatingSettingsViewToJSON(value.result),
        'messages': value.messages,
    };
}

