/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentView,
    TournamentViewFromJSON,
    TournamentViewFromJSONTyped,
    TournamentViewToJSON,
} from './TournamentView';

/**
 * 
 * @export
 * @interface SaveTournamentRequest
 */
export interface SaveTournamentRequest {
    /**
     * 
     * @type {TournamentView}
     * @memberof SaveTournamentRequest
     */
    parameters?: TournamentView;
}

export function SaveTournamentRequestFromJSON(json: any): SaveTournamentRequest {
    return SaveTournamentRequestFromJSONTyped(json, false);
}

export function SaveTournamentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveTournamentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TournamentViewFromJSON(json['parameters']),
    };
}

export function SaveTournamentRequestToJSON(value?: SaveTournamentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TournamentViewToJSON(value.parameters),
    };
}

