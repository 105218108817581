import { HTMLAttributes } from "react";
import styles from './RatingInfoHeadling.module.css';

interface RatingInfoHeadlingProps extends HTMLAttributes<HTMLDivElement> {
  index: number;
}

const RatingInfoHeadling = ({ index, children, className }: RatingInfoHeadlingProps) => {
  return (
    <div className={`${className} ${styles['headling']}`}>
      <div className={styles['circle']}>
        <span>{index}</span>
      </div>
      <div className={styles['text']}>
        {children}
      </div>
    </div>
  );
}

export default RatingInfoHeadling;