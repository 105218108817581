/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TourGameInfo,
    TourGameInfoFromJSON,
    TourGameInfoFromJSONTyped,
    TourGameInfoToJSON,
} from './TourGameInfo';

/**
 * 
 * @export
 * @interface TourGameLoadListResult
 */
export interface TourGameLoadListResult {
    /**
     * 
     * @type {number}
     * @memberof TourGameLoadListResult
     */
    total?: number;
    /**
     * 
     * @type {Array<TourGameInfo>}
     * @memberof TourGameLoadListResult
     */
    infos?: Array<TourGameInfo>;
}

export function TourGameLoadListResultFromJSON(json: any): TourGameLoadListResult {
    return TourGameLoadListResultFromJSONTyped(json, false);
}

export function TourGameLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TourGameLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(TourGameInfoFromJSON)),
    };
}

export function TourGameLoadListResultToJSON(value?: TourGameLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(TourGameInfoToJSON)),
    };
}

