import styles from './TournamentResultsClassic.module.css';
import Header from "@/components/Header/Header";
import PageBackground from "@/components/PageBackground/PageBackground";
import bgImage from '@/static/images/match-page-background.jpeg';
import { useCallback, useEffect, useState } from "react";
import { TournamentMemberView, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import { saveClassicTournamentTable } from "../service";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@/hooks/hooks";
import { FetchErrorMessage } from "@/types/types";
import TournamentTable from "../components/TournamentTable/TournamentTable";
import withScrolling from 'react-dnd-scrolling';
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { TournamentResultsAction, TournamentResultsActionParams, UpdadeTournamentResultListArgs } from "../TournamentPage.interfaces";
import { TournamentResultClassicContext } from "../utils";
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const ScrollingComponent = withScrolling('div');

type TournamentResultsClassiProps = {
  tournament: TournamentView;
}

const TournamentResultsClassic = ({ tournament }: TournamentResultsClassiProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const [participants, setParticipants] = useState<Array<TournamentMemberView>>([]);
  
  const updateList = ({ item, placement }: UpdadeTournamentResultListArgs) => {
    const sliceIndex = placement > item.placement ? placement : placement - 1;
    const left = participants.slice(0, sliceIndex).filter(p => p.player?.uid !== item.participant?.player?.uid);
    const right = participants.slice(sliceIndex).filter(p => p.player?.uid !== item.participant?.player?.uid);
    const resultArray = left.concat([item.participant]).concat(right);
    setParticipants(resultArray);
  };
  
  const handleButtonAction = useCallback(async (action: TournamentResultsAction, params?: TournamentResultsActionParams) => {
    try {
      switch(action) {
        case 'save': {
          const { result } = await saveClassicTournamentTable({
            members: participants,
            tournamentUid: tournament.identity?.uid
          });
          if(result) {
            navigate(-1);
          }
          break;
        }
        case 'edit': {
          break;
        }
        default: {
          const _: never = action;
          throw new Error('not every case handled');
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, [participants, tournament.identity?.uid]);

  useEffect(() => {
    setParticipants(tournament?.members?.slice() ?? []);
  }, [tournament]);

  return ( 
    <TournamentResultClassicContext.Provider value={{ participants, updateList, isOwner: current.identity?.uid === tournament.owner?.uid }}>
      <>
        <PageBackground imgSrc={bgImage} />
        <Header className={styles['header']}>
          Турнир
        </Header>
        <DndProvider backend={TouchBackend}>
          <ScrollingComponent className={styles['wrapper']}>
            <TournamentTable 
              handleButtonAction={handleButtonAction}
              tournamentStatus={tournament.status}
            />
          </ScrollingComponent>
        </DndProvider>
      </>
    </TournamentResultClassicContext.Provider>
  );
}
 
export default TournamentResultsClassic;
