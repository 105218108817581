/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameElementInfo,
    GameElementInfoFromJSON,
    GameElementInfoFromJSONTyped,
    GameElementInfoToJSON,
} from './GameElementInfo';

/**
 * 
 * @export
 * @interface LoadListPastGameResult
 */
export interface LoadListPastGameResult {
    /**
     * 
     * @type {number}
     * @memberof LoadListPastGameResult
     */
    total?: number;
    /**
     * 
     * @type {Array<GameElementInfo>}
     * @memberof LoadListPastGameResult
     */
    infos?: Array<GameElementInfo>;
}

export function LoadListPastGameResultFromJSON(json: any): LoadListPastGameResult {
    return LoadListPastGameResultFromJSONTyped(json, false);
}

export function LoadListPastGameResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadListPastGameResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(GameElementInfoFromJSON)),
    };
}

export function LoadListPastGameResultToJSON(value?: LoadListPastGameResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(GameElementInfoToJSON)),
    };
}

