type FemaleIconProps = {
  fill?: string;
}

const FemaleIcon = ({ fill }: FemaleIconProps) => {
  return ( 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.19126 12.9118C5.86643 13.3585 5.96518 13.9839 6.41183 14.3087C6.85848 14.6336 7.4839 14.5348 7.80874 14.0882L6.19126 12.9118ZM11 8V7C10.6801 7 10.3794 7.15308 10.1913 7.41183L11 8ZM12 22L11.0025 22.0712H12.9975L12 22ZM16.1913 14.0882C16.5161 14.5348 17.1415 14.6336 17.5882 14.3087C18.0348 13.9839 18.1336 13.3585 17.8087 12.9118L16.1913 14.0882ZM13 8L13.8087 7.41183C13.6206 7.15308 13.3199 7 13 7V8ZM12 3V2V3ZM14.5 16.5V17.5H15.6919L15.4848 16.3262L14.5 16.5ZM9.5 16.5L8.51522 16.3262L8.30808 17.5H9.5V16.5ZM7.80874 14.0882L11.8087 8.58817L10.1913 7.41183L6.19126 12.9118L7.80874 14.0882ZM10.0025 8.07125L11.0025 22.0712L12.9975 21.9288L11.9975 7.92875L10.0025 8.07125ZM17.8087 12.9118L13.8087 7.41183L12.1913 8.58817L16.1913 14.0882L17.8087 12.9118ZM12.0025 7.92875L11.0025 21.9288L12.9975 22.0712L13.9975 8.07125L12.0025 7.92875ZM12 6C12.5304 6 13.0391 5.78929 13.4142 5.41421L12 4V4V6ZM13.4142 5.41421C13.7893 5.03914 14 4.53043 14 4H12V4L13.4142 5.41421ZM14 4C14 3.46957 13.7893 2.96086 13.4142 2.58579L12 4V4H14ZM13.4142 2.58579C13.0391 2.21071 12.5304 2 12 2V4V4L13.4142 2.58579ZM12 2C11.4696 2 10.9609 2.21071 10.5858 2.58579L12 4V4V2ZM10.5858 2.58579C10.2107 2.96086 10 3.46957 10 4H12V4L10.5858 2.58579ZM10 4C10 4.53043 10.2107 5.03914 10.5858 5.41421L12 4V4H10ZM10.5858 5.41421C10.9609 5.78929 11.4696 6 12 6V4V4L10.5858 5.41421ZM11 9H13V7H11V9ZM12.0152 8.17379L13.5152 16.6738L15.4848 16.3262L13.9848 7.82621L12.0152 8.17379ZM14.5 15.5H9.5V17.5H14.5V15.5ZM10.4848 16.6738L11.9848 8.17379L10.0152 7.82621L8.51522 16.3262L10.4848 16.6738Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default FemaleIcon;
