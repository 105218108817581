/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentPlayerStatisticsInfo,
    TournamentPlayerStatisticsInfoFromJSON,
    TournamentPlayerStatisticsInfoFromJSONTyped,
    TournamentPlayerStatisticsInfoToJSON,
} from './TournamentPlayerStatisticsInfo';

/**
 * 
 * @export
 * @interface TournamentStatisticsLoadResult
 */
export interface TournamentStatisticsLoadResult {
    /**
     * 
     * @type {Array<TournamentPlayerStatisticsInfo>}
     * @memberof TournamentStatisticsLoadResult
     */
    playersStatistics?: Array<TournamentPlayerStatisticsInfo>;
}

export function TournamentStatisticsLoadResultFromJSON(json: any): TournamentStatisticsLoadResult {
    return TournamentStatisticsLoadResultFromJSONTyped(json, false);
}

export function TournamentStatisticsLoadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentStatisticsLoadResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playersStatistics': !exists(json, 'playersStatistics') ? undefined : ((json['playersStatistics'] as Array<any>).map(TournamentPlayerStatisticsInfoFromJSON)),
    };
}

export function TournamentStatisticsLoadResultToJSON(value?: TournamentStatisticsLoadResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playersStatistics': value.playersStatistics === undefined ? undefined : ((value.playersStatistics as Array<any>).map(TournamentPlayerStatisticsInfoToJSON)),
    };
}

