import { LineupCardProps } from './LineupCard.interfaces';
import styles from './LineupCard.module.css';
import ProfileCard from '../ProfileCard/ProfileCard';
import LineupVersus from '../LineupVersus/LineupVersus';
import RatingBage from '../Badge/RatingBage/RatingBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { GameLineupView, GameMatchView, QuickGameView, Role } from '@/integration-api/server-rest-lundapadelApi';
import StickyTopBorder from '../StickyTopBorder/StickyTopBorder';
import LineupCardMatchItem from './LineupCardMatchItem';
import { calculateShownMatchSets } from './utils';
import PencilIcon from '@/static/images/icons/PencilIcon';

const LineupCard = ({ lineup, type, index, stickyBorderTop, mayBeEdited, ...props }: LineupCardProps) => {
  if(type === 'empty' || type === 'edit') {
    return (
      <div>
        <StickyTopBorder top={stickyBorderTop} height='1.5px' color={'var(--blue-darker)'}/>
        <div {...props} className={styles['card-empty-wrapper']}>
          <LineupVersus 
            lineup={lineup} 
            index={index} 
            listItem 
            type={type}
          />
        </div>
      </div>
    );
  }

  if(type === 'filled') {
    const matches = (lineup as GameLineupView).matches ?? [];
    const sets = (lineup as GameLineupView).gameSets ?? [];
    const setsMatch: GameMatchView = { gameSets: sets };

    const finalArray = calculateShownMatchSets(matches, setsMatch);

    return (
      <div>
        <StickyTopBorder top={stickyBorderTop} height='1.5px' color={'var(--blue-darker)'}/>
        <div {...props} className={styles['card-filled-wrapper']}>
          {mayBeEdited ? 
            <div className={styles['edit-icon']}>
              <PencilIcon fill='#D8D7D7'/>
            </div> : null
          }
          {index ? <span className={styles['lineup-number']}>{index}</span> : null}
          <div className={styles['lineup-filled-wrapper']}>
            <div className={styles['lineup-team']}>
              <ProfileCard
                player={lineup.player1}
                team='left'
                noPreferedSide
                fullName
                bage={[{
                  element: <RatingBage rating={lineup.player1?.displayRating ?? ''}/>,
                  position: 'top-right'
                }, {
                  element: lineup.player1?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
              <ProfileCard
                player={lineup.player2}
                team='left'
                noPreferedSide
                fullName
                bage={[{
                  element: <RatingBage rating={lineup.player2?.displayRating ?? ''}/>,
                  position: 'top-right'
                }, {
                  element: lineup.player2?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
            </div>
            <div className={styles['lineup-team']}>
              <ProfileCard
                player={lineup.player3}
                team='right'
                noPreferedSide
                fullName
                bage={[{
                  element: <RatingBage rating={lineup.player3?.displayRating ?? ''} style={{ background: 'var(--blue)' }}/>,
                  position: 'top-right'
                }, {
                  element: lineup.player3?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
              <ProfileCard
                player={lineup.player4}
                team='right'
                noPreferedSide
                fullName
                bage={[{
                  element: <RatingBage rating={lineup.player4?.displayRating ?? ''} style={{ background: 'var(--blue)' }}/>,
                  position: 'top-right'
                }, {
                  element: lineup.player4?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
            </div>
          </div>
          <div className={styles['game-results']}>
            {finalArray.map((match, i) => (
              <LineupCardMatchItem
                key={i}
                match={match}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if(type === 'tournament') {
    return (
      <div>
        <StickyTopBorder color='var(--green-lunda)' top={stickyBorderTop}/>
        <div {...props} className={styles['card-tournament-wrapper']}>
          <span className={styles['lineup-number']} style={{ color: 'var(--green-regular)' }}>{index}</span>
          <div className={styles['lineup-divider']} style={{ borderColor: 'var(--green-dark)' }}></div>
          <div className={styles['lineup-filled-wrapper']}>
            <div className={styles['lineup-team']}>
              <ProfileCard 
                player={lineup.player1} 
                team='left'
                noPreferedSide
                fullName
                bage={[{
                  element: <RatingBage rating={lineup.player1?.displayRating ?? ''}/>,
                  position: 'top-right'
                }, {
                  element: lineup.player1?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
              <ProfileCard 
                player={lineup.player2} 
                team='left'
                noPreferedSide
                fullName
                bage={[{
                  element: <RatingBage rating={lineup.player2?.displayRating ?? ''}/>,
                  position: 'top-right'
                }, {
                  element: lineup.player2?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
            </div>
            <div className={styles['lineup-team']}>
            <ProfileCard 
              player={lineup.player3} 
              team='right'
              noPreferedSide
              fullName
              bage={[{
                element: <RatingBage rating={lineup.player3?.displayRating ?? ''} style={{ background: 'var(--blue)' }}/>,
                position: 'top-right'
              }, {
                element: lineup.player3?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                position: 'top-left'
              }]}
            />
            <ProfileCard 
              player={lineup.player4} 
              team='right'
              noPreferedSide
              fullName
              bage={[{
                element: <RatingBage rating={lineup.player4?.displayRating ?? ''} style={{ background: 'var(--blue)' }}/>,
                position: 'top-right'
              }, {
                element: lineup.player4?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                position: 'top-left'
              }]}
            />
            </div>
          </div>
          <div className={styles['tournament-score']}>
            <span style={{ color: (lineup as QuickGameView).score1! < (lineup as QuickGameView).score2! ? 'var(--gray-blue)' : 'var(--white)'}}>{(lineup as QuickGameView).score1}</span>
            <span style={{ color: (lineup as QuickGameView).score2! < (lineup as QuickGameView).score1! ? 'var(--gray-blue)' : 'var(--white)'}}>{(lineup as QuickGameView).score2}</span>
          </div>
        </div>
      </div>
    )
  }

  if(type === 'undistributed') {
    return (
      <div {...props} className={styles['card-undistributed-wrapper']}>
        <ProfileCard 
          player={lineup.player1}
          noPreferedSide
          fullName
          bage={[{
            element: <RatingBage rating={lineup.player1?.displayRating ?? ''}/>,
            position: 'top-right'
          }, {
            element: lineup.player1?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
            position: 'top-left'
          }]}
        />
        <ProfileCard 
          player={lineup.player2}
          noPreferedSide
          fullName
          bage={[{
            element: <RatingBage rating={lineup.player2?.displayRating ?? ''}/>,
            position: 'top-right'
          }, {
            element: lineup.player2?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
            position: 'top-left'
          }]}
        />
        <ProfileCard 
          player={lineup.player3}
          noPreferedSide
          fullName
          bage={[{
            element: <RatingBage rating={lineup.player3?.displayRating ?? ''}/>,
            position: 'top-right'
          }, {
            element: lineup.player3?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
            position: 'top-left'
          }]}
        />
        <ProfileCard 
          player={lineup.player4}
          noPreferedSide
          fullName
          bage={[{
            element: <RatingBage rating={lineup.player4?.displayRating ?? ''}/>,
            position: 'top-right'
          }, {
            element: lineup.player4?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
            position: 'top-left'
          }]}
        />
      </div>
    )
  }
  
  return null;
}
 
export default LineupCard;
