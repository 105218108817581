import styles from './InfoPopup.module.css'
import infoIcon from '@/static/images/icons/info-icon.svg'
import infoIconActive from '@/static/images/icons/info-icon-yellow.svg'
import { FC } from 'react';

export interface InfoPopupProps{
  content:string,
  id:string,
  showInfo:boolean,
  setShowInfo: any,
  width?:number,
  right?:number,
  arrowOffset?:number
}
const InfoPopup:FC<InfoPopupProps> = ({content, id, showInfo, setShowInfo, width, right,arrowOffset}) => {
  return (
    <>
      <span className={styles['popup-wrapper']}>
        <img
          id={id}
          src={showInfo ? infoIconActive : infoIcon} alt={id}
          onClick={() => setShowInfo(!showInfo)}
        />
        {showInfo &&
          <div className={styles['popup-second']} style={{width:`${width}px`, right:`${right}px`}}>
           {content}
           <span  className={styles['popup-arrow']} style={{left:`${arrowOffset}px`}}></span>
          </div>
        }
      </span>
    </>
  )
}

export default InfoPopup
