import { playerListBlockerElementID } from './RatingSection';

export function setPlayerListBlockerStatus(status: 'block' | 'unblock') {
  const playerListBlockerElement = document.getElementById(playerListBlockerElementID);
  if (!playerListBlockerElement) return;

  switch(status) {
    case 'block': {
      playerListBlockerElement.style.zIndex = '1'; // устанавливаем блокировку списка игроков
      break;
    } case 'unblock': {
      playerListBlockerElement.style.zIndex = '0'; // снимаем блокировку свайпера
      break;
    }
  } 
}
