/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InviteLoadListResult,
    InviteLoadListResultFromJSON,
    InviteLoadListResultFromJSONTyped,
    InviteLoadListResultToJSON,
} from './InviteLoadListResult';

/**
 * 
 * @export
 * @interface LoadListInviteResponse
 */
export interface LoadListInviteResponse {
    /**
     * 
     * @type {InviteLoadListResult}
     * @memberof LoadListInviteResponse
     */
    result?: InviteLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadListInviteResponse
     */
    messages?: Array<object>;
}

export function LoadListInviteResponseFromJSON(json: any): LoadListInviteResponse {
    return LoadListInviteResponseFromJSONTyped(json, false);
}

export function LoadListInviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadListInviteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : InviteLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadListInviteResponseToJSON(value?: LoadListInviteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': InviteLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

