import styles from './TournamentCard.module.css';
import medalIcon from '@/static/images/icons/medal-icon.svg';
import calendarIcon from '@/static/images/icons/calendar-icon.svg';
import tourneyIcon from '@/static/images/icons/tournament-type-icon.svg';
import walletIcon from '@/static/images/icons/wallet-small-icon.svg';
import trophyImage from '@/static/images/trophy.svg';
import formatter from "@/utils/utils";
import { EMPTY_STRING, TIME_FORMAT_HH_MM } from "@/utils/constants";
import { TournamentCardProps, isTournamentView } from "./TournamentCard.interfaces";
import { cardBackground, eyeFill, tournamentType } from './utils';
import { translateTournamentType } from '@/pages/application/create/components/SelectTournamentType/utils';
import { TournamentKind, TournamentStatus } from '@/integration-api/server-rest-lundapadelApi';
import CommnentIcon from '@/static/images/icons/CommentIcon';
import LocationIconOutline from '@/static/images/icons/LocationIconOutline';
import ParticipantsIcon from '@/static/images/icons/ParticipantsIcon';
import PrivateGameIcon from '@/static/images/icons/PrivateGameIcon';

const TournamentCard = ({ tournament, restricted, ...props }: TournamentCardProps) => {
  return (  
    <div 
      {...props}
      className={`${styles['card-wrapper']} ${!isTournamentView(tournament) ? cardBackground(tournament?.tournamentStatus) : EMPTY_STRING}`}
    >
      <div className={styles['headling']}>{tournamentType(tournament?.genders)} «{tournament?.title}»</div>
      <div className={styles['info']}>
        <div className={styles['info-item']}>
          <img src={calendarIcon} alt="икона календаря" />
          <span>
            {`${formatter.formatDateToDayDateMonth(tournament?.plannedDate)}, 
            ${formatter.formatDate(tournament?.plannedDate ?? new Date(), TIME_FORMAT_HH_MM)}`}
          </span>
        </div>
        <div className={styles['info-item']}>
          <div style={{ alignSelf: 'flex-start' }}>
            <LocationIconOutline/>
          </div>
          <span>{isTournamentView(tournament) ? tournament?.club?.displayName : tournament?.club?.caption}</span>
        </div>
        {!restricted && tournament?.description ?
          <div className={styles['info-item']}>
            <div style={{ alignSelf: 'flex-start' }}>
              <CommnentIcon/>
            </div>
            <span>{tournament?.description}</span>
          </div> : null
        }
        <div className={styles['info-item']}>
          <img src={medalIcon} alt="икона медали" />
          {tournament?.displayMinGrade === tournament?.displayMaxGrade ?
            <span>{tournament?.displayMaxGrade}</span> :
            <span>{tournament?.displayMinGrade}...{tournament?.displayMaxGrade}</span>
          }
        </div>
        <div className={styles['info-item']}>
          <img src={tourneyIcon} alt="икона мяча" />
          <span>{translateTournamentType(tournament?.kind ?? TournamentKind.CLASSIC)}</span>
        </div>
        <div className={styles['info-item']}>
          <img src={walletIcon} alt="икона портмоне" />
          <span>{tournament?.price} ₽</span>
        </div>
        <div className={styles['info-item']}>
          <div style={{ alignSelf: 'flex-start' }}>
            <ParticipantsIcon/>
          </div>
          {restricted || (isTournamentView(tournament) && ![TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.FINISH].includes(tournament?.status!)) ?
            <span>{isTournamentView(tournament) ? tournament?.members?.length : tournament?.countPlayers}/{tournament?.playersLimit} игроков</span> :
            <span>{isTournamentView(tournament) ? tournament?.members?.length : tournament?.countPlayers} игроков</span>
          }
        </div>
      </div>
      {restricted && !isTournamentView(tournament) && tournament.privateTourGame ?
        <div className={styles['private']}>
          <PrivateGameIcon fill={eyeFill(tournament?.tournamentStatus)}/> 
        </div> : null
      }
      <img className={styles['trophy']} src={trophyImage} alt="кубок" />
    </div>
  );
}
 
export default TournamentCard;
