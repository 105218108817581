import { Gender } from "@/integration-api/server-rest-lundapadelApi";
import { AdditionalGender } from "../../tournamentCreate/TournamentCreatePage.interfaces";
import MaleIcon from "@/static/images/icons/MaleIcon";
import FemaleIcon from "@/static/images/icons/FemaleIcon";
import { GenderSelectOption } from "./SelectTournamentGender.interfaces";
import styles from './SelectTournamentGender.module.css';

export const TranslateTournamentGender = (gender: Gender | AdditionalGender) => {
  switch(gender) {
    case AdditionalGender.MULTIPLE:
      return 'Смешанный';
    case Gender.MAN:
      return 'Мужской';
    case Gender.WOMAN:
      return 'Женский';
    default: 
      const _: never = gender;
      throw new Error('not every instance handled');
  }
}


export const genderOptions: Array<GenderSelectOption> = [
  {
    label: (
      <div className={styles['option-label']}>
        <div><MaleIcon/><FemaleIcon/></div>
        {TranslateTournamentGender(AdditionalGender.MULTIPLE)}
      </div> 
    ),
    value: AdditionalGender.MULTIPLE
  },
  {
    label: <div className={styles['option-label']}><MaleIcon/>{TranslateTournamentGender(Gender.MAN)}</div>,
    value: Gender.MAN
  },
  {
    label: <div className={styles['option-label']}><FemaleIcon/>{TranslateTournamentGender(Gender.WOMAN)}</div>,
    value: Gender.WOMAN
  },
]
