import UserAvatar from '@/components/UserAvatar/UserAvatar';
import styles from './OwnerButton.module.css';
import { PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import { HTMLAttributes } from 'react';
import { createImgSrc } from '@/utils/utils';

type OwnerButtonProps = {
  owner?: PlayerProfileInfo
} & HTMLAttributes<HTMLDivElement>

const OwnerButton = ({ owner, ...props }: OwnerButtonProps) => {
  return (  
    <div {...props} className={styles['wrapper']}>
      <UserAvatar
        className={styles['avatar']}
        avatarUid={createImgSrc.playerProfile(owner?.uid, owner?.avatarUid)}
        nameInitials={owner?.nameInitials}
      />
      <span>
        {owner?.displayName + ' (организатор)'}
      </span>
    </div>
  );
}
 
export default OwnerButton;
