import React, { PropsWithChildren, RefObject } from 'react';
import styles from './OversideWrapper.module.css';

interface OversideWrapperInterface extends PropsWithChildren {
  className?: string;
  buttonsRef?: RefObject<HTMLDivElement>
}

function OversideWrapper({ children, buttonsRef, className }: OversideWrapperInterface) {

  const wrapperClassName = [
    styles.wrapper,
    className
  ].filter(e => e).join(' ');

  return (
    <div className={wrapperClassName} ref={buttonsRef}>
      {children}
    </div>
  );
}

export default OversideWrapper;
