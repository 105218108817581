import { FastGameView, GameRoundLoadListResult, GameRoundView, GameTournamentStatus, QuickGameView, TournamentRoundLoadListResult, TournamentRoundView, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import { PropsWithChildren } from "react";
import { MinitournamentView } from "../TournamentPage.interfaces";

export type TournamentResultsSteps = 'lineups' | 'score' | 'rounds' | 'courts';
export type TournamentAlias = MinitournamentView | TournamentView;
export type TournamentType = 'mini' | 'full';

export type TournamentResultsNewContextWrapperProps = {
  tournament: TournamentAlias;
  loading: boolean;
  tournamentType: TournamentType;
  setLoading: (state: boolean) => void;
} & PropsWithChildren;

export type TournamentResultsActiveLineup = {
  lineup: QuickGameView | FastGameView;
  indexes?: {
    index1?: number,
    index2?: number
  };
}

export type ITournamentResultsNewContext = {
  tournament: TournamentAlias;
  round?: TournamentRoundView;
  step: TournamentResultsSteps;
  roundNumber: number;
  roundCounter: number;
  activeLineup?: TournamentResultsActiveLineup;
  loading: boolean;
  tournamentType: TournamentType;
  setLoading: (state: boolean) => void;
  updateStep?: (step: TournamentResultsSteps) => void;
  updateRoundNumber?: (roundNumber: number) => void;
  updateActiveLineup?: (lineup?: TournamentResultsActiveLineup) => void;
  updateRound?: (round?: TournamentRoundView) => void;
  updateRoundCounter?: (value: number) => void;
}

export function isMiniTournament(tournament: TournamentAlias): tournament is MinitournamentView {
  return 'gameTournamentKind' in tournament;
}

export function isGameRoundView(round: GameRoundView | TournamentRoundView): round is GameRoundView {
  return 'gameUid' in round;
}

export function isGameRoundLoadListResult(data: GameRoundLoadListResult | TournamentRoundLoadListResult): data is GameRoundLoadListResult {
  return !!data.tournamentStatus && (data.tournamentStatus in GameTournamentStatus);
} 
