/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClubInfo,
    ClubInfoFromJSON,
    ClubInfoFromJSONTyped,
    ClubInfoToJSON,
} from './ClubInfo';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';
import {
    TournamentInviteView,
    TournamentInviteViewFromJSON,
    TournamentInviteViewFromJSONTyped,
    TournamentInviteViewToJSON,
} from './TournamentInviteView';
import {
    TournamentKind,
    TournamentKindFromJSON,
    TournamentKindFromJSONTyped,
    TournamentKindToJSON,
} from './TournamentKind';
import {
    TournamentMemberView,
    TournamentMemberViewFromJSON,
    TournamentMemberViewFromJSONTyped,
    TournamentMemberViewToJSON,
} from './TournamentMemberView';
import {
    TournamentStatus,
    TournamentStatusFromJSON,
    TournamentStatusFromJSONTyped,
    TournamentStatusToJSON,
} from './TournamentStatus';
import {
    ViewObjectIdentity,
    ViewObjectIdentityFromJSON,
    ViewObjectIdentityFromJSONTyped,
    ViewObjectIdentityToJSON,
} from './ViewObjectIdentity';

/**
 * 
 * @export
 * @interface TournamentView
 */
export interface TournamentView {
    /**
     * 
     * @type {ViewObjectIdentity}
     * @memberof TournamentView
     */
    identity?: ViewObjectIdentity;
    /**
     * 
     * @type {string}
     * @memberof TournamentView
     */
    title?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof TournamentView
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof TournamentView
     */
    closingDate?: LocalDateTime;
    /**
     * 
     * @type {ClubInfo}
     * @memberof TournamentView
     */
    club?: ClubInfo;
    /**
     * 
     * @type {Grade}
     * @memberof TournamentView
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof TournamentView
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof TournamentView
     */
    displayMinGrade?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentView
     */
    displayMaxGrade?: string;
    /**
     * 
     * @type {TournamentKind}
     * @memberof TournamentView
     */
    kind?: TournamentKind;
    /**
     * 
     * @type {number}
     * @memberof TournamentView
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentView
     */
    playersLimit?: number;
    /**
     * 
     * @type {TournamentStatus}
     * @memberof TournamentView
     */
    status?: TournamentStatus;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TournamentView
     */
    owner?: PlayerProfileInfo;
    /**
     * 
     * @type {string}
     * @memberof TournamentView
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentView
     */
    privateTournament?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TournamentView
     */
    roundsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentView
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentView
     */
    courtsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentView
     */
    maxScore?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentView
     */
    available?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentView
     */
    reverseSortFirstRound?: boolean;
    /**
     * 
     * @type {Array<Gender>}
     * @memberof TournamentView
     */
    genders?: Array<Gender>;
    /**
     * 
     * @type {Array<TournamentMemberView>}
     * @memberof TournamentView
     */
    members?: Array<TournamentMemberView>;
    /**
     * 
     * @type {Array<TournamentMemberView>}
     * @memberof TournamentView
     */
    queue?: Array<TournamentMemberView>;
    /**
     * 
     * @type {Array<TournamentMemberView>}
     * @memberof TournamentView
     */
    excluded?: Array<TournamentMemberView>;
    /**
     * 
     * @type {Array<TournamentInviteView>}
     * @memberof TournamentView
     */
    invitations?: Array<TournamentInviteView>;
}

export function TournamentViewFromJSON(json: any): TournamentView {
    return TournamentViewFromJSONTyped(json, false);
}

export function TournamentViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': !exists(json, 'identity') ? undefined : ViewObjectIdentityFromJSON(json['identity']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'closingDate': !exists(json, 'closingDate') ? undefined : LocalDateTimeFromJSON(json['closingDate']),
        'club': !exists(json, 'club') ? undefined : ClubInfoFromJSON(json['club']),
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'displayMinGrade': !exists(json, 'displayMinGrade') ? undefined : json['displayMinGrade'],
        'displayMaxGrade': !exists(json, 'displayMaxGrade') ? undefined : json['displayMaxGrade'],
        'kind': !exists(json, 'kind') ? undefined : TournamentKindFromJSON(json['kind']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'playersLimit': !exists(json, 'playersLimit') ? undefined : json['playersLimit'],
        'status': !exists(json, 'status') ? undefined : TournamentStatusFromJSON(json['status']),
        'owner': !exists(json, 'owner') ? undefined : PlayerProfileInfoFromJSON(json['owner']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'privateTournament': !exists(json, 'privateTournament') ? undefined : json['privateTournament'],
        'roundsLimit': !exists(json, 'roundsLimit') ? undefined : json['roundsLimit'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'courtsCount': !exists(json, 'courtsCount') ? undefined : json['courtsCount'],
        'maxScore': !exists(json, 'maxScore') ? undefined : json['maxScore'],
        'available': !exists(json, 'available') ? undefined : json['available'],
        'reverseSortFirstRound': !exists(json, 'reverseSortFirstRound') ? undefined : json['reverseSortFirstRound'],
        'genders': !exists(json, 'genders') ? undefined : ((json['genders'] as Array<any>).map(GenderFromJSON)),
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(TournamentMemberViewFromJSON)),
        'queue': !exists(json, 'queue') ? undefined : ((json['queue'] as Array<any>).map(TournamentMemberViewFromJSON)),
        'excluded': !exists(json, 'excluded') ? undefined : ((json['excluded'] as Array<any>).map(TournamentMemberViewFromJSON)),
        'invitations': !exists(json, 'invitations') ? undefined : ((json['invitations'] as Array<any>).map(TournamentInviteViewFromJSON)),
    };
}

export function TournamentViewToJSON(value?: TournamentView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': ViewObjectIdentityToJSON(value.identity),
        'title': value.title,
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'closingDate': LocalDateTimeToJSON(value.closingDate),
        'club': ClubInfoToJSON(value.club),
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'displayMinGrade': value.displayMinGrade,
        'displayMaxGrade': value.displayMaxGrade,
        'kind': TournamentKindToJSON(value.kind),
        'price': value.price,
        'playersLimit': value.playersLimit,
        'status': TournamentStatusToJSON(value.status),
        'owner': PlayerProfileInfoToJSON(value.owner),
        'description': value.description,
        'privateTournament': value.privateTournament,
        'roundsLimit': value.roundsLimit,
        'duration': value.duration,
        'courtsCount': value.courtsCount,
        'maxScore': value.maxScore,
        'available': value.available,
        'reverseSortFirstRound': value.reverseSortFirstRound,
        'genders': value.genders === undefined ? undefined : ((value.genders as Array<any>).map(GenderToJSON)),
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(TournamentMemberViewToJSON)),
        'queue': value.queue === undefined ? undefined : ((value.queue as Array<any>).map(TournamentMemberViewToJSON)),
        'excluded': value.excluded === undefined ? undefined : ((value.excluded as Array<any>).map(TournamentMemberViewToJSON)),
        'invitations': value.invitations === undefined ? undefined : ((value.invitations as Array<any>).map(TournamentInviteViewToJSON)),
    };
}

