import { FC } from "react";
import { ClubItemProps } from "./ClubItem.interfaces";
import styles from './ClubItem.module.css';
import geoIcon from '@/static/images/geolocation-outline-icon.svg';

const ClubItem: FC<ClubItemProps> = ({ club }): JSX.Element => {
  return (
    <div className={styles['container']}>
      <img src={geoIcon} alt="иконка геолокации" />
      <div className={styles['info']}>
        <span className={styles['name']}>{club.name}</span>
        <span className={styles['location']}>{club.mapAddress}</span>
      </div>
    </div>
  );
}

export default ClubItem;