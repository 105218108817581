import { InputHTMLAttributes } from 'react';
import styles from './CheckboxButton.module.css';

interface CheckboxButtonInterface extends InputHTMLAttributes<HTMLInputElement> {
  label: string,
  wrapperClassName?: string
}

function CheckboxButton({ label, wrapperClassName, ...inputProps }: CheckboxButtonInterface) {
  return (
    <div className={[styles.wrapper, wrapperClassName].join(' ')}>
      <input className={styles.checkbox} type='checkbox' {...inputProps} id={`checkbox_${label}`} />
      <label className={styles.label} htmlFor={`checkbox_${label}`}>
        {label}
      </label>
    </div>
  );
}

export default CheckboxButton;
