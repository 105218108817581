import { TourGameLoadListParam, UIGameSettingsView } from "@/integration-api/server-rest-get-info";
import RestClient from "@/integration/RestClient";

export const loadTourGameList = (parameters: TourGameLoadListParam) => {
  return RestClient.gameApi.loadTourGameList({
    loadTourGameListRequest: {
      parameters
    }
  });
}

export const getUIGameSettings = () => {
  return RestClient.profileApi.getUIGameSettings();
}

export const saveUIGameSettings = (parameters: UIGameSettingsView) => {
  return RestClient.profileApi.saveUIGameSettings({
    saveUIGameSettingsRequest: {
      parameters
    }
  })
}
