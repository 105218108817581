/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartnerDeleteParameters
 */
export interface PartnerDeleteParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof PartnerDeleteParameters
     */
    partnersUids?: Array<string>;
}

export function PartnerDeleteParametersFromJSON(json: any): PartnerDeleteParameters {
    return PartnerDeleteParametersFromJSONTyped(json, false);
}

export function PartnerDeleteParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerDeleteParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partnersUids': !exists(json, 'partnersUids') ? undefined : json['partnersUids'],
    };
}

export function PartnerDeleteParametersToJSON(value?: PartnerDeleteParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partnersUids': value.partnersUids,
    };
}

