/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicKeyLoadResult
 */
export interface PublicKeyLoadResult {
    /**
     * 
     * @type {string}
     * @memberof PublicKeyLoadResult
     */
    key?: string;
}

export function PublicKeyLoadResultFromJSON(json: any): PublicKeyLoadResult {
    return PublicKeyLoadResultFromJSONTyped(json, false);
}

export function PublicKeyLoadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicKeyLoadResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function PublicKeyLoadResultToJSON(value?: PublicKeyLoadResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
    };
}

