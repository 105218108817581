import s from './SelectDate.module.css'
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import DateItem from '../DateItem/DateItem';
import { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { DateParams, getDateArray } from './helper';
import dayjs from 'dayjs';
import { LocalDateTime, toLocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

interface SelectDateInterface extends InputHTMLAttributes<HTMLInputElement> {
  onActiveDateChange?: (date: LocalDateTime) => void,
  initialStartDate?: LocalDateTime,
  initialActiveDate?: LocalDateTime,
  setErrorField?: (field: string) => void;
}

function SelectDate({ onActiveDateChange, initialStartDate = toLocalDateTime(new Date()) as LocalDateTime, initialActiveDate, setErrorField, ...props }: SelectDateInterface) {
  const dateItemContainerRef = useRef<HTMLDivElement>(null);

  const [startDate, setStartDate] = useState<LocalDateTime>(initialStartDate);
  const [activeDate, setActiveDate] = useState<LocalDateTime>(initialActiveDate || initialStartDate);

  const handleDateChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    const date = toLocalDateTime(new Date((target as HTMLInputElement).value)) as LocalDateTime;
    if(dayjs(date).valueOf() < dayjs(new Date(dayjs().year(), dayjs().month(), dayjs().date())).valueOf()) {
      setStartDate(toLocalDateTime(new Date()) as LocalDateTime);
      setActiveDate(toLocalDateTime(new Date()) as LocalDateTime);
      onActiveDateChange?.(toLocalDateTime(new Date()) as LocalDateTime);
      PopupMessage.open?.('Нельзя выбрать прошедшую дату!');
      setErrorField?.('date');
    } else {
      setStartDate(date);
      setActiveDate(date);
      onActiveDateChange?.(date);
      setErrorField?.('');
    }
  }, []);
  
  const handleDateItemClick: React.MouseEventHandler<HTMLInputElement> = ({ target }) => {
    const date = toLocalDateTime(new Date((target as HTMLInputElement).value));

    setActiveDate(date as LocalDateTime);
    setErrorField?.('');
    onActiveDateChange?.(date as LocalDateTime);
  }
  
  // определяем длину списка дат, она не может быть меньше 10
  const dateDiff = dayjs(dayjs(activeDate).format('YYYY-MM-DD')).diff(dayjs(startDate).format('YYYY-MM-DD'), 'days') + 1;
  const dateArray = getDateArray(startDate, dateDiff > 10 ? dateDiff : 10);

  useEffect(() => {
    const activeDateParentOffset = dateItemContainerRef.current?.querySelector('*:checked')?.parentElement?.offsetLeft;
    if (dateItemContainerRef.current) dateItemContainerRef.current.scrollLeft = activeDateParentOffset ?? 0;
  }, [activeDate])

  return (
    <>
      <DatePicker name='startDate' date={activeDate} isMatchCreate title="Выберите дату" isCalendarIcon={true} {...props} onChange={handleDateChange} />
      <div className={s.dateItemContainer} ref={dateItemContainerRef}>
        {dateArray.map((el: DateParams) =>
          <DateItem
            value={el.value}
            day={el.day}
            date={el.date}
            month={el.month}
            key={el.value.toString()}
            {...props}
            activeDate={activeDate}
            onClick={handleDateItemClick}
          />
        )}
      </div>
    </>
  );
}

export default SelectDate;
