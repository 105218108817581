import { InputHTMLAttributes } from 'react';
import styles from './RadioButton.module.css';

interface RadioButtonInterface extends InputHTMLAttributes<HTMLInputElement>{
  label?: string,
}

function RadioButton({ label, ...inputProps}: RadioButtonInterface) {
  return (
    <label className={styles.radioBtn}>
      <input className={styles.radioInput} type='radio' {...inputProps} />
      <span className={styles.customRadioIndicator} />
      {label}
    </label>
  );
}

export default RadioButton;
