import styles from './GameJoiningCard.module.css';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import { createImgSrc } from '@/utils/utils';
import { PlayerProfileInfo, Role } from '@/integration-api/server-rest-lundapadelApi';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import AcceptSignIcon from '@/static/images/icons/AcceptSignIcon';
import DeclineCrossIcon from '@/static/images/icons/DeclineCrossIcon';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';

type GameJoiningCardProps = {
  player: PlayerProfileInfo;
  acceptHandler: (uid?: string) => void;
  declineHandler: (uid?: string) => void;
}

const GameJoiningCard = ({ player, acceptHandler, declineHandler }: GameJoiningCardProps) => {
  return (  
    <div className={styles['card']}>
      <div 
        className={`${styles['action']} ${styles['accept']}`}
        onClick={() => acceptHandler(player.uid)}
      >
        <AcceptSignIcon/>
      </div>
      <div className={styles['player']}>
        <UserAvatar
          avatarUid={createImgSrc.playerProfile(player.uid, player.avatarUid)}
          nameInitials={player.nameInitials}
          badge={[
            {
              element: <RatingBage rating={player.displayRating ?? '1.0'}/>,
              position: 'top-right'
            },
            {
              element: player.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }
          ]}
        />
        <span><b>{player.displayName}</b> хочет присоединиться к игре. Принять?</span>
      </div>
      <div 
        className={`${styles['action']} ${styles['decline']}`}
        onClick={() => declineHandler(player.uid)}
      >
        <DeclineCrossIcon/>
      </div>
    </div>
  );
}
 
export default GameJoiningCard;
