import { HTMLAttributes } from 'react';
import styles from './EmptyMatchListPlaceholder.module.css';
import newGameIcon from '@/static/images/icons/new-game-placeholder-icon.svg';
import placeholderBg from '@/static/images/empty-matches-placeholder-bg.png';
import { useNavigate } from 'react-router-dom';

interface EmptyMatchListPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  bgImage?: boolean;
  filters?: boolean;
  favoriteClubs?: boolean
  clearFilters?: () => void;
}

const EmptyMatchListPlaceholder = ({ title, bgImage, filters, favoriteClubs, clearFilters, className, ...props }: EmptyMatchListPlaceholderProps) => {
  const navigate = useNavigate();
  return ( 
    <div {...props} className={`${className} ${styles['wrapper']}`}>
      <div className={styles['title']} style={{ padding: filters ? '0 30px' : '0'}}>{title}</div>
      {favoriteClubs ? 
        <div className={styles['action']} onClick={() => navigate('/profile/me/edit?favoriteClubs')}>
          <span>Выбрать любимые клубы</span>
          <img src={newGameIcon} alt="новая игра" />
        </div> : 
      !filters ?       
        <div className={styles['action']} onClick={() => navigate('/create/game')}>
          <span>Создать новую игру</span>
          <img src={newGameIcon} alt="новая игра" />
        </div> :
        <div className={filters ? styles['filters-action'] : styles['action']}>
          <span onClick={clearFilters}>Сбросить фильтры</span>
          <span style={{ color: 'var(--gray-dark)' }}> или </span>
          <span onClick={() => navigate('/create/game')}>создайте новую игру</span>
        </div>
      }
      {bgImage ? <img className={styles['bg-image']} src={placeholderBg} alt="ракетка" /> : null}
    </div>
  );
}
 
export default EmptyMatchListPlaceholder;
