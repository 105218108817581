interface SendBtnProps {

}

const SendBtn: React.FC<SendBtnProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z" fill="#4EB857"/>
    </svg>
  );
}
 
export default SendBtn;
