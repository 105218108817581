/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FastGameView,
    FastGameViewFromJSON,
    FastGameViewFromJSONTyped,
    FastGameViewToJSON,
} from './FastGameView';

/**
 * 
 * @export
 * @interface GameRoundLineupView
 */
export interface GameRoundLineupView {
    /**
     * 
     * @type {Array<FastGameView>}
     * @memberof GameRoundLineupView
     */
    games?: Array<FastGameView>;
}

export function GameRoundLineupViewFromJSON(json: any): GameRoundLineupView {
    return GameRoundLineupViewFromJSONTyped(json, false);
}

export function GameRoundLineupViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameRoundLineupView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'games': !exists(json, 'games') ? undefined : ((json['games'] as Array<any>).map(FastGameViewFromJSON)),
    };
}

export function GameRoundLineupViewToJSON(value?: GameRoundLineupView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'games': value.games === undefined ? undefined : ((value.games as Array<any>).map(FastGameViewToJSON)),
    };
}

