type DnDIconProps = {
  fill?: string
}

const DnDIcon = ({ fill }: DnDIconProps) => {
  return (  
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.84277 12H14.5288V10.6667H2.84277V12ZM2.84277 8.66667H14.5288V7.33333H2.84277V8.66667ZM2.84277 4V5.33333H14.5288V4H2.84277Z" fill={fill ?? "#6E8AA3"}/>
    </svg>
  );
}
 
export default DnDIcon;
