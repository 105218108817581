import 'swiper/css';
import 'swiper/css/virtual';
import styles from './LineupScoreSwiper.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperType from 'swiper';
import { Virtual } from 'swiper/modules';
import { useEffect, useMemo, useState } from 'react';
import { applySlideClass } from './utils';
import { LineupScoreTabsValues, UpdateListScoresArgs } from '../../GameResults/GameResultsPage.interfaces';
import { TournamentResultsActiveLineup } from '@/pages/application/tournament/TournamentResultsNew/TournamentResultsNew.interfaces';
import { GameSetView } from '@/integration-api/server-rest-lundapadelApi';
import { TouchSwiperState } from '@/pages/application/tournament/TournamentResultsLineupScore/TournamentResultsLineupScore';

type LineupScoreSwiperProps = {
  index?: number;
  matchIndex?: number;
  team: keyof Pick<GameSetView, 'score1' | 'score2'>;
  type: LineupScoreTabsValues;
  initialIndex?: number;
  oppositeIndex?: number;
  limit?: number;
  tournament?: boolean;
  setLimit?: number;
  touchSwiper?: TouchSwiperState;
  updateTouchSwiper?: (key: keyof TouchSwiperState) => void;
  updateScores?: (args: UpdateListScoresArgs) => void;
  updateActiveLineup?: (lineup?: TournamentResultsActiveLineup) => void;
}

const LineupScoreSwiper = ({ index, team, initialIndex, type, matchIndex, oppositeIndex, limit, tournament, setLimit, touchSwiper, updateTouchSwiper, updateActiveLineup, updateScores }: LineupScoreSwiperProps) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const scoreList = useMemo(() => new Array(limit ?? 52).fill(0), []);

  useEffect(() => {
    if(tournament && setLimit && oppositeIndex !== undefined && !(!activeIndex && !oppositeIndex)) {
      if(team === 'score1') {
        if(touchSwiper?.['score2'] && !touchSwiper?.[team]) {
          swiper?.slideTo((setLimit - oppositeIndex - 3) < 0 ? 0 : (setLimit - oppositeIndex - 3));
          setActiveIndex((setLimit - oppositeIndex - 3) < 0 ? 0 : (setLimit - oppositeIndex - 3));
        }
      } else {
        if(touchSwiper?.['score1'] && !touchSwiper?.[team]) {
          swiper?.slideTo((setLimit - oppositeIndex - 3) < 0 ? 0 : (setLimit - oppositeIndex - 3))
          setActiveIndex((setLimit - oppositeIndex - 3) < 0 ? 0 : (setLimit - oppositeIndex - 3));
        }
      }
    }
  }, [touchSwiper, team, setLimit, oppositeIndex, tournament]);

  // kostylek)))
  useEffect(() => {
    if(scoreList.length - activeIndex - 2 <= 0) {
      swiper?.slideTo(scoreList.length - 2);
    }
  }, [activeIndex, scoreList.length]);

  return ( 
    <Swiper
      modules={[ Virtual ]}
      virtual
      direction="vertical"
      slideToClickedSlide
      slidesPerView={3}
      initialSlide={initialIndex}
      onSwiper={setSwiper}
      onSlideChange={(swiper: SwiperType) => {
        setActiveIndex(swiper.activeIndex);
      }}
      onTouchEnd={() => updateTouchSwiper?.(team)}
      onTransitionEnd={(swiper: SwiperType) => { 
        updateScores?.({ type, index, team, score: swiper.activeIndex, matchIndex });
        updateActiveLineup?.({ 
          lineup: { [team]: swiper.activeIndex }
        });
      }}
      style={{height: '168px', width: '57px'}}
    >
      {scoreList.map((_, index) => (
        <SwiperSlide 
          key={index}
          virtualIndex={index}
          className={styles['slide']}
        >
          {slide => {
            return scoreList.length - index - 2 > 0 ? 
              <span className={applySlideClass(slide)}>{index}</span> 
            : null
          }}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
 
export default LineupScoreSwiper;
