/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';
import {
    TournamentMemberStatus,
    TournamentMemberStatusFromJSON,
    TournamentMemberStatusFromJSONTyped,
    TournamentMemberStatusToJSON,
} from './TournamentMemberStatus';

/**
 * 
 * @export
 * @interface TournamentMemberView
 */
export interface TournamentMemberView {
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TournamentMemberView
     */
    player?: PlayerProfileInfo;
    /**
     * 
     * @type {number}
     * @memberof TournamentMemberView
     */
    score?: number;
    /**
     * 
     * @type {TournamentMemberStatus}
     * @memberof TournamentMemberView
     */
    status?: TournamentMemberStatus;
    /**
     * 
     * @type {number}
     * @memberof TournamentMemberView
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof TournamentMemberView
     */
    displayRating?: string;
    /**
     * 
     * @type {number}
     * @memberof TournamentMemberView
     */
    tournamentRatingChange?: number;
    /**
     * 
     * @type {string}
     * @memberof TournamentMemberView
     */
    tournamentDisplayRatingChange?: string;
}

export function TournamentMemberViewFromJSON(json: any): TournamentMemberView {
    return TournamentMemberViewFromJSONTyped(json, false);
}

export function TournamentMemberViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentMemberView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'player': !exists(json, 'player') ? undefined : PlayerProfileInfoFromJSON(json['player']),
        'score': !exists(json, 'score') ? undefined : json['score'],
        'status': !exists(json, 'status') ? undefined : TournamentMemberStatusFromJSON(json['status']),
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'displayRating': !exists(json, 'displayRating') ? undefined : json['displayRating'],
        'tournamentRatingChange': !exists(json, 'tournamentRatingChange') ? undefined : json['tournamentRatingChange'],
        'tournamentDisplayRatingChange': !exists(json, 'tournamentDisplayRatingChange') ? undefined : json['tournamentDisplayRatingChange'],
    };
}

export function TournamentMemberViewToJSON(value?: TournamentMemberView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': PlayerProfileInfoToJSON(value.player),
        'score': value.score,
        'status': TournamentMemberStatusToJSON(value.status),
        'rating': value.rating,
        'displayRating': value.displayRating,
        'tournamentRatingChange': value.tournamentRatingChange,
        'tournamentDisplayRatingChange': value.tournamentDisplayRatingChange,
    };
}

