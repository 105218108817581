import styles from "./FindMeButton.module.css";
import type { FC } from "react";
import type { FindMeButtonProps } from "./FindMeButton.interfaces";

const FindMeButton: FC<FindMeButtonProps> = (props): JSX.Element => {
  const { onClick } = props;

  return (
    <span onClick={onClick} className={styles["button"]}>
      Где я
    </span>
  );
};

export default FindMeButton;
