import { useMemo } from 'react';
import styles from './PercentRing.module.css';

interface PercentRingInterface {
  value: number
}

function PercentRing(props: PercentRingInterface) {
  const { value: incomingValue = 0} = props;

  const value = useMemo(() => {
    if (isNaN(incomingValue)) {
      return 0;
    }
    return incomingValue;
  }, [incomingValue]);

  return (
    <div className={styles.singleChart}>
      <svg viewBox="0 0 36 36" className={styles.circularChart}>
        <path className={styles.circleBg}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path className={styles.circle}
          strokeDasharray={`${value}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
      <div className={styles.description}>
        <div className={styles.percent}>{value}%</div>
        <div>Эффективность</div>
        <div>в последних</div>
        <div>10 матчах</div>
      </div>
    </div>
  );
}

export default PercentRing;
