import { MessagesDateBlock, MessagesDateBlockEntry } from "./ChatPage.intefaces";
import { FeedbackMessageView } from "@/integration-api/server-rest-lundapadelApi";

export const getMessagesDateBlocks = <T extends FeedbackMessageView>(messageList: Array<T>): Array<MessagesDateBlockEntry<MessagesDateBlock<T>>> => {
  const messagesDateBlock: MessagesDateBlock<T> = {};
  messageList.forEach(msg => {
    if(!msg.timestamp) {
      return
    } else {
      const onlyDate = new Date(msg.timestamp);
      onlyDate.setHours(0, 0, 0, 0);

      messagesDateBlock[onlyDate.toISOString()] = messagesDateBlock[onlyDate.toISOString()] ?? [];
      messagesDateBlock[onlyDate.toISOString()].push(msg);
    }
  });

  return Object.entries(messagesDateBlock).sort((a, b) => {
    if (a[0] > b[0]) {
      return 1;
    }
    if (b[0] > a[0]) {
      return -1;
    }
    return 0;
  })
}
