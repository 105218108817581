import React from 'react';
import styles  from './Tooltip.module.css';

interface TooltipProps extends React.PropsWithChildren {
  visible: boolean;
}

export const tooltipWrapID = 'tooltip'

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { children, visible } = props;
  
  return (
    <div className={styles.wrap} id={tooltipWrapID}>
      {children}
      {visible && <span className={styles.tooltip}>
        Если ваш рейтинг не 1.0, измените его, пролистывая вправо. Если ваш рейтинг равен 1.0 продолжите регистрацию.
      </span>}
    </div>
  );
}
 
export default Tooltip;
