import Input from "../Input/Input"
import styles from './Search.module.css';
import searchIcon from '@/static/images/icons/search-icon.svg'
import { ChangeEvent, FC, MouseEvent, TouchEvent } from 'react';
import s from './Search.module.css'

export interface SearchProps {
  setInputActive?: (state?: boolean) => void
  searchHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?:string
}

const Search:FC<SearchProps> = ({setInputActive, searchHandler, placeholder}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(searchHandler) {
      searchHandler(event);
      if(setInputActive) {
        setInputActive(true);
      }
    }
  }

  return(
    <div className={s.wrap}>
      <Input 
        prefixIcon={<img src={searchIcon} alt='иконка поиска'/>}
        wrapperClassName={`${styles['input-bg']}`}
        placeholder={placeholder}
        onClick={setInputActive && (() => setInputActive())}
        onChange={handleChange}
        enterKeyHint="search"
      />
    </div>
  );
}

export default Search
