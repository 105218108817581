import styles from './SelectIndicator.module.css';
import ballImg from '@/static/images/icons/green-ball-icon.svg'

interface SelectIndicatorProps {
  selected: boolean;
}

function SelectIndicator({ selected }: SelectIndicatorProps) {
  return selected ? <img className={styles['background']} src={ballImg} alt='Мяч'/> : <span className={styles['indicator']} />
}

export default SelectIndicator;
