/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CityClubsGroupLoadListParameters,
    CityClubsGroupLoadListParametersFromJSON,
    CityClubsGroupLoadListParametersFromJSONTyped,
    CityClubsGroupLoadListParametersToJSON,
} from './CityClubsGroupLoadListParameters';

/**
 * 
 * @export
 * @interface CityClubsLoadListRequest
 */
export interface CityClubsLoadListRequest {
    /**
     * 
     * @type {CityClubsGroupLoadListParameters}
     * @memberof CityClubsLoadListRequest
     */
    parameters?: CityClubsGroupLoadListParameters;
}

export function CityClubsLoadListRequestFromJSON(json: any): CityClubsLoadListRequest {
    return CityClubsLoadListRequestFromJSONTyped(json, false);
}

export function CityClubsLoadListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CityClubsLoadListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : CityClubsGroupLoadListParametersFromJSON(json['parameters']),
    };
}

export function CityClubsLoadListRequestToJSON(value?: CityClubsLoadListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': CityClubsGroupLoadListParametersToJSON(value.parameters),
    };
}

