import { BadgePositionType } from '../UserAvatar.interfaces';
import { badgePosition } from '../utils';

interface UserAvatarBadgeProps {
  position: BadgePositionType;
  element: React.ReactElement;
}

export default function UserAvatarBadge({element, position}: UserAvatarBadgeProps) {
  const badgeStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '22px',
    maxHeight: '22px',
    width: 'fit-content',
    ...badgePosition[position],
    zIndex: position !== 'middle-center' ? '2' : '1'
  }

  return <div style={badgeStyle}>{element}</div>
}
