import { RatingSearchParams } from '@/components/FilterModal/FilterModal.interfaces';
import { PlayerProfileField, UIRatingSettingsView } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export const playerLoadList = (searchParam: RatingSearchParams) => {
  return RestClient.profileApi.playerLoadList({
    playerLoadListRequest: {
      parameters: {
        ...searchParam,
        offset: 0,
        sortDesc: true,
        includeSelf: true,
        sortBy: PlayerProfileField.GRADE,
      },
    },
  });
};

export const gradeInfoLoadList = () => {
  return RestClient.mainApi.gradeInfoLoadList();
};

export function getSavedSettings() {
  return RestClient.profileApi.getUIRatingSettings();
}

export function saveSearchParams(parameters: UIRatingSettingsView) {
  return RestClient.profileApi.saveUIRatingSettings({
  saveUIRatingSettingsRequest: {
    parameters
  }
  });
}
