import { FC } from 'react';
import ProfileSection from '../ProfileSection/ProfileSection';
import NewsSection from '../NewsSection/NewsSection';
import InvitesSection from '../InvitesSection/InvitesSection';
import ClubsSection from '../ClubsSection/ClubsSection';
import { HomePageContentProps } from './HomePageContent.interfaces';
import HLine from '@/components/HLine/HLine';
import TourgamesSection from '../TourgamesSection/TourgamesSection';
import TourgamesPendingSection from '../TourgamesPendingSection/TourgamesPendingSection';
import { useAdsSrcLink } from '@/components/AdsBanner/hooks';
import { AdvertisementPlaceType } from '@/integration-api/server-rest-lundapadelApi';
import AdsBanner from '@/components/AdsBanner/AdsBanner';

const HomePageContent: FC<HomePageContentProps> = (props): JSX.Element => {
  const { onOpenNotifications } = props;

  const adsBanner = useAdsSrcLink(AdvertisementPlaceType.MAIN_PAGE);

  return (
    <>
      <ProfileSection onOpenNotifications={onOpenNotifications} />
      <HLine />
      <NewsSection />
      <HLine/>
      {adsBanner?.visible && <AdsBanner bannerSrc={adsBanner.src} redirectLink={adsBanner.link} />}
      <HLine/>
      <TourgamesPendingSection/>
      <HLine/>
      <InvitesSection/>
      <HLine />
      <TourgamesSection/>
      <HLine />
      <ClubsSection />
    </>
  );
};

export default HomePageContent;
