import { Role, TournamentMemberView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentTableItem.module.css';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import editIcon from '@/static/images/icons/edit-table-icon.svg';
import { useDrag, useDrop } from 'react-dnd';
import { CSSProperties, useContext, useEffect} from 'react';
import { createImgSrc } from '@/utils/utils';
import { DragCollectedProps, DraggableItem, DropCollectedProps } from '../../TournamentPage.interfaces';
import { renderPlacement } from './utils';
import { TournamentResultClassicContext } from '../../utils';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { useAppSelector } from '@/hooks/hooks';

export type TournamentTableItemDraggableProps = {
  participant: TournamentMemberView;
  placement: number;
  setDropTarget: (val: number | undefined) => void;
  setDragTarget: (val: number | undefined) => void;
  style?: CSSProperties;
}

const TournamentTableItemDraggable = ({ participant, placement, style, setDropTarget, setDragTarget }: TournamentTableItemDraggableProps) => {
  const { isOwner, updateList, participants } = useContext(TournamentResultClassicContext);
  const current = useAppSelector(state => state.auth.currentProfile);

  const [dragCollect, drag] = useDrag<unknown, DraggableItem, DragCollectedProps>(() => ({
    type: 'tableItem',
    item: { participant, placement, avatar: createImgSrc.playerProfile(participant.player?.uid, participant.player?.avatarUid) },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      index: placement
    })
  }), [participant, placement]);

  const [dropCollect, drop] = useDrop<DraggableItem, unknown, DropCollectedProps>(() => ({
    accept: 'tableItem',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
    drop: (item) => {
      updateList({ item, placement });
      setDropTarget(undefined);
      setDragTarget(undefined);
    }
  }), [placement, participants]);

  useEffect(() => {
    if(dropCollect.isOver) {
      if(dropCollect.item.placement === placement) {
        setDropTarget(undefined);
      } else {
        setDropTarget(placement);
      }
    }
  }, [dropCollect.isOver, placement, dragCollect.index]);

  useEffect(() => {
    if(!dragCollect.isDragging) {
      setDragTarget(undefined);
    } else {
      setDragTarget(placement);
    }
  }, [dragCollect.isDragging, placement]);

  return (
    <div ref={drop} style={{ position: 'relative', height: '57px'}}>
      <div className={styles['wrapper']} style={{ ...style, opacity: dragCollect.isDragging ? '0' : '1'}}>
        <div className={styles['main-content']}>
          {renderPlacement(placement)}
          <UserAvatar
            avatarUid={createImgSrc.playerProfile(participant.player?.uid, participant.player?.avatarUid)}
            nameInitials={participant.player?.nameInitials}
            badge={{
              element: participant.player?.uid === current.identity?.uid && current.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }}
          />
          <div className={styles['info']}>
            <span className={styles['name']}>{participant.player?.displayName?.substring(0, 25) || '-'}</span>
            <span className={styles['rating-value-draggable']}>{participant.displayRating}</span>
          </div>

        </div>
        {isOwner ? <img src={editIcon} className={styles['image']} alt="edit" ref={drag}/> : null}
      </div>     
    </div>
  );
}
 
export default TournamentTableItemDraggable;
