/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendCodeParameters
 */
export interface SendCodeParameters {
    /**
     * 
     * @type {string}
     * @memberof SendCodeParameters
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SendCodeParameters
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendCodeParameters
     */
    telegramSend?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendCodeParameters
     */
    ticket?: string;
    /**
     * 
     * @type {string}
     * @memberof SendCodeParameters
     */
    answer?: string;
}

export function SendCodeParametersFromJSON(json: any): SendCodeParameters {
    return SendCodeParametersFromJSONTyped(json, false);
}

export function SendCodeParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendCodeParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'telegramSend': !exists(json, 'telegramSend') ? undefined : json['telegramSend'],
        'ticket': !exists(json, 'ticket') ? undefined : json['ticket'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function SendCodeParametersToJSON(value?: SendCodeParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': value.countryCode,
        'phone': value.phone,
        'telegramSend': value.telegramSend,
        'ticket': value.ticket,
        'answer': value.answer,
    };
}

