import { PushNotifications } from '@capacitor/push-notifications';
import RestClient from './RestClient';

export const turnOnFirebaseNotifications = (redirect: (url: string) => void) => {
  const addListeners = async () => {
    await PushNotifications.addListener('registration', token => {
      console.info('fb-token: ', token.value);
      localStorage.setItem("fb-token", token.value);
      RestClient.mainApi.firebaseNotificationsSubscribe({
        firebaseNotificationsSubscribeRequest: {
          parameters: {
            token: token.value
          }
        }
      })
        .catch((err) => console.log(err));
    });

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);

      let link = notification.notification.data?.link
      if (link) {
        redirect(link);
      }
    });
  }

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
      redirect('/');
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }

  addListeners().then(registerNotifications).then(getDeliveredNotifications);
}

export const unregisterNotifications = async (token: string) => {
  await PushNotifications.unregister();
  await RestClient.mainApi.firebaseNotificationsUnsubscribe({ firebaseNotificationsUnsubscribeRequest: { parameters: { token } }});
}
