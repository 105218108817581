import Headling from '@/components/Headling/Headling';
import styles from './TourgamesSection.module.css';
import { Link } from 'react-router-dom';
import SeeMore from '@/components/SeeMore/SeeMore';
import EmptyMatchListPlaceholder from '@/components/EmptyMatchListPlaceholder/EmptyMatchListPlaceholder';
import CountCircle from '@/components/CountCircle/CountCircle';
import { useCallback, useEffect, useState } from 'react';
import { myTourGameLoadList } from '@/pages/application/myGames/components/srevice';
import { TourGameEntityType, TourGameLoadListResult } from '@/integration-api/server-rest-lundapadelApi';
import GameListCard from '@/components/GameListCard/GameListCard';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import { FetchErrorMessage } from '@/types/types';
import DBManager from '@/utils/DBManager/DBManager';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const TourgamesSection = () => {
  const [tourgamesInfo, setTourgamesInfo] = useState<TourGameLoadListResult>();
  
  const renderTourgames = useCallback(() => {
    return tourgamesInfo?.infos?.map(tg => {
      if(tg.type === TourGameEntityType.GAME) {
        return (
          <GameListCard
            key={tg.uid}
            game={tg}
          />
        );
      } else {
        return (
          <Link key={tg.uid} to={`/tournament/${tg.uid}`}>          
            <TournamentCard
              tournament={tg}
              restricted
            />
          </Link>
        )
      }
    });
  }, [tourgamesInfo?.infos]);
  
  useEffect(() => {
    if(navigator.onLine) {
      myTourGameLoadList({ limit: 2, ownOnly: false, offset: 0 })
        .then(({ result }) => {
          setTourgamesInfo(result);
          DBManager.create('tourgames', result, 'homePlannedTourgames');
        })
        .catch(err => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        });
    } else {
      DBManager.read('tourgames', 'homePlannedTourgames')
        .then(plannedTourgames => {
          setTourgamesInfo(plannedTourgames);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (  
    <section className={styles['tourgames']}>
      <div className={styles['headling']}>
        <div className={styles['info']}>
          <Headling appearence="big-normal" hasExpansion>Мои игры</Headling>
          {tourgamesInfo?.total ? <CountCircle>{tourgamesInfo?.total}</CountCircle> : null}
        </div>
        <Link to={'/my-games?tab=planned'}>
          <SeeMore>Все</SeeMore>
        </Link>
      </div>
      {tourgamesInfo?.total ?
        <div className={styles['tourgames-wrapper']}>
          {renderTourgames()}
        </div> :
        <EmptyMatchListPlaceholder
          className={styles['empty-tourgames']}
          title="Здесь будет отображаться список ваших будущих игр"
        />
      }
    </section>
  );
}
 
export default TourgamesSection;
