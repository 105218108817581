/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionKeys,
    SubscriptionKeysFromJSON,
    SubscriptionKeysFromJSONTyped,
    SubscriptionKeysToJSON,
} from './SubscriptionKeys';

/**
 * 
 * @export
 * @interface SubscriptionParameters
 */
export interface SubscriptionParameters {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionParameters
     */
    endpoint?: string;
    /**
     * 
     * @type {SubscriptionKeys}
     * @memberof SubscriptionParameters
     */
    keys?: SubscriptionKeys;
}

export function SubscriptionParametersFromJSON(json: any): SubscriptionParameters {
    return SubscriptionParametersFromJSONTyped(json, false);
}

export function SubscriptionParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'keys': !exists(json, 'keys') ? undefined : SubscriptionKeysFromJSON(json['keys']),
    };
}

export function SubscriptionParametersToJSON(value?: SubscriptionParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoint': value.endpoint,
        'keys': SubscriptionKeysToJSON(value.keys),
    };
}

