import styles from "./Tabs.module.css";
import { ReactNode, useState } from "react";
import HLWrapper from "@/components/HLWrapper/HLWrapper";
import { EMPTY_STRING } from "@/utils/constants";
import { useSearchParams } from "react-router-dom";

export type TabsData<T extends string> = {
  type: T;
  label: string | ReactNode;
};

export type TabsContent<T extends string> = Record<T, ReactNode>;

interface TabsProps<T extends string> {
  tabsData: TabsData<T>[];
  tabsContent: TabsContent<T>;
  initialTab: T;
  className?: string;
  tabClassName?: string;
}

const Tabs = <T extends string>(props: TabsProps<T>): JSX.Element => {
  const { tabsData, tabsContent, initialTab, className, tabClassName } = props;
  const [activeTab, setActiveTab] = useState<T>(initialTab);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <HLWrapper>
        <div
          style={{
            gridTemplateColumns: `repeat(${tabsData.length}, 1fr)`,
            height: '48px'
          }}
          className={`${styles["tabs-wrapper"]} ${className}`.trimEnd()}
        >
          {tabsData.map(({ type, label }) => {
            return (
              <button
                key={type}
                type="button"
                className={`${tabClassName} ${styles["tab"]} ${
                  activeTab === type ? styles["tab-active"] : EMPTY_STRING
                }`.trim()}
                onClick={(e) => {
                  setActiveTab(type);
                  setSearchParams(`tab=${type}`, { replace: true });
                }}
              >
                {label}
              </button>
            );
          })}
        </div>
      </HLWrapper>
      {tabsContent[activeTab]}
    </>
  );
};

export default Tabs;
