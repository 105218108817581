/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    TournamentStatus,
    TournamentStatusFromJSON,
    TournamentStatusFromJSONTyped,
    TournamentStatusToJSON,
} from './TournamentStatus';

/**
 * 
 * @export
 * @interface TournamentExistInfo
 */
export interface TournamentExistInfo {
    /**
     * 
     * @type {LocalDateTime}
     * @memberof TournamentExistInfo
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {string}
     * @memberof TournamentExistInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentExistInfo
     */
    tournamentUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentExistInfo
     */
    isOwner?: boolean;
    /**
     * 
     * @type {TournamentStatus}
     * @memberof TournamentExistInfo
     */
    status?: TournamentStatus;
    /**
     * 
     * @type {number}
     * @memberof TournamentExistInfo
     */
    daysToStart?: number;
}

export function TournamentExistInfoFromJSON(json: any): TournamentExistInfo {
    return TournamentExistInfoFromJSONTyped(json, false);
}

export function TournamentExistInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentExistInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'tournamentUid': !exists(json, 'tournamentUid') ? undefined : json['tournamentUid'],
        'isOwner': !exists(json, 'isOwner') ? undefined : json['isOwner'],
        'status': !exists(json, 'status') ? undefined : TournamentStatusFromJSON(json['status']),
        'daysToStart': !exists(json, 'daysToStart') ? undefined : json['daysToStart'],
    };
}

export function TournamentExistInfoToJSON(value?: TournamentExistInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'title': value.title,
        'tournamentUid': value.tournamentUid,
        'isOwner': value.isOwner,
        'status': TournamentStatusToJSON(value.status),
        'daysToStart': value.daysToStart,
    };
}

