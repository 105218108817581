import styles from './PageBackground.module.css';

interface PageBackgroundInterface {
  imgSrc?: string;
  light?:boolean
}

function PageBackground({ imgSrc,light }: PageBackgroundInterface) {
  const style = {
    background: `linear-gradient(transparent, var(--background-color)), url(${imgSrc}) center / cover`,
  }

  return <div className={light ? styles.background :  styles.backgroundDark} style={style} />
}

export default PageBackground;
