import { swiperBlockerElementID } from './Swiper';

export function setSwiperBlockerStatus(status: 'block' | 'unblock') {
  const swiperBlockerElement = document.getElementById(swiperBlockerElementID);
  if (!swiperBlockerElement) return;

  switch(status) {
    case 'block': {
      swiperBlockerElement.style.zIndex = '1'; // устанавливаем блокировку списка игроков
      break;
    } case 'unblock': {
      swiperBlockerElement.style.zIndex = '0'; // снимаем блокировку свайпера
      break;
    }
  } 
}
