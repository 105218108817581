import RestClient from '@/integration/RestClient';
import { LundapadelGameApiMyPastTourGameLoadListRequest, LundapadelGameApiMyTourGameLoadListRequest } from '@/integration-api/server-rest-lundapadelApi';

export const myTourGameLoadList = (params: LundapadelGameApiMyTourGameLoadListRequest) => {
  return RestClient.gameApi.myTourGameLoadList(params)
}

export const myPastTourGameLoadList = (params: LundapadelGameApiMyPastTourGameLoadListRequest) => {
  return RestClient.gameApi.myPastTourGameLoadList(params);
}
