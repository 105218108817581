import { useEffect } from "react"
import Header from "@/components/Header/Header"
import InvitePlayerSerach from '../components/PlayerSerach/InvitePlayerSerach'
import { MatchSearchPageProps } from './MatchSearchPage.interfaces'

const MatchSearchPage: React.FC<MatchSearchPageProps> = ({ onSubmit, ratingRange, initialSlectedPlayers, onClose, excludedUids }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header handleClick={onClose ? onClose : undefined}>Пригласить игроков</Header>
      <InvitePlayerSerach
        onSubmit={(players) => onSubmit?.(players)}
        initialSlectedPlayers={initialSlectedPlayers}
        ratingRange={ratingRange}
        excludedUids={excludedUids}
      />
    </>
  )
}

export default MatchSearchPage
