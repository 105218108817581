import React, { PropsWithChildren } from 'react';
import styles from './HLWrapper.module.css';

interface HLWrapperInterface extends PropsWithChildren {
  className?: string;
  fieldsErrors?:boolean
}

function HLWrapper({ children, className, fieldsErrors }: HLWrapperInterface) {

  const wrapperClassName = [
    fieldsErrors && styles.wrapper_error,
    styles.wrapper,
    className
  ].filter(e => e).join(' ');

  return (
    <div className={wrapperClassName}>
      {children}
    </div>
  );
}

export default HLWrapper;
