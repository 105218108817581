import { Fragment, useState } from 'react';
import styles from './GamePlayersFormItem.module.css';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import { useAppSelector } from '@/hooks/hooks';
import { GameTournamentKind, PlayerProfileInfo, Role, TournamentKind } from '@/integration-api/server-rest-lundapadelApi';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import CoachWhistle from '@/static/images/icons/CoachWhistle';
import PlaceholderCard from '@/components/PlaceholderCard/PlaceholderCard';
import GamePlayerCarousel from '@/pages/application/game/components/GamePlayerCarousel/GamePlayerCarousel';
import GamePlayersRadio from '../GamePlayersRadio/GamePlayersRadio';
import PlayersCaterpillar from '../PlayersCaterpillar/PlayersCaterpillar';
import SelectTournamentType from '../SelectTournamentType/SelectTournamentType';

type GamePlayersFormItem = {
  playersLimit: number;
  evetyoneCanInvite: boolean;
  isCoachParticipating: boolean;
  invitations: Array<PlayerProfileInfo>;
  lundaClub?: boolean;
  tourenamentKind?: GameTournamentKind;
  inputChange: (value: number) => void; 
  switcherChange: (name: 'isCoachParticipating' | 'everyoneCanInvite') => void;
  tournamentChange: (value: GameTournamentKind | TournamentKind) => void;
  onInviteClick: () => void;
}

const GamePlayersFormItem = ({ playersLimit, evetyoneCanInvite, isCoachParticipating, invitations, lundaClub, tourenamentKind, tournamentChange, inputChange, switcherChange, onInviteClick }: GamePlayersFormItem) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  
  const slicedPlayers: Array<PlayerProfileInfo | undefined> = Array(playersLimit).fill(undefined);

  function renderContentByMode() {
    return (
      <>    
        {slicedPlayers.length === 4 ?        
          <InnerPadding className={styles['players']}>
            {slicedPlayers.map((_, index) => {
              return (
                <Fragment key={index}>
                  {isCoachParticipating && !index ?
                    <ProfileCard
                      player={{ ...current, uid: current.identity?.uid, nameInitials: current.nameInitials }}
                      bage={[
                        {
                          element: <RatingBage rating={current.displayRating ?? '1.0'}/>,
                          position: 'top-right'
                        },
                        {
                          element: current.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                          position: 'top-left'
                        }
                      ]}
                    /> :
                    <PlaceholderCard type='invite' onClick={onInviteClick}/>
                  }
                </Fragment>
              )
            })}
          </InnerPadding> :
          <InnerPadding className={styles['caterpillar']}>
            {isCoachParticipating ?
              <ProfileCard
                player={{ ...current, uid: current.identity?.uid, nameInitials: current.nameInitials }}
                style={{ flexGrow: 0 }}
                bage={[
                  {
                    element: <RatingBage rating={current.displayRating ?? '1.0'}/>,
                    position: 'top-right'
                  },
                  {
                    element: current.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                    position: 'top-left'
                  }
                ]}
              /> : null
            }
            <PlayersCaterpillar players={!isCoachParticipating ? slicedPlayers : slicedPlayers.slice(1)} onInviteClick={onInviteClick}/>
          </InnerPadding>
        }  
        <GamePlayersRadio 
          playersLimit={playersLimit}
          onChange={e => inputChange(Number(e.target.value))}
          lundaClub={lundaClub}
        />
      </>
    );
  }

  return (  
    <div className={styles['wrapper']}>
      <InnerPadding className={styles['headling-wrapper']}>
        <div className={styles['headling']}>Количество игроков</div>
      </InnerPadding>
      {renderContentByMode()}
      <InnerPadding className={styles['switchers']}>
        <div className={styles['switcher']}>
          <span>Приглашать могут все участники</span>
          <ToggleSwitch 
            id='everyoneCanInvite'
            name='everyoneCanInvite'
            checked={evetyoneCanInvite}
            onChange={() => switcherChange('everyoneCanInvite')}
          /> 
        </div>
        {current.roles?.includes(Role.COACH) || current.roles?.includes(Role.ADMIN) ?
          <div className={styles['switcher']}>
            <div style={{ display: 'inline-flex' }}>
              <span style={{ paddingRight: '8px' }}>Я буду игроком в игре</span>
              <CoachWhistle fill='var(--gray-blue)'/>
            </div>
            <ToggleSwitch 
              id='isCoachParticipating'
              name='isCoachParticipating'
              checked={isCoachParticipating}
              onChange={() => switcherChange('isCoachParticipating')}
            /> 
          </div>
          : null
        }
      </InnerPadding>
      {invitations.length ? 
        <GamePlayerCarousel 
          players={invitations} 
          type='invintations'
          createForm
        /> : null
      }
      {playersLimit === 8 ? 
        <SelectTournamentType
          mini
          handleChange={tournamentChange}
          value={tourenamentKind}
        /> : null  
      }
    </div>
  );
}
 
export default GamePlayersFormItem;
