import { CalculatedGameStatus, GameTournamentStatus, InvitationStatus, Role, TicketType } from '@/integration-api/server-rest-lundapadelApi';
import styles from './GameStatusContent.module.css';
import GameDetailsCard from '../components/GameDetailsCard/GameDetailsCard';
import GamePlayerCarousel from '../components/GamePlayerCarousel/GamePlayerCarousel';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { canUserInvite, canUserSetScore, gameModifiableStatuses } from '../utils';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks/hooks';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { GameActions, HandleGameActionParams, ModalsState, SwitcherState } from './GameStatusContent.interfaces';
import { acceptInviteForGame, cancelGameInvite, createInviteForGame, exitGame, gameCancel, ownerEnterGame, setPrivateGame, startGameTournament } from '../service';
import { useImmer } from 'use-immer';
import GameActionModal from '../components/GameActionModal/GameActionModal';
import GameJoiningCard from '../../../../components/GameJoiningCard/GameJoiningCard';
import { FetchErrorMessage } from '@/types/types';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import { useWebsocketTicket } from './utils';
import { IGamePageContext } from '../GamePage.interfaces';
import { uidSearchName } from '../GameChatPage/GameChatPage';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const PlannedGameContent = () => {
  const { game, handlerLoading, setHandlerLoading, updateGame, gameChats } = useOutletContext<IGamePageContext>();

  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const { id } = useParams();
  const webSocketURL = useWebsocketTicket(game.identity?.uid, TicketType.GAME);

  const [editMode, setEditMode] = useState(false);
  const [offset, setOffset] = useState(0);

  const [modalsState, setModalsState] = useImmer<ModalsState>({
    leaveGameVisible: false,
    removeInviteVisible: false,
    cancelGameVisible: false,
    kickPlayerVisible: false,
    playerData: {}
  });

  const buttonsRef = useRef<HTMLDivElement>(null);

  const handleGameAction = async (action: GameActions, params?: HandleGameActionParams) => {
    try {
      setHandlerLoading(state => { state.handler = true });
      switch(action) {
        case 'join': {
          await createInviteForGame({ gameUid: id });
          setModalsState(state => { state.playerData = {} });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'cancelRequest': {
          if(id) {
            await cancelGameInvite(id);
            if(!webSocketURL) {
              updateGame?.();
            }
          }
          break;
        }
        case 'acceptInvite': {
          await acceptInviteForGame({ accepted: true, gameUid: id, playerUid: params?.playerUid });
          setModalsState(state => {
            state.removeInviteVisible = false;
            state.playerData = {};
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'removeInvite': {
          await acceptInviteForGame({ accepted: false, gameUid: id, playerUid: params?.playerUid });
          setModalsState(state => {
            state.removeInviteVisible = false;
            state.playerData = {};
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'kick': {
          await exitGame({ gameUid: id, playerUid: params?.playerUid });
          setModalsState(state => {
            state.kickPlayerVisible = false;
            state.leaveGameVisible = false;
            state.playerData = {};
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'cancelGame': {
          await gameCancel({ gameUid: id });
          setModalsState(state => {
            state.cancelGameVisible = false;
          });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'start': {
          await startGameTournament({ gameUid: id });
          navigate(`/game/${id}/result`);
          break;
        }
        default: {
          const _: never = action;
          throw new Error('not evety action is handled');
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    } finally {
      setHandlerLoading(state => { state.handler = false });
    }
  }

  const handleSwitchAction = async (e: ChangeEvent<HTMLInputElement>, name: keyof SwitcherState) => {
    try {
      switch(name) {
        case 'privateGame': {
          await setPrivateGame({ gameUid: id, privateGame: e.target.checked });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        case 'ownerParticipation': {
          await ownerEnterGame({ enter: e.target.checked, gameUid: id });
          if(!webSocketURL) {
            updateGame?.();
          }
          break;
        }
        default: {
          const _: never = name;
          throw new Error('Not every action is handled'); 
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }

  const handleSetResultBtnClick = () => {
    if (game.condition?.locked) {
      PopupMessage.open('Матч редактируется дргуим пользователем.', 'error');
    } else {
      navigate(`/game/${id}/result`);
    }
  }

  const hasSentRequest = useMemo(() => {
    return !!game?.invitations?.find(inv => inv.sender?.uid === current?.identity?.uid && inv.player?.uid === current?.identity?.uid && inv.invitationStatus === InvitationStatus.SENT);
  }, [game?.invitations, current?.identity?.uid]);

  const hasBeenInvited = useMemo(() => {
    return !!game?.invitations?.find(inv => inv.player?.uid === current?.identity?.uid && inv.sender?.uid !== current?.identity?.uid && inv.invitationStatus === InvitationStatus.SENT);
  }, [game?.invitations, current?.identity?.uid, game?.owner?.uid]);

  const renderJoininUsers = () => {
    if(current?.identity?.uid === game?.owner?.uid) {
      const suitableInvites = game?.invitations?.filter(inv => inv.sender?.uid === inv.player?.uid && inv.invitationStatus === InvitationStatus.SENT);
      return suitableInvites?.length ? (
        <div className={styles['joining-players']}>
          {suitableInvites?.map(inv => (
            <GameJoiningCard
              key={inv.player?.uid}
              player={inv.player ?? {}} 
              acceptHandler={playerUid => handleGameAction('acceptInvite', { playerUid })}
              declineHandler={playerUid => handleGameAction('removeInvite', { playerUid })}
            />
          ))}
        </div>
      ) : null;
    }
    return null;
  }

  const renderButtons = () => {
    if(editMode) {
      return (
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={() => setEditMode(false)}
          disabled={handlerLoading || !navigator.onLine}
        >
          Сохранить состав игроков
        </Button>
      )
    }
    if(canUserSetScore(game, current?.identity?.uid!) && game?.status === CalculatedGameStatus.STARTED && game?.members?.length! >= 4) {
      return ( 
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={handleSetResultBtnClick}
          disabled={handlerLoading || !navigator.onLine}
        >
          <span>Выставить результаты игры</span>
        </Button>
      );
    }
    if(game.owner?.uid === current?.identity?.uid && game?.status === CalculatedGameStatus.PLANNED && game?.tournamentStatus === GameTournamentStatus.PLANNED && game?.gameTournamentKind && game?.members?.length === game?.playersLimit) {
      return (
        <>        
          <Button
            variant={ButtonVariants.PRIMARY}
            onClick={() => handleGameAction('start')}
            disabled={handlerLoading || !navigator.onLine}
          >
            <span>Начать игру</span>
          </Button>
          <Button
            variant={ButtonVariants.CANCEL}
            bordered
            onClick={() => handleGameAction('cancelGame')}
            disabled={handlerLoading || !navigator.onLine}
          >
            Отменить игру
          </Button>
        </>
      );
    }
    if(gameModifiableStatuses.includes(game?.status!) && canUserInvite(game, current?.identity?.uid!)) {
      return (
        <>
          {(game?.status === CalculatedGameStatus.STARTED && game?.members?.length! < 4) || (game?.status === CalculatedGameStatus.PLANNED && game?.members?.length! < game?.playersLimit!) ?            
            <Button
              variant={ButtonVariants.PRIMARY}
              onClick={() => navigate(`/game/${id}/invitation`)}
              disabled={handlerLoading || !navigator.onLine}
            >
              Пригласить игроков
            </Button> : null
          }
          {game.status === CalculatedGameStatus.PLANNED && game.owner?.uid !== current.identity?.uid ?
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setModalsState(state => { 
                state.leaveGameVisible = true;
                state.playerData = { uid: current?.identity?.uid } 
              })}
              disabled={handlerLoading || !navigator.onLine}
            >
              Покинуть игру
            </Button> : null
          }
          {game?.status === CalculatedGameStatus.PLANNED && game?.owner?.uid === current?.identity?.uid ?
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setModalsState(state => { state.cancelGameVisible = true })}
              disabled={handlerLoading || !navigator.onLine}
            >
              Отменить игру
            </Button> : null
          }
        </>
      );
    }
    if(current?.identity?.uid !== game?.owner?.uid) {
      if(game?.isAuthUserParticipating && game?.status === CalculatedGameStatus.PLANNED) {
        return (
          <>
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setModalsState(state => { 
                state.leaveGameVisible = true;
                state.playerData = { uid: current?.identity?.uid } 
              })}
              disabled={handlerLoading || !navigator.onLine}
            >
              Покинуть игру
            </Button>
          </>
        );
      }
      if(gameModifiableStatuses.includes(game?.status!) && !game?.isAuthUserParticipating && game?.members?.length !== game?.playersLimit) {
        if(hasBeenInvited) {
          return (
            <>
              <span className={styles['invite-received']}>Вы приглашены в игру!</span>
              <Button
                variant={ButtonVariants.PRIMARY}
                onClick={() => handleGameAction('acceptInvite', { playerUid: current?.identity?.uid })}
                disabled={handlerLoading || !navigator.onLine}
              >
                Принять
              </Button>
              <Button
                variant={ButtonVariants.CANCEL}
                bordered
                onClick={() => handleGameAction('removeInvite', { playerUid: current?.identity?.uid })}
                disabled={handlerLoading || !navigator.onLine}
              >
                Отклонить
              </Button>
            </>
          );
        }
        if(!hasBeenInvited) {
          return (
            <>
              {!hasSentRequest ?  
                <Button
                  variant={ButtonVariants.PRIMARY}
                  onClick={() => handleGameAction('join')}
                  disabled={handlerLoading || !navigator.onLine}
                >
                  Присоединиться к игре
                </Button> : 
                <Button
                  variant={ButtonVariants.DISABLED}
                  onClick={() => handleGameAction('cancelRequest')}
                  disabled={handlerLoading || !navigator.onLine}
                >
                  Отменить запрос
                </Button>
              }
            </>
          );
        }
      }
    }
    return null;
  }

  useEffect(() => {
    let ignoreWSConnection = false;
    const socket = webSocketURL ? new WebSocket(webSocketURL) : null;

    if (socket) {
      socket.onopen = (e) => {
        if (ignoreWSConnection) {
          socket?.close();
        } else {
          console.log('Websocket is open!');
        }
      };
    
      socket.onerror = (error) => {
        console.log(error);
      };

      socket.onmessage = ({ data }) => {
        const { eventType } = JSON.parse(data);
        if (eventType === 'UPDATED') {
          updateGame();
        }
      };
    }
  
    return () => {
      if (socket?.readyState === 1) {
        socket?.close();
      } else {
        ignoreWSConnection = true;
      }
    }
  }, [webSocketURL]);

  useEffect(() => {
    setOffset(buttonsRef.current?.clientHeight ?? 0);
  }, [buttonsRef.current?.clientHeight, offset, game])

  return (  
    <>
      <GameActionModal
        type='kickPlayerVisible'
        visible={modalsState.kickPlayerVisible}
        handler={playerUid => handleGameAction('kick', { playerUid })}
        onClose={() => setModalsState(state => {state.kickPlayerVisible = false})}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='removeInviteVisible'
        visible={modalsState.removeInviteVisible}
        handler={playerUid => handleGameAction('removeInvite', { playerUid })}
        onClose={() => setModalsState(state => {state.removeInviteVisible = false})}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='leaveGameVisible'
        visible={modalsState.leaveGameVisible}
        handler={playerUid => handleGameAction('kick', { playerUid })}
        onClose={() => setModalsState(state => {state.leaveGameVisible = false})}
        playerData={modalsState.playerData}
      />
      <GameActionModal
        type='cancelGameVisible'
        visible={modalsState.cancelGameVisible}
        handler={() => handleGameAction('cancelGame')}
        onClose={() => setModalsState(state => { state.cancelGameVisible = false })}
      />
      <div className={styles['wrapper']}>
        <GameDetailsCard game={game} showQR={game.owner?.uid === current.identity?.uid}/>

        {gameChats.some((chat) => chat.opened) && (
          // Приоритетней ссылка на закрытый чат 
          <Link to={`chat?${uidSearchName}=${gameChats.find((chat) => !chat.opened)?.chatUid || gameChats.find((chat) => chat.opened)?.chatUid}`}>
            <Button variant={ButtonVariants.BLUE} className={[styles['chat-btn'], gameChats.some((chat) => chat.unreadMessages) ? styles.unreadIndicator : ''].join(' ')} >
              Чат
            </Button>
          </Link>
        )}

        {game?.owner?.uid === current?.identity?.uid && game.status === CalculatedGameStatus.PLANNED ?
          <div className={styles['switchers']}>
            {game?.status === CalculatedGameStatus.PLANNED ?
              <div className={styles['switcher']}>
                <div>Приватная игра</div>
                <ToggleSwitch 
                  id='privateGame' 
                  name='privateGame' 
                  checked={game?.privateGame}
                  onChange={e => handleSwitchAction(e, 'privateGame')}
                />
              </div> : null
            }
            {current?.roles?.includes(Role.COACH) || current.roles?.includes(Role.ADMIN) ?
              <div className={styles['switcher']}>
                <div>Я буду игроком в этой игре</div>
                <ToggleSwitch 
                  id='ownerParticipation' 
                  name='ownerParticipation' 
                  checked={game?.isAuthUserParticipating}
                  onChange={e => handleSwitchAction(e, 'ownerParticipation')}
                />
              </div> : null
            }
          </div> : null
        }
        <GamePlayerCarousel
          players={game?.members?.map(member => member.player ?? {}).concat(Array(game?.playersLimit! - game?.members?.length! > 0 ? game?.playersLimit! - game?.members?.length! : 0).fill(undefined)) ?? []}
          playersLimit={game?.playersLimit}
          type='participants'
          editMode={editMode}
          setEditMode={state => setEditMode(state)}
          owner={(current?.identity?.uid === game?.owner?.uid) && game?.status === CalculatedGameStatus.PLANNED && navigator.onLine}
          ownerUid={game?.owner?.uid}
          playerHandler={(uid, displayName) => {
            setModalsState(state => { 
              state.kickPlayerVisible = true;
              state.playerData = { uid, displayName } 
            }
          )}}
          openInvitesPage={() => navigate(`/game/${id}/invitation`)}
          canInvite={navigator.onLine && canUserInvite(game, current?.identity?.uid ?? '') && ((game?.status === CalculatedGameStatus.STARTED && game?.members?.length! < 4) || (game?.status === CalculatedGameStatus.PLANNED && game?.members?.length! < game?.playersLimit!))}
        />
        {renderJoininUsers()}
        {game?.invitations?.length ?
          <GamePlayerCarousel
            players={game?.invitations?.map(inv => ({ ...inv.player, inviteType: inv.sender?.uid === inv.player?.uid ? 'player' : 'onwer' } ?? {})) ?? []}
            type='invintations'
            collapsable
            editMode={editMode}
            setEditMode={state => setEditMode(state)}
            owner={(current?.identity?.uid === game?.owner?.uid) && game?.status === CalculatedGameStatus.PLANNED && navigator.onLine}
            ownerUid={game?.owner?.uid}
            playerHandler={(uid, displayName) => {
              setModalsState(state => { 
                state.removeInviteVisible = true;
                state.playerData = { uid, displayName } 
              }
            )}}
          /> : null
        }
        <div style={{ height: offset + 16 }}></div>
      </div>
      <OversideWrapper className={styles['buttons-wrapper']} buttonsRef={buttonsRef}>
        {renderButtons()}
      </OversideWrapper>
    </>
  );
}
 
export default PlannedGameContent;
