import Headling from '@/components/Headling/Headling';
import styles from './SelectTournamentGender.module.css';
import InnerPadding from "@/components/InnerPadding/InnerPadding";
import ArrowIcon from '@/static/images/icons/ArrowIcon';
import { useEffect, useState } from 'react';
import { genderOptions } from './utils';
import { GenderSelectOption, SelectTournamentGenderProps } from './SelectTournamentGender.interfaces';

const SelectTournamentGender = ({ value, handleChange }: SelectTournamentGenderProps) => {
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<GenderSelectOption>();

  document.addEventListener('click', ev => {
    if(!((ev.target as HTMLElement).id === 'fake-gender' || (ev.target as HTMLInputElement)?.name?.includes('gender')) && selectOpen) {
      setSelectOpen(false)
    }
  })

  document.removeEventListener('click', ev => {
    if(!((ev.target as HTMLElement).id === 'fake-gender' || (ev.target as HTMLInputElement)?.name?.includes('gender')) && selectOpen) {
      setSelectOpen(false);
    }
  })

  const renderOptions = () => {
    return genderOptions.map((option, index) => (
      <div key={option.value} className={`${styles['option']} ${option.value === value ? styles['labelChecked'] : ''}`}>
      <input 
        type="radio" 
        name='gender' 
        id={`gender-${genderOptions.length - index}`} 
        value={option.value} 
        onChange={() => {
          setSelectOpen(false);
          setSelectedOption(option);
          handleChange(option.value);
        }}
        checked={value === option.value}
        style={{display: 'none'}}/>
      <label className={styles['label-style']} htmlFor={`gender-${genderOptions.length - index}`}>{option.label}</label>
    </div>
    ))
  }

  useEffect(() => {
    if(value && !selectedOption) {
      const actualOption = genderOptions.find(option => option.value === value);
      if(actualOption) {
        setSelectedOption(actualOption);
      }
    }
  }, [value, selectedOption]);

  return ( 
    <div className={styles['wrapper']}>
      <InnerPadding>
        <Headling appearence='medium-normal'>
          Выберите состав участников
        </Headling>
      </InnerPadding>
      <div className={styles['fake-select']}>
        <input
          type="checkbox" 
          name="fake-select" 
          id="fake-gender" 
          style={{display: 'none'}}
          onClick={() => setSelectOpen(!selectOpen)}
        />
        <label htmlFor="fake-gender" className={styles['fake-label']}>
          {selectedOption?.label}
          <div style={{ marginLeft: 'auto' }}>
            <ArrowIcon />
          </div>
        </label>
        <div className={styles['fake-options']} style={{ display: selectOpen ? 'flex' : 'none'}}>
          {renderOptions()}
        </div>
      </div>
    </div> 
  );
}
 
export default SelectTournamentGender;
