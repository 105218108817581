import { useEffect, useState } from 'react';
import RestClient from '@/integration/RestClient';
import { TicketType } from '@/integration-api/server-rest-lundapadelApi';

export function useWebsocketTicket(entityUid?: string | null, entityType?: TicketType) {
  const [ticket, setTicket] = useState('');

  useEffect(() => {
    if (entityUid && entityType) {
      RestClient.mainApi.websocketTicketRegister({
        relatedEntityUid: entityUid,
        ticketType: entityType,
      })
        .then(({ result }) => setTicket(result?.ticket!))
        .catch((e) => {console.log(e)});
    }
  }, [entityUid]);

  let URL: null | string = null;

  if (ticket) {
    URL = (process.env.NODE_ENV === 'development') || (window.location.hostname.includes('dev'))
      ? `wss://app.lundapadel-dev.gridnine.com/api-ws/websocket-listen?ticket=${ticket}`
      : `wss://app.lundapadel.ru/api-ws/websocket-listen?ticket=${ticket}`
  }

  return URL;
}
