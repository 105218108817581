const VenokIcon = () => {
  return ( 
    <svg width="77" height="65" viewBox="0 0 77 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.1628 58.4237C34.8685 59.7715 35.3207 62.0151 35.3207 62.0151C35.3207 62.0151 33.0121 62.0684 31.3064 60.7206C29.6007 59.3728 29.1484 57.1292 29.1484 57.1292C29.1484 57.1292 31.4599 57.0758 33.1628 58.4237Z" fill="#4EB857"/>
      <path d="M31.1038 61.9279C33.1613 61.2091 35.3248 62.0122 35.3248 62.0122C35.3248 62.0122 34.1495 63.9806 32.092 64.6994C30.0346 65.4182 27.8711 64.6152 27.8711 64.6152C27.8711 64.6152 29.0464 62.6496 31.1038 61.9279Z" fill="#4EB857"/>
      <path d="M26.5204 56.6132C28.0223 58.1828 28.1591 60.4629 28.1591 60.4629C28.1591 60.4629 25.8643 60.2017 24.3652 58.6349C22.8633 57.0652 22.7266 54.7852 22.7266 54.7852C22.7266 54.7852 25.0185 55.0435 26.5204 56.6132Z" fill="#4EB857"/>
      <path d="M23.9902 59.8007C26.1286 59.3683 28.1581 60.4578 28.1581 60.4578C28.1581 60.4578 26.7204 62.2465 24.582 62.6789C22.4436 63.1113 20.4141 62.019 20.4141 62.019C20.4141 62.019 21.8518 60.2303 23.9902 59.8007Z" fill="#4EB857"/>
      <path d="M21.2853 54.0063C22.5165 55.7893 22.2876 58.0638 22.2876 58.0638C22.2876 58.0638 20.0627 57.4461 18.8315 55.663C17.6004 53.8799 17.8293 51.6055 17.8293 51.6055C17.8293 51.6055 20.0542 52.2232 21.2853 54.0063Z" fill="#4EB857"/>
      <path d="M18.2766 56.762C20.4569 56.6721 22.2882 58.0649 22.2882 58.0649C22.2882 58.0649 20.5825 59.6065 18.4023 59.6991C16.222 59.789 14.3906 58.3962 14.3906 58.3962C14.3906 58.3962 16.0963 56.8546 18.2766 56.762Z" fill="#4EB857"/>
      <path d="M16.3979 50.0379C17.1544 52.0624 16.3756 54.2162 16.3756 54.2162C16.3756 54.2162 14.3683 53.0845 13.6118 51.0572C12.8553 49.0326 13.6341 46.8789 13.6341 46.8789C13.6341 46.8789 15.6385 48.0105 16.3979 50.0379Z" fill="#4EB857"/>
      <path d="M12.7957 51.9896C14.9341 52.4248 16.3662 54.2163 16.3662 54.2163C16.3662 54.2163 14.3339 55.303 12.1955 54.8678C10.0571 54.4325 8.625 52.641 8.625 52.641C8.625 52.641 10.6601 51.5544 12.7957 51.9896Z" fill="#4EB857"/>
      <path d="M12.6886 45.4387C12.9427 47.584 11.6753 49.4934 11.6753 49.4934C11.6753 49.4934 9.99473 47.9266 9.74069 45.7813C9.48665 43.636 10.754 41.7266 10.754 41.7266C10.754 41.7266 12.4346 43.2934 12.6886 45.4387Z" fill="#4EB857"/>
      <path d="M8.73829 46.5039C10.712 47.4249 11.6807 49.5001 11.6807 49.5001C11.6807 49.5001 9.44736 50.0841 7.47366 49.1631C5.49996 48.2421 4.53125 46.167 4.53125 46.167C4.53125 46.167 6.76459 45.5829 8.73829 46.5039Z" fill="#4EB857"/>
      <path d="M9.52695 39.646C9.34549 41.7997 7.71797 43.4227 7.71797 43.4227C7.71797 43.4227 6.38632 41.5554 6.56777 39.4017C6.74923 37.248 8.37676 35.625 8.37676 35.625C8.37676 35.625 9.70841 37.4951 9.52695 39.646Z" fill="#4EB857"/>
      <path d="M5.43518 39.9018C7.18555 41.1934 7.71601 43.4173 7.71601 43.4173C7.71601 43.4173 5.41009 43.5494 3.65973 42.2577C1.90937 40.966 1.37891 38.7421 1.37891 38.7421C1.37891 38.7421 3.68482 38.6101 5.43518 39.9018Z" fill="#4EB857"/>
      <path d="M7.90012 34.1306C7.37528 36.2282 5.51045 37.576 5.51045 37.576C5.51045 37.576 4.49428 35.5234 5.01632 33.4258C5.54115 31.3283 7.40599 29.9805 7.40599 29.9805C7.40599 29.9805 8.42215 32.0331 7.90012 34.1306Z" fill="#4EB857"/>
      <path d="M3.81903 33.7373C5.34048 35.2845 5.51071 37.5646 5.51071 37.5646C5.51071 37.5646 3.21321 37.3343 1.69176 35.7843C0.170307 34.2343 0 31.957 0 31.957C0 31.957 2.29758 32.1901 3.81903 33.7373Z" fill="#4EB857"/>
      <path d="M7.72233 27.9766C6.70059 29.886 4.55937 30.7453 4.55937 30.7453C4.55937 30.7453 4.0764 28.5101 5.09814 26.6007C6.11989 24.6913 8.2611 23.832 8.2611 23.832C8.2611 23.832 8.74408 26.0672 7.72233 27.9766Z" fill="#4EB857"/>
      <path d="M3.86221 26.6256C4.95933 28.4929 4.56291 30.7449 4.56291 30.7449C4.56291 30.7449 2.39102 29.9699 1.2939 28.0998C0.196785 26.2325 0.593197 23.9805 0.593197 23.9805C0.593197 23.9805 2.76509 24.7583 3.86221 26.6256Z" fill="#4EB857"/>
      <path d="M8.8145 21.7742C7.36843 23.3916 5.08203 23.7285 5.08203 23.7285C5.08203 23.7285 5.14349 21.4428 6.58956 19.8254C8.03564 18.208 10.322 17.8711 10.322 17.8711C10.322 17.8711 10.2606 20.1568 8.8145 21.7742Z" fill="#4EB857"/>
      <path d="M5.36969 19.5568C5.99223 21.6263 5.07376 23.7239 5.07376 23.7239C5.07376 23.7239 3.14755 22.4631 2.52501 20.3936C1.90248 18.3241 2.82095 16.2266 2.82095 16.2266C2.82095 16.2266 4.74994 17.4873 5.36969 19.5568Z" fill="#4EB857"/>
      <path d="M11.0452 16.6648C9.26408 17.9144 6.96094 17.7262 6.96094 17.7262C6.96094 17.7262 7.54725 15.5136 9.32832 14.2668C11.1094 13.0173 13.4125 13.2054 13.4125 13.2054C13.4125 13.2054 12.8262 15.4153 11.0452 16.6648Z" fill="#4EB857"/>
      <path d="M8.2115 13.7485C8.33992 15.9051 6.96358 17.7387 6.96358 17.7387C6.96358 17.7387 5.37795 16.0763 5.24674 13.9198C5.11832 11.7633 6.4946 9.92969 6.4946 9.92969C6.4946 9.92969 8.08309 11.592 8.2115 13.7485Z" fill="#4EB857"/>
      <path d="M13.8382 12.0584C11.8812 13.0131 9.63672 12.4656 9.63672 12.4656C9.63672 12.4656 10.5692 10.3736 12.5261 9.4189C14.483 8.46419 16.7276 9.01176 16.7276 9.01176C16.7276 9.01176 15.7951 11.1037 13.8382 12.0584Z" fill="#4EB857"/>
      <path d="M11.5093 8.71757C11.2916 10.8657 9.63895 12.4634 9.63895 12.4634C9.63895 12.4634 8.33808 10.5737 8.55583 8.42556C8.77357 6.27745 10.4262 4.67969 10.4262 4.67969C10.4262 4.67969 11.7271 6.56946 11.5093 8.71757Z" fill="#4EB857"/>
      <path d="M44.9958 58.1347C43.2901 59.4825 42.8379 61.7261 42.8379 61.7261C42.8379 61.7261 45.1466 61.7794 46.8523 60.4316C48.558 59.0838 49.0103 56.8402 49.0103 56.8402C49.0103 56.8402 46.7015 56.784 44.9958 58.1347Z" fill="#4EB857"/>
      <path d="M47.0589 61.6389C45.0015 60.92 42.8379 61.7231 42.8379 61.7231C42.8379 61.7231 44.0132 63.6915 46.0706 64.4103C48.1281 65.1292 50.2917 64.3261 50.2917 64.3261C50.2917 64.3261 49.1163 62.3577 47.0589 61.6389Z" fill="#4EB857"/>
      <path d="M50.8047 56.6053C49.3028 58.175 49.166 60.4551 49.166 60.4551C49.166 60.4551 51.4607 60.1939 52.9598 58.6271C54.4617 57.0574 54.5986 54.7773 54.5986 54.7773C54.5986 54.7773 52.3038 55.0357 50.8047 56.6053Z" fill="#4EB857"/>
      <path d="M53.3339 59.8007C51.1955 59.3683 49.166 60.4578 49.166 60.4578C49.166 60.4578 50.6037 62.2465 52.7421 62.6789C54.8805 63.1113 56.91 62.019 56.91 62.019C56.91 62.019 55.4723 60.2303 53.3339 59.8007Z" fill="#4EB857"/>
      <path d="M56.0327 53.7797C54.8015 55.5628 55.0305 57.8373 55.0305 57.8373C55.0305 57.8373 57.2554 57.2195 58.4865 55.4364C59.7176 53.6534 59.4888 51.3789 59.4888 51.3789C59.4888 51.3789 57.2638 51.9966 56.0327 53.7797Z" fill="#4EB857"/>
      <path d="M59.037 56.5393C56.8567 56.4495 55.0254 57.8422 55.0254 57.8422C55.0254 57.8422 56.7311 59.3838 58.9113 59.4765C61.0916 59.5691 62.9229 58.1736 62.9229 58.1736C62.9229 58.1736 61.2172 56.632 59.037 56.5393Z" fill="#4EB857"/>
      <path d="M60.7544 49.9168C59.9978 51.9442 60.7767 54.0951 60.7767 54.0951C60.7767 54.0951 62.7839 52.9635 63.5404 50.9361C64.297 48.9115 63.5181 46.7578 63.5181 46.7578C63.5181 46.7578 61.5109 47.8894 60.7544 49.9168Z" fill="#4EB857"/>
      <path d="M64.3537 51.8695C62.2153 52.3047 60.7832 54.0962 60.7832 54.0962C60.7832 54.0962 62.8155 55.1829 64.9539 54.7477C67.0923 54.3124 68.5244 52.5209 68.5244 52.5209C68.5244 52.5209 66.4921 51.4314 64.3537 51.8695Z" fill="#4EB857"/>
      <path d="M64.6606 45.0129C64.4066 47.1582 65.6739 49.0677 65.6739 49.0677C65.6739 49.0677 67.3545 47.5008 67.6085 45.3555C67.8626 43.2102 66.5952 41.3008 66.5952 41.3008C66.5952 41.3008 64.9147 42.8676 64.6606 45.0129Z" fill="#4EB857"/>
      <path d="M68.6123 46.0781C66.6386 46.9992 65.6699 49.0743 65.6699 49.0743C65.6699 49.0743 67.9033 49.6583 69.877 48.7373C71.8507 47.8163 72.8194 45.7412 72.8194 45.7412C72.8194 45.7412 70.586 45.1571 68.6123 46.0781Z" fill="#4EB857"/>
      <path d="M67.4682 39.5445C67.6496 41.6982 69.2771 43.3212 69.2771 43.3212C69.2771 43.3212 70.6087 41.4539 70.4273 39.3002C70.2458 37.1464 68.6183 35.5234 68.6183 35.5234C68.6183 35.5234 67.2867 37.3907 67.4682 39.5445Z" fill="#4EB857"/>
      <path d="M71.5601 39.8041C69.8097 41.0958 69.2793 43.3197 69.2793 43.3197C69.2793 43.3197 71.5852 43.4517 73.3356 42.16C75.0859 40.8684 75.6163 38.6444 75.6163 38.6444C75.6163 38.6444 73.3104 38.5125 71.5601 39.8041Z" fill="#4EB857"/>
      <path d="M69.0975 34.0174C69.6223 36.115 71.4872 37.4628 71.4872 37.4628C71.4872 37.4628 72.5033 35.4101 71.9813 33.3126C71.4564 31.215 69.5916 29.8672 69.5916 29.8672C69.5916 29.8672 68.5727 31.9226 69.0975 34.0174Z" fill="#4EB857"/>
      <path d="M73.1792 33.6358C71.6577 35.1858 71.4902 37.463 71.4902 37.463C71.4902 37.463 73.7877 37.2328 75.3092 35.6827C76.8306 34.1356 77.0009 31.8555 77.0009 31.8555C77.0009 31.8555 74.7006 32.0857 73.1792 33.6358Z" fill="#4EB857"/>
      <path d="M69.2496 28.0704C70.2713 29.9798 72.4125 30.839 72.4125 30.839C72.4125 30.839 72.8954 28.6039 71.8737 26.6944C70.8519 24.785 68.7108 23.9258 68.7108 23.9258C68.7108 23.9258 68.225 26.1609 69.2496 28.0704Z" fill="#4EB857"/>
      <path d="M73.1084 26.7193C72.0112 28.5867 72.4076 30.8386 72.4076 30.8386C72.4076 30.8386 74.5795 30.0637 75.6767 28.1935C76.7738 26.3262 76.3774 24.0742 76.3774 24.0742C76.3774 24.0742 74.2027 24.852 73.1084 26.7193Z" fill="#4EB857"/>
      <path d="M68.106 21.864C69.552 23.4814 71.8356 23.8184 71.8356 23.8184C71.8356 23.8184 71.7742 21.5327 70.3282 19.9153C68.8821 18.2979 66.5957 17.9609 66.5957 17.9609C66.5957 17.9609 66.6571 20.2466 68.106 21.864Z" fill="#4EB857"/>
      <path d="M71.5387 19.6467C70.9161 21.7162 71.8346 23.8137 71.8346 23.8137C71.8346 23.8137 73.7608 22.5529 74.3834 20.4834C75.0059 18.414 74.0875 16.3164 74.0875 16.3164C74.0875 16.3164 72.1584 17.5772 71.5387 19.6467Z" fill="#4EB857"/>
      <path d="M65.9338 16.8484C67.7149 18.098 70.0179 17.9099 70.0179 17.9099C70.0179 17.9099 69.4317 15.6972 67.6506 14.4504C65.8696 13.2009 63.5664 13.389 63.5664 13.389C63.5664 13.389 64.1527 15.5989 65.9338 16.8484Z" fill="#4EB857"/>
      <path d="M68.7604 13.9137C68.632 16.0702 70.0083 17.9039 70.0083 17.9039C70.0083 17.9039 71.5939 16.2415 71.7251 14.0878C71.8535 11.9313 70.4772 10.0977 70.4772 10.0977C70.4772 10.0977 68.8888 11.7572 68.7604 13.9137Z" fill="#4EB857"/>
      <path d="M62.9792 12.2693C64.9362 13.224 67.1807 12.6765 67.1807 12.6765C67.1807 12.6765 66.2482 10.5846 64.2913 9.62986C62.3343 8.67514 60.0898 9.22268 60.0898 9.22268C60.0898 9.22268 61.0195 11.3146 62.9792 12.2693Z" fill="#4EB857"/>
      <path d="M65.3053 8.9246C65.523 11.0727 67.1757 12.6704 67.1757 12.6704C67.1757 12.6704 68.4765 10.7807 68.2588 8.63256C68.041 6.48445 66.3884 4.88672 66.3884 4.88672C66.3884 4.88672 65.0903 6.77649 65.3053 8.9246Z" fill="#4EB857"/>
      <path d="M61.402 5.67578C61.3127 5.76844 61.2122 5.85829 61.095 5.95096C61.0615 5.97623 61.0252 5.99588 60.9916 6.01835C67.8341 11.8786 72.1445 20.3615 72.1445 29.7823C72.1445 47.4277 57.033 61.7821 38.4543 61.7821C19.8784 61.7821 4.76423 47.4277 4.76423 29.7823C4.76423 20.7771 8.70051 12.634 15.0237 6.81303C14.845 6.75968 14.6636 6.68947 14.4793 6.59681C8.15335 12.4992 4.22266 20.7125 4.22266 29.7823C4.22266 47.7113 19.5797 62.2959 38.4543 62.2959C57.329 62.2959 72.6861 47.7085 72.6861 29.7823C72.6861 20.2295 68.3282 11.6287 61.402 5.67578Z" fill="#4EB857"/>
      <path d="M18.3755 7.97765C16.2427 8.43254 14.1992 7.36549 14.1992 7.36549C14.1992 7.36549 15.6174 5.55995 17.7502 5.10505C19.883 4.65016 21.9265 5.71721 21.9265 5.71721C21.9265 5.71721 20.5083 7.52276 18.3755 7.97765Z" fill="#4EB857"/>
      <path d="M16.931 4.17828C16.194 6.21126 14.198 7.36252 14.198 7.36252C14.198 7.36252 13.3996 5.21722 14.1366 3.18424C14.8736 1.15126 16.8696 0 16.8696 0C16.8696 0 17.6708 2.1453 16.931 4.17828Z" fill="#4EB857"/>
      <path d="M58.6721 7.97765C60.805 8.43254 62.8484 7.36549 62.8484 7.36549C62.8484 7.36549 61.4303 5.55995 59.2975 5.10505C57.1646 4.65016 55.1211 5.71721 55.1211 5.71721C55.1211 5.71721 56.5393 7.52276 58.6721 7.97765Z" fill="#4EB857"/>
      <path d="M60.1183 4.17828C60.8553 6.21126 62.8513 7.36252 62.8513 7.36252C62.8513 7.36252 63.6497 5.21722 62.9127 3.18424C62.1757 1.15126 60.1797 0 60.1797 0C60.1797 0 59.3785 2.1453 60.1183 4.17828Z" fill="#4EB857"/>
    </svg>
  );
}
 
export default VenokIcon;
