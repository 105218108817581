import styles from './HLine.module.css';

interface HLineInterface {
  calssName?: string;
  fieldsErrors?:boolean
}

function HLine({ calssName = '',fieldsErrors }: HLineInterface) {
  return (
    <div className={`${styles.hrWrapper} ${calssName}`}>
      <hr className={`${styles.hr}  ${fieldsErrors ? styles.hrError : ''}`}/>
    </div>
  );
}

export default HLine;
