/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationExistResult,
    NotificationExistResultFromJSON,
    NotificationExistResultFromJSONTyped,
    NotificationExistResultToJSON,
} from './NotificationExistResult';

/**
 * 
 * @export
 * @interface ExistNotificationResponse
 */
export interface ExistNotificationResponse {
    /**
     * 
     * @type {NotificationExistResult}
     * @memberof ExistNotificationResponse
     */
    result?: NotificationExistResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof ExistNotificationResponse
     */
    messages?: Array<object>;
}

export function ExistNotificationResponseFromJSON(json: any): ExistNotificationResponse {
    return ExistNotificationResponseFromJSONTyped(json, false);
}

export function ExistNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExistNotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : NotificationExistResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function ExistNotificationResponseToJSON(value?: ExistNotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': NotificationExistResultToJSON(value.result),
        'messages': value.messages,
    };
}

