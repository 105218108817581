import RestClient from "@/integration/RestClient";
import RatingGrade from "@/pages/application/rating/components/RatingGrade/RatingGrade";
import { FC, useEffect, useState } from "react";
import styles from './Grade.module.css';
import { useFormik } from 'formik';

export interface GradeSwiperProps{
  formik:any
}

const GradeSwiper:FC<GradeSwiperProps> = ({formik}) => {
  const [ratingDescription, setRatingDescription] = useState<Array<any>>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [visibleGroups, setVisibleGroups] = useState<Array<string>>([]);

  const loadRatingDescription = async () => {
    try {
      const {result} =
        await RestClient.mainApi.gradeInfoLoadList()
      if (result) {
        setRatingDescription(result.infos);
      }
    } catch (err: unknown) {
      console.log(err);  
    }
  }

  const handleSwiperWrapTouchend = () => {
    (formik as ReturnType<typeof useFormik>).setTouched({
      ...(formik as ReturnType<typeof useFormik>).touched,
      grade: true,
    });
  }

  useEffect(() => {
    loadRatingDescription()
   }, [])

  useEffect(() => {
    formik.setFieldValue('grade', `GRADE_${currentIndex+1}`)
   }, [currentIndex])

  return (
    <>
      <div className={styles.header}>Выберите свой уровень:</div>
      <div className={styles.swiperContainer} onTouchEnd={handleSwiperWrapTouchend}>
        <RatingGrade
          visibleGroups={visibleGroups}
          isQuestionnaires setCurrentIndex={setCurrentIndex}
          activeIndexClassName={formik.touched.grade ? '' : styles.activeGrade}
        />
      </div>
      <div className={styles.ratingDescription}>
        {ratingDescription && ratingDescription[currentIndex].description}
      </div>
    </>
  )
}

export default GradeSwiper
