import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'u', 'p', 'h1', 'h2', 'h3', 'h4', 'h5'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty: string, options: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(dirty, options),
});

interface SanitizeHTMLProps {
  html: string;
  options?: sanitizeHtml.IOptions;
}

const SanitizeHTML: React.FC<SanitizeHTMLProps> = (props) => {
  const { html, options = {} } = props;
  return <div dangerouslySetInnerHTML={sanitize(html, {...defaultOptions, ...options})} />;
};

export default SanitizeHTML;
