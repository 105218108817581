import styles from './GameCountBageProps.module.css';

type GameCountBageProps = {
  playersLimit: number
}

const GameCountBage = ({ playersLimit}: GameCountBageProps) => {
  return (  
    <div className={styles['count']}>
      <span>+{playersLimit - 4}</span>
    </div>
  );
}
 
export default GameCountBage;
