export type ReactComponentDefaultProps = {
  key?: React.Key | null | undefined;
  children?: React.ReactNode;
}

export interface FetchErrorMessage {
  exceptionStakeTrace: string;
  errorMessage?: string;
  errorType?: string;
  userErrorMessage: string;
}

export function isEnum<T>(e: T){
  return(value: unknown): value is T[keyof T] => Object.values(e as {[key: string] : unknown}).includes(value as T[keyof T]);
}

export type DebugCodegen<T, Y extends T> = Partial<Omit<T, keyof Y>> & Required<Y>;