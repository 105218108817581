import { FullscreenLoaderProps } from './FullscreenLoader.interface';
import styles from './FullscreenLoader.module.css';
import { PuffLoader } from 'react-spinners';

function FullscreenLoader({ loading }: FullscreenLoaderProps) {
  return loading ? (
    <div className={styles['background']}>
      <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} />
    </div>
  ) : null;
}

export default FullscreenLoader;
