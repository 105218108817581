/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CalculatedGameStatus {
    PLANNED = 'PLANNED',
    STARTED = 'STARTED',
    AFTER_GAME = 'AFTER_GAME',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    SCORE_UNDEFINED = 'SCORE_UNDEFINED'
}

export function CalculatedGameStatusFromJSON(json: any): CalculatedGameStatus {
    return CalculatedGameStatusFromJSONTyped(json, false);
}

export function CalculatedGameStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculatedGameStatus {
    return json as CalculatedGameStatus;
}

export function CalculatedGameStatusToJSON(value?: CalculatedGameStatus | null): any {
    return value as any;
}

