import { Grade, PlayerProfileField } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

type fetchPlayerListType = {
  range: [number, number],
  textPattern: string,
  onlyPartners: boolean;
  excludedUids: Array<string>;
}

export const fetchPlayerList = ({range, textPattern, onlyPartners, excludedUids}: fetchPlayerListType) => {
  return RestClient.profileApi.playerLoadList({
    playerLoadListRequest: {
      parameters: {
        lowerRatingFilter: Grade[`_${range[0]}` as keyof typeof Grade],
        upperRatingFilter: Grade[`_${range[1]}` as keyof typeof Grade],
        textPattern,
        sortBy: onlyPartners ? PlayerProfileField.COUNT_MATCHES_WITH_PARTNER : PlayerProfileField.RATING,
        matchContext: true,
        onlyPartners,
        excludedUids
      }
    }
  });
}
