/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentExistInfo,
    TournamentExistInfoFromJSON,
    TournamentExistInfoFromJSONTyped,
    TournamentExistInfoToJSON,
} from './TournamentExistInfo';

/**
 * 
 * @export
 * @interface GameScoringExistResult
 */
export interface GameScoringExistResult {
    /**
     * 
     * @type {boolean}
     * @memberof GameScoringExistResult
     */
    gameExist?: boolean;
    /**
     * 
     * @type {Array<TournamentExistInfo>}
     * @memberof GameScoringExistResult
     */
    tournaments?: Array<TournamentExistInfo>;
}

export function GameScoringExistResultFromJSON(json: any): GameScoringExistResult {
    return GameScoringExistResultFromJSONTyped(json, false);
}

export function GameScoringExistResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameScoringExistResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameExist': !exists(json, 'gameExist') ? undefined : json['gameExist'],
        'tournaments': !exists(json, 'tournaments') ? undefined : ((json['tournaments'] as Array<any>).map(TournamentExistInfoFromJSON)),
    };
}

export function GameScoringExistResultToJSON(value?: GameScoringExistResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameExist': value.gameExist,
        'tournaments': value.tournaments === undefined ? undefined : ((value.tournaments as Array<any>).map(TournamentExistInfoToJSON)),
    };
}

