import { PlayerProfileInfo } from "@/integration-api/server-rest-lundapadelApi";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export enum ItemType {
  SEARCH = 'search',
  RATING = 'rating'
}

export interface PlayerItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type: ItemType;
  withTopBorder?: boolean;
  isActive?: boolean;
  withoutIcon?: boolean;
  player: PlayerProfileInfo;
  playerPlacement?: number;
  isInvitation?:boolean
  updateFollow?: (updatePlayer: PlayerProfileInfo) => void;
  addPlayer?: (player: PlayerProfileInfo) => void;
  updateSuggestions?: (player: PlayerProfileInfo) => void;
  isAlreadySelect?:boolean,
  handleClick?: any;
  customRating?: React.ReactNode;
}
