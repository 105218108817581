/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedbackReadResult
 */
export interface FeedbackReadResult {
    /**
     * 
     * @type {number}
     * @memberof FeedbackReadResult
     */
    readCount?: number;
}

export function FeedbackReadResultFromJSON(json: any): FeedbackReadResult {
    return FeedbackReadResultFromJSONTyped(json, false);
}

export function FeedbackReadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackReadResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readCount': !exists(json, 'readCount') ? undefined : json['readCount'],
    };
}

export function FeedbackReadResultToJSON(value?: FeedbackReadResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readCount': value.readCount,
    };
}

