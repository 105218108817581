import dayjs from "dayjs"
import { Time } from "./SelectTime"
import { LocalDateTime } from "@/integration-api/server-rest-lundapadelApi";

export const timeData = [
  { time: '7:00' }, { time: '7:30' }, { time: '8:00' }, { time: '8:30' }, { time: '9:00' }, { time: '9:30' }, { time: '10:00' }, { time: '10:30' }, { time: '11:00' },
  { time: '11:30' }, { time: '12:00' }, { time: '12:30' }, { time: '13:00' }, { time: '13:30' }, { time: '14:00' }, { time: '14:30' },
  { time: '15:00' }, { time: '15:30' }, { time: '16:00' }, { time: '16:30' }, { time: '17:00' }, { time: '17:30' }, { time: '18:00' }, { time: '18:30' },
  { time: '19:00' }, { time: '19:30' }, { time: '20:00' }, { time: '20:30' }, { time: '21:00' }, { time: '21:30' }, { time: '22:00' }
]

export const durationData = ['1', '1,5', '2'];
export const durationBookingData = ['1', '1,5', '2'];

export const gamePlayersOptions = [4, 5, 6, 7, 8];

export function isBooked(times: Array<LocalDateTime>, el: Time) {
  return times.find(time => dayjs(time).format('H:mm') === el.time);
};

export const setScoreValues = ['16', '24', '32'];

export const quickPlayersLimitValues = ['8', '12', '16', '20']

type ModifiedReservationSlot = {
  time?: string;
  reserved?: boolean;
  matchPlanned?: boolean;
  price?: Array<number>;
  tariffId?: Array<number>;
}

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export function timeToISOString(time: string) {
  return dayjs(time, 'H:mm').format('HH:mm:ss');
}
