export function gradesReducer(acc: any, val: string, index: number) {
  return {...acc, [index + 1]: {style: {marginTop: '15px', fontSize: '16px', color: 'white'}, label: val}}
}

export const styles = {
  track: {
    background: '#CDD78C',
    height: '10px',
  },
  rail: {
    height: '10px',
    background: '#3A5B78',
  },
  handle: {
    height: 20,
    width: 20,
    border: 0,
    marginTop: -11,
  },
  activeDotStyle: {
    background: '#CDD78C',
    border: 0,
    top: '-5px',
    width: '20px',
    height: '20px',
  },
  dotStyle: {
    background: '#3A5B78',
    border: 0,
    top: '-5px',
    width: '20px',
    height: '20px',
  },
}
