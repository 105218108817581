import { CreateTournamentInviteListParam, GameDateBookedParam, GameTimeBookedParam, GameView, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";

export function saveTournament(parameters: TournamentView) {
  return RestClient.tournamentApi.saveTournament({
    saveTournamentRequest: {
      parameters
    }
  })
}

export function gameSave(parameters: GameView) {
  return RestClient.gameApi.gameSave({
    gameSaveRequest: {
      parameters
    }
  })
}

export function gameBookedListTime(parameters: GameDateBookedParam) {
  return RestClient.gameApi.gameBookedListTime({
    gameBookedListTimeRequest: {
      parameters
    }
  });
}

export function gameTimeBooked(parameters: GameTimeBookedParam) {
  return RestClient.gameApi.gameTimeBooked({
    gameTimeBookedRequest: {
      parameters
    }
  });
}

export function createInviteListForTournament(parameters: CreateTournamentInviteListParam) {
  return RestClient.tournamentApi.createInviteListForTournament({
    createInviteListForTournamentRequest: {
      parameters
    }
  })
}
