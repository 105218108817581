/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TournamentInviteRejectParam
 */
export interface TournamentInviteRejectParam {
    /**
     * 
     * @type {string}
     * @memberof TournamentInviteRejectParam
     */
    tournamentUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentInviteRejectParam
     */
    playerUid?: string;
}

export function TournamentInviteRejectParamFromJSON(json: any): TournamentInviteRejectParam {
    return TournamentInviteRejectParamFromJSONTyped(json, false);
}

export function TournamentInviteRejectParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentInviteRejectParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentUid': !exists(json, 'tournamentUid') ? undefined : json['tournamentUid'],
        'playerUid': !exists(json, 'playerUid') ? undefined : json['playerUid'],
    };
}

export function TournamentInviteRejectParamToJSON(value?: TournamentInviteRejectParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentUid': value.tournamentUid,
        'playerUid': value.playerUid,
    };
}

