import styles from './LineupListEditableItem.module.css';
import { usePreview } from "react-dnd-preview";
import { LineupListEditableItemProps } from "./LineupListEditableItem.interfaces";
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import DnDIcon from '@/static/images/icons/DnDIcon';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import { Role } from '@/integration-api/server-rest-lundapadelApi';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';

const LineupListEditableItemPreview = () => {
  const preview = usePreview<LineupListEditableItemProps>();

  if (!preview.display) {
    return null
  }
  const { item: { player, team }, style } = preview;

  return ( 
    <div style={{...style, backgroundColor: !team || team === 'left' ?  'var(--green-lunda)' : 'var(--blue)', width: 'calc((100% - 40px) * 0.25)', zIndex: '10'}} className={styles['wrapper']}>
      <div className={styles['dnd-icon']}>
        <DnDIcon fill='var(--white)'/>
      </div>
      {player ?
        <ProfileCard
          player={player}
          noPreferedSide
          fullName
          bage={[
            {
              element: <RatingBage rating={player.displayRating ?? '1.0'} className={`${styles['preview']} ${styles[!team || team === 'left' ? 'green' : 'blue']}`.trim()}/>,
              position: 'top-right'
            },
            {
              element: player.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }
          ]}
        /> :
        null}
    </div>
  );
}
 
export default LineupListEditableItemPreview;
