import { InputHTMLAttributes, useCallback } from 'react';
import styles from './SelectTime.module.css'
import { isBooked, timeData } from './helper';
import { LocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import { isWithinDay } from '../../utils';
export interface Time {
  time: string
}
interface SelectTimeInterface<T> extends InputHTMLAttributes<HTMLInputElement> {
  formikValues?: T;
  bookedTimes?: Array<LocalDateTime>;

}

function SelectTime<T extends Record<string, any>>({ formikValues, bookedTimes, ...props }: SelectTimeInterface<T>) {
  const renderTimeData = useCallback(() => {
    const validTimeData = timeData.filter(time => formikValues?.duration ? isWithinDay(time.time, formikValues?.duration!) : time);
    return validTimeData.map((el: Time, index: number) => (
      <label 
        key={index}>
        <input className={styles.radioInput} type="radio" name='plannedDate.time' checked={formikValues?.plannedDate?.time === el.time ?? false} value={el.time} {...props} />
        <div 
          key={index} 
          className={`${styles.timeItem} ${bookedTimes && isBooked(bookedTimes, el) ? styles.booked : ''}`} >
          {el.time}
          </div>
      </label>
    ));
  }, [formikValues?.plannedDate?.time, formikValues?.duration, bookedTimes])

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Выберите время</div>
      <div className={styles.timeContainer}>
        {renderTimeData()}
      </div>
    </div>
  );
}

export default SelectTime;
