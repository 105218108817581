/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface PlayerScheduleResult
 */
export interface PlayerScheduleResult {
    /**
     * 
     * @type {Array<LocalDateTime>}
     * @memberof PlayerScheduleResult
     */
    times?: Array<LocalDateTime>;
}

export function PlayerScheduleResultFromJSON(json: any): PlayerScheduleResult {
    return PlayerScheduleResultFromJSONTyped(json, false);
}

export function PlayerScheduleResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerScheduleResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'times': !exists(json, 'times') ? undefined : ((json['times'] as Array<any>).map(LocalDateTimeFromJSON)),
    };
}

export function PlayerScheduleResultToJSON(value?: PlayerScheduleResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'times': value.times === undefined ? undefined : ((value.times as Array<any>).map(LocalDateTimeToJSON)),
    };
}

