/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtendGameLockInfo,
    ExtendGameLockInfoFromJSON,
    ExtendGameLockInfoFromJSONTyped,
    ExtendGameLockInfoToJSON,
} from './ExtendGameLockInfo';

/**
 * 
 * @export
 * @interface ExtendGameLockRequest
 */
export interface ExtendGameLockRequest {
    /**
     * 
     * @type {ExtendGameLockInfo}
     * @memberof ExtendGameLockRequest
     */
    parameters?: ExtendGameLockInfo;
}

export function ExtendGameLockRequestFromJSON(json: any): ExtendGameLockRequest {
    return ExtendGameLockRequestFromJSONTyped(json, false);
}

export function ExtendGameLockRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendGameLockRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : ExtendGameLockInfoFromJSON(json['parameters']),
    };
}

export function ExtendGameLockRequestToJSON(value?: ExtendGameLockRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': ExtendGameLockInfoToJSON(value.parameters),
    };
}

