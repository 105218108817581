import formatter from '@/utils/utils';
import { DATE_FORMAT_WITH_DOTS, DATE_FORMAT_WITH_DOTS_SHORT, TIME_FORMAT_HH_MM } from '@/utils/constants';
import { LocalDate, LocalDateTime } from '@/integration-api/server-rest-lundapadelApi';

export const getNotificationRelativeTime = (value: LocalDate | LocalDateTime) => {
  return formatter.relativeDateShort(value);
};

export const getNotificationTime = (value: LocalDate | LocalDateTime, time?: boolean) => {
  const date = new Date(value);

  return formatter.formatDate(date, `${DATE_FORMAT_WITH_DOTS_SHORT} | ${TIME_FORMAT_HH_MM}`);
}