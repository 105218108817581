/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface ChatInfo
 */
export interface ChatInfo {
    /**
     * 
     * @type {string}
     * @memberof ChatInfo
     */
    chatUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatInfo
     */
    opened?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatInfo
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatInfo
     */
    unreadMessages?: boolean;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof ChatInfo
     */
    lastMessageRead?: LocalDateTime;
    /**
     * 
     * @type {boolean}
     * @memberof ChatInfo
     */
    silentMode?: boolean;
}

export function ChatInfoFromJSON(json: any): ChatInfo {
    return ChatInfoFromJSONTyped(json, false);
}

export function ChatInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatUid': !exists(json, 'chatUid') ? undefined : json['chatUid'],
        'opened': !exists(json, 'opened') ? undefined : json['opened'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'unreadMessages': !exists(json, 'unreadMessages') ? undefined : json['unreadMessages'],
        'lastMessageRead': !exists(json, 'lastMessageRead') ? undefined : LocalDateTimeFromJSON(json['lastMessageRead']),
        'silentMode': !exists(json, 'silentMode') ? undefined : json['silentMode'],
    };
}

export function ChatInfoToJSON(value?: ChatInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatUid': value.chatUid,
        'opened': value.opened,
        'active': value.active,
        'unreadMessages': value.unreadMessages,
        'lastMessageRead': LocalDateTimeToJSON(value.lastMessageRead),
        'silentMode': value.silentMode,
    };
}

