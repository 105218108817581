import Auth from '@/components/Auth/Auth'
import App from '@/components/App/App'

export default function BaseRoutingPath() {
  return (
    <Auth>
      <App />
    </Auth>
  )
}
