import { AdvertisementPlaceType } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';
import { createImgSrc } from '@/utils/utils';
import { useEffect, useState } from 'react';

type adsBanner = {
  src: string;
  link?: string;
  visible?: boolean;
}

export function useAdsSrcLink(type: AdvertisementPlaceType) {
  const [adsSrcLink, setAdsSrcLink] = useState<adsBanner>();

  useEffect(() => {
    RestClient.mainApi.loadAdvertisement({
      type
    })
    .then(({ result }) => {
      if (result?.imageOwnerUid, result?.imageUid) {
        const adsSrc = createImgSrc.advertisement(result?.imageOwnerUid, result?.imageUid);
        setAdsSrcLink({
          src: adsSrc,
          link: result?.url,
          visible: result.enabled,
        });
      }
    })
    .catch((e) => console.log(e))
  }, [])

  return adsSrcLink;
}
