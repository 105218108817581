import { useContext, useRef, useState } from 'react';
import SendBtn from '../sendBtn/SendBtn';
import styles from './MessageControl.module.css';
import { sendChatMessage } from './service';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { IGamePageContext } from '@/pages/application/game/GamePage.interfaces';
import GameChatPageContext from '@/pages/application/game/GameChatPage/GameChatPageContext';

interface MessageControlProps {

}

const MessageControl: React.FC<MessageControlProps> = (props) => {
  const { getChatMessages } = useContext(GameChatPageContext);
  const { gameChats } = useOutletContext<IGamePageContext>();
  const [ searchParams ] = useSearchParams();
  const controlDivElement = useRef<HTMLDivElement | null>(null);

  const [ messageContent, setMessageContent ] = useState('');

  const chatUid = searchParams.get('uid');

  const handleControlInput: React.ChangeEventHandler<HTMLDivElement> = (e) => {
    if (messageContent.length < 150) setMessageContent(e.target.innerText);
  }

  const handleSendBtnClick: React.MouseEventHandler = () => {
    controlDivElement?.current?.focus?.();
    const chatInfo = gameChats.find((chat) => chat.chatUid === chatUid)
    if (chatInfo && messageContent.length > 0) {
      sendChatMessage(chatInfo, messageContent)
        .then(() => {
          setMessageContent('');
          if (controlDivElement.current) {
            controlDivElement.current.innerText = '';
            getChatMessages?.();
          }
        })
        .catch((e) => console.log(e));
    };
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.control}`}
        contentEditable
        onInput={handleControlInput}
        ref={controlDivElement}
      />
      {!!messageContent || <span onClick={() => controlDivElement.current?.focus()} className={styles.placeholder}>Сообщение</span>}

      <div className={styles['send-btn']} onClick={handleSendBtnClick} style={{filter: `grayscale(${messageContent ? '0': '100'}%)`}}>
        <SendBtn />
      </div>
    </div>
  );
}
 


export default MessageControl;
