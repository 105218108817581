import React from 'react';
import styles from './CheckboxSquare.module.css'

function CheckboxSquare(props: React.AllHTMLAttributes<HTMLInputElement>) {
  return (
    <input {...props} type='checkbox' className={[styles['checkbox'], props.className].join(' ')} />
  );
}

export default CheckboxSquare;
