import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import styles from './CreatePage.module.css';
import { Outlet, useNavigate } from "react-router-dom";

const CreatePage = () => {

  return ( 
    <BasePageWrapper>
      <Outlet/>
    </BasePageWrapper>
  );
}
 
export default CreatePage;
