type PencilIconProps = {
  fill?: string
}

const PencilIcon = ({ fill }: PencilIconProps) => {
  return ( 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0775 4.24399C15.2338 4.08776 15.4457 4 15.6667 4C15.8876 4 16.0996 4.08776 16.2558 4.24399L19.5892 7.57732C19.7454 7.7336 19.8332 7.94552 19.8332 8.16649C19.8332 8.38746 19.7454 8.59938 19.5892 8.75566L8.75583 19.589C8.59959 19.7453 8.38766 19.8331 8.16667 19.8332H4.83333C4.61232 19.8332 4.40036 19.7454 4.24408 19.5891C4.0878 19.4328 4 19.2208 4 18.9998V15.6665C4.00005 15.4455 4.08788 15.2336 4.24417 15.0773L12.5775 6.74399L15.0775 4.24399ZM13.1667 8.51149L5.66667 16.0115V18.1665H7.82167L15.3217 10.6665L13.1667 8.51149ZM16.5 9.48816L17.8217 8.16649L15.6667 6.01149L14.345 7.33316L16.5 9.48816Z" fill={fill ?? "white"}/>
    </svg>
  );
}
 
export default PencilIcon;
