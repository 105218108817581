/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaptchaDataLoadParam
 */
export interface CaptchaDataLoadParam {
    /**
     * 
     * @type {string}
     * @memberof CaptchaDataLoadParam
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CaptchaDataLoadParam
     */
    phone?: string;
}

export function CaptchaDataLoadParamFromJSON(json: any): CaptchaDataLoadParam {
    return CaptchaDataLoadParamFromJSONTyped(json, false);
}

export function CaptchaDataLoadParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaptchaDataLoadParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function CaptchaDataLoadParamToJSON(value?: CaptchaDataLoadParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': value.countryCode,
        'phone': value.phone,
    };
}

