import { ChatMessageInfo } from '@/integration-api/server-rest-lundapadelApi';
import CurrentUserMessage from '../currentUserMessage/CurrentUserMessage';
import UserChatMessage from '../userChatMessage/UserChatMessage';
import styles from './Messages.module.css';
import { useContext, useEffect, useRef } from 'react';
import GameChatPageContext from '@/pages/application/game/GameChatPage/GameChatPageContext';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';

interface MessagesProps {
  
}

dayjs().locale(ru); 

const Messages: React.FC<MessagesProps> = () => {
  const { chatMessages } = useContext(GameChatPageContext);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      const containerElement = messagesContainerRef.current;
      containerElement.scrollTop = containerElement.scrollHeight - containerElement.clientHeight;
    }
  }, [chatMessages]);
  
  return (
    <div className={styles.container} ref={messagesContainerRef}>
      {chatMessages?.map((msg, ind) => {
        const dateSameAsPrev = ind > 0 && dayjs(msg.created).isSame(chatMessages[ind - 1].created, 'day'); 
        const isToday = dayjs(new Date()).isSame(msg.created, 'day');

        return (
          <>
            {dateSameAsPrev || <p className={styles.messageDate}>{isToday ? 'Сегодня' : dayjs(msg.created).format('D MMMM')}</p>}
            {msg.me
              ? <CurrentUserMessage
                  key={`${msg.created?.toString()}${ind}`}
                  message={msg}
                />
              : <UserChatMessage
                  key={`${msg.created?.toString()}${ind}`}
                  message={msg}
                />
            }
          </>
        )
      })
      }
    </div>
  );
}

export default Messages;
