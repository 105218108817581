import { InputPhoneValue } from '@/components/InputPhone/InputPhone';

export function checkPhoneValidation(number?: {countryCode?: string, phone?: string}): asserts number is InputPhoneValue {
  if (number) {
    const {countryCode, phone} = number;
    
    if (!countryCode) {
      throw new Error('В номере телефона отсутствует код страны');
    }
  
    if (phone) {
      if (!(/\d{7,10}/.test(phone))) {
        throw new Error('Проверьте номер телефона');
      }
    } else {
      throw new Error('Не введен номер телефона');
    }
  } else {
    throw new Error('Не введен номер телефона');
  }
}
