/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClubCitiesLoadListResult
 */
export interface ClubCitiesLoadListResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClubCitiesLoadListResult
     */
    cities?: Array<string>;
}

export function ClubCitiesLoadListResultFromJSON(json: any): ClubCitiesLoadListResult {
    return ClubCitiesLoadListResultFromJSONTyped(json, false);
}

export function ClubCitiesLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClubCitiesLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cities': !exists(json, 'cities') ? undefined : json['cities'],
    };
}

export function ClubCitiesLoadListResultToJSON(value?: ClubCitiesLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cities': value.cities,
    };
}

