import styles from '../GamePage.module.css';
import Header from "@/components/Header/Header";
import { useState } from "react";
import CompletedGameContent from '../GameStatusContent/CompletedGameContent';
import { CompletedGameSubPage } from '../GameStatusContent/GameStatusContent.interfaces';
import bgImage from '@/static/images/match-page-background.jpeg';
import PageBackground from '@/components/PageBackground/PageBackground';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { IGamePageContext } from '../GamePage.interfaces';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import { gameModifiableStatuses } from '../utils';
import { CalculatedGameStatus } from '@/integration-api/server-rest-lundapadelApi';

const CompletedGamePage = () => {
  const { game, goBackHandler } = useOutletContext<IGamePageContext>();
  const navigate = useNavigate();
  
  const [subPage, setSubPage] = useState<CompletedGameSubPage>('game');
  const [activeLineup, setActiveLineup] = useState<string>();

  if(game?.status && gameModifiableStatuses.includes(game?.status)) {
    if(game.status === CalculatedGameStatus.CANCELED || game.status === CalculatedGameStatus.SCORE_UNDEFINED) {
      return <Navigate to={`/my-games?tab=completed`} replace={true}/>
    } else {
      return <Navigate to={`/game/${game?.identity?.uid}/planned`} replace={true} />
    }
  }

  const handleBackBtnClick = () => {
    const lastPaths = sessionStorage.getItem('lastPaths'); 
    
    if (lastPaths && (JSON.parse(lastPaths) as Array<string>)[0].includes('chat')) {
      return () => { navigate('/my-games?tab=completed') }
    }
    return subPage === 'game' ? goBackHandler() : () => { setActiveLineup(undefined); setSubPage('game'); }
  }

  return (  
    <BasePageWrapper>
      <Header
        className={styles['header']}
        handleClick={handleBackBtnClick()}
      >
        {subPage === 'game' ? 'Результаты игры' : 'Детали матча'}
      </Header>
      {subPage === 'lineup' ? <PageBackground imgSrc={bgImage}/> : null}
      <CompletedGameContent 
        subPage={subPage} 
        activeLineup={activeLineup}
        setSubPage={setSubPage}
        setActiveLineup={setActiveLineup}  
      />
    </BasePageWrapper>
  );
}
 
export default CompletedGamePage;
