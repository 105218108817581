
import { v4 as uuidv4 } from 'uuid';
import { Middleware, RequestContext, ResponseContext } from '../integration-api/server-rest-lundapadelApi';


class RestMiddleware implements Middleware {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  startTime = 0;

  pre = (context: RequestContext) => {
    const abortController: AbortController = new AbortController();
    const requestId = uuidv4();

    this.startTime = performance.now();
    context.init.headers = {
      ...(context.init.headers || {}),
      ...{ 'X-Request-ID': requestId },
    };
    context.init.signal = abortController.signal;
    return Promise.resolve(context);
  };

  post = (context: ResponseContext) => {
    const { response } = context || {};
    return Promise.resolve(response);
  };

 
}

export default RestMiddleware;
