/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GameCondition
 */
export interface GameCondition {
    /**
     * 
     * @type {boolean}
     * @memberof GameCondition
     */
    locked?: boolean;
}

export function GameConditionFromJSON(json: any): GameCondition {
    return GameConditionFromJSONTyped(json, false);
}

export function GameConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locked': !exists(json, 'locked') ? undefined : json['locked'],
    };
}

export function GameConditionToJSON(value?: GameCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locked': value.locked,
    };
}

