import { useDrag, useDrop } from 'react-dnd';
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import { ExtedndedLineupEditableItemProps, LineupEditableItemDropProps, LineupListEditableItemProps } from './LineupListEditableItem.interfaces';
import styles from './LineupListEditableItem.module.css';
import { useContext } from 'react';
import { GameLineupsContext } from '../../GameResults/utils';
import DnDIcon from '@/static/images/icons/DnDIcon';
import { GameLineupView, Role } from '@/integration-api/server-rest-lundapadelApi';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

export const LineupListEditableItem = ({ team, player, lineupIndex, playerProp }: LineupListEditableItemProps) => {
  const { editableLineups, handleEditableLineups } = useContext(GameLineupsContext);

  const [dragCollect, dragRef] = useDrag<unknown, ExtedndedLineupEditableItemProps, { isDragging: boolean; }>({
    type: 'lineupItem',
    item: { team, player, lineupIndex, playerProp, type: 'lineupItem', lineup: editableLineups[lineupIndex] },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    }),
    canDrag: !!player
  }, [team, player, lineupIndex, playerProp, editableLineups, handleEditableLineups]);

  const [dropCollect, dropRef] = useDrop<ExtedndedLineupEditableItemProps, unknown, LineupEditableItemDropProps>({
    accept: ['lineupItem', 'playerPoolItem'],
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
    canDrop: (item) => {
      const isPresent = !!Object.values(editableLineups[lineupIndex]).find((val: any) => val && !Array.isArray(val) && typeof val === 'object' && (val?.uid === item.player?.uid)) && item.type === 'playerPoolItem';
      if(isPresent) {
        PopupMessage.open?.('Игрок уже в составе!');
        return false;
      }
      const isValidRow = typeof item.lineupIndex !== 'undefined' ? lineupIndex === item.lineupIndex : true;
      if(!isValidRow) {
        PopupMessage.open?.('Невозможно перенести игрока из одного состава в другой!');
        return false;
      }
      return true;
    },
    drop: (item) => {
      if(item.type === 'lineupItem') {
        const newPlayers: GameLineupView = { ...editableLineups[lineupIndex], [item.playerProp]: player, [playerProp]: item.player };
        handleEditableLineups('edit', { index: lineupIndex, players: newPlayers });
      } else {
        const newPlayers: GameLineupView = { ...editableLineups[lineupIndex], [playerProp]: item.player };
        handleEditableLineups('edit', { index: lineupIndex, players: newPlayers });
      }
    }
  }, [player, team, lineupIndex, playerProp, editableLineups, handleEditableLineups]);

  return (
    <div style={{ flex: '1', background: dropCollect.isOver ? 'var(--gray-blue)' : undefined }} ref={dropRef}>
      <div className={styles['wrapper']} ref={dragRef}>
        <div className={styles['dnd-icon']}>
          <DnDIcon />
        </div>
        {player ?
          <ProfileCard
            player={player}
            style={{ opacity: dragCollect.isDragging ? '0' : '1' }}
            team={team}
            noPreferedSide
            fullName
            bage={[
              {
                element: <RatingBage rating={player.displayRating ?? '1.0'} style={{ backgroundColor: team === 'left' ? 'var(--green-lunda)' : 'var(--blue)'}}/>,
                position: 'top-right'
              },
              {
                element: player.roles?.includes(Role.COACH) ? <CoachWhistleFilled /> : <></>,
                position: 'top-left'
              }
            ]} /> : null}
        <span className={styles['placeholder']} style={{ opacity: !player || dragCollect.isDragging ? '1' : '0' }}>
          Переместите игрока
        </span>
      </div>
    </div>
  );
}
 
export default LineupListEditableItem;
