/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameLineupView,
    GameLineupViewFromJSON,
    GameLineupViewFromJSONTyped,
    GameLineupViewToJSON,
} from './GameLineupView';

/**
 * 
 * @export
 * @interface LineupLoadListResult
 */
export interface LineupLoadListResult {
    /**
     * 
     * @type {string}
     * @memberof LineupLoadListResult
     */
    gameUid?: string;
    /**
     * 
     * @type {Array<GameLineupView>}
     * @memberof LineupLoadListResult
     */
    lineups?: Array<GameLineupView>;
}

export function LineupLoadListResultFromJSON(json: any): LineupLoadListResult {
    return LineupLoadListResultFromJSONTyped(json, false);
}

export function LineupLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineupLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'lineups': !exists(json, 'lineups') ? undefined : ((json['lineups'] as Array<any>).map(GameLineupViewFromJSON)),
    };
}

export function LineupLoadListResultToJSON(value?: LineupLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'lineups': value.lineups === undefined ? undefined : ((value.lineups as Array<any>).map(GameLineupViewToJSON)),
    };
}

