import { ChatInfo, ChatSilentModeParam } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export function fetchChatMessages(chat: ChatInfo) {
  return RestClient.mainApi.chatMessageLoadList({
    chatUid: chat.chatUid!,
    offset: 0,
    limit: 1000,
  })
}

export function setChatSilentMode(chatUid: ChatSilentModeParam['chatUid'], silent: ChatSilentModeParam['silent']) {
  return RestClient.mainApi.chatSilentMode({
    chatSilentModeRequest: {
      parameters: {
        chatUid,
        silent,
      }
    }
  })
}
