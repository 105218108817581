import RacketIcon from "@/static/images/icons/RacketIcon";
import styles from './RacketBage.module.css';

const RacketBage = () => {
  return (  
    <span className={styles['bage']}>
      <RacketIcon fill="var(--white)"/>
    </span>
  );
}
 
export default RacketBage;
