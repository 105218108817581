import { ClubView, Gender, PlayerProfileInfo, TournamentKind } from "@/integration-api/server-rest-lundapadelApi";

export enum AdditionalGender {
  MULTIPLE = "MULTIPLE"
}

export type TournamentFormValues = {
  plannedDate: {
    date: Date;
    time?: string;
  }
  club?: ClubView;
  kind?: TournamentKind; 
  minGrade: number;
  maxGrade: number;
  title: string;
  price: string;
  playersLimit: string;
  description: string;
  gender: Gender | AdditionalGender;
  maxScore?: string;
  reverseSortFirstRound: boolean;
  privateTournament: boolean;
  tournamentInvitations?: Array<PlayerProfileInfo>;
}
