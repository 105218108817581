/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameExitParam,
    GameExitParamFromJSON,
    GameExitParamFromJSONTyped,
    GameExitParamToJSON,
} from './GameExitParam';

/**
 * 
 * @export
 * @interface ExitGameRequest
 */
export interface ExitGameRequest {
    /**
     * 
     * @type {GameExitParam}
     * @memberof ExitGameRequest
     */
    parameters?: GameExitParam;
}

export function ExitGameRequestFromJSON(json: any): ExitGameRequest {
    return ExitGameRequestFromJSONTyped(json, false);
}

export function ExitGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExitGameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : GameExitParamFromJSON(json['parameters']),
    };
}

export function ExitGameRequestToJSON(value?: ExitGameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': GameExitParamToJSON(value.parameters),
    };
}

