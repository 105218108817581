import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import { getCurrentProfile } from './service';
import { RootState } from '@/models/store';
import { updateCurrentProfileThunk } from '@/models/auth/auth';
import FullscreenLoader from '../FullscreenLoader/FullscreenLoader';
import DBManager from '@/utils/DBManager/DBManager';

function Auth(props: {children: React.ReactElement}) {
  const currentUser = useAppSelector((state: RootState) => state.auth.currentProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cookieHasToken = Boolean(document.cookie.split(';').filter(cookie => cookie.includes('auth-token')).length);

  useEffect(() => {
    if (cookieHasToken) {
      getCurrentProfile()
        .then(({ result }) => {
          dispatch(updateCurrentProfileThunk(result || {}));
          DBManager.create('currentUser',result, 'currentUser')
        })
        .catch ((error) => {
          navigate('/auth');
          console.error(error);
        })
    }
  }, [])


  return cookieHasToken
    ? currentUser ? props.children : <FullscreenLoader loading /> // проверяем загружен ли профиль пользователя
    : <Navigate to="/auth" />
}

export default Auth;
