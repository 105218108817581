import styles from './NotificationStatusWrapper.module.css';
import { FC } from 'react';
import {
  NotificationsStatus,
  NotificationStatusWrapperProps,
} from './NotificationStatusWrapper.interfaces';


const NotificationStatusWrapper: FC<NotificationStatusWrapperProps> = (
  props
): JSX.Element => {
  const { children } = props;

  return (
    <div className={styles['wrapper']}>
      {children}
    </div>
  );
};

export default NotificationStatusWrapper;
