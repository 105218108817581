import { HTMLAttributes } from 'react';
import styles from './HorizontalClubListItem.module.css';

interface HorizontalClubListItemProps extends HTMLAttributes<HTMLDivElement> {
  clubName: string;
  clubCity?: string;
}

const HorizontalClubListItem = ({ clubCity, clubName, ...props }: HorizontalClubListItemProps) => {
  return (
    <div {...props} className={styles['item-wrapper']} style={{ minWidth: clubName.length > 15 ? `${124 + (clubName.length - 15) * 6.9}px`: '124px'}}>
      <span className={styles['item-name']}>{clubName}</span>
      <span className={styles['item-city']}>{clubCity}</span>
    </div>
  );
}
 
export default HorizontalClubListItem;
