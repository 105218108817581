import RestClient from "@/integration/RestClient"

export const newsItemLoad = (uid: string) => {
  return RestClient.mainApi.newsItemLoad({
    newsItemLoadRequest: {
      parameters: {
        uid
      }
    }
  });
}
