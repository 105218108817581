import { PlayerRatingProps } from './PlayerRating.interface';
import styles from './PlayerRating.module.css';
import ChangeVector from './svg/ChangeVector';

export default function PlayerRating({ player, displayGrade = true, displayRating = true }: PlayerRatingProps) {
  return (
    <div className={styles.rating}>
      {Number(player.ratingChangeForPeriod) > 0 && (
        <span className={styles.positive}><ChangeVector vector="up" /> {`${player.displayRatingChangeForPeriod}`}</span>
      )}
      {Number(player.ratingChangeForPeriod) < 0 && (
        <span className={styles.negative}><ChangeVector vector="down" /> {`${player.displayRatingChangeForPeriod}`}</span>
      )}

      <span>
        {`
          ${displayGrade ? player.displayGrade : ''}
          ${displayGrade && displayRating ? ' | ' : ''}
          ${displayRating ? player.displayRating : ''}
        `}
      </span>
    </div>
  )
}
