interface Action {
  read: (store:any, uid:any) => Promise<any>,
  create: (store:any, data: any, uid:any) => Promise<boolean>,
}

// ВАЖНО!!! Если в STORE_OBJECTS добавляется новый элемент, следует увеличить версию базы на 1
const STORE_OBJECTS = ['profile', 'matches', 'games', 'tournaments', 'currentUser', 'news', 'clubs', 'tourgames'] as const
const DB = 'cached'

class DBManager implements Action {
  private db: IDBDatabase | null = null;
  constructor(private room: string) {
    try {
      let openRequest = window.indexedDB.open(room, 2); // если изменяется STORE_OBJECTS, увеличьте версию базы на 1
  
      openRequest.onsuccess = () => {
        this.db = openRequest.result;
      };
      openRequest.onerror = () => {
        console.log('db open fail');
      }
  
      openRequest.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        STORE_OBJECTS.map(store => !db.objectStoreNames.contains(store) && db.createObjectStore(store))
      }
    } catch(err) {
      console.log(err)
    }
  }

  public read =  (store: typeof STORE_OBJECTS[number], key: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(null);
      }
      const transaction = this.db.transaction(store);
      const objectStore = transaction.objectStore(store);
      const request = key && objectStore.get(key)
      console.log(request);
      if(request){
        request.onerror = () => {
          console.log('Transaction failed');
          return reject(null);
        };
  
        request.onsuccess = () => {
          if (request.result) {
            console.log('Transaction succeed')
            return resolve(request.result);
          } else {
            console.log('No data record');
            return resolve(null);
          }
        };
      }
    })
  }


  public create = (store: typeof STORE_OBJECTS[number], data: any, key: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject(false);
      }
      const tx = this.db.transaction(store, 'readwrite')
      const objectStore = tx.objectStore(store);

      const request = objectStore.put(data, key);

      request.onsuccess = () => {
        console.log('The data has been added successfully');
        return resolve(true);
      };

      request.onerror = () => {
        console.log('The data has been added failed', key);
        return reject(false);
      }
    })
  }
}

export default new DBManager(DB);

