/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvitationStatus {
    SENT = 'SENT',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED'
}

export function InvitationStatusFromJSON(json: any): InvitationStatus {
    return InvitationStatusFromJSONTyped(json, false);
}

export function InvitationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationStatus {
    return json as InvitationStatus;
}

export function InvitationStatusToJSON(value?: InvitationStatus | null): any {
    return value as any;
}

