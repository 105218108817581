import { toLocalDateTime } from "@/integration-api/server-rest-lundapadelApi";
import { GameFormValues } from "./GameCreate/GameCreatePage.interfaces";
import { useFormik } from "formik";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

export const loopThourghErrors = (errors: Record<string, any>) => {
  for (let key in errors) {
    if(typeof errors[key] === 'object') {
      loopThourghErrors(errors[key]);
    } else {
      PopupMessage.open(errors[key]);
    }
  }
};

export const getPlannedDate = (formik: ReturnType<typeof useFormik<any>>) => {
  const date = formik.getFieldProps('plannedDate.date').value;
  const time = formik.getFieldProps('plannedDate.time').value;

  return toLocalDateTime(date, time && time.length === 4 ? `0${time}` : time);
}

export const gameInitialValues: GameFormValues = {
  plannedDate: {
    date: new Date(),
  },
  minGrade: 1,
  maxGrade: 2,
  description: '',
  duration: '2',
  courtBooked: false,
  courtNumber: '',
  privateGame: false,
  ranking: true,
  bookingCourt: '',
  everyoneCanInvite: false,
  isCoachParticipating: true,
  invitations: [],
  members: [],
  everyoneCanSetScore: false,
  playersLimit: 4
}

export function timeStringToNumber(time: string) {
  const hoursMinutes = time.split(':');
  const hours = Number(hoursMinutes[0]);
  const minutes = Number(hoursMinutes[1]) ? 0.5 : 0;
  return hours + minutes;
}

export const isWithinDay = (time: string, duration: string) => {
  const parsedTime = timeStringToNumber(time) + Number(duration.replace(',', '.'));
  return parsedTime <= 24;
}
