/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDate,
    LocalDateFromJSON,
    LocalDateFromJSONTyped,
    LocalDateToJSON,
} from './LocalDate';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface AvailableBookingTimeLoadListParameters
 */
export interface AvailableBookingTimeLoadListParameters {
    /**
     * 
     * @type {Reference}
     * @memberof AvailableBookingTimeLoadListParameters
     */
    club?: Reference;
    /**
     * 
     * @type {LocalDate}
     * @memberof AvailableBookingTimeLoadListParameters
     */
    date?: LocalDate;
    /**
     * 
     * @type {number}
     * @memberof AvailableBookingTimeLoadListParameters
     */
    duration?: number;
}

export function AvailableBookingTimeLoadListParametersFromJSON(json: any): AvailableBookingTimeLoadListParameters {
    return AvailableBookingTimeLoadListParametersFromJSONTyped(json, false);
}

export function AvailableBookingTimeLoadListParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableBookingTimeLoadListParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'date': !exists(json, 'date') ? undefined : LocalDateFromJSON(json['date']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function AvailableBookingTimeLoadListParametersToJSON(value?: AvailableBookingTimeLoadListParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'club': ReferenceToJSON(value.club),
        'date': LocalDateToJSON(value.date),
        'duration': value.duration,
    };
}

