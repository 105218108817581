import { v4 as uuidv4 } from 'uuid';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const GLOBAL_ID = `GLOBAL_ID_${uuidv4()}`;

interface SetLoaderByIdPayload {
  id?: string;
  loading: boolean;
}

// Define a type for the slice state
interface LoaderState {
  loaders: { [key: string]: boolean };
}

const initialState: LoaderState = {
  loaders: {},
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<SetLoaderByIdPayload>) => {
      const { id = GLOBAL_ID, loading } = action.payload;
      state.loaders[id] = loading === true;
    },
  },
});

export const { setLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
