import { HTMLAttributes } from 'react';
import styles from './RatingBage.module.css';

interface RatingBageProps extends HTMLAttributes<HTMLSpanElement> {
  rating: string;
}

export default function RatingBage({ rating, className, ...props }: RatingBageProps) {
  return (
    <span {...props} className={`${styles.badge} ${className}`}>{rating.slice(0, 3)}</span>
  )
}
