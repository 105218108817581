import InnerPadding from '@/components/InnerPadding/InnerPadding';
import styles from './TournamentParticipants.module.css';
import Headling from '@/components/Headling/Headling';
import { PlayerProfileInfo, Role, TournamentMemberView } from '@/integration-api/server-rest-lundapadelApi';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/hooks/hooks';
import ProfileCard from '@/components/ProfileCard/ProfileCard';
import TrashBage from '@/components/Badge/TrashBage/TrashBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import RatingBage from '@/components/Badge/RatingBage/RatingBage';

type TournamentParticipantsProps = {
  queue?: boolean;
  owner?: boolean
  participants: Array<TournamentMemberView>;
  kickPlayer?: (player?: PlayerProfileInfo) => void;
  isOver?: boolean;
  editMode?: boolean;
  setEditMode?: (state: boolean) => void;
  results?: boolean;
}

const TournamentParticipants = ({ queue, owner, participants, kickPlayer, isOver, editMode, setEditMode, results }: TournamentParticipantsProps) => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();

  const handleKick = (player?: PlayerProfileInfo) => {
    kickPlayer?.(player);
  }

  return ( 
    <div className={styles['wrapper']}>
      <InnerPadding className={styles['header']}>
        {results ?
          <div className={styles['participants-count']}>
            {participants?.length} игроков
          </div> :
          <Headling appearence='medium-normal' className={styles['headling']}>
            {!queue ? 'Участники турнира' : 'Лист ожидания'}
          </Headling>
        }
        {owner && !isOver && !editMode ?
          <span 
            className={styles['remove']}
            onClick={() => setEditMode?.(true)}
          >
            Изменить состав
          </span> : null
        }
      </InnerPadding>
      <div className={styles['participants']}>
        {participants.map(p => (
          editMode ? 
            <div className={styles['participant']} key={p?.player?.uid}>
              <ProfileCard
                player={p.player}
                fullName
                bage={[{
                  element: <TrashBage/>,
                  position: 'top-right'
                }, {
                  element: p.player?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
                onClick={() => handleKick(p.player)}
              />
            </div> :
            <div className={styles['participant']} key={p?.player?.uid}>
              <ProfileCard
                player={p.player}
                onClick={(current.identity?.uid === p?.player?.uid) || !p.player?.active ? undefined : () => navigate(`/profile/${p?.player?.uid}`)}
                fullName
                bage={[{
                  element: <RatingBage rating={p.player?.displayRating ?? ''}/>,
                  position: 'top-right'
                }, {
                  element: p.player?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
                  position: 'top-left'
                }]}
              />
              {results ? <span className={styles['score']}>{p.score}</span> : null}
            </div>
        ))}
      </div>
    </div>
  );
}
 
export default TournamentParticipants;
