import { ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './PlayerRating.module.css';
import { useAppSelector } from '@/hooks/hooks';
import DBManager from '@/utils/DBManager/DBManager';
import { useOutletContext, useParams } from 'react-router-dom';

const PlayerRating = () => {
  const { id } = useParams<{ id: string }>();
  const [profile] = useOutletContext<any>()
  const currentRating =  parseFloat(profile?.displayRating ?? '1')
  const { grades } = useAppSelector(state => ({
    grades: Object.keys(state.auth.currentProfile.grades ?? {}).length
  }));

  const [currentRatingData, setCurrentRatingData] = useState<any>(null)
  const [gradesData, setGradesData] = useState<any>(null)
  const [currentUserData, setCurrentUserData] = useState<any>(null)

  useEffect(() => {
    if (navigator.onLine) {
      setCurrentRatingData(currentRating)
      setGradesData(grades)
    } else {
      !currentUserData && DBManager.read('currentUser', 'currentUser').then(currentUser => {
        setGradesData(Object.keys(currentUser.grades ?? {}).length)
        setCurrentUserData(currentUser)
      }).catch((err) => console.log(err))

      !currentRatingData && DBManager.read('profile', id === 'me' ? (currentUserData?.identity?.uid) : id).then(profileInfo =>
        setCurrentRatingData(parseFloat(profileInfo?.displayRating ?? '1'))).catch((err) => console.log(err))
    }
  }, [currentRating, grades, currentUserData])

  const renderProgressBars = useMemo(() => {
    const bars: Array<ReactNode> = [];
    for(let i = 0; i < gradesData; i++) {

      const barsStyle: React.CSSProperties = {
        width: currentRatingData - i > 1 ? '100%' : `calc(100% * ${(currentRatingData - i)})`,
        height: '100%',
        backgroundColor: 'var(--green-yellow)',
        borderRadius: 32,
      }
      bars.push(
        <div className={styles['bar']} key={i}>
          <div style={barsStyle}/>
        </div>
      )
    }
    return bars;
  }, [currentRatingData, gradesData]);

  return ( 
    <div className={styles['wrapper']}>
      {renderProgressBars}
    </div>
  );
}
 
export default PlayerRating;
