import MobileCodeInput from './MobileCodeInput/MobileCodeInput';
import NumberKeyboard from './NumberKeyboard/NumberKeyboard';
import styles from './KeyboardCodeInput.module.css';
import { useCallback, useEffect, useState } from 'react';
import { InitialCodeinputType } from './helpers/types';
import { initialCodeinput } from './helpers/constants';
import CountdownTimer from './CountdownTimer';

interface KeyboardCodeInputProps {
  onChange?: (newValue: InitialCodeinputType) => void;
  resendCode?: () => void;
  signIn?: () => void;
  changePhoneNumberHandler?: () => void;
}

const KeyboardCodeInput: React.FC<KeyboardCodeInputProps> = (props) => {
  const { onChange, resendCode, signIn, changePhoneNumberHandler } = props;

  const [codeInput, setCodeInput] = useState<InitialCodeinputType>();

  const handleKeyboardClick = useCallback(
    (value: string) => {
      if (codeInput) {
        const changedCodeInput = { ...codeInput };
        const lastIndex = codeInput.currentFocus === 3;
        const firstindex = codeInput.currentFocus === 0;

        if (value === 'del') {
          changedCodeInput.currentFocus = firstindex
            ? 0
            : codeInput.value[codeInput.currentFocus]
              ? codeInput.currentFocus
              : codeInput.currentFocus - 1;
          changedCodeInput.value.splice(
            firstindex
              ? 0
              : codeInput.value[codeInput.currentFocus]
                ? codeInput.currentFocus
                : codeInput.currentFocus - 1,
            1,
            ''
          );
        } else if (value === '*') {
          changedCodeInput.currentFocus = 0;
          changedCodeInput.value = ['', '', '', ''];
        } else {
          changedCodeInput.currentFocus = lastIndex
            ? 3
            : codeInput.currentFocus + 1;
          changedCodeInput.value.splice(
            lastIndex ? 3 : codeInput.currentFocus,
            1,
            value
          );
        }

        setCodeInput(changedCodeInput);

        onChange?.(changedCodeInput)

        if (changedCodeInput.value.join('').length === 4) {
          signIn?.();
        }
      }
    }, [codeInput, onChange, signIn]
  );

  useEffect(() => {
    setCodeInput({
      currentFocus: initialCodeinput.currentFocus,
      value: [...initialCodeinput.value],
    });

    return () => { setCodeInput(undefined) };
  }, []);

  return (
    <>
      <MobileCodeInput codeInput={codeInput} />
      <div className={styles.resendCode}>
        <CountdownTimer
          minutes={2}
          resendCode={async () => { resendCode?.() }}
        />
      </div>
      <div className={styles.keyboardWrapper}>
        <NumberKeyboard onClick={handleKeyboardClick} />
      </div>
      <div className={styles.inputLabel}>
        <span onClick={changePhoneNumberHandler}>Изменить номер телефона</span>
      </div>

    </>
  );
};

export default KeyboardCodeInput;
