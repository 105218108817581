import { FC, InputHTMLAttributes } from 'react';
import styles from './DatePicker.module.css';
import editIcon from '../../static/images/icons/edit-icon.svg';
import calendarIcon from '../../static/images/icons/calendar-gray.svg';
import formatter from '../../utils/utils';
import dayjs from 'dayjs';

export interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  isCalendarIcon?: boolean,
  title?: string,
  date?: Date | string,
  isMatchCreate?: boolean
}
const DatePicker: FC<DatePickerProps> = (props) => {
  const { isCalendarIcon, title, date, isMatchCreate, style, ...inputProps } = props;
  const maxDate = dayjs().add(1, 'year').toDate().toISOString().split('T')[0]
  const minDate = dayjs().toISOString().split('T')[0]

  let normolizedDate = '';

  if (typeof date === 'string') {
    if (new Date(date).toString() !== 'Invalid Date') {
      normolizedDate = new Date(date).toISOString().slice(0, 10);
    } else {
      normolizedDate = '';
    }
  } else if (date instanceof Date) {
    normolizedDate = date.toISOString().slice(0, 10);
  }

  return (
    <div className={styles.datePicker}>
      {title ? <div className={styles.title}>{title}</div> : <span style={style} className={styles.displayDate}>{formatter.dateWithDots(normolizedDate)}</span>}
      <span className={styles.editButton}>
        <input
          type='date'
          className={styles.datePickerInput} value={normolizedDate}
          min={isMatchCreate ? minDate : undefined}
          max={isMatchCreate ? maxDate : undefined}
          {...inputProps}
        />
        <img src={isCalendarIcon ? calendarIcon : editIcon} alt="Edit" />
      </span>
    </div>
  );
}

export default DatePicker;
