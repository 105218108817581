import dayjs from 'dayjs';
import { useEffect, useState } from 'react';


const useCountdown = (targetDate: dayjs.Dayjs) => {
  const countDownDate = dayjs(targetDate).toDate().getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - dayjs().toDate().getTime()
  );

  
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - dayjs().toDate().getTime());
    }, 1000);
    
    return () => clearInterval(interval);
  }, [countDownDate]);
  
  return getReturnValues(countDown);
};

const getReturnValues = (totalMillisecond: number) => {
  // calculate time left
  const days = Math.floor(totalMillisecond / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (totalMillisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((totalMillisecond % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((totalMillisecond % (1000 * 60)) / 1000);
    
  return [days, hours, minutes, seconds, totalMillisecond];
};

export { useCountdown };