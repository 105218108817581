import { FC } from "react";
import { SeeMoreProps } from "./SeeMore.interfaces";
import styles from './SeeMore.module.css';
import arrowRight from '../../static/images/arrow-right.svg';

const SeeMore: FC<SeeMoreProps> = ({className, children, ...props}): JSX.Element => {
  return (
    <div className={`${className} ${styles['watch']}`} {...props}>
      <span>{children}</span>
      <img src={arrowRight} alt="стрелка вправо"/>
    </div>
  );
}

export default SeeMore;