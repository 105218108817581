import { countries } from '@/static/images/countries/countries'

export const COUNTRIES_CODES = {
  '+7': countries.ru,
  '+1': countries.us,
  '+20': countries.eg,
  '+30': countries.gr,
  '+31': countries.nl,
  '+33': countries.mf,
  '+34': countries.es,
  '+351': countries.pt,
  '+358': countries.fi,
  '+36': countries.hu,
  '+370': countries.lt,
  '+371': countries.lv,
  '+372': countries.ee,
  '+374': countries.am,
  '+375': countries.by,
  '+381': countries.rs,
  '+385': countries.hr,
  '+386': countries.si,
  '+39': countries.it,
  '+420': countries.cz,
  '+421': countries.sk,
  '+43': countries.at,
  '+44': countries.gb,
  '+45': countries.dk,
  '+46': countries.se,
  '+47': countries.sj,
  '+48': countries.pl,
  '+49': countries.de,
  '+65': countries.sg,
  '+90': countries.tr,
  '+971': countries.ae,
  '+972': countries.il,
  '+974': countries.qa,
  '+994': countries.az,
  '+995': countries.ge,
  // '+7': countries.kz,
} as const;
