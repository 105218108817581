/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthStatus,
    AuthStatusFromJSON,
    AuthStatusFromJSONTyped,
    AuthStatusToJSON,
} from './AuthStatus';

/**
 * 
 * @export
 * @interface AuthResult
 */
export interface AuthResult {
    /**
     * 
     * @type {AuthStatus}
     * @memberof AuthResult
     */
    status?: AuthStatus;
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    registrationToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResult
     */
    previouslyDeleted?: boolean;
}

export function AuthResultFromJSON(json: any): AuthResult {
    return AuthResultFromJSONTyped(json, false);
}

export function AuthResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : AuthStatusFromJSON(json['status']),
        'registrationToken': !exists(json, 'registrationToken') ? undefined : json['registrationToken'],
        'previouslyDeleted': !exists(json, 'previouslyDeleted') ? undefined : json['previouslyDeleted'],
    };
}

export function AuthResultToJSON(value?: AuthResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': AuthStatusToJSON(value.status),
        'registrationToken': value.registrationToken,
        'previouslyDeleted': value.previouslyDeleted,
    };
}

