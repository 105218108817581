import formatter from '@/utils/utils';
import styles from './GameDetailsCard.module.css';
import { GameView } from '@/integration-api/server-rest-lundapadelApi';
import { TIME_FORMAT_HH_MM } from '@/utils/constants';
import ClockIcon from '@/static/images/icons/ClockIcon';
import { calculateEndTime } from '@/pages/application/create/components/MatchResultSettings/utils';
import LocationIcon from '@/static/images/icons/LocationIcon';
import CourtIcon from '@/static/images/icons/CourtIcon';
import CommnentIcon from '@/static/images/icons/CommentIcon';
import QrCode from '@/components/QrCode/QrCode';
import { translateGameTournamentKind } from '@/components/GameListCard/utils';
import BallIcon from '@/static/images/icons/BallIcon';

type GameDetailsCardProps = { 
  game: GameView;
  showQR?: boolean;
}

const GameDetailsCard = ({ game, showQR }: GameDetailsCardProps) => {
  return (  
    <div className={styles['card']}>
      <div className={styles['card-field-short']}>
        <div className={styles['icon']}>
          <ClockIcon/>
        </div>
        <span>
          {formatter.formatDateToDayDateMonth(game.plannedDate)} | {formatter.formatDate(game.plannedDate!, TIME_FORMAT_HH_MM)} 
          {calculateEndTime(formatter.formatDate(game.plannedDate!, TIME_FORMAT_HH_MM), game.duration!)}
        </span>
      </div>
      <div className={styles['rating']}>
        {game.displayMinGrade === game.displayMaxGrade ?
          <span>{game.displayMinGrade}</span> :
          <span>{game.displayMinGrade}...{game.displayMaxGrade}</span>
        }
      </div>
      <div className={styles[showQR ? 'card-field-short' : 'card-field']}>
        <div className={styles['icon']}>
          <LocationIcon />
        </div>
        <span>{game.club?.displayName}</span>
      </div>
      <div className={styles[showQR ? 'card-field-short' : 'card-field']}>
        <div className={styles['icon']}>
          <CourtIcon/>
        </div>
        {game.courtBooked ?
          <span>Корт забронирован (№ {game.courtNumber})</span> :
          <span>Корт без брони</span>
        }
      </div>
      {game.gameTournamentKind ?
        <div className={styles[showQR ? 'card-field-short' : 'card-field']}>
          <div className={styles['icon']}>
            <BallIcon/>
          </div>
          <span>{translateGameTournamentKind(game.gameTournamentKind)}</span>
        </div> : null
      }
      {game.description ?
        <div className={styles[showQR ? 'card-field-short' : 'card-field']}>
          <div className={styles['icon']}>
            <CommnentIcon /> 
          </div>
          <span className={styles['comment']}>{game.description}</span>
        </div> : null
      }
      {showQR ?
        <div className={styles['qrCode']}>
          <QrCode value={`https://app.lundapadel.ru/game/${game?.identity?.uid}/join-via-qrcode`} />
        </div> : null
      }
    </div>  
  );
}
 
export default GameDetailsCard;
