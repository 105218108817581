import BasePageWrapper from '../../../components/BasePageWrapper/BasePageWrapper';
import Button from '../../../components/Button/Button';
import LundaPadelLogo from '../../../components/LundaPadelLogo/LundaPadelLogo';
import styles from './WelcomePage.module.css';
import OversideWrapper from '../../../components/OversideWrapper/OversideWrapper';
import { Link } from 'react-router-dom';
import HLWrapper from '../../../components/HLWrapper/HLWrapper';

function WelcomePage() {
  return (
    <div className={styles.background}>
      <BasePageWrapper>
        <div className={styles.content}>
          <div className={styles.logoWrapper}>
            <HLWrapper>
              <LundaPadelLogo />
            </HLWrapper>
          </div>
          <OversideWrapper className={styles.buttonWrapper}>
            <Link to="sign-in">
              <Button>Войти</Button>
            </Link>
            <Link to="sign-up">
              <Button className={styles.mainBackgroundColor} bordered>
                Зарегистирироваться
              </Button>
            </Link>
          </OversideWrapper>
        </div>
      </BasePageWrapper>
    </div>
  );
}

export default WelcomePage;
