import { useAppSelector } from '@/hooks/hooks';
import lundaPadelLogo from '../../static/images/lunda-padel-logo.svg';
import styles from './LundaPadelLogo.module.css';
import { RootState } from '@/models/store';
import { useNavigate } from 'react-router-dom';

const LundaPadelLogo: React.FC<unknown> = () => {
  const navigate = useNavigate();
  const isAuthorized = useAppSelector(
    (state: RootState) => !!state.auth.currentProfile?.identity?.uid
  );
  return (
    <img
      className={styles.logo}
      src={lundaPadelLogo}
      alt="Lunda padel logo"
      onClick={() => {
        if (isAuthorized) {
          navigate('/');
        }
      }}
    />
  );
};

export default LundaPadelLogo;
