/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableSlot,
    AvailableSlotFromJSON,
    AvailableSlotFromJSONTyped,
    AvailableSlotToJSON,
} from './AvailableSlot';

/**
 * 
 * @export
 * @interface AvailableBookingTimeLoadListResult
 */
export interface AvailableBookingTimeLoadListResult {
    /**
     * 
     * @type {Array<AvailableSlot>}
     * @memberof AvailableBookingTimeLoadListResult
     */
    slots?: Array<AvailableSlot>;
}

export function AvailableBookingTimeLoadListResultFromJSON(json: any): AvailableBookingTimeLoadListResult {
    return AvailableBookingTimeLoadListResultFromJSONTyped(json, false);
}

export function AvailableBookingTimeLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableBookingTimeLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slots': !exists(json, 'slots') ? undefined : ((json['slots'] as Array<any>).map(AvailableSlotFromJSON)),
    };
}

export function AvailableBookingTimeLoadListResultToJSON(value?: AvailableBookingTimeLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slots': value.slots === undefined ? undefined : ((value.slots as Array<any>).map(AvailableSlotToJSON)),
    };
}

