import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import { TournamentKind, TournamentStatus, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loadTournament } from "../service";
import TournamentResultsClassic from "../TournamentResultsClassic/TournamentResultsClassic";
import TournamentResultsNew from "../TournamentResultsNew/TournamentResultsNew";
import TournamentResultsNewContextWrapper from "../TournamentResultsNew/TournamentResultsNewContextWrapper";

const TournamentResultsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tournament, setTournament] = useState<TournamentView>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if(id) {
      setLoading(true);
      loadTournament(id)
        .then(({ result }) => {
          if(result?.status === TournamentStatus.PLANNED || result?.status === TournamentStatus.CANCEL) {
            navigate(-1);
          } else {
            setTournament(result ?? {});
          }
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
  }, [id]);

  return ( 
    <BasePageWrapper>
      {tournament?.kind === TournamentKind.CLASSIC ? 
        <TournamentResultsClassic tournament={tournament}/> :
        <TournamentResultsNewContextWrapper 
          tournament={tournament} 
          loading={loading} 
          setLoading={state => setLoading(state)}
          tournamentType="full"
        >
          <TournamentResultsNew />
        </TournamentResultsNewContextWrapper>
      }
    </BasePageWrapper>
  );
}
 
export default TournamentResultsPage;
