/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentInviteAcceptParam,
    TournamentInviteAcceptParamFromJSON,
    TournamentInviteAcceptParamFromJSONTyped,
    TournamentInviteAcceptParamToJSON,
} from './TournamentInviteAcceptParam';

/**
 * 
 * @export
 * @interface AcceptInviteForTournamentRequest
 */
export interface AcceptInviteForTournamentRequest {
    /**
     * 
     * @type {TournamentInviteAcceptParam}
     * @memberof AcceptInviteForTournamentRequest
     */
    parameters?: TournamentInviteAcceptParam;
}

export function AcceptInviteForTournamentRequestFromJSON(json: any): AcceptInviteForTournamentRequest {
    return AcceptInviteForTournamentRequestFromJSONTyped(json, false);
}

export function AcceptInviteForTournamentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptInviteForTournamentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TournamentInviteAcceptParamFromJSON(json['parameters']),
    };
}

export function AcceptInviteForTournamentRequestToJSON(value?: AcceptInviteForTournamentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TournamentInviteAcceptParamToJSON(value.parameters),
    };
}

