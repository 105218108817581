import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useFormik } from 'formik';
import RegisterStepOne from './components/RegisterStepOne/RegisterStepOne';
import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import styles from './RegisterPage.module.css';
import RegisterStepTwo from './components/RegisterStepTwo/RegisterStepTwo';
import RegisterStepThree from './components/RegisterStepThree/RegisterStepThree';
import objectPath from 'object-path';
import { FitnessLevel, Gender, PlayedMatches, PlayerProfileView, RacketSportExperience } from '@/integration-api/server-rest-lundapadelApi';
import dayjs from 'dayjs';
import { FetchErrorMessage } from '@/types/types';
import { fetchProfileInfo } from '@/models/profile/service';
import { SessionStorageAuthData } from '../signIn/SignInPage.interface';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

function RegisterPage() {
  const errors: any = {};
  const [fieldsErrors, setFieldsErrors] = useState<any>([])
  const [registerData, setRegisterData] = useState<any>()
  const [registerStep, setRegisterStep] = useState<1 | 2 | 3>(1);
  const [selectedDate, setSelectedDate] = useState<any>()
  const [profile, setProfile]  = useState<PlayerProfileView>()
  let authData: SessionStorageAuthData = JSON.parse(sessionStorage.getItem('authData') ?? '{}');

  const formik = useFormik<any>({
    initialValues: {
      ...registerData,
      gender: registerData?.gender ?? Gender.MAN,
      experience: registerData?.experience ?? RacketSportExperience.NEVER,
      fitnessLevel: registerData?.fitnessLevel ?? FitnessLevel.LOW,
      playedMatches: registerData?.playedMatches ?? PlayedMatches.LOW_NUMBER,
      grade: `GRADE_1`
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    validate: values => {
      if (registerStep === 1 && (!values.firstName || !values.lastName)) {
        objectPath.set(errors, 'stepOne', 'Заполните имя и фамилию!');
      }
      if(registerStep === 1 && authData.previouslyDeleted && (values.birthday && !(values.firstName || values.lastName))) {
        objectPath.set(errors, 'stepOne', 'Заполните имя и фамилию!');
      }
      if (registerStep === 2 && (!values.experience || !values.fitnessLevel || !values.gender || !values.birthday)) {
        objectPath.set(errors, 'stepTwo', 'Заполните анкету!');
      }
      if (values.firstName && values.lastName && !values.birthday) {
        objectPath.set(errors, 'birthday', 'Заполните дату рождения');
        setFieldsErrors([...fieldsErrors, 'birthday'])
      } else {
        setFieldsErrors(fieldsErrors.filter((el: any) => el !== 'birthday'))
      }
      for (let key in errors) {
        PopupMessage.open(`${(errors as string)[key as any]}`);
      }
      return errors;
    },
    onSubmit(values) {
    },
  })

  useEffect(() => {
    if (dayjs(selectedDate).isAfter(dayjs())) {
      PopupMessage.open("Нельзя выбрать будущую дату!");
    }
  }, [selectedDate])

  useEffect(() => {
    if (window.history.state.usr?.step === 3) {
      setRegisterStep(3);
    }
  }, [window.history.state.usr]);

  const onSave = (step: React.SetStateAction<1 | 2 | 3>) => {
    step === 2 && !errors.stepOne && setRegisterStep(step)
    step === 3 && !errors.stepTwo && setRegisterStep(step)
  }

  useEffect(() => {
    if(window.history.state.usr?.step === 3) {
      const uid = sessionStorage.getItem('profileUid');
      if(uid) {
        fetchProfileInfo(uid)
          .then(({ result }) => {
            setProfile(result);
          })
          .catch((err) => {
            if(err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open(userErrorMessage ?? errorMessage);
              })
            }
          });
      }
    }
  }, []);

  return (
    <div className={styles.background}>
      <BasePageWrapper>
        <form onSubmit={formik.handleSubmit} className={styles.registerPageContent}>
          {registerStep === 1 ? authData?.previouslyDeleted ? 
            <RegisterStepOne formik={formik} onSave={() => onSave(3)} deletedUser fieldsErrors={fieldsErrors} setSelectedDate={setSelectedDate} updateProfile={(profile: PlayerProfileView)=> setProfile(profile)}/> :
            <RegisterStepOne formik={formik} onSave={() => onSave(2)} />
            : null
          }
          {registerStep === 2 && <RegisterStepTwo updateProfile={(profile: PlayerProfileView)=> setProfile(profile)} formik={formik} onSave={() => onSave(3)} fieldsErrors={fieldsErrors} setSelectedDate={setSelectedDate} />}
          {registerStep === 3 && <RegisterStepThree playerProfile={profile}/>}
          <Outlet />
        </form>
      </BasePageWrapper>
    </div>
  )
}

export default RegisterPage;
