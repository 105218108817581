import { TournamentInputProps } from "./TournamentInput.interfaces";
import Headling from "@/components/Headling/Headling";
import InnerPadding from "@/components/InnerPadding/InnerPadding";
import Input from "@/components/Input/Input";
import styles from './TournamentInput.module.css';
import { getInputIcon, getInputType } from "./utils";

const TournamentInput = ({ label, placeholder, name, values, handleChange, ...props }: TournamentInputProps) => {
  return ( 
    <div className={styles['content']}>
      {label ?      
        <InnerPadding>
          <Headling 
            className={styles['headling']}
            appearence='medium-normal'
          >
            {label}
          </Headling>
        </InnerPadding> : null
      }
      <div className={styles['input-wrapper']}>
        <img src={getInputIcon(name)} alt={`${name} icon`} />
        <Input 
          {...props}
          name={name}
          type={getInputType(name)}
          className={styles['input']}
          placeholder={placeholder}
          onChange={e => handleChange(e.target.value)}
          value={values[name]}
          enterKeyHint="done"
        /> 
      </div>
    </div>
  );
}
 
export default TournamentInput;
