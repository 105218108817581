/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PlayerProfileField {
    DISPLAY_NAME = 'DISPLAY_NAME',
    RATING = 'RATING',
    GRADE = 'GRADE',
    COUNT_MATCHES_WITH_PARTNER = 'COUNT_MATCHES_WITH_PARTNER'
}

export function PlayerProfileFieldFromJSON(json: any): PlayerProfileField {
    return PlayerProfileFieldFromJSONTyped(json, false);
}

export function PlayerProfileFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileField {
    return json as PlayerProfileField;
}

export function PlayerProfileFieldToJSON(value?: PlayerProfileField | null): any {
    return value as any;
}

