import { HTMLAttributes } from 'react';
import styles from './TrashBage.module.css';
import TrashBageIcon from '@/static/images/icons/TrashBageIcon';

interface TrashBageProps extends HTMLAttributes<HTMLSpanElement> {}

const TrashBage = ({ className, ...props }: TrashBageProps) => {
  return ( 
    <span {...props} className={`${styles.bage} ${className}`}>
      <TrashBageIcon />
    </span>
   );
}
 
export default TrashBage;
