import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loaderReducer from './loader/loader';
import authReducer from './auth/auth';
import profileReducer from './profile/profile';
import resetRecuder from './reset/reset';
import { StateProvider } from '../integration/StateProvider';

const combinedReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  loader: loaderReducer,
  resetRecuder,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'reset/all') {
    state = {};
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([]),
});

StateProvider.setStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
