/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameTableLoadResult,
    GameTableLoadResultFromJSON,
    GameTableLoadResultFromJSONTyped,
    GameTableLoadResultToJSON,
} from './GameTableLoadResult';

/**
 * 
 * @export
 * @interface LoadGameTournamentTableResponse
 */
export interface LoadGameTournamentTableResponse {
    /**
     * 
     * @type {GameTableLoadResult}
     * @memberof LoadGameTournamentTableResponse
     */
    result?: GameTableLoadResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadGameTournamentTableResponse
     */
    messages?: Array<object>;
}

export function LoadGameTournamentTableResponseFromJSON(json: any): LoadGameTournamentTableResponse {
    return LoadGameTournamentTableResponseFromJSONTyped(json, false);
}

export function LoadGameTournamentTableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadGameTournamentTableResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : GameTableLoadResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadGameTournamentTableResponseToJSON(value?: LoadGameTournamentTableResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': GameTableLoadResultToJSON(value.result),
        'messages': value.messages,
    };
}

