import { GameMatchView } from "@/integration-api/server-rest-lundapadelApi";

export function calculateShownMatchSets(matches: Array<GameMatchView>, setMatch: GameMatchView): Array<GameMatchView> {
  if(matches.length >= 2) {
    if(matches[0].gameSets?.length === 3 && matches[1].gameSets?.length === 3) {
      return matches.slice(0, 2);
    }
    if(matches[0].gameSets?.length === 3 || matches[1].gameSets?.length === 3) {
      return matches.slice(0, 2);
    }
    if(matches.length >= 3 && matches[2].gameSets?.length === 3) {
      return matches.slice(0, 2);
    }
    if(matches.length >= 3) {
      return matches.slice(0, 3);
    }
    return matches.slice(0, 2).concat([{ gameSets: setMatch.gameSets?.slice(0, 6 - (matches[0].gameSets?.length! + matches[1].gameSets?.length!)) }]);
  }
  if(matches.length === 1) {
    return matches.concat([{ gameSets: setMatch.gameSets?.slice(0, 6 - matches[0].gameSets?.length!) }])
  }
  return [{ gameSets: setMatch.gameSets?.slice(0, 6) }];
}
