const BallSmallIcon = () => {
  return (  
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2815_56014)">
        <path d="M10.7903 2.71085C12.608 5.35647 11.9361 8.97428 9.2907 10.7902C6.64508 12.6078 3.02708 11.9364 1.20945 9.29022C-0.608174 6.6446 0.0636386 3.02641 2.70945 1.20897C5.35508 -0.607903 8.9727 0.0637214 10.7888 2.70897" fill="#C3D82E"/>
        <path d="M8.49981 11.2489C8.49981 11.2489 5.72481 5.0655 8.68094 0.84375L8.929 0.980625C8.929 0.980625 5.83263 5.22262 9.088 10.9324L8.49981 11.2493M1.21056 9.29081C1.21056 9.29081 3.94488 5.22975 3.19131 0.91275L2.85456 1.14431C2.85456 1.14431 4.33019 2.34919 1 8.98406" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2815_56014">
          <rect width="12" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
 
export default BallSmallIcon;
