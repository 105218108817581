import { useLayoutEffect, useState } from 'react';
import styles from './TourgamesPendingSection.module.css';
import { useNavigate } from 'react-router-dom';
import useDocumentVisibility from '@/hooks/useDocumentVisibility';
import { GameScoringExistResult, TournamentStatus } from '@/integration-api/server-rest-lundapadelApi';
import formatter from '@/utils/utils';
import icon from '@/static/images/icons/watch-tournament-arrow-icon.svg';
import { gameScoringExist } from './service';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const TourgamesPendingSection = (): JSX.Element | null => {
  const [pendingTourMatches, setPendingTourMatches] = useState<GameScoringExistResult>({})
  const navigate = useNavigate();
  useDocumentVisibility(gameScoringExist)

  useLayoutEffect(() => {
    if(navigator.onLine){
      gameScoringExist()
      .then(({ result }) => {
        if(result) {
          setPendingTourMatches(result)
        }
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
    }
  }, []);

  const renderContent = () => {
    if(pendingTourMatches.gameExist && !pendingTourMatches.tournaments?.length) {
      return (
        <section className={`${styles['wrapper']} ${styles['match']}`} onClick={() => navigate('/my-games?tab=completed')}>
          <span className={styles['text']}>Выставьте результаты своей недавней игры!</span>
        </section>
      )
    }
    if(!pendingTourMatches.gameExist && pendingTourMatches.tournaments?.length === 1 && ([TournamentStatus.PLANNED, TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.FINISH].includes(pendingTourMatches.tournaments?.[0]?.status!))) {
      return (
        <section className={`${styles['wrapper']} ${pendingTourMatches.tournaments?.[0].status === TournamentStatus.PLANNED ? styles['active-tournament'] : styles['finished-tournament']}`} onClick={() => navigate(`/tournament/${pendingTourMatches.tournaments?.[0].tournamentUid}`)}>
          {pendingTourMatches.tournaments?.[0].status === TournamentStatus.PLANNED ?
            <span className={styles['text']}>{formatter.relativeDateTourney(pendingTourMatches.tournaments?.[0]?.daysToStart)}</span> :
            <div className={styles['finished-text']}>
              <span>Турнир закончен</span>
              <div className={styles['watch-results']}>
                <span>Посмотреть результаты</span>
                <img src={icon} alt="иконка" />
              </div>
            </div>
          }
        </section>
      )
    } 
    if(!pendingTourMatches.gameExist && !pendingTourMatches.tournaments?.length) {
      return null;
    }
    return (
      <section className={styles['wrapper-continues']}>
        <div className={styles['list']}>
          {pendingTourMatches.gameExist ?
            <div className={`${styles['list-item']} ${styles['match-li']}`} onClick={() => navigate('/my-games?tab=completed')}>
              <span className={styles['text']}>Выставьте результаты своей недавней игры!</span>
            </div> : null
          }
          {(pendingTourMatches.tournaments && pendingTourMatches.tournaments.length) ? 
            <>
              {pendingTourMatches.tournaments.filter(tourney => [TournamentStatus.PLANNED, TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.FINISH].includes(tourney.status!)).map(tourney => (
                <div 
                  key={tourney.tournamentUid}
                  onClick={() => navigate(`/tournament/${tourney.tournamentUid}`)}
                  className={`${styles['list-item']} ${tourney.status === TournamentStatus.FINISH || tourney.status === TournamentStatus.AFTER_TOURNAMENT ? styles['finished-tournament-li'] : styles['active-tournament-li']}`}>
                  {tourney.status === TournamentStatus.FINISH || tourney.status === TournamentStatus.AFTER_TOURNAMENT ?
                    <div className={styles['finished-text']}>
                      <span>Турнир закончен</span>
                      <div className={styles['watch-results']}>
                        <span>Посмотреть результаты</span>
                        <img src={icon} alt="иконка" />
                      </div>
                    </div> : 
                    <span className={styles['text']}>
                      {formatter.relativeDateTourney(tourney.daysToStart)}
                    </span>
                  } 
                </div>
              ))}
            </> : null
          }
        </div>
      </section>
    )
  }

  return (
    <>{renderContent()}</>
   )
}
 
export default TourgamesPendingSection;
