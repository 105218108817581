import styles from './AdsBanner.module.css';

interface AdsBannerProps {
  bannerSrc: string;
  redirectLink?: string;
}

const AdsBanner: React.FC<AdsBannerProps> = (props) => {
  const { bannerSrc, redirectLink } = props;

  return (
    <a href={redirectLink} className={styles.wrap} target='_blank'>
      <img className={styles.banner} src={bannerSrc} alt="ads" />
    </a>
  );
}
 
export default AdsBanner;
