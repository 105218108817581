import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";

const resetRecuder = createSlice({
  initialState: {},
  name: "reset",
  reducers: {
    all: () => ({}),
  },
});
export const resetAll = resetRecuder.actions.all;

export const resetListenerMiddleware = createListenerMiddleware();
resetListenerMiddleware.startListening({
  actionCreator: resetAll,
  effect: (_, listenerApi) => {
    listenerApi.cancelActiveListeners();
  },
});

export default resetRecuder.reducer;
