/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Grade {
    _1 = 'GRADE_1',
    _2 = 'GRADE_2',
    _3 = 'GRADE_3',
    _4 = 'GRADE_4',
    _5 = 'GRADE_5',
    _6 = 'GRADE_6',
    _7 = 'GRADE_7'
}

export function GradeFromJSON(json: any): Grade {
    return GradeFromJSONTyped(json, false);
}

export function GradeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Grade {
    return json as Grade;
}

export function GradeToJSON(value?: Grade | null): any {
    return value as any;
}

