import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { acceptGameInviteByQr } from "../service";
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";

const GameJoinViaQrCode = () => {
  const { id } = useParams();
  const navigate = useNavigate();
 
  useEffect(() => {
    if(id) {
      acceptGameInviteByQr(id)
        .then(() => {
          navigate(`/game/${id}/planned` , { replace: true });
        })
        .catch(() => {
          navigate('/', { replace: true });
        });
    }
  }, [id]);

  return (  
    <BasePageWrapper/>
  );
}
 
export default GameJoinViaQrCode;
