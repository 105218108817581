type PrivateGameIconProps = {
  fill?: string;
}

const PrivateGameIcon = ({ fill }: PrivateGameIconProps) => {
  return (  
    <svg width="158" height="158" viewBox="0 0 158 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.749 26.5404L26.5375 19.752L138.249 131.464L131.461 138.252L19.749 26.5404ZM80.0303 59.2776L98.7219 77.9691C98.4622 73.096 96.4096 68.4912 92.9589 65.0405C89.5083 61.5899 84.9034 59.5372 80.0303 59.2776ZM77.9689 98.722L59.2774 80.0305C59.5371 84.9035 61.5897 89.5084 65.0404 92.9591C68.491 96.4097 73.0959 98.4623 77.9689 98.722Z" fill={fill ?? "#296AB5"}/>
    <path d="M79 108.625C74.4425 108.625 69.9463 107.574 65.8614 105.553C61.7764 103.532 58.2129 100.596 55.448 96.9725C52.683 93.3495 50.7913 89.1373 49.92 84.6638C49.0487 80.1904 49.2212 75.5761 50.4242 71.1802L29.1096 49.8626C20.4042 57.8366 12.1092 68.2424 4.9375 79C13.0905 92.5781 24.2431 106.539 35.8586 114.544C49.1837 123.721 63.6721 128.375 78.9259 128.375C87.2619 128.381 95.5357 126.94 103.379 124.116L86.8352 107.576C84.2822 108.275 81.6469 108.627 79 108.625ZM79 49.375C83.5575 49.3748 88.0537 50.4261 92.1386 52.4471C96.2236 54.4682 99.7871 57.4044 102.552 61.0275C105.317 64.6506 107.209 68.8627 108.08 73.3362C108.951 77.8096 108.779 82.4239 107.576 86.8198L129.381 108.625C138.377 100.524 146.684 89.628 153.062 79C144.922 65.5978 133.652 51.6709 121.839 43.5673C108.347 34.3156 93.9082 29.625 78.9259 29.625C70.6816 29.6368 62.5066 31.1305 54.7908 34.0348L71.1802 50.4242C73.7283 49.7268 76.3583 49.3739 79 49.375Z" fill={fill ?? "#296AB5"}/>
  </svg>

  );
}
 
export default PrivateGameIcon;
