/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentStartParam,
    TournamentStartParamFromJSON,
    TournamentStartParamFromJSONTyped,
    TournamentStartParamToJSON,
} from './TournamentStartParam';

/**
 * 
 * @export
 * @interface StartTournamentRequest
 */
export interface StartTournamentRequest {
    /**
     * 
     * @type {TournamentStartParam}
     * @memberof StartTournamentRequest
     */
    parameters?: TournamentStartParam;
}

export function StartTournamentRequestFromJSON(json: any): StartTournamentRequest {
    return StartTournamentRequestFromJSONTyped(json, false);
}

export function StartTournamentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartTournamentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TournamentStartParamFromJSON(json['parameters']),
    };
}

export function StartTournamentRequestToJSON(value?: StartTournamentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TournamentStartParamToJSON(value.parameters),
    };
}

