/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnlockGameParam
 */
export interface UnlockGameParam {
    /**
     * 
     * @type {string}
     * @memberof UnlockGameParam
     */
    lockToken?: string;
}

export function UnlockGameParamFromJSON(json: any): UnlockGameParam {
    return UnlockGameParamFromJSONTyped(json, false);
}

export function UnlockGameParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnlockGameParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lockToken': !exists(json, 'lockToken') ? undefined : json['lockToken'],
    };
}

export function UnlockGameParamToJSON(value?: UnlockGameParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lockToken': value.lockToken,
    };
}

