/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameRoundInfo,
    GameRoundInfoFromJSON,
    GameRoundInfoFromJSONTyped,
    GameRoundInfoToJSON,
} from './GameRoundInfo';
import {
    GameTournamentStatus,
    GameTournamentStatusFromJSON,
    GameTournamentStatusFromJSONTyped,
    GameTournamentStatusToJSON,
} from './GameTournamentStatus';

/**
 * 
 * @export
 * @interface GameRoundLoadListResult
 */
export interface GameRoundLoadListResult {
    /**
     * 
     * @type {GameTournamentStatus}
     * @memberof GameRoundLoadListResult
     */
    tournamentStatus?: GameTournamentStatus;
    /**
     * 
     * @type {Array<GameRoundInfo>}
     * @memberof GameRoundLoadListResult
     */
    rounds?: Array<GameRoundInfo>;
}

export function GameRoundLoadListResultFromJSON(json: any): GameRoundLoadListResult {
    return GameRoundLoadListResultFromJSONTyped(json, false);
}

export function GameRoundLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameRoundLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentStatus': !exists(json, 'tournamentStatus') ? undefined : GameTournamentStatusFromJSON(json['tournamentStatus']),
        'rounds': !exists(json, 'rounds') ? undefined : ((json['rounds'] as Array<any>).map(GameRoundInfoFromJSON)),
    };
}

export function GameRoundLoadListResultToJSON(value?: GameRoundLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentStatus': GameTournamentStatusToJSON(value.tournamentStatus),
        'rounds': value.rounds === undefined ? undefined : ((value.rounds as Array<any>).map(GameRoundInfoToJSON)),
    };
}

