/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentClassicSaveTableParam,
    TournamentClassicSaveTableParamFromJSON,
    TournamentClassicSaveTableParamFromJSONTyped,
    TournamentClassicSaveTableParamToJSON,
} from './TournamentClassicSaveTableParam';

/**
 * 
 * @export
 * @interface SaveClassicTournamentTableRequest
 */
export interface SaveClassicTournamentTableRequest {
    /**
     * 
     * @type {TournamentClassicSaveTableParam}
     * @memberof SaveClassicTournamentTableRequest
     */
    parameters?: TournamentClassicSaveTableParam;
}

export function SaveClassicTournamentTableRequestFromJSON(json: any): SaveClassicTournamentTableRequest {
    return SaveClassicTournamentTableRequestFromJSONTyped(json, false);
}

export function SaveClassicTournamentTableRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveClassicTournamentTableRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TournamentClassicSaveTableParamFromJSON(json['parameters']),
    };
}

export function SaveClassicTournamentTableRequestToJSON(value?: SaveClassicTournamentTableRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TournamentClassicSaveTableParamToJSON(value.parameters),
    };
}

