import { CurrentPlayerProfileView, PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';
import { scrollToUser } from '../../../RatingPage';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import styles from './PlayerRow.module.css';
import PlayerItem from '@/components/PlayerItem/PlayerItem';
import { ItemType } from '@/components/PlayerItem/PlayerItem.interfaces';
import PlayerRating from '../../PlayerRating/PlayerRating';
interface PlayerRowProps {
  currentUser: CurrentPlayerProfileView;
  playersList: PlayerProfileInfo[];
  observerRef: React.MutableRefObject<IntersectionObserver | null>;
}

const PlayerRow = ({playersList, observerRef, currentUser}: PlayerRowProps) => {

  return ({ index, style }: any) => {
    const player = playersList[index];
    return (
      <div
        key={player.uid}
        data-grade={player.displayGrade}
        style={style}
        ref={(node) => {
          if (node && observerRef.current) observerRef.current.observe(node);
        }}
        onClick={() => {
          scrollToUser.condition = true;
          setTimeout(() => {
            scrollToUser.condition = false;
          }, 30000)
        }}
      >
        {(player.displayGrade !== playersList[index - 1]?.displayGrade) && (
          <InnerPadding>
            <span className={styles['groupName']}>{player.displayGrade}</span>
          </InnerPadding>
        )}
        <PlayerItem
          player={player}
          type={ItemType.RATING}
          isActive={player.uid === currentUser?.identity?.uid}
          withTopBorder={index !== 0}
          customRating={<PlayerRating player={player} displayGrade={false} />}
        />
      </div>
  )};
}
 
export default PlayerRow;
