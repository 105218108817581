const TournamentAwardGold = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.6245 2.18625C35.0676 2.68125 30.7363 11.2767 30.7363 11.2767L27.5137 24.2756L40.0537 23.1258C40.0537 23.1258 50.4848 3.72797 50.6962 3.27938C51.0726 2.46469 51.2737 2.12953 49.8351 2.12953C48.3965 2.12438 36.1452 1.72219 35.6245 2.18625Z" fill="#176CC7"/>
      <path d="M42.1165 22.3206C41.4926 21.9854 23.1157 21.6967 22.6826 22.3206C22.2907 22.8878 22.3474 25.4814 22.5382 25.8165C22.729 26.1517 28.9062 29.0237 28.9062 29.0237L28.901 30.0704C28.901 30.0704 29.2413 30.8903 32.6857 30.8903C36.1301 30.8903 36.4858 30.1942 36.4858 30.1942L36.5168 29.1371C36.5168 29.1371 41.9257 26.4353 42.2093 26.1981C42.498 25.9557 42.7404 22.6557 42.1165 22.3206ZM36.4549 26.6157C36.4549 26.6157 36.4394 25.8939 36.0837 25.7134C35.7279 25.5329 29.9993 25.5639 29.5146 25.5897C29.0299 25.6154 29.0299 26.4817 29.0299 26.4817L25.1112 24.5739V24.1923L39.7033 24.2954L39.7291 24.7286L36.4549 26.6157Z" fill="#FCC417"/>
      <path d="M30.5561 26.3848C30.0714 26.3848 29.793 26.8901 29.793 27.7615C29.793 28.5762 30.0714 29.262 30.685 29.2104C31.1903 29.1691 31.3656 28.3957 31.3192 27.7099C31.2728 26.8952 31.1697 26.3848 30.5561 26.3848Z" fill="#FDFFFF"/>
      <path d="M14.9434 46.7771C14.9434 59.1315 26.1118 64.6332 33.5368 64.3702C41.7249 64.0763 51.5321 57.6929 50.9391 45.9057C50.3771 34.7372 40.9824 29.4418 32.8459 29.4985C23.3893 29.5655 14.9434 35.9851 14.9434 46.7771Z" fill="#FCC417"/>
      <path d="M33.2735 62.22C33.1446 62.22 33.0105 62.22 32.8816 62.2148C29.0144 62.0859 25.1936 60.4566 22.3989 57.7495C19.4083 54.8517 17.7686 50.933 17.7893 46.7203C17.8357 36.4336 26.8282 31.6641 32.928 31.6641H32.9796C41.245 31.7002 48.0307 38.1248 48.2214 46.8338C48.3039 50.7113 46.7158 54.7795 43.6014 57.8423C40.6933 60.6938 36.8364 62.22 33.2735 62.22ZM32.9177 33.3759C27.4469 33.3759 19.3568 37.8516 19.3361 46.7203C19.3207 53.3513 24.4563 60.1678 33.0105 60.4566C36.2692 60.5597 39.626 59.3119 42.3382 56.6461C45.1535 53.8772 46.7364 50.3091 46.6797 46.8544C46.556 39.3623 40.4303 33.4017 32.995 33.3708C32.9744 33.3708 32.9332 33.3759 32.9177 33.3759Z" fill="#FA912C"/>
      <path d="M29.8135 31.2516C29.4577 30.7617 25.4256 30.8545 21.6151 34.5774C17.9387 38.1713 17.5416 41.5589 18.2481 41.7549C19.0473 41.9766 20.0218 38.3878 23.3888 35.3714C26.4826 32.6077 30.5251 32.2261 29.8135 31.2516ZM45.4112 44.5908C44.1685 44.7661 45.4576 48.4889 42.5752 52.6552C40.0745 56.2697 37.2385 57.5794 37.5685 58.3734C38.012 59.4356 42.5546 56.7905 44.6584 52.4799C46.5662 48.5766 46.3393 44.4567 45.4112 44.5908Z" fill="#FEFFFA"/>
      <path d="M28.7978 39.7133C28.5296 40.1104 28.772 43.5908 28.9318 43.7455C29.2412 44.0549 31.5924 42.9463 31.5924 42.9463L31.5048 52.2533C31.5048 52.2533 29.7774 52.2327 29.597 52.2997C29.2412 52.4338 29.2876 56.0225 29.5506 56.1979C29.8135 56.3732 36.8621 56.466 37.1303 56.1102C37.3984 55.7544 37.3314 52.6143 37.2076 52.4699C36.9859 52.2018 35.2998 52.31 35.2998 52.31C35.2998 52.31 35.4442 38.6047 35.3978 38.1664C35.3514 37.7282 34.8667 37.4549 34.2892 37.5889C33.7117 37.723 28.9679 39.4555 28.7978 39.7133Z" fill="#FA912C"/>
      <path d="M13.1527 1.91797C12.8279 2.21704 25.2493 24.3373 25.2493 24.3373C25.2493 24.3373 27.3324 24.6055 31.9834 24.6467C36.6343 24.688 38.9391 24.3786 38.9391 24.3786C38.9391 24.3786 29.2815 2.13969 28.704 1.91797C28.4204 1.80454 24.5945 1.76329 20.8562 1.72204C17.1127 1.67563 13.4621 1.62922 13.1527 1.91797Z" fill="#2E9DF4"/>
    </svg>
  );
}
 
export default TournamentAwardGold;
