import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import styles from './ProfileGamesPage.module.css';
import Header from "@/components/Header/Header";
import Headling from "@/components/Headling/Headling";
import InnerPadding from "@/components/InnerPadding/InnerPadding";
import { TourGameEntityType, TourGameInfo } from "@/integration-api/server-rest-get-info";
import { UIEvent, useEffect, useState } from "react";
import GameListCard from "@/components/GameListCard/GameListCard";
import { Link, useParams } from "react-router-dom";
import TournamentCard from "@/components/TournamentCard/TournamentCard";
import TournamentFinishedCard from "@/components/TournamentFinishedCard/TournamentFinishedCard";
import { gameCardSubtype } from "../../myGames/components/CompletedContent/utils";
import useDebounce from "@/hooks/useDebounce";
import { FetchErrorMessage } from "@/types/types";
import { pastTourGameLoadList } from "../service";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

const ProfileGamesPage = () => {
  const { id } = useParams();

  const [afterTourgames, setAfterTourgames] = useState<TourGameInfo[]>();
  const [completedTourgames, setCompletedTourgames] = useState<TourGameInfo[]>();
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const scrollHandler = (e: UIEvent) => {
    if(navigator.onLine && Math.floor((e.target as HTMLDivElement).scrollTop + window.innerHeight - (window.innerHeight - (e.target as HTMLDivElement).clientHeight) + 80) >= (e.target as HTMLDivElement).scrollHeight
      && (offset + 30 < total)) {
      pastTourGameLoadList({
        limit: 30,
        offset: offset + 30,
        playerUid: id ?? ''
      })
        .then(({ result }) => {
          if(result) {
            setAfterTourgames(result.afterStarted);
            setCompletedTourgames(state => state?.concat(result.finished ?? []));
            setTotal(result.total ?? 0);
            setOffset(state => state + 30);
          }
        })
        .catch(err => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        });
    }
  }

  const debouncedScrollHandler = useDebounce(scrollHandler, 200);

  useEffect(() => {
    if(id) {
      pastTourGameLoadList({
        limit: 30,
        offset: 0,
        playerUid: id
      })
        .then(({ result }) => {
          if(result) {
            setAfterTourgames(result.afterStarted);
            setCompletedTourgames(result.finished);
            setTotal(result.total ?? 0);
          }
        })
        .catch(err => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        });
    }
  }, [id]);

  return (
    <>
      <BasePageWrapper>
        <Header>
          Прошедшие игры
        </Header>
        <div className={styles['wrapper']} onScroll={debouncedScrollHandler}>
          {afterTourgames?.length ?
            <>
              <InnerPadding>
                <Headling appearence='big-normal' className={styles['headling']}>
                  Редактируемые
                </Headling>
              </InnerPadding>
              <div className={styles['content-wrapper']}>
                {afterTourgames?.map(tourgame => {
                  if(tourgame.type === TourGameEntityType.GAME) {
                    return (
                      <GameListCard
                        key={tourgame.uid}
                        game={tourgame}
                        subtype='blue'
                      />
                    );
                  } else {
                    return (
                      <Link key={tourgame.uid} to={`/tournament/${tourgame.uid}`}>
                        <TournamentCard
                          tournament={tourgame}
                          restricted
                        />
                      </Link>
                    );
                  }
                })}
              </div>
            </> : null
          }
          {completedTourgames?.length ?
            <>
              <InnerPadding>
                <Headling appearence="big-normal" className={styles['headling']}>
                  Завершенные
                </Headling>
              </InnerPadding>
              <div className={styles['content-wrapper']}>
                {completedTourgames?.map(tourgame => {
                  if(tourgame.type === TourGameEntityType.GAME) {
                    return (
                      <GameListCard
                        subtype={gameCardSubtype(tourgame.gameStatus)}
                        key={tourgame.uid}
                        game={tourgame}
                      />
                    );
                  } else {
                    return (
                      <Link to={`/tournament/${tourgame.uid}`} key={tourgame.uid}>
                        <TournamentFinishedCard
                          tournament={tourgame}
                        />
                      </Link>
                    );
                  }
                })}
              </div>
            </> : null}
        </div>
      </BasePageWrapper>
    </>
  );
}
 
export default ProfileGamesPage;
