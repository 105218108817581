/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendGameLockInfo
 */
export interface ExtendGameLockInfo {
    /**
     * 
     * @type {string}
     * @memberof ExtendGameLockInfo
     */
    lockToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendGameLockInfo
     */
    gameUid?: string;
}

export function ExtendGameLockInfoFromJSON(json: any): ExtendGameLockInfo {
    return ExtendGameLockInfoFromJSONTyped(json, false);
}

export function ExtendGameLockInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendGameLockInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lockToken': !exists(json, 'lockToken') ? undefined : json['lockToken'],
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
    };
}

export function ExtendGameLockInfoToJSON(value?: ExtendGameLockInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lockToken': value.lockToken,
        'gameUid': value.gameUid,
    };
}

