/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvitationStatus,
    InvitationStatusFromJSON,
    InvitationStatusFromJSONTyped,
    InvitationStatusToJSON,
} from './InvitationStatus';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface TournamentInviteView
 */
export interface TournamentInviteView {
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TournamentInviteView
     */
    sender?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof TournamentInviteView
     */
    player?: PlayerProfileInfo;
    /**
     * 
     * @type {InvitationStatus}
     * @memberof TournamentInviteView
     */
    invitationStatus?: InvitationStatus;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof TournamentInviteView
     */
    sendDate?: LocalDateTime;
}

export function TournamentInviteViewFromJSON(json: any): TournamentInviteView {
    return TournamentInviteViewFromJSONTyped(json, false);
}

export function TournamentInviteViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentInviteView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sender': !exists(json, 'sender') ? undefined : PlayerProfileInfoFromJSON(json['sender']),
        'player': !exists(json, 'player') ? undefined : PlayerProfileInfoFromJSON(json['player']),
        'invitationStatus': !exists(json, 'invitationStatus') ? undefined : InvitationStatusFromJSON(json['invitationStatus']),
        'sendDate': !exists(json, 'sendDate') ? undefined : LocalDateTimeFromJSON(json['sendDate']),
    };
}

export function TournamentInviteViewToJSON(value?: TournamentInviteView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender': PlayerProfileInfoToJSON(value.sender),
        'player': PlayerProfileInfoToJSON(value.player),
        'invitationStatus': InvitationStatusToJSON(value.invitationStatus),
        'sendDate': LocalDateTimeToJSON(value.sendDate),
    };
}

