import { PartnerInfo } from '@/integration-api/server-rest-lundapadelApi';
import { Partners, SortBy } from './ProfileParthers.interface';

export function sortByFunc(sortBy: SortBy) {
  switch (sortBy) {
    case 'matchTogether': return sortByMatchCount;
    case 'rating': return sortByRating;
  }
}

export function sortByMatchCount(a: PartnerInfo, b: PartnerInfo) {
  if (a?.playedMatches! < b?.playedMatches!) return 1;
  if (a?.playedMatches! > b?.playedMatches!) return -1;
  return 0
}

export function sortByRating(a: PartnerInfo, b: PartnerInfo) {
  if (a?.ratingPosition! < b?.ratingPosition!) return 1;
  if (a?.ratingPosition! > b?.ratingPosition!) return -1;
  return 0
}

export function hasSelectedPartners(partners: Partners) {
  return Boolean(Object.values(partners).filter((partner) => partner.selected).length);
}
