import Header from "@/components/Header/Header";
import styles from "./TournamentResultsRounds.module.css";
import OversideWrapper from "@/components/OversideWrapper/OversideWrapper";
import Button from "@/components/Button/Button";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { gameTournamentStatusToTournamentStatus, mapRoundStatus, TournamentResultsNewContext } from "../utils";
import { deleteTournamentRound, gameRoundCreate, gameRoundDelete, gameTournamentFinish, loadGameTournamentTable, loadListGameRound, loadListTournamentRound, loadTournamentTable, tournamentFinish, tournamentRoundCreate } from "../service";
import { RoundStatus, TournamentKind, TournamentRoundInfo, TournamentStatus } from "@/integration-api/server-rest-lundapadelApi";
import RoundStatusCard from "../components/RoundStatusCard/RoundStatusCard";
import TournamentParticipants from "../components/TournamentParticipants/TournamentParticipants";
import { FetchErrorMessage } from "@/types/types";
import { useNavigate, useParams } from "react-router-dom";
import CrossIcon from "@/static/images/icons/CrossIcon";
import { PuffLoader } from "react-spinners";
import { TournamentMemberView } from "@/integration-api/server-rest-get-info";
import InfoIcon from "@/static/images/icons/InfoIcon";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";
import { GameResultsContext } from "../../game/GameResults/utils";

const TournamentResultsRounds = () => {
  const { loading, tournament, tournamentType, setLoading, updateRoundNumber, updateStep, roundCounter, updateRoundCounter } = useContext(TournamentResultsNewContext);
  const { lockToken } = useContext(GameResultsContext);
  const { id } = useParams();
  const [rounds, setRounds] = useState<Array<TournamentRoundInfo>>([]);
  const [participants, setParticipants] = useState<Array<TournamentMemberView>>();
  const [showAdditional, setShowAdditional] = useState(false);
  const [tournamentStatus, setTournamentStatus] = useState<TournamentStatus>();
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const handleFinishTournament = useCallback(async () => {
    try {
      if(tournamentStatus === TournamentStatus.FINISH || tournamentStatus === TournamentStatus.AFTER_TOURNAMENT) {
        navigate(-1);
      } else {
        if(id) {
          const request = tournamentType === 'mini' ? gameTournamentFinish({ gameUid: id, lockToken }) : tournamentFinish({ tournamentUid: id });
          await request;
          navigate(-1);
        }
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    }
  }, [id, navigate, tournamentStatus, tournamentType, lockToken]);

  const handleNextRound = useCallback(async () => {
    if(tournamentType === 'mini' && !lockToken) {
      return
    } else {
      try {
        if(id) {
          if(rounds.at(-1)?.status === RoundStatus.STARTED) {
            updateRoundNumber?.(rounds.length - 1);
            updateStep?.('lineups');
            return;
          }
          setLoading(true);
          const request = tournamentType === 'mini' ? gameRoundCreate(id, lockToken ?? '') : tournamentRoundCreate({ tournamentUid: id });
          const { result } = await request;
          if(result?.roundNumber) {
            updateRoundNumber?.(result.roundNumber);
            updateRoundCounter?.(result.roundNumber + 1);
            updateStep?.('lineups');
          }
        }
      } catch(err) {
        if(err instanceof Promise) {
          const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
          PopupMessage.open?.(userErrorMessage ?? errorMessage);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [id, updateStep, updateRoundNumber, setLoading, rounds, updateRoundCounter, tournamentType, lockToken]);

  const handleDeleteRound = async (roundNumber: number) => {
    try {
      if(id) {
        const deleteRequest = tournamentType === 'mini' ? 
          gameRoundDelete({ gameUid: id, roundNumber, lockToken }) : 
          deleteTournamentRound({ roundNumber, tournamentUid: id });
        await deleteRequest;
        const tableRequest = tournamentType === 'mini' ? loadGameTournamentTable(id) : loadTournamentTable({ tournamentUid: id })
        const { result } = await tableRequest;
        setParticipants(result?.infos);
        setRounds(rounds.slice(0, -1));
        updateRoundCounter?.(roundCounter - 1);
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    }
  } 

  const renderButton = () => {
    return (
      <Button
        onClick={handleFinishTournament}
        disabled={(rounds?.at(-1)?.status === RoundStatus.STARTED) || (tournamentType === 'mini' && !lockToken)}
      >
        {tournament.status === TournamentStatus.AFTER_TOURNAMENT ? 'Сохранить турнир' : 'Завершить турнир'}
      </Button>
    );
  } 

  useEffect(() => {
    if(ref.current && (ref.current.clientHeight - 350) < 60 * rounds.length) {
      if(tournament.kind !== TournamentKind.AMERICANO) {
        setShowAdditional(true);
      } else {
        if(rounds.length < tournament.roundsLimit!) {
          setShowAdditional(true);
        } else {
          setShowAdditional(false);
        }
      }
    }
  }, [ref.current, rounds.length, tournament.kind, tournament.roundsLimit]);

  useEffect(() => {
    if(id) {
      setLoading(true);
      //duplicate iz-za buga typescripta
      if(tournamentType === 'mini') {
        Promise.all([loadGameTournamentTable(id), loadListGameRound(id)])
          .then(([ { result }, { result: result2 } ]) => {
            setParticipants(result?.infos);
            setTournamentStatus(gameTournamentStatusToTournamentStatus(result2?.tournamentStatus!));
            if(result2?.rounds) {
              if(result2.rounds.length === 0) {
                return gameRoundCreate(id ?? '', lockToken ?? '');
              } else {
                setRounds(result2.rounds.map(round => ({ ...round, status: mapRoundStatus(round.status!) })));
                updateRoundCounter?.(result2.rounds.length)
              }
            }
          })
          .then((res) => {
            if(res) {
              updateRoundNumber?.(res.result?.roundNumber!);
              updateRoundCounter?.(1);
              updateStep?.('courts');
            }
          })
          .catch(err => {
            if(err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open?.(userErrorMessage ?? errorMessage);
              })
            }
          })
          .finally(() => setLoading(false));
      } else {
        Promise.all([loadTournamentTable({ tournamentUid: id }), loadListTournamentRound({ tournamentUid: id })])
          .then(([ { result }, { result: result2 } ]) => {
            setParticipants(result?.infos);
            setTournamentStatus(result2?.tournamentStatus);
            if(result2?.rounds) {
              if(result2.rounds.length === 0) {
                return tournamentRoundCreate({ tournamentUid: id ?? '' })
              } else {
                setRounds(result2.rounds);
                updateRoundCounter?.(result2.rounds.length)
              }
            }
          })
          .then((res) => {
            if(res) {
              updateRoundNumber?.(res.result?.roundNumber!);
              updateRoundCounter?.(1);
              updateStep?.('courts');
            }
          })
          .catch(err => {
            if(err instanceof Promise) {
              err.then(err => {
                const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
                PopupMessage.open?.(userErrorMessage ?? errorMessage);
              })
            }
          })
          .finally(() => setLoading(false));
      }
    }
  }, [id, tournamentType, updateRoundNumber, updateStep, updateRoundCounter]);

  return ( 
    <div>
      <Header 
        className={styles['header']}
        additional={showAdditional ?
          <div onClick={handleNextRound}>
            <CrossIcon fill="var(--white)"/>
          </div> : undefined
        }
      >
        Раунды
      </Header>
      {loading ?
        <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} /> :
        <>
          <div className={styles['wrapper']} ref={ref}>
          {tournament.kind === TournamentKind.AMERICANO && tournamentStatus === TournamentStatus.AFTER_TOURNAMENT ?
            <div className={styles['warning']}>
              <div>
                <InfoIcon fill="var(--orange)"/>
              </div>
              <div className={styles['warning-text']}>Редактировать можно только последний раунд. Раунд можно удалить свайпом влево, таким образом вы сможете отредактировать все раунды, начиная с 1-го. Используя удаление раундов, не забудьте выставить счет по ВСЕМ раундам для того, чтобы таблица результатов успешно сформировалась.</div>
            </div> : null
          }
            <TournamentParticipants participants={participants ?? []} results/>
            {rounds.map(round => (
              <RoundStatusCard
                key={round.roundNumber}
                status={round.status ?? RoundStatus.STARTED}
                lastRound={round.roundNumber! > 0 && rounds.length - 1 === round.roundNumber}
                index={round.roundNumber ? round.roundNumber + 1 : 1}
                openRound={() => {
                  updateRoundNumber?.(round.roundNumber ?? 0)
                  updateStep?.('lineups')
                }}
                deleteRound={() => (tournamentType === 'mini' && !lockToken) ? undefined : handleDeleteRound(round.roundNumber ?? 0)}
              />
            ))}
            {rounds?.at(-1)?.status === RoundStatus.FINISHED && (tournament.kind !== TournamentKind.AMERICANO || (tournament.kind === TournamentKind.AMERICANO && (rounds.length < tournament.roundsLimit!))) ?
              <div className={styles['add-round']} onClick={handleNextRound}>
                <CrossIcon fill="var(--green-yellow)"/>
                <span>Добавить новый раунд</span>
              </div> : null
            }
          </div>
          <OversideWrapper className={styles['button']}>
            {renderButton()}
          </OversideWrapper>
        </>
      }
    </div>
  );
}
 
export default TournamentResultsRounds;
