import { HTMLAttributes } from 'react';
import styles from './FavoriteClubItem.module.css';
import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import locationIcon from '@/static/images/icons/location.svg';
import locationIconActive from '@/static/images/icons/location_active.svg';
import starIcon from '@/static/images/icons/favorite-club-star-icon.svg';
import starIconOutline from '@/static/images/icons/favorite-club-star-icon-outline.svg';

interface FavoriteClubItemProps extends HTMLAttributes<HTMLDivElement> {
  club: ClubView;
  selectedClub?: string;
  favoriteClubSelection?: boolean;
  favoriteSelectedClubs?: Array<ClubView>;
}

const FavoriteClubItem = ({ club, selectedClub, favoriteClubSelection, favoriteSelectedClubs, ...props }: FavoriteClubItemProps) => {
  const isFavoriteSelected = !!favoriteSelectedClubs?.find(c => club.reference?.uid === c.reference?.uid)
  return ( 
    <div {...props} className={`${styles['wrapper']} ${selectedClub === club.reference?.uid ? styles['active'] : ''}`}>
      <img src={selectedClub === club.reference?.uid ? locationIconActive : locationIcon} alt="локация" />
      <div className={styles['info']}>
        <div className={styles['top']}>
          <span>{club.name}</span>
          {(club.favorite && !favoriteClubSelection) || isFavoriteSelected ? <img src={starIcon} alt="любимый клуб"/> : favoriteClubSelection ? <img src={starIconOutline} alt='нелюбимый клуб'/> : null}
        </div>
        <span className={`${styles['bottom']} ${selectedClub === club.reference?.uid ? styles['active-text'] : ''}`}>
          {club.mapAddress}
        </span>
      </div>
    </div>
  );
}
 
export default FavoriteClubItem;
