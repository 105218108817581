import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './ClubCard.module.css';
import Button, { ButtonVariants } from '@/components/Button/Button';
import PhoneAddIcon from '@/components/Icons/PhoneAddIcon';
import NavigatorIcon from '@/components/Icons/NavigatorIcon';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SanitizeHTML from '@/components/SanitizeHTML/SanitizeHTML';

interface ClubCardProps {
  club: ClubView;
}

const ClubCard: React.FC<ClubCardProps> = (props) => {
  const { club } = props;

  const { clubName } = useParams();
  const innerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (clubName && club.name === clubName && innerRef.current !== null) {
      innerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [club.name, clubName]);

  return (
    <div className={styles.club} ref={innerRef}>
      <div className={styles.container}>
        <div className={styles.header}>{club.name}</div>
        <div className={styles.info}><SanitizeHTML html={club.info || ''} /></div>
      </div>
      <OversideWrapper>
        <Button variant={ButtonVariants.PRIMARY} className={styles.button}>
          <div className={styles.buttonInner}>
            <a href={`tel:+${club.phone}`} className={styles.addressText}>
              <div className={styles.buttonWithIcon}>
                <PhoneAddIcon />
                <div className={styles.clubName}>{club.name}</div>
              </div>
            </a>
            <a
              href={club.mapUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div className={styles.mapButton}>
                <NavigatorIcon />
              </div>
            </a>
          </div>
        </Button>
      </OversideWrapper>
    </div>
  );
};

export default ClubCard;
