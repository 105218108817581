import { CityClubsGroupLoadListParameters, ClubLoadListParameters, LundapadelGameApiPastGameListRequest, LundapadelMainApiNotificationLoadListRequest, LundapadelTournamentApiPastTourGameLoadListRequest, PlayerProfileAvatarSaveParameters, PlayerProfileView, SetFavoriteClubsParameters, UpdateFollowerParameter } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';


export function signOut() {
  return RestClient.profileApi.logout();
}

export function uploadAvatar(file: Blob) {
  return RestClient.mainApi.attachmentUpload({ file })
}

export function saveAvatar(fileUid: string, view: PlayerProfileView, avatarSaveParameters: PlayerProfileAvatarSaveParameters) {
  return RestClient.profileApi.playerImageSave({
    playerImageSaveRequest: {
      parameters: {
        temporalProfileImageUid: fileUid,
        avatarSaveParameters,
        view
      }
    }
  })
}

export const notificationLoadList = (parameters: LundapadelMainApiNotificationLoadListRequest) => {
  return RestClient.mainApi.notificationLoadList(parameters);
};

export function deleteProfile() {
  return RestClient.profileApi.profileDelete();
}

export function loadListClubCities() {
  return RestClient.clubApi.loadListClubCities();
}

export function saveFavoriteClubs(parameters: SetFavoriteClubsParameters) {
  return RestClient.profileApi.setFavoriteClubs({
    setFavoriteClubsRequest: {
      parameters
    }
  });
}

export function clubLoadList(parameters: ClubLoadListParameters) {
  return RestClient.clubApi.clubLoadList({
    clubLoadListRequest: {
      parameters
    }
  })
}

export function cityClubsLoadList(parameters: CityClubsGroupLoadListParameters) {
  return RestClient.clubApi.cityClubsLoadList({
    cityClubsLoadListRequest: {
      parameters
    }
  })
}

export function feedbackReplyExist() {
  return RestClient.mainApi.feedbackReplyExist()
}

export function pastGameList(params: LundapadelGameApiPastGameListRequest) {
  return RestClient.gameApi.pastGameList(params)
}

export function pastTourGameLoadList(params: LundapadelTournamentApiPastTourGameLoadListRequest) {
  return RestClient.tournamentApi.pastTourGameLoadList(params)
}
