import { InviteInfo, TourGameInfo, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export interface TournamentCardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  tournament: TournamentView | TourGameInfo;
  restricted?: boolean;
}

export interface TournamentInviteCardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  invite: InviteInfo;
  acceptInvite: () => void;
  rejectInvite: () => void;
}

export function isTournamentView(tournament: TournamentView | TourGameInfo): tournament is TournamentView  {
  return tournament && 'members' in tournament && 'queue' in tournament;
}
