/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationReadParameter
 */
export interface NotificationReadParameter {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationReadParameter
     */
    notificationUids?: Array<string>;
}

export function NotificationReadParameterFromJSON(json: any): NotificationReadParameter {
    return NotificationReadParameterFromJSONTyped(json, false);
}

export function NotificationReadParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationReadParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationUids': !exists(json, 'notificationUids') ? undefined : json['notificationUids'],
    };
}

export function NotificationReadParameterToJSON(value?: NotificationReadParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationUids': value.notificationUids,
    };
}

