import styles from './ChangeVector.module.css';

export default function ChangeVector({ vector }: {vector: 'up' | 'down'}) {
  const className = vector === 'up' ? styles.up : styles.down;

  return (
    <svg className={className} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.77067 2.24974L1.38394 5.75L0.416504 4.75013L4.77067 0.25L9.12484 4.75013L8.15671 5.75L4.76999 2.24974H4.77067Z"/>
    </svg>
  )
}
