import styles from './LineupCard.module.css';
import { LineupCardMatchItemProps } from './LineupCard.interfaces';

const LineupCardMatchItem = ({ match }: LineupCardMatchItemProps) => {
  return (  
    <div className={`${styles['match-wrapper']} ${match.matchResult ? styles['match-border'] : ''}`.trim()}>
      {match.gameSets?.map?.((set, index) => (
        <div className={styles['match-score']} key={index}>
          <div className={styles['match-score-value']}>
            <span>{set.score1}</span>
          </div>
          <div className={styles['match-score-value']}>
            <span>{set.score2}</span>
          </div>
        </div>
      ))}
      {match.matchResult ? <span className={styles['match-text']}>Матч</span> : null}
    </div>
  );
}
 
export default LineupCardMatchItem;
