/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameRoundView,
    GameRoundViewFromJSON,
    GameRoundViewFromJSONTyped,
    GameRoundViewToJSON,
} from './GameRoundView';

/**
 * 
 * @export
 * @interface GameRoundSaveRequest
 */
export interface GameRoundSaveRequest {
    /**
     * 
     * @type {GameRoundView}
     * @memberof GameRoundSaveRequest
     */
    parameters?: GameRoundView;
}

export function GameRoundSaveRequestFromJSON(json: any): GameRoundSaveRequest {
    return GameRoundSaveRequestFromJSONTyped(json, false);
}

export function GameRoundSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameRoundSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : GameRoundViewFromJSON(json['parameters']),
    };
}

export function GameRoundSaveRequestToJSON(value?: GameRoundSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': GameRoundViewToJSON(value.parameters),
    };
}

