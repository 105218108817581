/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CityClubsItemView,
    CityClubsItemViewFromJSON,
    CityClubsItemViewFromJSONTyped,
    CityClubsItemViewToJSON,
} from './CityClubsItemView';

/**
 * 
 * @export
 * @interface CityClubsGroupLoadListResult
 */
export interface CityClubsGroupLoadListResult {
    /**
     * 
     * @type {Array<CityClubsItemView>}
     * @memberof CityClubsGroupLoadListResult
     */
    items?: Array<CityClubsItemView>;
}

export function CityClubsGroupLoadListResultFromJSON(json: any): CityClubsGroupLoadListResult {
    return CityClubsGroupLoadListResultFromJSONTyped(json, false);
}

export function CityClubsGroupLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CityClubsGroupLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(CityClubsItemViewFromJSON)),
    };
}

export function CityClubsGroupLoadListResultToJSON(value?: CityClubsGroupLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(CityClubsItemViewToJSON)),
    };
}

