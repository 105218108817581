import styles from './MyGamesTabs.module.css';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import PlannedContent from '../PlannedContent/PlannedContent';
import CompletedContent from '../CompletedContent/CompletedContent';
import Tabs, { TabsContent, TabsData } from '@/components/Tabs/Tabs';
import { useSearchParams } from 'react-router-dom';
import { isEnum } from '@/types/types';
import { myPastTourGameLoadList } from '../srevice';

enum TabType {
  PLANNED = 'planned',
  COMPLETED = 'completed',
}

const MyGamesTabs: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [waitningForScore, setWaitingForScore] = useState<number>(0);

  const tabsData: TabsData<TabType>[] = useMemo(
    () => [
      {
        type: TabType.PLANNED,
        label: 'Запланированные',
      },
      {
        type: TabType.COMPLETED,
        label: 
          <div className={styles['tab-button']}>
            <span>Прошедшие</span>
            {waitningForScore ? <span className={styles['tab-matches-count']}>{waitningForScore}</span> : null}
          </div>,
      },
    ],
  [waitningForScore]);
    
  const handleWaitingForScore = useCallback((count: number) => {
    setWaitingForScore(count);
  }, []);

  const tabsContent: TabsContent<TabType> = {
    [TabType.PLANNED]: (
      <PlannedContent />
    ),
    [TabType.COMPLETED]: (
      <CompletedContent handleWaitingForScore={handleWaitingForScore}/>
    ),
  };

  const tab = searchParams.get('tab');

  // загружаем список матчей для выставления счета, чтобы в таб установить кол-во таких матчей до перехода на вкладку "прошедшие"
  // в будущем нужно поднимать список матчей в родительский компонент и брать значения от туда
  useEffect(() => {
    if (tab !== TabType.COMPLETED) {
      myPastTourGameLoadList({
        limit: 1000,
        offset: 0,
        ownOnly: false,
        showCanceled: false,
      })
      .then(({ result }) => setWaitingForScore(result?.started?.length ?? 0))
      .catch((e) => console.log(e));
    }
  }, [])

  return (
    <Tabs
      tabsData={tabsData}
      initialTab={isEnum(TabType)(tab) ? tab : TabType.PLANNED}
      tabsContent={tabsContent}
    />
  );
};

export default MyGamesTabs;
