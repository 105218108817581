import { RoundStatus } from '@/integration-api/server-rest-lundapadelApi';
import styles from './RoundStatusCard.module.css';
import SignOutlineIcon from '@/static/images/icons/SignOutlineIcon';
import { HTMLAttributes, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import TrashIcon from '@/static/images/icons/TrashIcon';
import 'swiper/css';
import { Swiper as SwiperType } from "swiper"

type RoundStatusCardProps = {
  status: RoundStatus;
  index: number;
  lastRound?: boolean;
  openRound: () => void;
  deleteRound?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const RoundStatusCard = ({ status, index, lastRound, openRound, deleteRound, ...props }: RoundStatusCardProps) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  if(status === RoundStatus.STARTED) {
    return lastRound ? (
      <div style={{ width: '100%'}}>
        <Swiper
          className={`${styles['swiper']} ${activeIndex === 1 ? styles['trash-visible'] : ''}`}
          slidesPerView={2}
          onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.activeIndex)}
          onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
          freeMode
        >
          <SwiperSlide className={styles['slide']}>
            <div {...props} className={styles['card-started']} onClick={openRound}>
              <span>{index} раунд</span>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles['trash']} onClick={deleteRound}>
            <TrashIcon/>
          </SwiperSlide>
          <SwiperSlide/>
        </Swiper>
      </div>
    ) : (
      <div className={styles['card-wrapper']}>
        <div {...props} className={styles['card-started']} onClick={openRound}>
          <span>{index} раунд</span>
        </div>
      </div>
    )
  }
  if(status === RoundStatus.FINISHED) {
    return lastRound ? (
      <div style={{ width: '100%'}}>
        <Swiper
          className={`${styles['swiper']} ${activeIndex === 1 ? styles['trash-visible'] : ''}`}
          slidesPerView={2}
          onSlideChange={(swiper: SwiperType) => setActiveIndex(swiper.activeIndex)}
          onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
          freeMode
        > 
          <SwiperSlide className={styles['slide']}>
            <div {...props} className={`${styles['card-finished']}`} onClick={openRound}>
              <span>{index} раунд</span>
              <SignOutlineIcon />
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles['trash']} onClick={deleteRound}>
            <TrashIcon/>
          </SwiperSlide>
          <SwiperSlide/>
        </Swiper>
      </div>
    ) : ( 
      <div className={styles['card-wrapper']}>
        <div {...props} className={styles['card-finished']} onClick={openRound}>
          <span>{index} раунд</span>
          <SignOutlineIcon />
        </div>
      </div>
    );
  } 
  return null;
}
 
export default RoundStatusCard;
