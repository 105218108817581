/* tslint:disable */
/* eslint-disable */
/**
 * info
 * Service for compare entity from different development environments Операции, для которых требуется авторизация, могут возвращать HTTP код 403 (Forbidden). В этом случае нужно воспользваться операцией POST common/profile для прохождения процедуры авторизации и получения авторизационного токена, который передаётся в куки.
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TourGameEntityType {
    GAME = 'GAME',
    TOURNAMENT = 'TOURNAMENT'
}

export function TourGameEntityTypeFromJSON(json: any): TourGameEntityType {
    return TourGameEntityTypeFromJSONTyped(json, false);
}

export function TourGameEntityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TourGameEntityType {
    return json as TourGameEntityType;
}

export function TourGameEntityTypeToJSON(value?: TourGameEntityType | null): any {
    return value as any;
}

