/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaptchaDataLoadParam,
    CaptchaDataLoadParamFromJSON,
    CaptchaDataLoadParamFromJSONTyped,
    CaptchaDataLoadParamToJSON,
} from './CaptchaDataLoadParam';

/**
 * 
 * @export
 * @interface LoadCaptchaRequest
 */
export interface LoadCaptchaRequest {
    /**
     * 
     * @type {CaptchaDataLoadParam}
     * @memberof LoadCaptchaRequest
     */
    parameters?: CaptchaDataLoadParam;
}

export function LoadCaptchaRequestFromJSON(json: any): LoadCaptchaRequest {
    return LoadCaptchaRequestFromJSONTyped(json, false);
}

export function LoadCaptchaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadCaptchaRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : CaptchaDataLoadParamFromJSON(json['parameters']),
    };
}

export function LoadCaptchaRequestToJSON(value?: LoadCaptchaRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': CaptchaDataLoadParamToJSON(value.parameters),
    };
}

