import { FC } from "react";
import { NewsCardProps } from "./NewsCard.interfaces";
import wavePart from "@/static/images/dogshit.svg";
import styles from "./NewsCard.module.css";
import { Link } from "react-router-dom";

const NewsCard: FC<NewsCardProps> = (props): JSX.Element => {
  const { news, color, title, ...rest } = props;
  return (
    <Link to={`/news/${news.uid}`}>    
      <div {...rest} className={`${styles['card']} ${styles[color]}`}>
        <img src={wavePart} alt="new" />
        <span className={styles['text']}>{title}</span>
      </div>
    </Link>
  );
}

export default NewsCard;
