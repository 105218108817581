/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentRoundLoadListResult,
    TournamentRoundLoadListResultFromJSON,
    TournamentRoundLoadListResultFromJSONTyped,
    TournamentRoundLoadListResultToJSON,
} from './TournamentRoundLoadListResult';

/**
 * 
 * @export
 * @interface LoadListTournamentRoundResponse
 */
export interface LoadListTournamentRoundResponse {
    /**
     * 
     * @type {TournamentRoundLoadListResult}
     * @memberof LoadListTournamentRoundResponse
     */
    result?: TournamentRoundLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadListTournamentRoundResponse
     */
    messages?: Array<object>;
}

export function LoadListTournamentRoundResponseFromJSON(json: any): LoadListTournamentRoundResponse {
    return LoadListTournamentRoundResponseFromJSONTyped(json, false);
}

export function LoadListTournamentRoundResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadListTournamentRoundResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : TournamentRoundLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadListTournamentRoundResponseToJSON(value?: LoadListTournamentRoundResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': TournamentRoundLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

