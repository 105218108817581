import { FC } from "react";
import styles from './LevelDefine.module.css'
import infoIcon from '@/static/images/icons/info-icon.svg'
import { Link } from "react-router-dom";


const LevelDefine: FC = () => {
  return (
    <Link to='/rating/info'>        
      <img src={infoIcon} alt='Показать инфу' className={styles.infoIcon}/>
    </Link>
  );
}

export default LevelDefine
