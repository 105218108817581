/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameInviteLoadListResult,
    GameInviteLoadListResultFromJSON,
    GameInviteLoadListResultFromJSONTyped,
    GameInviteLoadListResultToJSON,
} from './GameInviteLoadListResult';

/**
 * 
 * @export
 * @interface LoadListGameInviteResponse
 */
export interface LoadListGameInviteResponse {
    /**
     * 
     * @type {GameInviteLoadListResult}
     * @memberof LoadListGameInviteResponse
     */
    result?: GameInviteLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadListGameInviteResponse
     */
    messages?: Array<object>;
}

export function LoadListGameInviteResponseFromJSON(json: any): LoadListGameInviteResponse {
    return LoadListGameInviteResponseFromJSONTyped(json, false);
}

export function LoadListGameInviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadListGameInviteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : GameInviteLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadListGameInviteResponseToJSON(value?: LoadListGameInviteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': GameInviteLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

