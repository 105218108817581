import { useContext } from 'react';
import LineupListEditableItem from '../LineupListEditableItem/LineupListEditableItem';
import TrashIcon from '@/static/images/icons/TrashIcon';
import styles from './LineupList.module.css';
import { LineupListEditableProps } from './LineupList.interfaces';
import { GameLineupsContext } from '../../GameResults/utils';

const LineupListEditable = ({ lineup, index }: LineupListEditableProps) => {
  const { handleEditableLineups } = useContext(GameLineupsContext);

  const deleteLineup = () => {
    handleEditableLineups('delete', { index, players: {}});
  }

  return ( 
    <div className={styles['wrapper']}>
      {index > 0 ? 
        <div className={styles['trash-icon']} onClick={deleteLineup}>
          <TrashIcon/>
        </div> : null
      }
      <div className={styles['team-left']}>
        <div className={styles['team-title']}>Команда A</div>
        <div className={styles['team-wrapper']}>
          <LineupListEditableItem team='left' player={lineup.player1} lineupIndex={index} playerProp='player1'/>
          <div className={styles['separator']}></div>
          <LineupListEditableItem team='left' player={lineup.player2} lineupIndex={index} playerProp='player2'/>
        </div>
      </div>
      <div className={styles['team-right']}>
        <div className={styles['team-title']}>Команда B</div>
        <div className={styles['team-wrapper']}>
          <LineupListEditableItem team='right' player={lineup.player3} lineupIndex={index} playerProp='player3'/>
          <div className={styles['separator']}></div>
          <LineupListEditableItem team='right' player={lineup.player4} lineupIndex={index} playerProp='player4'/>
        </div>
      </div>
    </div>
  );
}
 
export default LineupListEditable;
