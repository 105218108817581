/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OwnerEnterGameParam
 */
export interface OwnerEnterGameParam {
    /**
     * 
     * @type {string}
     * @memberof OwnerEnterGameParam
     */
    gameUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerEnterGameParam
     */
    enter?: boolean;
}

export function OwnerEnterGameParamFromJSON(json: any): OwnerEnterGameParam {
    return OwnerEnterGameParamFromJSONTyped(json, false);
}

export function OwnerEnterGameParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerEnterGameParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameUid': !exists(json, 'gameUid') ? undefined : json['gameUid'],
        'enter': !exists(json, 'enter') ? undefined : json['enter'],
    };
}

export function OwnerEnterGameParamToJSON(value?: OwnerEnterGameParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameUid': value.gameUid,
        'enter': value.enter,
    };
}

