import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPlayerList, fetchProfileInfo, fetchProfileStat, fetchSaveProfile } from './service';
import { PlayerProfileStatisticsView, PlayerProfileView, PlayerSaveRequest } from '@/integration-api/server-rest-lundapadelApi';

export const getProfile = createAsyncThunk(
  'profile/fetch',
  async (profileUid: string) => {
    const { result } = await fetchProfileInfo(profileUid);
    return result;
  }
);

export const getProfileStat = createAsyncThunk(
  'profile/fetchStat',
  async (profileUid: string) => {
    const { result } = await fetchProfileStat(profileUid);
    return result as PlayerProfileStatisticsView;
  }
);

export const saveProfile = createAsyncThunk(
  'profile/save',
  async (payload: PlayerSaveRequest['parameters']) => {
    const { result } = await fetchSaveProfile(payload)

    return result
  }
);

export const getPlayerListThunk = createAsyncThunk(
  'profile/getAllPlayers',
  async ({lowerRatingFilter, upperRatingFilter, textPattern, matchContext}:any) => {
    const { result } = await fetchPlayerList(lowerRatingFilter, upperRatingFilter, textPattern, matchContext);
    return result;
  }
);
