import medalIcon from '@/static/images/icons/medal-icon.svg';
import houseCityIcon from '@/static/images/icons/house-city-icon.svg';
import calendarIcon from '@/static/images/icons/calendar-icon.svg';
import styles from './MatchResultSettings.module.css'
import dayjs from 'dayjs';
import { useAppSelector } from '@/hooks/hooks';
import { nameMonthArray } from '@/utils/constants';
import tourneyIcon from '@/static/images/icons/tournament-type-icon.svg';
import walletIcon from '@/static/images/icons/wallet-small-icon.svg';
import playerIcon from '@/static/images/icons/player-small-icon.svg';
import courtIcon from '@/static/images/icons/booking-court-icon.svg';
import { ClubView, Reference } from '@/integration-api/server-rest-lundapadelApi';
import { FieldConfig, FieldInputProps } from 'formik';
import { calculateEndTime } from './utils';
import { translateTournamentType } from '../SelectTournamentType/utils';

interface MatchResultSettingsInterface {
  getFieldValue: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  selectedCourt?: Reference;
}

function MatchResultSettings({getFieldValue, selectedCourt, ...props}: MatchResultSettingsInterface) {
  const { grades } = useAppSelector(state => state.auth.currentProfile);
  const club: ClubView = getFieldValue('club').value;
  const date = getFieldValue('plannedDate.date').value ? dayjs(getFieldValue('plannedDate.date').value).toDate().getDate() :dayjs().toDate().getDate();
  const month:any = getFieldValue('plannedDate.date').value ? dayjs(getFieldValue('plannedDate.date').value).toDate().getMonth() :dayjs().toDate().getMonth();
  const time = getFieldValue('plannedDate.time').value;
  const playerMinGrade = getFieldValue('playerMinGrade').value || getFieldValue('minGrade').value;
  const playerMaxGrade = getFieldValue('playerMaxGrade').value || getFieldValue('maxGrade').value;
  const kind = getFieldValue('kind').value;
  const playersLimit = getFieldValue('playersLimit').value;
  const courtBooked = getFieldValue('courtBooked').value;
  const duration = getFieldValue('duration').value;
  const price = getFieldValue('price').value;

  return (club || date || playerMinGrade || playerMaxGrade) ? (
    <div className={styles.itemWrapper}>
      {date ? 
        <div className={styles.item}>
          <img src={calendarIcon} alt="Календарь" />
          <div className={styles.text}>{`${date} ${nameMonthArray[month]}${time ? `, ${time} ${duration ? calculateEndTime(time, Number(duration.replace(',', '.')) * 60) : ''}` : ''}`}</div>
        </div> : null
      }
      {club ? 
        <div className={styles.item}>
          <img src={houseCityIcon} alt="Дом" />
          <div className={styles.text}>{club.name}</div>
        </div> : null
      }
      {(playerMinGrade || playerMaxGrade) ?
        <div className={styles.item}>
          <img src={medalIcon} alt="Медаль" />
          {playerMinGrade !== playerMaxGrade ?         
            <div className={styles.text}>
              {`${grades && grades[`GRADE_${playerMinGrade}`]} ... ${grades && grades[`GRADE_${playerMaxGrade}`]}`}
            </div> :
            <div className={styles.text}>{`${grades && grades[`GRADE_${playerMaxGrade}`]}`}</div>
          }
        </div> : null
      }
      {kind ?
        <div className={styles.item}>
          <img src={tourneyIcon} alt="мячик" />
          <div className={styles.text}>{translateTournamentType(kind)}</div>
        </div> : null
      }
      {courtBooked && club.yClientsIntegrated ?
        <div className={styles.item}>
          <img src={courtIcon} alt="корт"/>
          <div className={styles.text}>{selectedCourt ? selectedCourt.caption : 'Не выбран'}</div>
        </div> : null
      }
      {price !== undefined ?
        <div className={styles.item}>
          <img src={walletIcon} alt="икона портмоне" />
          <div className={styles.text}>{price || 0} ₽</div>
        </div> : null
      }
      {playersLimit ?
        <div className={styles.item}>
          <img src={playerIcon} alt="икона игроков" />
          <div className={styles.text}>{playersLimit} игроков</div>
        </div> : null
      }
    </div>
  ) : <></>;
}

export default MatchResultSettings;
