/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CheckVersionStatus {
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    SUPPORTED = 'SUPPORTED'
}

export function CheckVersionStatusFromJSON(json: any): CheckVersionStatus {
    return CheckVersionStatusFromJSONTyped(json, false);
}

export function CheckVersionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckVersionStatus {
    return json as CheckVersionStatus;
}

export function CheckVersionStatusToJSON(value?: CheckVersionStatus | null): any {
    return value as any;
}

