import UserAvatar from "@/components/UserAvatar/UserAvatar";
import styles from './TournamentTableItem.module.css';
import { usePreview } from "react-dnd-preview";
import { TournamentTableItemDraggableProps } from "./TournamentTableItemDraggable";
import editIcon from '@/static/images/icons/edit-table-icon.svg';
import { renderPlacement } from "./utils";
import { useAppSelector } from "@/hooks/hooks";
import CoachWhistle from "@/static/images/icons/CoachWhistle";
import { Role } from "@/integration-api/server-rest-lundapadelApi";

const TournamentTableItemPreview = () => {
  const preview = usePreview<TournamentTableItemDraggableProps & { avatar: string }>();
  const current = useAppSelector(state => state.auth.currentProfile);

  if (!preview.display) {
    return null
  }
  const { item: { participant, placement, avatar }, style, monitor } = preview;

  return ( 
    <div style={{...style, top: '-30px', left: `calc(95px - ${window.innerWidth}px`, width: 'calc(100% - 42px)', background: 'var(--gray-blue)'}}>
      <div className={styles['wrapper']}>
        <div className={styles['main-content']}>
          {renderPlacement(placement)}
          <UserAvatar
            avatarUid={avatar}
            nameInitials={participant.player?.nameInitials}
            badge={{
              element: participant.player?.uid === current.identity?.uid && current.roles?.includes(Role.COACH) ? <CoachWhistle/> : <></>,
              position: 'top-left'
            }}
          />
          <div className={styles['info']}>
            <span className={styles['name']}>{participant.player?.displayName?.substring(0, 25) || '-'}</span>
            <span className={styles['rating-value-draggable']}>{participant.displayRating}</span>
          </div>
        </div>
        <img src={editIcon} className={styles['image']} alt="edit"/>
      </div> 
    </div>
   );
}
 
export default TournamentTableItemPreview;
