import { GradeDetailsInfo, PlayerProfileInfo } from '@/integration-api/server-rest-lundapadelApi';

type GroupedPlayers = {
  [index: string]: PlayerProfileInfo[];
}

export function groupingByGrade(players: PlayerProfileInfo[]) {
  return players?.reduce<GroupedPlayers>((acc, currentPlayer) => {
    const accHasCurrentZoneRating = Boolean(acc?.[currentPlayer.displayGrade!]); // проверяем есть ли в "acc" такая группа

    accHasCurrentZoneRating
      ? acc[currentPlayer.displayGrade!].push(currentPlayer)
      : acc[currentPlayer.displayGrade!] = [currentPlayer]

    return acc;
  }, {});
}


export function sortByRating(playersList: PlayerProfileInfo[]) {
  return playersList.sort((a, b) => {
    if (a.ratingPosition! < b.ratingPosition!) return -1;
    if (a.ratingPosition! > b.ratingPosition!) return 1;
    return 0;
  })
}

export function compareStrings(str1: string, str2: string) {
  const withoutPlus1 = str1.replace('+', '');
  const withoutPlus2 = str2.replace('+', '');
  if (withoutPlus1 < withoutPlus2) {
    return -1;
  } else if (withoutPlus1 > withoutPlus2) {
    return 1;
  } else {
    if (str1.endsWith('+') && !str2.endsWith('+')) {
      return -1;
    } else if (!str1.endsWith('+') && str2.endsWith('+')) {
      return 1;
    } else {
      return 0;
    }
  }
}

export function createRatingGradeGroupId(index: number) {
  return `gradeGroup_${index}`;
}

export function searchParamsToObject(searchParams: URLSearchParams) {
  const searchParamsObject: {[index: string]: string} = {};

  for (let [ key, value ] of searchParams.entries()) {
    searchParamsObject[key] = value;
  }

  return searchParamsObject;
}

export function objectToSearchParams(searchParams: {[index: string]: any}) {
  const URLSearchParamsObject = new URLSearchParams();

  for (let [ key, value ] of Object.entries(searchParams)) {
    URLSearchParamsObject.set(key, value);
  }

  return URLSearchParamsObject;
}

export function getFirstPlayerIndexByGrade(players: PlayerProfileInfo[], grade: GradeDetailsInfo) {
  return players.findIndex((player) => player.displayGrade === grade.displayGrade);
}

export function getPlayerIndex(players: PlayerProfileInfo[], id: string) {
  return players.findIndex((player) => player.uid === id);
}
