/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrentPlayerProfileView,
    CurrentPlayerProfileViewFromJSON,
    CurrentPlayerProfileViewFromJSONTyped,
    CurrentPlayerProfileViewToJSON,
} from './CurrentPlayerProfileView';

/**
 * 
 * @export
 * @interface CurrentPlayerLoadResponse
 */
export interface CurrentPlayerLoadResponse {
    /**
     * 
     * @type {CurrentPlayerProfileView}
     * @memberof CurrentPlayerLoadResponse
     */
    result?: CurrentPlayerProfileView;
    /**
     * 
     * @type {Array<object>}
     * @memberof CurrentPlayerLoadResponse
     */
    messages?: Array<object>;
}

export function CurrentPlayerLoadResponseFromJSON(json: any): CurrentPlayerLoadResponse {
    return CurrentPlayerLoadResponseFromJSONTyped(json, false);
}

export function CurrentPlayerLoadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentPlayerLoadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : CurrentPlayerProfileViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function CurrentPlayerLoadResponseToJSON(value?: CurrentPlayerLoadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': CurrentPlayerProfileViewToJSON(value.result),
        'messages': value.messages,
    };
}

