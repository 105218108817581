import { TourGameInfo } from '@/integration-api/server-rest-get-info';

export const getMatchesByDate = (tourMatchList: Array<TourGameInfo>) => {
  const tourMatchObj: Record<string,  Array<TourGameInfo>> = {};

  tourMatchList.forEach((tourMatch: any) => {
    if (!tourMatch.plannedDate) {
      return;
    }

    const onlyDate = new Date(tourMatch.plannedDate);
    onlyDate.setHours(0, 0, 0, 0);

    tourMatchObj[onlyDate.toISOString()] = tourMatchObj[onlyDate.toISOString()] ?? [];

    tourMatchObj[onlyDate.toISOString()].push(tourMatch);
  });

  return Object.entries(tourMatchObj).sort(([dateA], [dateB]) => {
    if (dateA > dateB) {
      return 1;
    }
    if (dateB > dateA) {
      return -1;
    }
    return 0;
  });
};
