import { ChatMessageInfo } from '@/integration-api/server-rest-lundapadelApi';
import UserChatMessage from '../userChatMessage/UserChatMessage';

interface CurrentUserMessageProps {
  message: ChatMessageInfo;
}

const CurrentUserMessage: React.FC<CurrentUserMessageProps> = (props) => {
  return (
    <UserChatMessage
      hideAvatar
      backgroundColor='#C3D82E'
      contentColor='#071F34'
      avatarPosition='right'
      {...props}
    />
  );
}
 
export default CurrentUserMessage;
