/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfileQuestionnaireSaveParameters,
    ProfileQuestionnaireSaveParametersFromJSON,
    ProfileQuestionnaireSaveParametersFromJSONTyped,
    ProfileQuestionnaireSaveParametersToJSON,
} from './ProfileQuestionnaireSaveParameters';

/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {ProfileQuestionnaireSaveParameters}
     * @memberof RegisterRequest
     */
    parameters?: ProfileQuestionnaireSaveParameters;
}

export function RegisterRequestFromJSON(json: any): RegisterRequest {
    return RegisterRequestFromJSONTyped(json, false);
}

export function RegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : ProfileQuestionnaireSaveParametersFromJSON(json['parameters']),
    };
}

export function RegisterRequestToJSON(value?: RegisterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': ProfileQuestionnaireSaveParametersToJSON(value.parameters),
    };
}

