import { CurrentPlayerProfileView } from "@/integration-api/server-rest-lundapadelApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";


interface AuthState {
  currentProfile: CurrentPlayerProfileView;
  authData: any,
}

const initialState: AuthState = {
  currentProfile: {},
  authData: null
};

export const updateCurrentProfileThunk = createAsyncThunk<CurrentPlayerProfileView, CurrentPlayerProfileView, { state: RootState }>(
  "auth/updateCurrentProfile",
  (currentProfile: CurrentPlayerProfileView, thunkApi) => {
    const prev = thunkApi.getState().auth.currentProfile;
    return { ...prev, ...currentProfile };
  }
);

export const updateGradeRating = createAsyncThunk(
  "auth/updateGradeRating",
  (grades: { [key: string]: string }) => grades
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    cleanupCurrenProfile: () => initialState,
    setAuthData : (state,{payload})=> {
      state.authData = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrentProfileThunk.fulfilled, (state, action) => {
      state.currentProfile = action.payload;
    });
    builder.addCase(updateGradeRating.fulfilled, (state, action) => {
      state.currentProfile.grades = action.payload;
    })
  },
});

export const { cleanupCurrenProfile, setAuthData } = authSlice.actions;

export default authSlice.reducer;
