/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileStatisticsView,
    PlayerProfileStatisticsViewFromJSON,
    PlayerProfileStatisticsViewFromJSONTyped,
    PlayerProfileStatisticsViewToJSON,
} from './PlayerProfileStatisticsView';

/**
 * 
 * @export
 * @interface LoadProfileStatisticResponse
 */
export interface LoadProfileStatisticResponse {
    /**
     * 
     * @type {PlayerProfileStatisticsView}
     * @memberof LoadProfileStatisticResponse
     */
    result?: PlayerProfileStatisticsView;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadProfileStatisticResponse
     */
    messages?: Array<object>;
}

export function LoadProfileStatisticResponseFromJSON(json: any): LoadProfileStatisticResponse {
    return LoadProfileStatisticResponseFromJSONTyped(json, false);
}

export function LoadProfileStatisticResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadProfileStatisticResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : PlayerProfileStatisticsViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadProfileStatisticResponseToJSON(value?: LoadProfileStatisticResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': PlayerProfileStatisticsViewToJSON(value.result),
        'messages': value.messages,
    };
}

