/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameMatchView,
    GameMatchViewFromJSON,
    GameMatchViewFromJSONTyped,
    GameMatchViewToJSON,
} from './GameMatchView';

/**
 * 
 * @export
 * @interface GameMatchStatusCheckParam
 */
export interface GameMatchStatusCheckParam {
    /**
     * 
     * @type {GameMatchView}
     * @memberof GameMatchStatusCheckParam
     */
    match?: GameMatchView;
}

export function GameMatchStatusCheckParamFromJSON(json: any): GameMatchStatusCheckParam {
    return GameMatchStatusCheckParamFromJSONTyped(json, false);
}

export function GameMatchStatusCheckParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameMatchStatusCheckParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'match': !exists(json, 'match') ? undefined : GameMatchViewFromJSON(json['match']),
    };
}

export function GameMatchStatusCheckParamToJSON(value?: GameMatchStatusCheckParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'match': GameMatchViewToJSON(value.match),
    };
}

