import styles from './ClubSelectionPage.module.css';
import Header from '@/components/Header/Header';
import HLWrapper from '@/components/HLWrapper/HLWrapper';
import { CityClubsItemView, ClubLoadListParameters, ClubView } from '@/integration-api/server-rest-lundapadelApi';
import Search from '@/components/Search/Search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ClubCityBelonging from '../components/ClubCityBelonging/ClubCityBelonging';
import { useAppSelector } from '@/hooks/hooks';
import useDebounce from '@/hooks/useDebounce';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import { cityClubsLoadList } from '../../services';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

type ClubSelectionPageProps = {
  selectedClub?: ClubView;
  onClose: () => void;
  handleClubSelect: (uid: string) => void;
}

const ClubSelectionPage = ({ selectedClub, onClose, handleClubSelect }: ClubSelectionPageProps) => {
  const profileCity = useAppSelector(state => state.auth.currentProfile.city);

  const [textPattern, setTextPattern] = useState<string>('');
  const [favorite, setFavorite] = useState(false);
  const [clubList, setClubList] = useState<Array<CityClubsItemView>>([])

  const updateList = useCallback(() => {
    cityClubsLoadList({
      favorite,
      textPattern,
    })
      .then(({ result }) => {
        if(result) {
          setClubList(result.items ?? []);
        }
      })
      .catch(err => {
        if (err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
  }, [favorite, textPattern]);

  const debouncedUpdateClubList = useDebounce(updateList, 500);

  useEffect(() => {
    debouncedUpdateClubList();
  }, [textPattern, favorite])

  const sortedClubList = useMemo(() => {
    return clubList.map(entry => (
      <ClubCityBelonging
        key={entry.city}
        city={entry.city}
        clubs={entry.clubs ?? []}
        selectedClub={selectedClub?.reference?.uid}
        handleClubSelect={handleClubSelect}
      />
    ))
  }, [clubList, profileCity, selectedClub?.reference?.uid]);

  return ( 
    <section>
      <Header 
        className={styles['header']}
        handleClick={() => {
          onClose()
        }}
      >
        Все клубы
      </Header>
      <HLWrapper>
        <Search
          placeholder='Найти клуб'
          searchHandler={(e) => setTextPattern(e.target.value)}
        />
      </HLWrapper>
      <div className={styles.partnersSwitcher}>
        <span>Любимые клубы</span>
        <ToggleSwitch 
          id='favorites'
          onChange={() => setFavorite(!favorite)}
        /> 
      </div>
      <div className={styles['clubs']}>
        {sortedClubList}
      </div>
    </section>
  );
}
 
export default ClubSelectionPage;
