import { useCallback, useContext, useRef } from 'react';
import styles from './LineupScoresList.module.css';
import { LineupScoreContext } from '../../GameResults/utils';
import LineupScoresListItem from '../LineupScoresListItem/LineupScoresListItem';
import CrossIcon from '@/static/images/icons/CrossIcon';
import { v4 } from 'uuid';
import { ModifiedGameSetView } from '../../GameResults/GameResultsPage.interfaces';

const LineupScoresList = () => {
  const { setScores,  addSetScore } = useContext(LineupScoreContext);

  const ref = useRef<HTMLDivElement>(null);

  const addSet = useCallback(() => {
    const emptySet: ModifiedGameSetView = {
      score1: 0,
      score2: 0,
      uid: v4()
    };
    addSetScore?.(emptySet);
    scrollToEnd();
  }, [addSetScore]);

  const renderScoreItems = () => {
    return setScores.map((set, index) => (
      <LineupScoresListItem
        type='set'
        key={set.uid}
        index={index}
        set={set}
      />
    ))
  }

  const scrollToEnd = () => {
    setTimeout(() => {
      if(ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    }, 100);
  };

  return ( 
    <>
      <div className={styles['wrapper']}>
        {renderScoreItems()}                                                                                         
      </div> 
      {setScores.length ?
        <div className={styles['add-set']} onClick={addSet}>
          <CrossIcon fill='var(--green-yellow)'/>
          <span>Добавить тренировочный сет</span>
        </div> : 
        <div className={styles['add-set-wrapper']}>
          <div className={styles['add-set']} onClick={addSet}>
            <CrossIcon fill='var(--green-yellow)'/>
            <span>Добавить тренировочный сет</span>
          </div>
          <span className={styles['description']}>
            Вы можете добавить отдельные сеты, которые не входят в матч. Например, тренировочный сет перед матчем.  
          </span>
        </div>
      }
      <div ref={ref}></div>
    </>
  );
}
 
export default LineupScoresList;
