/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfileQuestionnaireView,
    ProfileQuestionnaireViewFromJSON,
    ProfileQuestionnaireViewFromJSONTyped,
    ProfileQuestionnaireViewToJSON,
} from './ProfileQuestionnaireView';

/**
 * 
 * @export
 * @interface ProfileQuestionnaireSaveParameters
 */
export interface ProfileQuestionnaireSaveParameters {
    /**
     * 
     * @type {string}
     * @memberof ProfileQuestionnaireSaveParameters
     */
    registrationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileQuestionnaireSaveParameters
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileQuestionnaireSaveParameters
     */
    countryCode?: string;
    /**
     * 
     * @type {ProfileQuestionnaireView}
     * @memberof ProfileQuestionnaireSaveParameters
     */
    view?: ProfileQuestionnaireView;
}

export function ProfileQuestionnaireSaveParametersFromJSON(json: any): ProfileQuestionnaireSaveParameters {
    return ProfileQuestionnaireSaveParametersFromJSONTyped(json, false);
}

export function ProfileQuestionnaireSaveParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileQuestionnaireSaveParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationToken': !exists(json, 'registrationToken') ? undefined : json['registrationToken'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'view': !exists(json, 'view') ? undefined : ProfileQuestionnaireViewFromJSON(json['view']),
    };
}

export function ProfileQuestionnaireSaveParametersToJSON(value?: ProfileQuestionnaireSaveParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationToken': value.registrationToken,
        'phone': value.phone,
        'countryCode': value.countryCode,
        'view': ProfileQuestionnaireViewToJSON(value.view),
    };
}

