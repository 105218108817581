import { GameTournamentKind } from "@/integration-api/server-rest-lundapadelApi";
import { GameListCardSubtype } from "./GameListCard.interfaces";

export function fillPrivateEye(subtype?: GameListCardSubtype) {
  switch(subtype) {
    case 'orange': 
      return '#731026';
    case 'green':
      return '#2C4F2E';
    case 'gray': 
      return '#515E69';
    case 'black':
      return '#183249';
    default:
      return '#296AB5';
  }
}

export function translateGameTournamentKind(kind: GameTournamentKind) {
  switch(kind) {
    case GameTournamentKind.AMERICANO:
      return 'Американо';
    case GameTournamentKind.MEXICANO:
      return 'Мексикано';
    default:
      const _: never = kind;
      throw new Error('not every case is handled');
  }
}
