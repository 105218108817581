/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GameTournamentKind {
    AMERICANO = 'AMERICANO',
    MEXICANO = 'MEXICANO'
}

export function GameTournamentKindFromJSON(json: any): GameTournamentKind {
    return GameTournamentKindFromJSONTyped(json, false);
}

export function GameTournamentKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameTournamentKind {
    return json as GameTournamentKind;
}

export function GameTournamentKindToJSON(value?: GameTournamentKind | null): any {
    return value as any;
}

