import PuffLoader from 'react-spinners/PuffLoader';
import styles from './TournamentResultsCourts.module.css';
import Header from "@/components/Header/Header";
import { Fragment, useContext } from 'react';
import { TournamentResultsNewContext } from '../utils';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import LineupCard from '@/components/LineupCard/LineupCard';

const TournamentResultsCourts = () => {
  const { loading, round, updateStep } = useContext(TournamentResultsNewContext);
  
  const renderLineups = () => {
    let actualIndex = 0;
    return round?.lineups?.map((lineup, index) => {
      return lineup.games?.slice(0, 1)?.map((game, i) => {
        actualIndex++;
        return (
          <Fragment key={`${index}_${i}`}>
            {!i ? <div className={styles['court-number']} style={{ marginTop: !index ? '0' : '24px' }}>{index + 1} корт</div> : null}
            <LineupCard
              type='undistributed'
              index={actualIndex}
              lineup={{ 
                player1: game.player1,
                player2: game.player2,
                player3: game.player3,
                player4: game.player4,
                score1: game.score1,
                score2: game.score2
              }}
            />
          </Fragment>
        );
      })
    });
  }
  return ( 
    <>
      <Header className={styles['header']}>
        Распределение по кортам
      </Header>
      {loading ?
        <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} />:
        <>        
          <div className={styles['wrapper']}>
            <div className={styles['lineups-wrapper']}>
              {renderLineups()}
            </div>
          </div>
          <OversideWrapper className={styles['button']}>
            <Button 
              variant={ButtonVariants.YELLOW}
              onClick={() => updateStep?.('lineups')}
            >
              Выставить результаты
            </Button>
          </OversideWrapper>
        </>
      }
    </>
  );
}
 
export default TournamentResultsCourts;
