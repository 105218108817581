interface StickyTopBorderProps {
  color: string;
  top?: string;
  height?: string
}

export default function StickyTopBorder({ color, top, height }: StickyTopBorderProps) {
  return (
    <hr
      style={{
        position: 'sticky',
        top: top ?? '0px',
        margin: '0px',
        width: '100%',
        background: color,
        border: 'none',
        height: height ?? '1.5px',
        zIndex: 2,
      }}
    />
  )
}
