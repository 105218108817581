/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChatType {
    GAME = 'GAME'
}

export function ChatTypeFromJSON(json: any): ChatType {
    return ChatTypeFromJSONTyped(json, false);
}

export function ChatTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatType {
    return json as ChatType;
}

export function ChatTypeToJSON(value?: ChatType | null): any {
    return value as any;
}

