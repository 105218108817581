import { FC, useState } from 'react'
import './RatingRange.css'
import { ReactSliderProps } from 'react-slider'
import { CurrentPlayerProfileView } from '@/integration-api/server-rest-lundapadelApi';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { gradesReducer, styles } from './utils';

export interface RatingRangeProps<T extends [number, number]>  extends ReactSliderProps<T> {
  value: T;
  onChange: (value: T) => void;
  grades: Exclude<CurrentPlayerProfileView['grades'], undefined>;
}

const RatingRange: FC<RatingRangeProps<[number, number]>> = (props) => {
  const { grades, value, onChange } = props;

  const [touchedRange, setTouchedRange] = useState(false);

  const gradesArray = Object.keys(grades).sort().map(key => grades[key]);
  const marks = gradesArray.reduce<{[index: number]: {style: React.CSSProperties, label: string}}>(gradesReducer, {})

  const handleChangeRange = (values: [number, number]) => {
    onChange(values);
  } 

  const handleTouchMove = () => {
    setTouchedRange(true);
  }

  const handleTouchEnd = (handleValue: number) => {
    // handleTouchEnd срабатывает в 2-х случаях: при движении ползунка и при клике по нему
    // если обработчик вызван НЕ движением ползунка, установим интервал в одну точку
    if (!touchedRange) onChange([handleValue, handleValue]);
    setTouchedRange(false);
  }

  return (
    <div className='range-wrapper'>
      <Slider
        range
        min={1}
        max={gradesArray.length}
        step={1}
        marks={marks}
        allowCross={false}
        value={value}
        onChange={(value) => handleChangeRange(value as [number, number])}
        styles={{
          track: styles.track,
          rail: styles.rail,
          handle: styles.handle,
        }}
        activeDotStyle={styles.activeDotStyle}
        dotStyle={styles.dotStyle}
        handleRender={(meta) => (
          <span
            key={meta.key}
            {...meta.props}
            className={`thumb ${(meta.props as any).className}`}
            onTouchEnd={() => handleTouchEnd((meta.props as any)["aria-valuenow"])}
            onTouchMove={handleTouchMove}
          />
        )}
      />
    </div>
  );
}

export default RatingRange;
