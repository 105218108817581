/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DayOfWeek,
    DayOfWeekFromJSON,
    DayOfWeekFromJSONTyped,
    DayOfWeekToJSON,
} from './DayOfWeek';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    InviteRule,
    InviteRuleFromJSON,
    InviteRuleFromJSONTyped,
    InviteRuleToJSON,
} from './InviteRule';
import {
    PlayerProfileDetailsView,
    PlayerProfileDetailsViewFromJSON,
    PlayerProfileDetailsViewFromJSONTyped,
    PlayerProfileDetailsViewToJSON,
} from './PlayerProfileDetailsView';
import {
    PlayerProfileStatisticsView,
    PlayerProfileStatisticsViewFromJSON,
    PlayerProfileStatisticsViewFromJSONTyped,
    PlayerProfileStatisticsViewToJSON,
} from './PlayerProfileStatisticsView';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';
import {
    RelationshipType,
    RelationshipTypeFromJSON,
    RelationshipTypeFromJSONTyped,
    RelationshipTypeToJSON,
} from './RelationshipType';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import {
    ViewObjectIdentity,
    ViewObjectIdentityFromJSON,
    ViewObjectIdentityFromJSONTyped,
    ViewObjectIdentityToJSON,
} from './ViewObjectIdentity';

/**
 * 
 * @export
 * @interface PlayerProfileView
 */
export interface PlayerProfileView {
    /**
     * 
     * @type {ViewObjectIdentity}
     * @memberof PlayerProfileView
     */
    identity?: ViewObjectIdentity;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileView
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileView
     */
    playerId?: number;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    profileImageUid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    avatarUid?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    nameInitials?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    displayRating?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileView
     */
    leftSide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileView
     */
    rightSide?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    city?: string;
    /**
     * 
     * @type {Reference}
     * @memberof PlayerProfileView
     */
    club?: Reference;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileView
     */
    lookingForTeam?: boolean;
    /**
     * 
     * @type {InviteRule}
     * @memberof PlayerProfileView
     */
    inviteRule?: InviteRule;
    /**
     * 
     * @type {RelationshipType}
     * @memberof PlayerProfileView
     */
    relationshipType?: RelationshipType;
    /**
     * 
     * @type {PlayerProfileDetailsView}
     * @memberof PlayerProfileView
     */
    details?: PlayerProfileDetailsView;
    /**
     * 
     * @type {PlayerProfileStatisticsView}
     * @memberof PlayerProfileView
     */
    statistics?: PlayerProfileStatisticsView;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    telegramUsername?: string;
    /**
     * 
     * @type {Grade}
     * @memberof PlayerProfileView
     */
    grade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof PlayerProfileView
     */
    displayGrade?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerProfileView
     */
    gradeVerified?: boolean;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof PlayerProfileView
     */
    freeDays?: Array<DayOfWeek>;
    /**
     * 
     * @type {Array<Reference>}
     * @memberof PlayerProfileView
     */
    favoriteClubs?: Array<Reference>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof PlayerProfileView
     */
    roles?: Array<Role>;
}

export function PlayerProfileViewFromJSON(json: any): PlayerProfileView {
    return PlayerProfileViewFromJSONTyped(json, false);
}

export function PlayerProfileViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': !exists(json, 'identity') ? undefined : ViewObjectIdentityFromJSON(json['identity']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'playerId': !exists(json, 'playerId') ? undefined : json['playerId'],
        'profileImageUid': !exists(json, 'profileImageUid') ? undefined : json['profileImageUid'],
        'avatarUid': !exists(json, 'avatarUid') ? undefined : json['avatarUid'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'nameInitials': !exists(json, 'nameInitials') ? undefined : json['nameInitials'],
        'displayRating': !exists(json, 'displayRating') ? undefined : json['displayRating'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'leftSide': !exists(json, 'leftSide') ? undefined : json['leftSide'],
        'rightSide': !exists(json, 'rightSide') ? undefined : json['rightSide'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'club': !exists(json, 'club') ? undefined : ReferenceFromJSON(json['club']),
        'lookingForTeam': !exists(json, 'lookingForTeam') ? undefined : json['lookingForTeam'],
        'inviteRule': !exists(json, 'inviteRule') ? undefined : InviteRuleFromJSON(json['inviteRule']),
        'relationshipType': !exists(json, 'relationshipType') ? undefined : RelationshipTypeFromJSON(json['relationshipType']),
        'details': !exists(json, 'details') ? undefined : PlayerProfileDetailsViewFromJSON(json['details']),
        'statistics': !exists(json, 'statistics') ? undefined : PlayerProfileStatisticsViewFromJSON(json['statistics']),
        'telegramUsername': !exists(json, 'telegramUsername') ? undefined : json['telegramUsername'],
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'displayGrade': !exists(json, 'displayGrade') ? undefined : json['displayGrade'],
        'gradeVerified': !exists(json, 'gradeVerified') ? undefined : json['gradeVerified'],
        'freeDays': !exists(json, 'freeDays') ? undefined : ((json['freeDays'] as Array<any>).map(DayOfWeekFromJSON)),
        'favoriteClubs': !exists(json, 'favoriteClubs') ? undefined : ((json['favoriteClubs'] as Array<any>).map(ReferenceFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleFromJSON)),
    };
}

export function PlayerProfileViewToJSON(value?: PlayerProfileView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': ViewObjectIdentityToJSON(value.identity),
        'active': value.active,
        'playerId': value.playerId,
        'profileImageUid': value.profileImageUid,
        'avatarUid': value.avatarUid,
        'displayName': value.displayName,
        'nameInitials': value.nameInitials,
        'displayRating': value.displayRating,
        'email': value.email,
        'leftSide': value.leftSide,
        'rightSide': value.rightSide,
        'phone': value.phone,
        'countryCode': value.countryCode,
        'city': value.city,
        'club': ReferenceToJSON(value.club),
        'lookingForTeam': value.lookingForTeam,
        'inviteRule': InviteRuleToJSON(value.inviteRule),
        'relationshipType': RelationshipTypeToJSON(value.relationshipType),
        'details': PlayerProfileDetailsViewToJSON(value.details),
        'statistics': PlayerProfileStatisticsViewToJSON(value.statistics),
        'telegramUsername': value.telegramUsername,
        'grade': GradeToJSON(value.grade),
        'displayGrade': value.displayGrade,
        'gradeVerified': value.gradeVerified,
        'freeDays': value.freeDays === undefined ? undefined : ((value.freeDays as Array<any>).map(DayOfWeekToJSON)),
        'favoriteClubs': value.favoriteClubs === undefined ? undefined : ((value.favoriteClubs as Array<any>).map(ReferenceToJSON)),
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleToJSON)),
    };
}

