/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessageInfo
 */
export interface ChatMessageInfo {
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageInfo
     */
    me?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInfo
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInfo
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInfo
     */
    nameInitials?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInfo
     */
    profileUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInfo
     */
    imageUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageInfo
     */
    chatOwner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInfo
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageInfo
     */
    admin?: boolean;
}

export function ChatMessageInfoFromJSON(json: any): ChatMessageInfo {
    return ChatMessageInfoFromJSONTyped(json, false);
}

export function ChatMessageInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'me': !exists(json, 'me') ? undefined : json['me'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'nameInitials': !exists(json, 'nameInitials') ? undefined : json['nameInitials'],
        'profileUid': !exists(json, 'profileUid') ? undefined : json['profileUid'],
        'imageUid': !exists(json, 'imageUid') ? undefined : json['imageUid'],
        'chatOwner': !exists(json, 'chatOwner') ? undefined : json['chatOwner'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
    };
}

export function ChatMessageInfoToJSON(value?: ChatMessageInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'me': value.me,
        'author': value.author,
        'message': value.message,
        'nameInitials': value.nameInitials,
        'profileUid': value.profileUid,
        'imageUid': value.imageUid,
        'chatOwner': value.chatOwner,
        'created': value.created,
        'admin': value.admin,
    };
}

