import React from 'react';

function Forgot() {
  return (
    <div>
      Forgot
    </div>
  );
}

export default Forgot;