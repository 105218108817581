/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentKickPlayerParam,
    TournamentKickPlayerParamFromJSON,
    TournamentKickPlayerParamFromJSONTyped,
    TournamentKickPlayerParamToJSON,
} from './TournamentKickPlayerParam';

/**
 * 
 * @export
 * @interface OwnerKickPlayerTournamentRequest
 */
export interface OwnerKickPlayerTournamentRequest {
    /**
     * 
     * @type {TournamentKickPlayerParam}
     * @memberof OwnerKickPlayerTournamentRequest
     */
    parameters?: TournamentKickPlayerParam;
}

export function OwnerKickPlayerTournamentRequestFromJSON(json: any): OwnerKickPlayerTournamentRequest {
    return OwnerKickPlayerTournamentRequestFromJSONTyped(json, false);
}

export function OwnerKickPlayerTournamentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerKickPlayerTournamentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : TournamentKickPlayerParamFromJSON(json['parameters']),
    };
}

export function OwnerKickPlayerTournamentRequestToJSON(value?: OwnerKickPlayerTournamentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': TournamentKickPlayerParamToJSON(value.parameters),
    };
}

