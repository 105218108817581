import { FC } from "react";
import { HeadlingProps } from "./Headling.interfaces";
import styles from './Headling.module.css';

const Headling: FC<HeadlingProps> = ({className, children, appearence, hasExpansion, ...props}): JSX.Element => {
  return (
    <div className={`${className} ${styles['headling']} ${styles[appearence]} ${hasExpansion && styles['has-expansion']}`} {...props}>
      {children}
    </div>
  );
}

export default Headling;