/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileLoadParameters,
    PlayerProfileLoadParametersFromJSON,
    PlayerProfileLoadParametersFromJSONTyped,
    PlayerProfileLoadParametersToJSON,
} from './PlayerProfileLoadParameters';

/**
 * 
 * @export
 * @interface PlayerLoadRequest
 */
export interface PlayerLoadRequest {
    /**
     * 
     * @type {PlayerProfileLoadParameters}
     * @memberof PlayerLoadRequest
     */
    parameters?: PlayerProfileLoadParameters;
}

export function PlayerLoadRequestFromJSON(json: any): PlayerLoadRequest {
    return PlayerLoadRequestFromJSONTyped(json, false);
}

export function PlayerLoadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerLoadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : PlayerProfileLoadParametersFromJSON(json['parameters']),
    };
}

export function PlayerLoadRequestToJSON(value?: PlayerLoadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': PlayerProfileLoadParametersToJSON(value.parameters),
    };
}

