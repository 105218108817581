import {
  nameMonthArray,
  shortNameDayOfWeekArray,
  TIME_FORMAT_HH_MM,
} from '@/utils/constants';
import { ClubView, DayOfWeek, LocalDate, LocalDateTime, PlayerProfileView, Role, TournamentKind } from '@/integration-api/server-rest-lundapadelApi';

const dayjs = require('dayjs');
const isToday = require('dayjs/plugin/isToday');
const isTomorrow = require('dayjs/plugin/isTomorrow');
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const formatter = {
  dateWithDots(value?: string) {
    const dateObj = value ? new Date(value) : new Date(); // нужно еще добавить валидацию передаваемого value
  
    if (Number.isNaN(dateObj.getTime())) return '';

    const date = {
      date:
        dateObj.getDate() + 1 > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`,
      month:
        dateObj.getMonth() + 1 > 9
          ? dateObj.getMonth() + 1
          : `0${dateObj.getMonth() + 1}`,
      year: dateObj.getFullYear(),
    };

    return `${date.date}.${date.month}.${date.year}`;
  },

  formatDate(date: Date | string, formatter: string) {
    return dayjs(date).format(formatter);
  },

  relativeDate(value: string, numAgoDays: number = 7) {
    const current = new Date();

    const currentDay = current.getDate();
    const currentMonth = current.getMonth() + 1;
    const currentYear = current.getFullYear();

    const date = new Date(value);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    for (let i = 1; i <= numAgoDays; i++) {
      if (
        year === currentYear &&
        month === currentMonth &&
        day === currentDay - i
      ) {
        return `${i} ${this.formatEndByNumber(
          ['день', 'дня', 'дней'],
          i
        )} назад`;
      }
    }

    if (year === currentYear && month === currentMonth && day === currentDay) {
      return 'Сегодня';
    }

    if (
      year === currentYear &&
      month === currentMonth &&
      day === currentDay + 1
    ) {
      return 'Завтра';
    }

    return this.dateWithDots(date.toISOString());
  },

  relativeDateTourney(value?: number) {
    switch(value) {
      case 0:
        return 'Турнир состоится сегодня';
      case 1:
        return 'Турнир состоится завтра';
      default: 
        return `До турнира осталось ${value} ${this.formatEndByNumber(['день', 'дня', 'дней'], value ?? 2)}`;
    }
  },

  relativeDateShort(value: LocalDate | LocalDateTime) {
    const current = new Date();
    const date = new Date(value + 'Z');
    const difference = (current.getTime() - date.getTime()) / 1000;
    if (difference < 60) {
      return `${difference} с`;
    } else if (difference < 3600) {
        return `${Math.floor(difference / 60)} м`;
    } else if (difference < 86400) {
        return `${Math.floor(difference / 3600)} ч`;
    } else if (difference < 31536000) {
        return `${Math.floor(difference / 86400)} д`;
    } else {
      return `${Math.floor(difference / 31449600)} г`;
    }
  },

  formatDateToDayDateMonth(gameDate?: Date | LocalDate | LocalDateTime) {
    if (!gameDate) {
      return '';
    }

    const month = nameMonthArray[dayjs(gameDate).get('month')];
    const day = shortNameDayOfWeekArray[dayjs(gameDate).get('day')];
    const date = dayjs(gameDate).get('date');

    return `${day} ${date} ${month}`;
  },

  formatDateToDayDateMonthWithParentheses(gameDate?: Date | LocalDate | LocalDateTime) {
    if(!gameDate) {
      return '';
    }
    const month = nameMonthArray[dayjs(gameDate).get('month')];
    const day = shortNameDayOfWeekArray[dayjs(gameDate).get('day')];
    const date = dayjs(gameDate).get('date');

    return `${date} ${month} (${day})`.toLowerCase();
  },

  formatEndByNumber(forms: [string, string, string], num: number) {
    let lastDigit = num % 10;
    let lastTwoDigits = num % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return forms[0];
    } else if (
      lastDigit >= 2 &&
      lastDigit <= 4 &&
      (lastTwoDigits < 10 || lastTwoDigits >= 20)
    ) {
      return forms[1];
    } else {
      return forms[2];
    }
  },

  formatRuPhone(phone?: string) {
    const code = phone?.slice(0, 3);
    const threeDigits = phone?.slice(3, 6);
    const secondLastDigits = phone?.slice(6, 8);
    const lastDigits = phone?.slice(-2);

    return `(${code}) ${threeDigits}-${secondLastDigits}-${lastDigits}`;
  }  
};

export function sortDayByOrder(dayList: Array<DayOfWeek>) {
  const dayOfWeek = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ] as const;

  return dayOfWeek.filter((day) => dayList.includes(day as any));
}

export const createImgSrc = {
  // this.origin - это чисто костыль, так как на localhost нет никакого Apache, который определяет, что по такому пути нужно доставать изображение
  origin: window.location.origin.includes('localhost') ? 'https://app.lundapadel-dev.gridnine.com' : window.location.origin,
  // origin: window.location.origin.includes('localhost') ? 'https://app.lundapadel.ru' : window.location.origin, если билдим нативное приложение
  playerProfile(ownerUid?: string, imgSrc?: string) {
    return `${this.origin}/attachment?ownerType=PLAYER_PROFILE&ownerUid=${ownerUid}&imageUid=${imgSrc}`
  },
  advertisement(ownerUid?: string, imgSrc?: string) {
    return `${this.origin}/attachment?ownerType=ADVERTISEMENT&ownerUid=${ownerUid}&imageUid=${imgSrc}`
  }
}

export default formatter;

 export function getOSName() {
  const userAgent = window.navigator.userAgent,
    // @ts-ignore
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export function playerHasRole(profile: PlayerProfileView, role: Role): boolean {
  return !!profile?.roles?.includes(role);
}
