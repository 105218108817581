import GameResultsPageContextWrapper from "./GameResultsPageContextWrapper";
import GameResultsSteps from "../GameResultsSteps/GameResultsSteps";

const GameResultsPage = () => {
  return ( 
    <GameResultsPageContextWrapper>
      <GameResultsSteps/>
    </GameResultsPageContextWrapper>
  );
}
 
export default GameResultsPage;
