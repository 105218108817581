const BallIcon = () => {
  return (  
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2913 15.5827C13.766 15.5827 16.5827 12.766 16.5827 9.29134C16.5827 5.81663 13.766 3 10.2913 3C6.81663 3 4 5.81663 4 9.29134C4 12.766 6.81663 15.5827 10.2913 15.5827Z" stroke="#C3D82E" strokeWidth="1.5"/>
      <path d="M10.2913 3C10.2599 5.09753 9.74462 6.671 8.74619 7.71976C7.74807 8.76916 6.16579 9.29354 4 9.29386" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5703 9.60765C14.5218 9.46704 12.9562 9.90523 11.8741 10.9213C10.792 11.9373 10.2642 13.4913 10.29 15.5829" stroke="#C3D82E" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
}
 
export default BallIcon;
