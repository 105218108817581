import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.css';
import HomeLogo from './components/HomeLogo';
import BallLogo from './components/BallLogo';
import RatingLogo from './components/RatingLogo';
import PersonLogo from './components/PersonLogo';

function NavBar() {
  return (
    <nav className={styles.navBar}>
      <NavLink to="/" className={styles.navBarLink}>
        <div className={styles['wrapper']}>
          <HomeLogo />
          <br />
          Главная
        </div>
      </NavLink>
      <NavLink to="/play" className={styles.navBarLink}>
        <div className={styles['wrapper']}>
          <BallLogo />
          <br />
          Играть
        </div>
      </NavLink>
      <NavLink to="/rating" className={styles.navBarLink} id='rating'>
        <div className={styles['wrapper']}>
          <RatingLogo />
          <br />
          Рейтинг
        </div>
      </NavLink>
      <NavLink to="/profile/me" className={styles.navBarLink}>
        <div className={styles['wrapper']}>
          <PersonLogo />
          <br />
          Профиль
        </div>
      </NavLink>
    </nav>
  );
}

export default NavBar;
