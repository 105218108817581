/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileView,
    PlayerProfileViewFromJSON,
    PlayerProfileViewFromJSONTyped,
    PlayerProfileViewToJSON,
} from './PlayerProfileView';

/**
 * 
 * @export
 * @interface PlayerSaveRequest
 */
export interface PlayerSaveRequest {
    /**
     * 
     * @type {PlayerProfileView}
     * @memberof PlayerSaveRequest
     */
    parameters?: PlayerProfileView;
}

export function PlayerSaveRequestFromJSON(json: any): PlayerSaveRequest {
    return PlayerSaveRequestFromJSONTyped(json, false);
}

export function PlayerSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : PlayerProfileViewFromJSON(json['parameters']),
    };
}

export function PlayerSaveRequestToJSON(value?: PlayerSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': PlayerProfileViewToJSON(value.parameters),
    };
}

