import { ChatMessageInfo } from '@/integration-api/server-rest-lundapadelApi';
import styles from './UserChatMessage.module.css';
import dayjs from 'dayjs';
import UserAvatar from '@/components/UserAvatar/UserAvatar';
import { usePlayerAvatar } from '@/hooks/usePlayerAvatar';
import { useNavigate } from 'react-router-dom';

export interface UserChatMessageProps {
  backgroundColor?: string;
  contentColor?: string;
  avatarPosition?: 'left' | 'right';
  hideAvatar?: boolean;
  message: ChatMessageInfo;
}

const UserChatMessage: React.FC<UserChatMessageProps> = (props) => {
  const {
    backgroundColor,
    contentColor = '#d1e1ef',
    avatarPosition = 'left',
    hideAvatar = false,
    message,
  } = props;

  const avatarUid = usePlayerAvatar({uid: message.profileUid!, avatarUid: message.imageUid, subscribed: '_'});
  const navigate = useNavigate();

  const cardFlexDirection: React.CSSProperties['flexDirection'] = avatarPosition === 'left' ? 'row' : 'row-reverse';
  const time = dayjs(message.created).format('HH:mm');

  const handleAvatarClick = () => {
    if (!message.admin) navigate(`/profile/${message.profileUid!}`);
  };

  return (
    <article className={styles.message} style={{flexDirection: cardFlexDirection}}>
      {hideAvatar || <UserAvatar onClick={handleAvatarClick} className={styles.avatar} avatarUid={avatarUid} nameInitials={message.nameInitials} />}
      <div className={styles.card} style={{backgroundColor}}>
        {message.me || (
          <span className={styles['user-name']}>{message.author} {message.chatOwner && <span className={styles['user-match-role']}>(Организатор)</span>}</span>
        )}
        <span className={styles.content} style={{color: contentColor}}>
          {message.message}
        </span>
        <span className={styles.time} style={{color: contentColor}}>{time}</span>
      </div>
    </article>
  );
}
 
export default UserChatMessage;
