import { GameSetView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './CompletedGameSetCard.module.css';
import StickyTopBorder from '@/components/StickyTopBorder/StickyTopBorder';
import SetWinnerArrowIcon from '@/static/images/icons/SetWInnerArrowIcon';

type CompletedGameSetCardProps = {
  gameSets: GameSetView[];
  type: 'match' | 'set';
}

const CompletedGameSetCard = ({ gameSets, type }: CompletedGameSetCardProps) => {
  const renderSets = () => {
    return gameSets?.map(set => (
      <div className={styles['set-wrapper']}>
        <div className={`${styles['score']} ${set?.score1! < set?.score2! ? styles['loss'] : ''}`.trim()}>
          <span>
            {set?.score1! > set?.score2! ?
              <SetWinnerArrowIcon/> : null
            } 
          </span>
          <span>{set?.score1}</span>
        </div>
        <div className={`${styles['score']} ${set?.score1! > set?.score2! ? styles['loss'] : ''}`.trim()}>
          <span>{set?.score2}</span>
          <span>
            {set?.score1! < set?.score2! ?
              <SetWinnerArrowIcon/> : null
            } 
          </span>
        </div>
      </div>
    ))
  }

  return (  
    <>
    <div>
      <StickyTopBorder top='-10px' color={type === 'match' ? 'var(--green-yellow)' : 'var(--blue)'}/>
      <div className={`${styles['card']} ${styles[type]}`}>
        <div className={styles['type']}>{type === 'match' ? 'Матч' : 'Сет'}</div>
        <div className={styles['sets-wrapper']}>
          {renderSets()}
        </div>
      </div>
    </div>
  </>
  );
}
 
export default CompletedGameSetCard;
