import { ComponentProps, PropsWithChildren } from 'react';
import styles from './InnerPadding.module.css';

type InnerPaddingProp = ComponentProps<"div"> & PropsWithChildren

function InnerPadding({className, children, ...anotherProps}: InnerPaddingProp) {
  return (
    <div {...anotherProps} className={`${styles.innerPadding} ${className || ''}`}>
      {children}
    </div>
  );
}

export default InnerPadding;
