import RestClient from '@/integration/RestClient';

export function getPartners(profileUid: string) {
  return RestClient.profileApi.partnerLoadList({
    profileUid
  })
}

export function partnerDelete(partnersUids: string[]) {
  return RestClient.profileApi.partnerDelete({
    partnerDeleteRequest: {
      parameters: {
        partnersUids
      }
    }
  })
}
