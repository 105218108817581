import styles from "./DateWrapper.module.css";
import { FC } from "react";
import type { DateWrapperProps } from "./DateWrapper.interfaces";
import formatter from "@/utils/utils";

const DateWrapper: FC<DateWrapperProps> = (props): JSX.Element => {
  const { date, children } = props;

  return (
    <div className={styles["date-wrapper"]}>
      <div className={styles["date"]}>
        {formatter.relativeDate(date.toISOString())}
      </div>
      <div className={styles["list"]}>{children}</div>
    </div>
  );
};

export default DateWrapper;
