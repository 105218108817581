import HLine from '../HLine/HLine';
import NavBar from '../NavBar/NavBar';
import { BasePageWrapperProps } from './BasePageWrapper.interfaces';
import styles from './BasePageWrapper.module.css';

const BasePageWrapper: React.FC<BasePageWrapperProps> = (props) => {
  const { children, contentWrapperClassName = '', showNavBar, className = '' } = props;
  
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={`${styles.content} ${contentWrapperClassName}`}>
        {children}
      </div>

      {showNavBar && <div className={styles.navBar}>
        <HLine calssName={styles.HLine} />
        <NavBar />
      </div>}
    </div>
  );
}

export default BasePageWrapper;
