/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChatMessageSendParameters,
    ChatMessageSendParametersFromJSON,
    ChatMessageSendParametersFromJSONTyped,
    ChatMessageSendParametersToJSON,
} from './ChatMessageSendParameters';

/**
 * 
 * @export
 * @interface ChatMessageSendRequest
 */
export interface ChatMessageSendRequest {
    /**
     * 
     * @type {ChatMessageSendParameters}
     * @memberof ChatMessageSendRequest
     */
    parameters?: ChatMessageSendParameters;
}

export function ChatMessageSendRequestFromJSON(json: any): ChatMessageSendRequest {
    return ChatMessageSendRequestFromJSONTyped(json, false);
}

export function ChatMessageSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : ChatMessageSendParametersFromJSON(json['parameters']),
    };
}

export function ChatMessageSendRequestToJSON(value?: ChatMessageSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': ChatMessageSendParametersToJSON(value.parameters),
    };
}

