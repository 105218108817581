type LocationIconProps = {
  fill?: string
}

const LocationIconOutline = ({ fill }: LocationIconProps) => {
  return ( 
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0721 11.6162C14.4499 12.8774 13.6068 14.1337 12.7446 15.2619C11.9268 16.3254 11.0523 17.3442 10.125 18.3137C9.19766 17.3442 8.32319 16.3254 7.50538 15.2619C6.64325 14.1337 5.80013 12.8774 5.17788 11.6162C4.5485 10.3421 4.1875 9.14863 4.1875 8.125C4.1875 6.55028 4.81306 5.04005 5.92655 3.92655C7.04005 2.81306 8.55028 2.1875 10.125 2.1875C11.6997 2.1875 13.2099 2.81306 14.3234 3.92655C15.4369 5.04005 16.0625 6.55028 16.0625 8.125C16.0625 9.14863 15.7003 10.3421 15.0721 11.6162ZM10.125 20C10.125 20 17.25 13.2479 17.25 8.125C17.25 6.23533 16.4993 4.42306 15.1631 3.08686C13.8269 1.75067 12.0147 1 10.125 1C8.23533 1 6.42306 1.75067 5.08686 3.08686C3.75067 4.42306 3 6.23533 3 8.125C3 13.2479 10.125 20 10.125 20Z" fill={fill ?? "#C3D82E"}/>
      <path d="M10.125 10.5C9.49511 10.5 8.89102 10.2498 8.44562 9.80438C8.00022 9.35898 7.75 8.75489 7.75 8.125C7.75 7.49511 8.00022 6.89102 8.44562 6.44562C8.89102 6.00022 9.49511 5.75 10.125 5.75C10.7549 5.75 11.359 6.00022 11.8044 6.44562C12.2498 6.89102 12.5 7.49511 12.5 8.125C12.5 8.75489 12.2498 9.35898 11.8044 9.80438C11.359 10.2498 10.7549 10.5 10.125 10.5ZM10.125 11.6875C11.0698 11.6875 11.976 11.3122 12.6441 10.6441C13.3122 9.97597 13.6875 9.06983 13.6875 8.125C13.6875 7.18017 13.3122 6.27403 12.6441 5.60593C11.976 4.93783 11.0698 4.5625 10.125 4.5625C9.18017 4.5625 8.27403 4.93783 7.60593 5.60593C6.93783 6.27403 6.5625 7.18017 6.5625 8.125C6.5625 9.06983 6.93783 9.97597 7.60593 10.6441C8.27403 11.3122 9.18017 11.6875 10.125 11.6875Z" fill={fill ?? "#C3D82E"}/>
    </svg>
  );
}
 
export default LocationIconOutline;
