import { ChatInfo } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export function sendChatMessage(chat: ChatInfo, message: string) {
  return RestClient.mainApi.chatMessageSend({
    chatMessageSendRequest: {
      parameters: {
        chatUid: chat.chatUid,
        message,
        opened: chat.opened,
      }
    }
  })
}
