import React, { FC, useRef } from 'react';
import styles from './DatePicker.module.css';
import editIcon from '@/static/images/icons/edit-icon.svg';
import calendarIcon from '@/static/images/icons/calendar-gray.svg';
import formatter from '@/utils/utils';
import dayjs from 'dayjs';

export type DatePickerProps = {
  isCalendarIcon?: boolean;
  title?: string;
  isBirthday?: boolean,
  formik?: any;
  setSelectedDate?: any
} & React.AllHTMLAttributes<HTMLInputElement>;

const DatePicker: FC<DatePickerProps> = (props) => {
  const { isCalendarIcon, title, isBirthday, formik, setSelectedDate, ...inputProps } = props;
  const datePickerRef: any = useRef(null)

  const handleInterviewDateClick = () => {
    try {
      datePickerRef.current?.focus();
      datePickerRef.current?.showPicker();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.datePicker} onClick={handleInterviewDateClick}>
      {title && !inputProps.value ? (
        <div className={isBirthday ? styles.birthdayTitle : styles.title}>{title}</div>
      ) : (
        <span className={styles.displayDate}>
          {formatter.dateWithDots(String(inputProps.value))}
        </span>
      )}
      <span className={styles.editButton}>
        <input
          ref={datePickerRef}
          type="date"
          className={styles.datePickerInput}
          onChange={e => {
            setSelectedDate(e.target.value)
            if (!dayjs(e.target.value).isAfter(dayjs())) {
              formik.setFieldValue('birthday', e.target.value)
            }
          }
          }
        />
        <img src={isCalendarIcon ? calendarIcon : editIcon} alt="Edit" />
      </span>
    </div>
  );
};

export default DatePicker;
