import Button from '@/components/Button/Button';
import styles from './SignInPage.module.css'

interface TOSProps {
  close: () => void;
}

const TOS = ({ close }: TOSProps) => {
  return (
    <div className={styles['tos']}>
        <p className={styles['tos-title']}>Пользовательское соглашение</p>
        <p className={styles['tos-subtitle']}>1. Общие положения</p>
        <ol>
          <li>Настоящее Пользовательское соглашение (именуемое в дальнейшем — Соглашение) регламентирует отношения между ООО «Лунда Падел» (именуемым в дальнейшем — Администрация) и физическим лицом (именуемым в дальнейшем — Пользователь) по использованию ресурсов Приложения www.lundapadel.ru (в дальнейшем – Приложение).</li>
          <li>Данное соглашение – договор между администрацией и пользователями Приложения www.lundapadel.ru. Выполнение данного договора является обязательным.</li>
          <li>Соглашение является юридически обязывающим договором между Пользователем и Администрацией и регламентирует использование Приложения.</li>
          <li>Несоблюдение условий настоящего Соглашения может повлечь за собой санкции, включая, но не ограничиваясь, потерей права пользования Приложение www.lundapadel.ru и удалением учетной записи с последующим блокированием IP-адреса Пользователя.</li>
          <li>В настоящем Соглашении используются следующие термины и определения:{'\n'}
            <b>«Персональные данные»</b> — личная информация, указанная Пользователем при регистрации на Приложениее либо переданная Администрации для осуществления регистрации на Приложениее ( в том числе Ф.И.О., адрес, адрес электронной почты, телефон), а также информация, полученная из заполненных Пользователем заявок на поставку товара и анкет опросов, проводимых Администрацией.{'\n'}
            <b>«Обработка персональных данных»</b> — действия, производимые в ручном или автоматическом режиме с персональными данными, включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передачу), обезличивание, блокирование и уничтожение персональных данных.</li>
          <li>Администрацией Приложения приняты все разумные меры к тому, чтобы обеспечить точность и актуальность размещенной на этом Приложениее информации, однако ее точность и полнота не могут быть гарантированы. Администрация оставляет за собой право вносить изменение в содержание материалов этого Приложения в любое время по собственному усмотрению. Продукты или услуги, не относящиеся напрямую к Приложению, упомянуты исключительно в информационных целях.</li>
          <li>Торговые марки, знаки, логотипы и т.п. (далее - "Торговые марки"), помещенные на данном Приложение, являются собственностью Администрации и третьих сторон. Информация, опубликованная на Приложение, не предоставляет никаких лицензионных прав на использование любых торговых марок без получения предварительного письменного согласия владельца.</li>
        </ol>
        <p className={styles['tos-subtitle']}>2. Вступление в силу и порядок изменения Соглашения</p>
        <ol>
          <li>Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями. Действия по использованию любого сервиса Приложения, либо его функционала (в том числе оформление заказа, направление Администрации заявления о регистрации, онлайн оплата заказа), совершенные пользователем, однозначно подтверждают безоговорочное согласие Пользователя со всеми условиями настоящего Соглашения. Пользователь обязуется соблюдать условия Соглашения, либо прекратить использование сервисов и функционала Приложения www.lundapadel.ru.</li>
          <li>Соглашение вступает в силу с момента начала работы Пользователя с Приложение. Соглашение может быть изменено в любой момент в одностороннем порядке без уведомления пользователя.</li>
          <li>Администрация оставляет за собой право вносить изменения в настоящее Соглашение без специального уведомления Пользователя об этом изменении. Новая редакция Соглашения вступает в силу с момента ее размещения в Приложение www.lundapadel.ru. Актуальная редакция Соглашения находится по адресу http://lundapadel.ru/about/terms/. Пользователь берет на себя всю меру ответственности за своевременную проверку обновлений Соглашения.</li>
        </ol>
        <p className={styles['tos-subtitle']}>3. Условия пользования Приложение</p>
        <ol>
          <li>Право использования Приложения предоставляется Пользователям на бесплатной основе. Это дает возможность использовать функционал Приложения, в том числе создать регистрационную запись Пользователя с использованием своих личных данных, формировать заказы и пользоваться другими возможностями Приложения.</li>
          <li>Мы предоставляем вам возможность бесплатной работы с Приложение и оставляем за собой право вносить изменения в предлагаемые нами услуги и правила пользования Приложением www.lundapadel.ru без предварительного уведомления Пользователя.</li>
          <li>Администрация имеет право изменять и прекращать предоставление услуг в любое время.</li>
          <li>Регистрируясь (создавая учетную запись), а также создавая заказы на поставку товара на Приложение www.lundapadel.ru, Пользователь обязуется предоставить достоверную, точную и полную информацию и поддерживать ее в актуальном состоянии.</li>
          <li>Если у Администрации возникнут основания предполагать, что предоставленная Пользователем информация не соответствует действительности или предоставлена в неполном объеме, его доступ к Приложению может быть прекращен на временной или постоянной основе.</li>
          <li>Логин и пароль Пользователя являются необходимой и достаточной информацией для доступа на Приложение. Пользователь не имеет права передавать их третьим лицам и несет полную ответственность за их сохранность, самостоятельно выбирая способ хранения данной информации.</li>
          <li>Правила и ограничения на пользование услугами Приложения www.lundapadel.ru могут быть установлены и изменены Администрацией в любой момент без предварительного уведомления Пользователя.</li>
          <li>Администрация оставляет за собой право изменять, прекращать (временно или полностью) предоставление услуг (либо их части) Приложения в любое время без предварительного уведомления. Пользователь признает, что Администрация не несет ответственности за внесение правок в правила, приостановление, изменение функционала либо прекращение услуг Приложения.</li>
          <li>Информация, размещенная в Приложение, защищена законом об авторских правах. Администрация предоставляет право Пользователю использовать опубликованные материалы в любых личных и некоммерческих целях. Пользователь обязуется не воспроизводить, не копировать, не продавать и не перепродавать какую-либо часть услуг или контента, пользование услугами либо доступ к услугам и контенту Приложения www.lundapadel.ru.</li>
          <li>Пользователь использованием функционала Приложения, равно как и регистрацией на нем, подтверждает, что осознает свое вхождение в правовую зону действия законодательства РФ. Таким образом, в случае нарушения пользователем законов РФ он понесет всю предусмотренную законодательством РФ меру ответственности за данные нарушения. Пользователь единолично несет ответственность при пользовании услуг Приложения www.lundapadel.ru, в том числе ответственность за издержки в связи с противоправными действиями и незаконным использованием Приложения.</li>
          <li>В случае нарушения Пользователем законов РФ, условий данного Соглашения либо по каким-либо по собственному усмотрению Администрации Приложения, доступ Пользователя к услугам или информации Приложения www.lundapadel.ru может быть прекращен Администрацией, в том числе может быть удален его профиль или блокирован доступ к Приложению по IP-адресу.</li>
          <li>При любом развитии событий, в связи с возможностью/невозможностью использования ресурсов Приложения, а также в связи с использованием третьими сторонами размещенных на Приложение материалов и документов (включая информацию, появление которой на Приложение произошло не по инициативе компании), Администрация не несет ответственность за какой-либо прямой, косвенный, случайный либо сопутствующий ущерб, в особенности, ущерб от упущенной выгоды, потери прибыльности, роста расходов, потери информации и т.п.</li>
          <li>Никакая информация, полученная с Приложения, либо с помощью сервисов и услуг Приложения в устном или письменном виде не может считаться каким-либо видом гарантии, за исключением случаев, оговоренных в Соглашении.</li>
        </ol>
        <p className={styles['tos-subtitle']}>4. Порядок обработки персональных данных Пользователя</p>
        <ol>
          <li>Обработка персональных данных осуществляется с соблюдением принципов и правил, установленных Федеральным законом «О персональных данных», с обеспечением конфиденциальности и безопасности.</li>
          <li>Регистрируясь на нашем Приложение, вы предоставляете нам право обработки и использования ваших личных данных в ваших интересах, в рамках действующего законодательства РФ. Согласие дается на срок 50 лет, однако может быть отозвано вами в одностороннем порядке. Мы гарантируем конфиденциальность и безопасность ваших личных данных. Мы обязуемся не использовать предоставленную вами личную информацию, кроме как для соблюдения ваших интересов либо исполнения требований законодательства.</li>
          <li>Оформляя заказы, либо регистрируясь на Приложение www.lundapadel.ru, Пользователь дает свое согласие ООО «Лунда Падел»:
            <ol>
              <li>в целях заключения и исполнения договора купли-продажи собирать, записывать, обрабатывать, систематизировать, накапливать, хранить, уточнять (обновлять, изменять), извлекать, использовать, передавать (в том числе поручать обработку другим лицам), обезличивать, блокировать, удалять, уничтожать и другими способами (в том числе в электронном виде) обрабатывать свои персональные данные: фамилию, имя, номера домашнего и мобильного телефонов, адрес электронной почты, почтовый адрес.</li>
              <li>в целях информирования о товарах, работах, услугах осуществлять обработку вышеперечисленных персональных данных и направлять на указанный Пользователем адрес электронной почты и/или на номер мобильного телефона рекламу и информацию о товарах, работах, услугах и заказах.</li>
              <li>в целях исполнения договора купли-продажи направлять в адрес Пользователя информационные сообщения о статусе заказа на указанный им почтовый адрес, адрес электронной почты и/или номер мобильного телефона.</li>
            </ol>
          </li>
          <li>Администрация обязуется не нарушать конфиденциальности полученных от Пользователя личных данных, за исключением случаев поступления запроса на предоставление подобной информации со стороны правоохранительных и надзорных органов.</li>
          <li>Разглашением конфиденциальной информации не является предоставление Администрацией личных данных Пользователя третьим лицам, действующим на основании договора с Администрацией, с целью исполнения обязательств перед Пользователем.</li>
          <li>Согласие в понятиях раздела 5 настоящего Соглашения выдается Пользователем на срок 50 (пятьдесят) лет, но может быть отозвано путем направления Администрации соответствующего письменного уведомления.</li>
          <li>Направление письменного уведомления, указанного в п. 4.5 настоящего Соглашения, влечет за собой удаление аккаунта Пользователя на Приложение и уничтожение всех его персональных данных.</li>
        </ol>
        <p className={styles['tos-subtitle']}>5. Заключительная информация</p>
        <ol>
          <li>Все разногласия по поводу настоящего Соглашения будут разрешаться по нормам российского права.</li>
          <li>Нормы о защите прав потребителей неприменимы к предоставляемым нами услугам ввиду безвозмездности последних. Обо всех случаях нарушений Соглашения просим вас сообщать Администрации.</li>
          <li>Нормы о защите прав потребителей не могут быть применены к данному Соглашению, ввиду безвозмездности услуг, оказываемых в его рамках.</li>
          <li>О любых нарушениях Соглашения Пользователь должен оперативно сообщать Администрации. Администрация гарантирует соблюдение анонимности заявителя. Ссылки на конкретные случаи нарушения и подробное описание всех их деталей ускорят рассмотрение заявления.</li>
        </ol>
        <Button onClick={close} className={styles['tos-button']}>Закрыть</Button>
    </div>
  )
}

export default TOS