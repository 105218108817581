/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameRoundLoadListResult,
    GameRoundLoadListResultFromJSON,
    GameRoundLoadListResultFromJSONTyped,
    GameRoundLoadListResultToJSON,
} from './GameRoundLoadListResult';

/**
 * 
 * @export
 * @interface LoadListGameRoundResponse
 */
export interface LoadListGameRoundResponse {
    /**
     * 
     * @type {GameRoundLoadListResult}
     * @memberof LoadListGameRoundResponse
     */
    result?: GameRoundLoadListResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadListGameRoundResponse
     */
    messages?: Array<object>;
}

export function LoadListGameRoundResponseFromJSON(json: any): LoadListGameRoundResponse {
    return LoadListGameRoundResponseFromJSONTyped(json, false);
}

export function LoadListGameRoundResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadListGameRoundResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : GameRoundLoadListResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadListGameRoundResponseToJSON(value?: LoadListGameRoundResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': GameRoundLoadListResultToJSON(value.result),
        'messages': value.messages,
    };
}

