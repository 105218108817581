/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameMatchResult,
    GameMatchResultFromJSON,
    GameMatchResultFromJSONTyped,
    GameMatchResultToJSON,
} from './GameMatchResult';
import {
    GameSetView,
    GameSetViewFromJSON,
    GameSetViewFromJSONTyped,
    GameSetViewToJSON,
} from './GameSetView';

/**
 * 
 * @export
 * @interface GameMatchView
 */
export interface GameMatchView {
    /**
     * 
     * @type {GameMatchResult}
     * @memberof GameMatchView
     */
    matchResult?: GameMatchResult;
    /**
     * 
     * @type {Array<GameSetView>}
     * @memberof GameMatchView
     */
    gameSets?: Array<GameSetView>;
}

export function GameMatchViewFromJSON(json: any): GameMatchView {
    return GameMatchViewFromJSONTyped(json, false);
}

export function GameMatchViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameMatchView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matchResult': !exists(json, 'matchResult') ? undefined : GameMatchResultFromJSON(json['matchResult']),
        'gameSets': !exists(json, 'gameSets') ? undefined : ((json['gameSets'] as Array<any>).map(GameSetViewFromJSON)),
    };
}

export function GameMatchViewToJSON(value?: GameMatchView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matchResult': GameMatchResultToJSON(value.matchResult),
        'gameSets': value.gameSets === undefined ? undefined : ((value.gameSets as Array<any>).map(GameSetViewToJSON)),
    };
}

