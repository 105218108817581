/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GamePlayerStatisticsInfo,
    GamePlayerStatisticsInfoFromJSON,
    GamePlayerStatisticsInfoFromJSONTyped,
    GamePlayerStatisticsInfoToJSON,
} from './GamePlayerStatisticsInfo';

/**
 * 
 * @export
 * @interface GameStatisticsLoadResult
 */
export interface GameStatisticsLoadResult {
    /**
     * 
     * @type {Array<GamePlayerStatisticsInfo>}
     * @memberof GameStatisticsLoadResult
     */
    playersStatistics?: Array<GamePlayerStatisticsInfo>;
}

export function GameStatisticsLoadResultFromJSON(json: any): GameStatisticsLoadResult {
    return GameStatisticsLoadResultFromJSONTyped(json, false);
}

export function GameStatisticsLoadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameStatisticsLoadResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playersStatistics': !exists(json, 'playersStatistics') ? undefined : ((json['playersStatistics'] as Array<any>).map(GamePlayerStatisticsInfoFromJSON)),
    };
}

export function GameStatisticsLoadResultToJSON(value?: GameStatisticsLoadResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playersStatistics': value.playersStatistics === undefined ? undefined : ((value.playersStatistics as Array<any>).map(GamePlayerStatisticsInfoToJSON)),
    };
}

