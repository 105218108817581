import styles from './LundaLockedModal.module.css';
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal";
import { ConfirmationModalProps } from "@/components/ConfirmationModal/ConfirmationModal.interfaces";
import ModalArrowIcon from "@/static/images/icons/ModalArrowIcon";
import ModalBallIcon from "@/static/images/icons/ModalBallIcon";

const LundaLockedModal = ({...props}: ConfirmationModalProps) => {
  return (  
    <ConfirmationModal {...props}>
      <div className={styles['question']}>
        <span>8 игроков</span>
      </div>
      <div className={styles['description']}>
        Создание игры на 8 игроков и подсчет очков по правилам Американо и Мексикано, пока доступно только в клубах Lunda Padel.
      </div>
      <button className={styles['button']} onClick={props.onClose}>
        <div className={styles['button-inner']}>
          <ModalBallIcon/>
          <span>Понятно</span>
          <ModalArrowIcon/>
        </div>
      </button>
    </ConfirmationModal>
  );
}
 
export default LundaLockedModal;
