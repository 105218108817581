/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TournamentKind {
    AMERICANO = 'AMERICANO',
    MEXICANO = 'MEXICANO',
    ESCALERA = 'ESCALERA',
    CLASSIC = 'CLASSIC'
}

export function TournamentKindFromJSON(json: any): TournamentKind {
    return TournamentKindFromJSONTyped(json, false);
}

export function TournamentKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentKind {
    return json as TournamentKind;
}

export function TournamentKindToJSON(value?: TournamentKind | null): any {
    return value as any;
}

