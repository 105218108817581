import styles from './CompletedContent.module.css';
import { FC, MouseEvent, UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@/hooks/hooks';
import { myPastTourGameLoadList } from '../srevice';
import { TourGameInfo, CalculatedGameStatus, TourGameEntityType } from '@/integration-api/server-rest-lundapadelApi';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import Headling from '@/components/Headling/Headling';
import infoIcon from '@/static/images/icons/info-icon.svg'
import infoIconActive from '@/static/images/icons/info-icon-yellow.svg'
import { FetchErrorMessage } from '@/types/types';
import TournamentCard from '@/components/TournamentCard/TournamentCard';
import TournamentFinishedCard from '@/components/TournamentFinishedCard/TournamentFinishedCard';
import filterIcon from '@/static/images/icons/filter.svg';
import ballIcon from '@/static/images/icons/notification-ball-icon.svg';
import CompletedContentFiltersModal from '../CompletedContentFiltersModal/CompletedContentFiltersModal';
import EmptyMatchListPlaceholder from '@/components/EmptyMatchListPlaceholder/EmptyMatchListPlaceholder';
import useDebounce from '@/hooks/useDebounce';
import GameListCard from '@/components/GameListCard/GameListCard';
import DBManager from '@/utils/DBManager/DBManager';
import { gameCardSubtype } from './utils';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

type CompletedContentProps = {
  handleWaitingForScore: (count: number) => void;
}

export type CompletedContentFilters = {
  showCanceled: boolean;
}

const CompletedContent: FC<CompletedContentProps> = ({ handleWaitingForScore }): JSX.Element => {
  const [startedTourgames, setStartedTourgames] = useState<TourGameInfo[]>();
  const [afterTourgames, setAfterTourgames] = useState<TourGameInfo[]>();
  const [completedTourgames, setCompletedTourgames] = useState<TourGameInfo[]>();
  const [own, setOwn] = useState<boolean>(false);
  const [showStartedMatchInfo, setShowStartedMatchInfo] = useState<boolean>(false);
  const [showEditingMatchInfo, setShowEditingMatchInfo] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState<CompletedContentFilters>({ showCanceled: false });

  const matchUidRef = useRef<string>();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const toggleSwitch = () => {
    setOwn(state => !state);
  }

  const profileUid = useAppSelector(
    (state) => state.auth.currentProfile?.identity?.uid
  );

  const matchLoadListHandle = useCallback(async (own: boolean) => {
    if(navigator.onLine) {
      try {
        const { result } = await myPastTourGameLoadList({
          ownOnly: own,
          limit: 30,
          offset: 0,
          showCanceled: filters.showCanceled
        });
        if (result) {
          setStartedTourgames(result.started);
          handleWaitingForScore(result.started ? result.started.length : 0);
          setAfterTourgames(result.afterStarted);
          setCompletedTourgames(result.finished);
          DBManager.create('tourgames', {
            startedTourgames: result.started,
            afterTourgames: result.afterStarted,
            completedTourgames: result.finished
          }, 'myPastTourgames');
          setTotal(result.total ?? 0);
          setOffset(0);
        }
      } catch (err: unknown) {
        if (err instanceof Promise) {
          const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
          PopupMessage.open(userErrorMessage ?? errorMessage);
        }
      }
    } else {
      DBManager.read('tourgames', 'myPastTourgames')
        .then(myPastTourgames => {
          setStartedTourgames(myPastTourgames?.startedTourgames);
          setAfterTourgames(myPastTourgames?.afterTourgames);
          setCompletedTourgames(myPastTourgames?.completedTourgames);
        })
        .catch((err) => console.log(err));
    }
  }, [profileUid, handleWaitingForScore, filters.showCanceled]);

  const closePopup = (e: MouseEvent) => {
    if ((e.target as HTMLDivElement).id !== 'startedTourgames') {
      setShowStartedMatchInfo(false)
    }
    if ((e.target as HTMLDivElement).id !== 'editingTourgames') {
      setShowEditingMatchInfo(false)
    }
  }

  const scrollHandler = (e: UIEvent) => {
    if(navigator.onLine && Math.floor((e.target as HTMLDivElement).scrollTop + window.innerHeight - (window.innerHeight - (e.target as HTMLDivElement).clientHeight) + 80) >= (e.target as HTMLDivElement).scrollHeight
      && (offset + 30 < total)) {
      myPastTourGameLoadList({
        limit: 30,
        offset: offset + 30,
        ownOnly: own,
        showCanceled: filters.showCanceled
      })
        .then(({ result }) => {
          if(result) {
            setStartedTourgames(result.started);
            handleWaitingForScore(result.started ? result.started?.length : 0);
            setAfterTourgames(result.afterStarted);
            setCompletedTourgames(state => state?.concat(result.finished ?? []));
            setTotal(result.total ?? 0);
            setOffset(state => state + 30);
          }
        })
        .catch(err => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        });
    }
  }

  const debouncedScrollHandler = useDebounce(scrollHandler, 200);

  const applyFilters = useCallback((values: CompletedContentFilters) => {
    setFilters(values);
    myPastTourGameLoadList({
      limit: 30,
      offset: 0,
      ownOnly: own,
      showCanceled: values.showCanceled
    })
      .then(({ result }) => {
        if(result) {
          setStartedTourgames(result.started);
          handleWaitingForScore(result.started ? result.started.length : 0);
          setAfterTourgames(result.afterStarted);
          setCompletedTourgames(result.finished);
          setTotal(result.total ?? 0);
          setOffset(0);
        }
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
      .finally(() => setModalVisible(false));
  }, [own]);

  useEffect(() => {
    matchLoadListHandle(own);
  }, [matchLoadListHandle, own]);

  useEffect(() => {
    if (window.history.state.usr && window.history.state.usr.matchUid) {
      matchUidRef.current = window.history.state.usr.matchUid;
    }
  }, [matchUidRef.current]);

  useEffect(() => {
    if (matchUidRef) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [matchUidRef.current, scrollRef.current]);

  return (
    <>
      <CompletedContentFiltersModal 
        modalVisible={modalVisible}
        handleSubmit={applyFilters}
      />
      <div onClick={closePopup} className={styles['wrapper']} onScroll={debouncedScrollHandler}>
        {!startedTourgames?.length && !afterTourgames?.length && !completedTourgames?.some(tm => tm.gameStatus !== CalculatedGameStatus.CANCELED) && !own ?
          null : 
          <InnerPadding className={styles['padding']}>
            <div className={styles['toggle']}>
              <span>Только созданные мной игры</span>
              <ToggleSwitch disabled={!navigator.onLine} id='content' onChange={toggleSwitch} checked={own} />
            </div>
          </InnerPadding>
        }
        {startedTourgames?.length ?
          <>
            <InnerPadding>
              <Headling appearence='big-normal' className={styles['headling']}>
                <span>Ожидает результатов</span>
                <span className={styles['popup-wrapper']}>
                  <img
                    id='startedTourgames'
                    className={styles.infoIcon}
                    src={showStartedMatchInfo ? infoIconActive : infoIcon} alt='инфо'
                    onClick={() => setShowStartedMatchInfo(state => !state)}
                  />
                  {showStartedMatchInfo &&
                    <div className={styles['popup']}>
                      Выставьте результат в течение 24 часов с момента начала игры, иначе за игру не будет начислен рейтинг
                    </div>
                  }
                </span>
              </Headling>
            </InnerPadding>
            <div className={styles['content-wrapper']}>
              {startedTourgames?.map(tourgame => {
                if(tourgame.type === TourGameEntityType.GAME) {
                  return (
                    <GameListCard
                      key={tourgame.uid}
                      subtype='orange'
                      game={tourgame}
                    />
                  );
                } else {
                  return (
                    <Link key={tourgame.uid} to={`/tournament/${tourgame.uid}`}>
                      <TournamentCard
                        tournament={tourgame}
                        restricted
                      />
                    </Link>
                  );
                }
              })}
            </div>
          </> : null
        }
        {afterTourgames?.length ?
          <>
            <InnerPadding>
              <Headling appearence='big-normal' className={styles['headling']}>
                <span>Редактируемые</span>
                <span className={styles['popup-wrapper']}>
                  <img
                    id='editingTourgames'
                    src={showEditingMatchInfo ? infoIconActive : infoIcon} alt='инфо'
                    onClick={() => setShowEditingMatchInfo(state => !state)}
                  />
                  {showEditingMatchInfo &&
                    <div className={styles['popup-second']}>
                      У вас есть  12 часов, чтобы отредактировать выставленный результат игры
                    </div>
                  }
                </span>
              </Headling>
            </InnerPadding>
            <div className={styles['content-wrapper']}>
              {afterTourgames?.map(tourgame => {
                if(tourgame.type === TourGameEntityType.GAME) {
                  return (
                    <GameListCard
                      key={tourgame.uid}
                      game={tourgame}
                      subtype='blue'
                    />
                  );
                } else {
                  return (
                    <Link key={tourgame.uid} to={`/tournament/${tourgame.uid}`}>
                      <TournamentCard
                        tournament={tourgame}
                        restricted
                      />
                    </Link>
                  );
                }
              })}
            </div>
          </> : null
        }
        <InnerPadding style={{ marginTop: '20px'}}>
          <Headling appearence='big-normal'>
            <div className={styles['completed-filters']}>
              <span>Завершенные</span>
              <div style={{ position: 'relative' }}>
                {(filters.showCanceled) ? <img src={ballIcon} alt="иконка мячика" className={styles['filters-selected']}/> : null}
                <img src={filterIcon} alt="Фильтр" onClick={() => navigator.onLine && setModalVisible(true)} />
              </div>
            </div>
          </Headling>
        </InnerPadding>
        {completedTourgames?.length ?
          <div className={styles['content-wrapper']}>
            {completedTourgames?.map(tourgame => {
              if(tourgame.type === TourGameEntityType.GAME) {
                return (
                  <GameListCard
                    subtype={gameCardSubtype(tourgame.gameStatus)}
                    key={tourgame.uid}
                    game={tourgame}
                  />
                );
              } else {
                return (
                  <Link to={`/tournament/${tourgame.uid}`} key={tourgame.uid}>
                    <TournamentFinishedCard
                      tournament={tourgame}
                    />
                  </Link>
                );
              }
            })}
          </div> : !startedTourgames?.length && !afterTourgames?.length ?
          <EmptyMatchListPlaceholder 
            className={styles['empty-completed-content']}
            title='Здесь будет отображаться список ваших завершенных игр'
            bgImage
          /> : null
        }
      </div>
    </>
  );
};

export default CompletedContent;
