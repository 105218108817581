/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerScheduleResult,
    PlayerScheduleResultFromJSON,
    PlayerScheduleResultFromJSONTyped,
    PlayerScheduleResultToJSON,
} from './PlayerScheduleResult';

/**
 * 
 * @export
 * @interface GameBookedListTimeResponse
 */
export interface GameBookedListTimeResponse {
    /**
     * 
     * @type {PlayerScheduleResult}
     * @memberof GameBookedListTimeResponse
     */
    result?: PlayerScheduleResult;
    /**
     * 
     * @type {Array<object>}
     * @memberof GameBookedListTimeResponse
     */
    messages?: Array<object>;
}

export function GameBookedListTimeResponseFromJSON(json: any): GameBookedListTimeResponse {
    return GameBookedListTimeResponseFromJSONTyped(json, false);
}

export function GameBookedListTimeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameBookedListTimeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : PlayerScheduleResultFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function GameBookedListTimeResponseToJSON(value?: GameBookedListTimeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': PlayerScheduleResultToJSON(value.result),
        'messages': value.messages,
    };
}

