/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CityClubsGroupLoadListParameters
 */
export interface CityClubsGroupLoadListParameters {
    /**
     * 
     * @type {string}
     * @memberof CityClubsGroupLoadListParameters
     */
    textPattern?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CityClubsGroupLoadListParameters
     */
    fullInfo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CityClubsGroupLoadListParameters
     */
    favorite?: boolean;
}

export function CityClubsGroupLoadListParametersFromJSON(json: any): CityClubsGroupLoadListParameters {
    return CityClubsGroupLoadListParametersFromJSONTyped(json, false);
}

export function CityClubsGroupLoadListParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): CityClubsGroupLoadListParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'textPattern': !exists(json, 'textPattern') ? undefined : json['textPattern'],
        'fullInfo': !exists(json, 'fullInfo') ? undefined : json['fullInfo'],
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
    };
}

export function CityClubsGroupLoadListParametersToJSON(value?: CityClubsGroupLoadListParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'textPattern': value.textPattern,
        'fullInfo': value.fullInfo,
        'favorite': value.favorite,
    };
}

