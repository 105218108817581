/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface WebsocketTicketRegisterResult
 */
export interface WebsocketTicketRegisterResult {
    /**
     * 
     * @type {string}
     * @memberof WebsocketTicketRegisterResult
     */
    ticket?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof WebsocketTicketRegisterResult
     */
    expiredDate?: LocalDateTime;
}

export function WebsocketTicketRegisterResultFromJSON(json: any): WebsocketTicketRegisterResult {
    return WebsocketTicketRegisterResultFromJSONTyped(json, false);
}

export function WebsocketTicketRegisterResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebsocketTicketRegisterResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticket': !exists(json, 'ticket') ? undefined : json['ticket'],
        'expiredDate': !exists(json, 'expiredDate') ? undefined : LocalDateTimeFromJSON(json['expiredDate']),
    };
}

export function WebsocketTicketRegisterResultToJSON(value?: WebsocketTicketRegisterResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticket': value.ticket,
        'expiredDate': LocalDateTimeToJSON(value.expiredDate),
    };
}

