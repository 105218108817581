/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reference,
    ReferenceFromJSON,
    ReferenceFromJSONTyped,
    ReferenceToJSON,
} from './Reference';

/**
 * 
 * @export
 * @interface ClubView
 */
export interface ClubView {
    /**
     * 
     * @type {string}
     * @memberof ClubView
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubView
     */
    mapAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubView
     */
    mapUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubView
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubView
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ClubView
     */
    info?: string;
    /**
     * 
     * @type {Reference}
     * @memberof ClubView
     */
    reference?: Reference;
    /**
     * 
     * @type {boolean}
     * @memberof ClubView
     */
    yClientsIntegrated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClubView
     */
    lunda?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClubView
     */
    favorite?: boolean;
}

export function ClubViewFromJSON(json: any): ClubView {
    return ClubViewFromJSONTyped(json, false);
}

export function ClubViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClubView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mapAddress': !exists(json, 'mapAddress') ? undefined : json['mapAddress'],
        'mapUrl': !exists(json, 'mapUrl') ? undefined : json['mapUrl'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'reference': !exists(json, 'reference') ? undefined : ReferenceFromJSON(json['reference']),
        'yClientsIntegrated': !exists(json, 'yClientsIntegrated') ? undefined : json['yClientsIntegrated'],
        'lunda': !exists(json, 'lunda') ? undefined : json['lunda'],
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
    };
}

export function ClubViewToJSON(value?: ClubView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'mapAddress': value.mapAddress,
        'mapUrl': value.mapUrl,
        'city': value.city,
        'phone': value.phone,
        'info': value.info,
        'reference': ReferenceToJSON(value.reference),
        'yClientsIntegrated': value.yClientsIntegrated,
        'lunda': value.lunda,
        'favorite': value.favorite,
    };
}

