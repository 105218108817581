/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClubView,
    ClubViewFromJSON,
    ClubViewFromJSONTyped,
    ClubViewToJSON,
} from './ClubView';

/**
 * 
 * @export
 * @interface CityClubsItemView
 */
export interface CityClubsItemView {
    /**
     * 
     * @type {string}
     * @memberof CityClubsItemView
     */
    city?: string;
    /**
     * 
     * @type {Array<ClubView>}
     * @memberof CityClubsItemView
     */
    clubs?: Array<ClubView>;
}

export function CityClubsItemViewFromJSON(json: any): CityClubsItemView {
    return CityClubsItemViewFromJSONTyped(json, false);
}

export function CityClubsItemViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CityClubsItemView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': !exists(json, 'city') ? undefined : json['city'],
        'clubs': !exists(json, 'clubs') ? undefined : ((json['clubs'] as Array<any>).map(ClubViewFromJSON)),
    };
}

export function CityClubsItemViewToJSON(value?: CityClubsItemView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'clubs': value.clubs === undefined ? undefined : ((value.clubs as Array<any>).map(ClubViewToJSON)),
    };
}

