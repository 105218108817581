import CrossBigIcon from "@/static/images/icons/CrossBigIcon";
import styles from './PlusBage.module.css';

const PlusBage = () => {
  return (  
    <span className={styles['bage']}>
      <CrossBigIcon/>
    </span>
  );
}
 
export default PlusBage;
