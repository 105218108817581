import { useCallback, useContext } from 'react';
import styles from './TournamentResultsLineupScore.module.css';
import { castGameRoundToTournamentRound, mapGameRoundStatus, TournamentResultsNewContext } from '../utils';
import Header from '@/components/Header/Header';
import LineupVersus from '@/components/LineupVersus/LineupVersus';
import LineupScoreSwiper from '../../game/components/LineupScoreSwiper/LineupScoreSwiper';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button, { ButtonVariants } from '@/components/Button/Button';
import { gameRoundSave, saveTournamentRound } from '../service';
import { FetchErrorMessage } from '@/types/types';
import PuffLoader from 'react-spinners/PuffLoader';
import { isGameRoundView, isMiniTournament } from '../TournamentResultsNew/TournamentResultsNew.interfaces';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';
import { GameResultsContext } from '../../game/GameResults/utils';
import { useImmer } from 'use-immer';

export type TouchSwiperState = {
  score1: boolean;
  score2: boolean;
}

const TournamentResultsLineupScore = () => {
  const { tournament, tournamentType, loading, activeLineup, round, updateActiveLineup, updateStep, updateRound, roundCounter } = useContext(TournamentResultsNewContext);
  const { lockToken } = useContext(GameResultsContext);

  const [touchSwiper, setTouchSwiper] = useImmer<TouchSwiperState>({
    score1: false,
    score2: false
  });

  const handleClick = useCallback(() => {
    updateActiveLineup?.(undefined);
    updateStep?.('lineups');
  }, [updateActiveLineup, updateStep]);

  const saveRound = async () => {
    try {
      // const newGames = round?.lineups?.[activeLineup?.indexes?.index1 ?? 0]?.games?.with?.(activeLineup?.indexes?.index2 ?? 0, activeLineup?.lineup ?? {});
      const newGames = round?.lineups?.[activeLineup?.indexes?.index1 ?? 0]?.games?.slice() ?? [];
      newGames[activeLineup?.indexes?.index2 ?? 0] = activeLineup?.lineup ?? {};
      // const newLineups = round?.lineups?.with?.(activeLineup?.indexes?.index1 ?? 0, { games: newGames });
      const newLineups = round?.lineups?.slice() ?? [];
      newLineups[activeLineup?.indexes?.index1 ?? 0] = { games: newGames };
      const request = tournamentType === 'mini' ?
        gameRoundSave({
          ...round,
          gameUid: round?.tournamentUid,
          status: mapGameRoundStatus(round?.status!),
          lineups: newLineups,
          lockToken
        }) :
        saveTournamentRound({
          ...round,
          lineups: newLineups
        })
      const { result } = await request;
      if(result) {
        updateActiveLineup?.(undefined);
        isGameRoundView(result) ? updateRound?.(castGameRoundToTournamentRound(result)) : updateRound?.(result);
        updateStep?.('lineups');
      }
    } catch (err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open?.(userErrorMessage ?? errorMessage);
      }
    }
  }

  const updateTouchSwiper = (key: keyof TouchSwiperState) => {
    setTouchSwiper(state => { state[key] = true });
  }

  return ( 
    <>
      <Header handleClick={handleClick}>
        Выставление счета
      </Header>
      {loading ? 
        <PuffLoader color='#4EB857' cssOverride={{margin: 'auto'}} /> :
        <>        
          <LineupVersus 
            lineup={{
              player1: activeLineup?.lineup?.player1,
              player2: activeLineup?.lineup?.player2,
              player3: activeLineup?.lineup?.player3,
              player4: activeLineup?.lineup?.player4
            }}
          />
          <div className={styles['scores-wrapper']}>
            <LineupScoreSwiper
              type='set' 
              team='score1'
              tournament={tournamentType === 'full'}
              initialIndex={activeLineup?.lineup?.score1}
              oppositeIndex={activeLineup?.lineup.score2}
              touchSwiper={tournamentType === 'full' ? touchSwiper : undefined}
              updateActiveLineup={updateActiveLineup}
              updateTouchSwiper={tournamentType === 'full' ? updateTouchSwiper : undefined}
              setLimit={isMiniTournament(tournament) ? undefined : ((tournament.maxScore ?? 0) + 3)}
            />
            <span className={styles['divider']}>:</span>
            <LineupScoreSwiper
              type='set'
              team='score2' 
              tournament={tournamentType === 'full'}
              initialIndex={activeLineup?.lineup?.score2} 
              oppositeIndex={activeLineup?.lineup.score1}
              touchSwiper={tournamentType === 'full' ? touchSwiper : undefined}
              updateActiveLineup={updateActiveLineup}
              updateTouchSwiper={tournamentType === 'full' ? updateTouchSwiper : undefined}
              setLimit={isMiniTournament(tournament) ? undefined : ((tournament.maxScore ?? 0) + 3)}
            />
          </div>
          {roundCounter === round?.roundNumber! + 1 ?          
            <OversideWrapper className={styles['button']}>
              <Button
                variant={ButtonVariants.YELLOW}
                onClick={saveRound}
                disabled={tournamentType === 'mini' && !lockToken}
              >
                Далее
              </Button>
            </OversideWrapper> 
            : null
          }
        </>
      }
    </>
  );
}
 
export default TournamentResultsLineupScore;
