/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FirebaseUnsubscriptionParameters,
    FirebaseUnsubscriptionParametersFromJSON,
    FirebaseUnsubscriptionParametersFromJSONTyped,
    FirebaseUnsubscriptionParametersToJSON,
} from './FirebaseUnsubscriptionParameters';

/**
 * 
 * @export
 * @interface FirebaseNotificationsUnsubscribeRequest
 */
export interface FirebaseNotificationsUnsubscribeRequest {
    /**
     * 
     * @type {FirebaseUnsubscriptionParameters}
     * @memberof FirebaseNotificationsUnsubscribeRequest
     */
    parameters?: FirebaseUnsubscriptionParameters;
}

export function FirebaseNotificationsUnsubscribeRequestFromJSON(json: any): FirebaseNotificationsUnsubscribeRequest {
    return FirebaseNotificationsUnsubscribeRequestFromJSONTyped(json, false);
}

export function FirebaseNotificationsUnsubscribeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirebaseNotificationsUnsubscribeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : FirebaseUnsubscriptionParametersFromJSON(json['parameters']),
    };
}

export function FirebaseNotificationsUnsubscribeRequestToJSON(value?: FirebaseNotificationsUnsubscribeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': FirebaseUnsubscriptionParametersToJSON(value.parameters),
    };
}

