import { CalculatedGameStatus } from "@/integration-api/server-rest-get-info";

export function gameCardSubtype(status?: CalculatedGameStatus) {
  switch(status) {
    case CalculatedGameStatus.FINISHED:
      return 'green';
    case CalculatedGameStatus.SCORE_UNDEFINED:
      return 'gray';
    case CalculatedGameStatus.CANCELED:
      return 'black';
    default: 
      return undefined;
  }
}
