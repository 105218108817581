export function clubsRuEnding(value: string) {
  switch(true) {
    case value.endsWith('11') || value.endsWith('12') || value.endsWith('13') || value.endsWith('14') || value.endsWith('15') || value.endsWith('16') || value.endsWith('17') || value.endsWith('18') || value.endsWith('19'):
    case value.endsWith('0') || value.endsWith('5') || value.endsWith('6') || value.endsWith('7') || value.endsWith('8') || value.endsWith('9'):
      return 'клубов'
    case value.endsWith('2') || value.endsWith('3') || value.endsWith('4'):
      return 'клуба'
    default:
      return 'клуб'
  }
}
