import { HTMLAttributes } from 'react';
import UserAvatar from '../UserAvatar/UserAvatar';
import styles from './PlaceholderCard.module.css';
import { UserAvatarBageType } from '../UserAvatar/UserAvatar.interfaces';

type PlaceholderCardProps = {
  type: 'invite' | 'enter';
  bage?: UserAvatarBageType | UserAvatarBageType[];
  orange?: boolean
} & HTMLAttributes<HTMLDivElement>

const PlaceholderCard = ({ type, bage, orange, ...props }: PlaceholderCardProps) => {
  return (  
    <div {...props} className={`${styles['card']} ${orange ? styles['orange'] : ''}`.trim()}>
      <UserAvatar
        isInvitable={type === 'invite'}
        badge={bage}
      />
      <span>{type === 'enter' ? 'Свободно' : 'Пригласить'}</span>
    </div>
  );
}
 
export default PlaceholderCard;
