/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GameSortParam {
    RATING = 'RATING',
    PLANNED_DATE = 'PLANNED_DATE'
}

export function GameSortParamFromJSON(json: any): GameSortParam {
    return GameSortParamFromJSONTyped(json, false);
}

export function GameSortParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameSortParam {
    return json as GameSortParam;
}

export function GameSortParamToJSON(value?: GameSortParam | null): any {
    return value as any;
}

