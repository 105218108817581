/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SaveLineupParam,
    SaveLineupParamFromJSON,
    SaveLineupParamFromJSONTyped,
    SaveLineupParamToJSON,
} from './SaveLineupParam';

/**
 * 
 * @export
 * @interface SaveGameLineupRequest
 */
export interface SaveGameLineupRequest {
    /**
     * 
     * @type {SaveLineupParam}
     * @memberof SaveGameLineupRequest
     */
    parameters?: SaveLineupParam;
}

export function SaveGameLineupRequestFromJSON(json: any): SaveGameLineupRequest {
    return SaveGameLineupRequestFromJSONTyped(json, false);
}

export function SaveGameLineupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveGameLineupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : SaveLineupParamFromJSON(json['parameters']),
    };
}

export function SaveGameLineupRequestToJSON(value?: SaveGameLineupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': SaveLineupParamToJSON(value.parameters),
    };
}

