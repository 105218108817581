/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackMessageView,
    FeedbackMessageViewFromJSON,
    FeedbackMessageViewFromJSONTyped,
    FeedbackMessageViewToJSON,
} from './FeedbackMessageView';

/**
 * 
 * @export
 * @interface FeedbackLoadListMessagesResult
 */
export interface FeedbackLoadListMessagesResult {
    /**
     * 
     * @type {number}
     * @memberof FeedbackLoadListMessagesResult
     */
    total?: number;
    /**
     * 
     * @type {Array<FeedbackMessageView>}
     * @memberof FeedbackLoadListMessagesResult
     */
    infos?: Array<FeedbackMessageView>;
}

export function FeedbackLoadListMessagesResultFromJSON(json: any): FeedbackLoadListMessagesResult {
    return FeedbackLoadListMessagesResultFromJSONTyped(json, false);
}

export function FeedbackLoadListMessagesResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackLoadListMessagesResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(FeedbackMessageViewFromJSON)),
    };
}

export function FeedbackLoadListMessagesResultToJSON(value?: FeedbackLoadListMessagesResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(FeedbackMessageViewToJSON)),
    };
}

