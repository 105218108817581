/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameMatchView,
    GameMatchViewFromJSON,
    GameMatchViewFromJSONTyped,
    GameMatchViewToJSON,
} from './GameMatchView';
import {
    GameSetView,
    GameSetViewFromJSON,
    GameSetViewFromJSONTyped,
    GameSetViewToJSON,
} from './GameSetView';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface GameLineupView
 */
export interface GameLineupView {
    /**
     * 
     * @type {string}
     * @memberof GameLineupView
     */
    lineupUid?: string;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameLineupView
     */
    player1?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameLineupView
     */
    player2?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameLineupView
     */
    player3?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameLineupView
     */
    player4?: PlayerProfileInfo;
    /**
     * 
     * @type {Array<GameSetView>}
     * @memberof GameLineupView
     */
    gameSets?: Array<GameSetView>;
    /**
     * 
     * @type {Array<GameMatchView>}
     * @memberof GameLineupView
     */
    matches?: Array<GameMatchView>;
}

export function GameLineupViewFromJSON(json: any): GameLineupView {
    return GameLineupViewFromJSONTyped(json, false);
}

export function GameLineupViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameLineupView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineupUid': !exists(json, 'lineupUid') ? undefined : json['lineupUid'],
        'player1': !exists(json, 'player1') ? undefined : PlayerProfileInfoFromJSON(json['player1']),
        'player2': !exists(json, 'player2') ? undefined : PlayerProfileInfoFromJSON(json['player2']),
        'player3': !exists(json, 'player3') ? undefined : PlayerProfileInfoFromJSON(json['player3']),
        'player4': !exists(json, 'player4') ? undefined : PlayerProfileInfoFromJSON(json['player4']),
        'gameSets': !exists(json, 'gameSets') ? undefined : ((json['gameSets'] as Array<any>).map(GameSetViewFromJSON)),
        'matches': !exists(json, 'matches') ? undefined : ((json['matches'] as Array<any>).map(GameMatchViewFromJSON)),
    };
}

export function GameLineupViewToJSON(value?: GameLineupView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineupUid': value.lineupUid,
        'player1': PlayerProfileInfoToJSON(value.player1),
        'player2': PlayerProfileInfoToJSON(value.player2),
        'player3': PlayerProfileInfoToJSON(value.player3),
        'player4': PlayerProfileInfoToJSON(value.player4),
        'gameSets': value.gameSets === undefined ? undefined : ((value.gameSets as Array<any>).map(GameSetViewToJSON)),
        'matches': value.matches === undefined ? undefined : ((value.matches as Array<any>).map(GameMatchViewToJSON)),
    };
}

