/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableBookingTimeLoadListParameters,
    AvailableBookingTimeLoadListParametersFromJSON,
    AvailableBookingTimeLoadListParametersFromJSONTyped,
    AvailableBookingTimeLoadListParametersToJSON,
} from './AvailableBookingTimeLoadListParameters';

/**
 * 
 * @export
 * @interface AvailableSlotsLoadListRequest
 */
export interface AvailableSlotsLoadListRequest {
    /**
     * 
     * @type {AvailableBookingTimeLoadListParameters}
     * @memberof AvailableSlotsLoadListRequest
     */
    parameters?: AvailableBookingTimeLoadListParameters;
}

export function AvailableSlotsLoadListRequestFromJSON(json: any): AvailableSlotsLoadListRequest {
    return AvailableSlotsLoadListRequestFromJSONTyped(json, false);
}

export function AvailableSlotsLoadListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableSlotsLoadListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : AvailableBookingTimeLoadListParametersFromJSON(json['parameters']),
    };
}

export function AvailableSlotsLoadListRequestToJSON(value?: AvailableSlotsLoadListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': AvailableBookingTimeLoadListParametersToJSON(value.parameters),
    };
}

