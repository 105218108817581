import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { InputNames } from './TournamentInput.interfaces';
import inputTrophyIcon from '@/static/images/icons/input-trophy-icon.svg';
import inputWalletIcon from '@/static/images/icons/input-wallet-icon.svg';
import inputPlayerIcon from '@/static/images/icons/input-player-icon.svg';
import inputCommentIcon from '@/static/images/icons/input-comment-icon.svg';

export function getInputIcon(icon: InputNames) {
  switch(icon) {
    case 'title': 
      return inputTrophyIcon;
    case 'price': 
      return inputWalletIcon;
    case 'playersLimit':
      return inputPlayerIcon;
    case 'description':
      return inputCommentIcon;
    default: 
      const _: never = icon;
      throw new Error('Not  cases handled');
  }
}

export function getInputType(type: InputNames): HTMLInputTypeAttribute {
  switch(type) {
    case 'price':
    case 'playersLimit':
      return 'tel';
    case 'title':
    case 'description':
      return 'text';
    default: 
      const _: never = type;
      throw new Error('Not  cases handled');
  }
}
