import RestClient from '@/integration/RestClient';

export const gradeRatingLoad = (reversed: boolean = true, available: boolean = false) => {
  return RestClient.mainApi.gradeRatingLoad({
    reversed,
    available
  });
};

export const gradeInfoLoadList = () => {
  return RestClient.mainApi.gradeInfoLoadList();
};
