/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface GamePlayerStatisticsInfo
 */
export interface GamePlayerStatisticsInfo {
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GamePlayerStatisticsInfo
     */
    playerInfo?: PlayerProfileInfo;
    /**
     * 
     * @type {number}
     * @memberof GamePlayerStatisticsInfo
     */
    wonMatches?: number;
    /**
     * 
     * @type {number}
     * @memberof GamePlayerStatisticsInfo
     */
    lostMatches?: number;
    /**
     * 
     * @type {number}
     * @memberof GamePlayerStatisticsInfo
     */
    drawMatches?: number;
    /**
     * 
     * @type {number}
     * @memberof GamePlayerStatisticsInfo
     */
    pointsScored?: number;
    /**
     * 
     * @type {number}
     * @memberof GamePlayerStatisticsInfo
     */
    pointsConceded?: number;
    /**
     * 
     * @type {number}
     * @memberof GamePlayerStatisticsInfo
     */
    pointDelta?: number;
}

export function GamePlayerStatisticsInfoFromJSON(json: any): GamePlayerStatisticsInfo {
    return GamePlayerStatisticsInfoFromJSONTyped(json, false);
}

export function GamePlayerStatisticsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GamePlayerStatisticsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playerInfo': !exists(json, 'playerInfo') ? undefined : PlayerProfileInfoFromJSON(json['playerInfo']),
        'wonMatches': !exists(json, 'wonMatches') ? undefined : json['wonMatches'],
        'lostMatches': !exists(json, 'lostMatches') ? undefined : json['lostMatches'],
        'drawMatches': !exists(json, 'drawMatches') ? undefined : json['drawMatches'],
        'pointsScored': !exists(json, 'pointsScored') ? undefined : json['pointsScored'],
        'pointsConceded': !exists(json, 'pointsConceded') ? undefined : json['pointsConceded'],
        'pointDelta': !exists(json, 'pointDelta') ? undefined : json['pointDelta'],
    };
}

export function GamePlayerStatisticsInfoToJSON(value?: GamePlayerStatisticsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playerInfo': PlayerProfileInfoToJSON(value.playerInfo),
        'wonMatches': value.wonMatches,
        'lostMatches': value.lostMatches,
        'drawMatches': value.drawMatches,
        'pointsScored': value.pointsScored,
        'pointsConceded': value.pointsConceded,
        'pointDelta': value.pointDelta,
    };
}

