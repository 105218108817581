import { TournamentPlayerStatisticsInfo, TournamentStatisticsLoadResult } from '@/integration-api/server-rest-lundapadelApi';
import styles from './TournamentStatistics.module.css';
import TournamentStatisticsItem from './TournamentStatisticsItem';
import { HTMLAttributes } from 'react';

type TournamentStatisticsProps = {
  tournamentStatistic?: Array<TournamentPlayerStatisticsInfo>;
} & HTMLAttributes<HTMLDivElement>;

const TournamentStatistics = ({ tournamentStatistic, ...props }: TournamentStatisticsProps) => {
  const renderItems = () => {
    return tournamentStatistic?.map((value, key) => (
      <TournamentStatisticsItem
        key={key}
        statistic={value}
      />
    ))
  }
  return ( 
    <div className={styles['container']} {...props}>
      <div className={styles['grid-header']}>
        <span>Игроки</span>
        <span>Игры +/-</span>
        <span>Очки +/-</span>
        <span>δ</span>
      </div>
      {renderItems()}
    </div>
  );
}
 
export default TournamentStatistics;
