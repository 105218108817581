import { PlayerLoadListRequest, PlayerProfileStatisticsView, PlayerProfileView } from '@/integration-api/server-rest-lundapadelApi';
import { createSlice } from '@reduxjs/toolkit';
import { getPlayerListThunk, getProfile, getProfileStat } from './thunks';

interface ProfileState {
  stat?: PlayerProfileStatisticsView;
  playerList?: PlayerLoadListRequest;
  myfriends?:PlayerLoadListRequest
}

const initialState: ProfileState = {};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileStat.fulfilled, (state, action) => {
      state.stat = action.payload;
    })
    
    builder.addCase(getPlayerListThunk.fulfilled, (state, action) => {
      state.playerList = action.payload as any;
    })
  },
});

export default profileSlice.reducer;
export const profileActions = profileSlice.actions;
