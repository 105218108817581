/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TourGameInfo,
    TourGameInfoFromJSON,
    TourGameInfoFromJSONTyped,
    TourGameInfoToJSON,
} from './TourGameInfo';

/**
 * 
 * @export
 * @interface MyPastTourGameLoadListResult
 */
export interface MyPastTourGameLoadListResult {
    /**
     * 
     * @type {number}
     * @memberof MyPastTourGameLoadListResult
     */
    total?: number;
    /**
     * 
     * @type {Array<TourGameInfo>}
     * @memberof MyPastTourGameLoadListResult
     */
    started?: Array<TourGameInfo>;
    /**
     * 
     * @type {Array<TourGameInfo>}
     * @memberof MyPastTourGameLoadListResult
     */
    afterStarted?: Array<TourGameInfo>;
    /**
     * 
     * @type {Array<TourGameInfo>}
     * @memberof MyPastTourGameLoadListResult
     */
    finished?: Array<TourGameInfo>;
}

export function MyPastTourGameLoadListResultFromJSON(json: any): MyPastTourGameLoadListResult {
    return MyPastTourGameLoadListResultFromJSONTyped(json, false);
}

export function MyPastTourGameLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyPastTourGameLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'started': !exists(json, 'started') ? undefined : ((json['started'] as Array<any>).map(TourGameInfoFromJSON)),
        'afterStarted': !exists(json, 'afterStarted') ? undefined : ((json['afterStarted'] as Array<any>).map(TourGameInfoFromJSON)),
        'finished': !exists(json, 'finished') ? undefined : ((json['finished'] as Array<any>).map(TourGameInfoFromJSON)),
    };
}

export function MyPastTourGameLoadListResultToJSON(value?: MyPastTourGameLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'started': value.started === undefined ? undefined : ((value.started as Array<any>).map(TourGameInfoToJSON)),
        'afterStarted': value.afterStarted === undefined ? undefined : ((value.afterStarted as Array<any>).map(TourGameInfoToJSON)),
        'finished': value.finished === undefined ? undefined : ((value.finished as Array<any>).map(TourGameInfoToJSON)),
    };
}

