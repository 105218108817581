import { FC } from "react";
import { CountCircleProps } from "./CountCircle.interfaces";
import styles from './CountCircle.module.css';

const CountCircle: FC<CountCircleProps> = ({className, children, ...props}): JSX.Element => {
  return (
    <div className={`${className} ${styles['count']}`} {...props}>{children}</div>
  );
}

export default CountCircle;