import styles from './SelectClubSection.module.css';
import Headling from "@/components/Headling/Headling";
import InnerPadding from "@/components/InnerPadding/InnerPadding";
import HorizontalClubList from "../HorizontalClubList/HorizontalClubList";
import { ClubView } from "@/integration-api/server-rest-lundapadelApi";
import arrowIcon from "@/static/images/icons/input-arrow.svg";
import locationIcon from '@/static/images/icons/location.svg';
import locationIconActive from '@/static/images/icons/location_active.svg';
import { HTMLAttributes } from 'react';

interface SelectClubSectionProps extends HTMLAttributes<HTMLDivElement> {
  clubList?: Array<ClubView>;
  selectedClub: ClubView;
  handleClubSelection: (uid: string) => void; 
}

const SelectClubSection = ({ clubList, selectedClub, handleClubSelection, ...props }: SelectClubSectionProps) => {
  return ( 
    <section className={styles['clubs-section']}>
      <InnerPadding>
        <Headling appearence='big-normal'>Выберите клуб</Headling>
      </InnerPadding>
      <div className={`${styles['clubs-button']} ${selectedClub ? styles['active'] : ''}`} onClick={props.onClick}>
        {selectedClub ? 
          <div className={styles['clubs-button-text__active']}>
            <img src={locationIconActive} alt="локация" />
            <div className={styles['clubs-button-info']}>
              <span className={styles['clubs-button-info-name']}>{selectedClub.name}</span>
              <span className={styles['clubs-button-info-adress']}>{selectedClub.mapAddress}</span>
            </div>
          </div> :
          <div className={styles['clubs-button-text']}>
            <img src={locationIcon} alt="локация" />
            <span>Клуб</span>
          </div>
        }
        <img src={arrowIcon} alt='стрелка ввести' style={{ transform: 'rotate(-90deg)' }}/> 
      </div>
      {clubList ?
        <HorizontalClubList
          clubList={clubList}
          handleClubSelection={handleClubSelection}
        /> : null
      }
    </section>
  );
}
 
export default SelectClubSection;
