import { useContext } from 'react';
import styles from './LineupList.module.css';
import { GameResultsContext } from '../../GameResults/utils';
import LineupCard from '../../../../../components/LineupCard/LineupCard';

const Lineups = () => {
  const { lineups, updateStep, updateActiveUid } = useContext(GameResultsContext);

  return ( 
    <div className={styles['wrapper-lineups']}>
      {lineups.map((lineup, index) => (
        <LineupCard
          key={index}
          lineup={lineup}
          type={lineup.matches?.length || lineup.gameSets?.length ? 'filled' : 'edit'}
          stickyBorderTop='-20px'
          onClick={() => {
            updateActiveUid?.(lineup.lineupUid)
            updateStep?.('score');
          }}
          mayBeEdited
        />
      ))}
    </div>
  );
}
 
export default Lineups;
