import styles from './FullScreenPage.module.css';

interface FullScreenPage extends React.PropsWithChildren {
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const FullScreenPage: React.FC<FullScreenPage> = (props) => {
  const { header, children, footer } = props;
  
  return (
    <div className={styles.outerWrap}>
      <div className={styles.innerWrap}>
        <header>
          {header}
        </header>
        <main className={styles.content}>
          {children}
        </main>
        <nav>
          {footer}
        </nav>
      </div>
    </div>
  );
}
 
export default FullScreenPage;
