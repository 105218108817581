/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackMessageAuthor,
    FeedbackMessageAuthorFromJSON,
    FeedbackMessageAuthorFromJSONTyped,
    FeedbackMessageAuthorToJSON,
} from './FeedbackMessageAuthor';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface FeedbackMessageView
 */
export interface FeedbackMessageView {
    /**
     * 
     * @type {FeedbackMessageAuthor}
     * @memberof FeedbackMessageView
     */
    author?: FeedbackMessageAuthor;
    /**
     * 
     * @type {string}
     * @memberof FeedbackMessageView
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeedbackMessageView
     */
    read?: boolean;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof FeedbackMessageView
     */
    timestamp?: LocalDateTime;
}

export function FeedbackMessageViewFromJSON(json: any): FeedbackMessageView {
    return FeedbackMessageViewFromJSONTyped(json, false);
}

export function FeedbackMessageViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackMessageView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': !exists(json, 'author') ? undefined : FeedbackMessageAuthorFromJSON(json['author']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'timestamp': !exists(json, 'timestamp') ? undefined : LocalDateTimeFromJSON(json['timestamp']),
    };
}

export function FeedbackMessageViewToJSON(value?: FeedbackMessageView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': FeedbackMessageAuthorToJSON(value.author),
        'message': value.message,
        'read': value.read,
        'timestamp': LocalDateTimeToJSON(value.timestamp),
    };
}

