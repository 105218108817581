import { Grade, PlayerProfileField, PlayerSaveRequest, RelationshipType } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export const fetchProfileInfo = (playerId: string) => {
  const uid = playerId.includes('-') ? playerId : undefined;
  const id = uid ? undefined : parseInt(playerId);
  return RestClient.profileApi.playerLoad({
    playerLoadRequest: {
      parameters: {
        uid,
        id
      }
    }
  });
}

export const fetchSaveProfile = (parameters: PlayerSaveRequest['parameters']) => {
  return RestClient.profileApi.playerSave({
    playerSaveRequest: {
      parameters,
    }
  });
}


export const fetchProfileStat = (profileId: string) => {
  const profileUid = profileId.includes('-') ? profileId : undefined;
  const playerId = profileUid ? undefined : parseInt(profileId);
  return RestClient.profileApi.loadProfileStatistic({
    loadProfileStatisticRequest: {
      parameters: {
        profileUid,
        playerId
      }
    }
  });
}
export const fetchPlayerList = (lowerRatingFilter: Grade, upperRatingFilter: Grade, textPattern: string, matchContext?: boolean) => {
  return RestClient.profileApi.playerLoadList({
    playerLoadListRequest: {
      parameters: {
        lowerRatingFilter,
        upperRatingFilter,
        textPattern,
        sortBy: PlayerProfileField.RATING,
        matchContext,
      }
    }
  });
}
