import InnerPadding from '@/components/InnerPadding/InnerPadding';
import styles from './TournamentSetScores.module.css';
import Headling from '@/components/Headling/Headling';
import { InputHTMLAttributes } from 'react';
import { setScoreValues } from '../SelectTime/helper';
import { TournamentKind } from '@/integration-api/server-rest-lundapadelApi';

interface TournamentSetScoresProps extends InputHTMLAttributes<HTMLInputElement> {
  maxScore?: string;
  kind?: TournamentKind;
}

const TournamentSetScores = ({ maxScore, kind, ...props }: TournamentSetScoresProps) => {
  return ( 
    <InnerPadding className={styles['wrapper']}>
      <Headling appearence='medium-normal' className={styles['headling']}>
        Всего очков в сете
      </Headling>
      <div className={styles['radio-wrapper']}>
        {kind === TournamentKind.ESCALERA ?
          <label key={15} htmlFor={`maxScore-${15}`}>
            <input type="radio" id={`maxScore-${15}`} name='maxScore' value={'15'} checked={maxScore === '15'} {...props} />
            <div className={styles['radio-item']}>15</div>
          </label> :
          <>          
            {setScoreValues.map(val => (
              <label key={val} htmlFor={`maxScore-${val}`}>
                <input type="radio" id={`maxScore-${val}`} name='maxScore' value={val} checked={maxScore === val} {...props} />
                <div className={styles['radio-item']}>{val}</div>
              </label>
            ))}
          </>
        }
      </div>
    </InnerPadding>
   );
}
 
export default TournamentSetScores;
