import { ChatMessageInfo } from '@/integration-api/server-rest-lundapadelApi';
import { createContext } from 'react';

type GameChatPageContext = {
  chatMessages: ChatMessageInfo[];
  setChatMessages?: React.Dispatch<React.SetStateAction<ChatMessageInfo[]>>;
  getChatMessages?: () => void;
}

const contextInitValue: GameChatPageContext = {
  chatMessages: [],
}

const GameChatPageContext = createContext<GameChatPageContext>(contextInitValue);

export default GameChatPageContext
