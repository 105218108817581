import { useNavigate } from 'react-router-dom';
import styles from './CreateTabs.module.css';
import { EMPTY_STRING } from '@/utils/constants';
import { useAppSelector } from '@/hooks/hooks';
import { Role } from '@/integration-api/server-rest-lundapadelApi';

type ActiveTabs = 'match' | 'tournament' | 'game';

type CreateTabsProps = {
  activeTab: ActiveTabs
}

const CreateTabs = ({ activeTab }: CreateTabsProps) => {
  const navigate = useNavigate();
  const roles = useAppSelector(state => state.auth.currentProfile.roles);

  return ( 
    <div className={styles["tabs-wrapper"]}>
      <button
        type="button"
        className={`${styles["tab"]} ${activeTab === 'game' ? styles["tab-active"] : EMPTY_STRING}`}
        onClick={activeTab !== 'game' ? () => navigate('/create/game') : undefined}
      >
        Игра
      </button>
      <button
        type="button"
        className={`${styles["tab"]} ${(roles?.includes(Role.COACH) || roles?.includes(Role.ADMIN)) ? EMPTY_STRING : styles['tab-forbidden']} ${activeTab === 'tournament' ? styles["tab-active"] : EMPTY_STRING}`}
        onClick={activeTab !== 'tournament' ? () => navigate('/create/tournament') : undefined}
        disabled={!(roles?.includes(Role.COACH) || roles?.includes(Role.ADMIN))}
      >
        Турнир
      </button>
    </div>
  );
}
 
export default CreateTabs;
