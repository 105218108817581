import { useCallback, useEffect, useMemo, useState } from "react";
import { cityClubsLoadList, clubLoadList } from "../service";
import styles from './ProfileFavoriteClubs.module.css';
import { CityClubsGroupLoadListParameters, CityClubsItemView, ClubLoadListParameters, ClubView, Reference } from "@/integration-api/server-rest-lundapadelApi";
import { FetchErrorMessage } from "@/types/types";
import Header from "@/components/Header/Header";
import { useAppSelector } from "@/hooks/hooks";
import ClubCityBelonging from "../../create/components/ClubCityBelonging/ClubCityBelonging";
import OversideWrapper from "@/components/OversideWrapper/OversideWrapper";
import Button from "@/components/Button/Button";
import { PuffLoader } from "react-spinners";
import HLWrapper from "@/components/HLWrapper/HLWrapper";
import Search from "@/components/Search/Search";
import useDebounce from "@/hooks/useDebounce";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

type ProfileFavoriteClubsProps = {
  favoriteClubs: Array<ClubView>;
  onClose: () => void;
  updateClubs: (clubs: Array<ClubView>) => void;
}

const ProfileFavoriteClubs = ({ favoriteClubs, onClose, updateClubs }: ProfileFavoriteClubsProps) => {
  const profileCity = useAppSelector(state => state.auth.currentProfile.city);
  const [clubs, setClubs] = useState<Array<CityClubsItemView>>([]);
  const [textPattern, setTextPattern] = useState<string>('');
  const [selectedClubs, setSelectedClubs] = useState<Array<ClubView>>([]);
  const [loading, setLoading] = useState(false);

  const loadClubs = useCallback((params: CityClubsGroupLoadListParameters) => {
    cityClubsLoadList(params)
    .then(({ result }) => {
      setClubs(result?.items ?? []);
      setSelectedClubs(result?.items?.map(entry => entry.clubs ?? []).flat().filter(club => club.favorite) ?? []);
    })
    .catch((err) => {
      if(err instanceof Promise) {
        err.then(err => {
          const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
          PopupMessage.open(userErrorMessage ?? errorMessage);
        })
      }
    })
    .finally(() => setLoading(false));
  }, [favoriteClubs]);

  const debouncedLoadClubs = useDebounce(loadClubs, 500);

  const handleClubSelect = useCallback((uid: string) => {
    const favClub = clubs.map(entry => entry.clubs ?? []).flat().find(club => club.reference?.uid === uid);
    const favClubIndex = selectedClubs.findIndex(club => club.reference?.uid === favClub?.reference?.uid);
    setSelectedClubs(state => favClubIndex > -1 ? state.slice(0, favClubIndex).concat(state.slice(favClubIndex + 1)) : state.concat([favClub ?? {}]));
  }, [clubs, selectedClubs]);

  const renderClubs = useMemo(() => {
    return clubs.map(entry => (
      <ClubCityBelonging
        key={entry.city}
        city={entry.city}
        clubs={entry.clubs ?? []}
        handleClubSelect={handleClubSelect}
        favoriteClubSelection
        favoriteSelectedClubs={selectedClubs}
      />
    ));
  }, [clubs, profileCity, selectedClubs]);

  useEffect(() => {
    if(textPattern) {
      debouncedLoadClubs({ textPattern });
    } else {
      loadClubs({});
    }
  }, [textPattern])

  return ( 
    <section>
      <Header handleClick={onClose} className={styles['header']}>
        Любимый клуб
      </Header>
      <HLWrapper>
        <Search
          placeholder='Найти клуб'
          searchHandler={(e) => setTextPattern(e.target.value)}
        />
      </HLWrapper>
      {loading ? 
        <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }} /> :
        <div className={styles['clubs']}>
          {renderClubs}
        </div>
      }
      <OversideWrapper className={styles.saveBtm}>
        <Button onClick={() => updateClubs(selectedClubs)}>Сохранить</Button>
      </OversideWrapper>
    </section>
  );
}
 
export default ProfileFavoriteClubs;
