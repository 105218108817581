import { timeStringToNumber } from "../../utils"

export function timeNumberToString(time: number) {
  if(time - Math.trunc(time) > 0) {
    return `${Math.trunc(time)}:30`;
  } else {
    return `${time}:00`;
  }
}

export function calculateEndTime(time: string, duration: number) {
  const parsedTime = timeStringToNumber(time);
  if(parsedTime + duration / 60 === 24) {
    return ` - 00:00`
  } else {
    return ` - ${timeNumberToString(parsedTime + duration / 60)}`
  }
}
