import s from "./DialogBlock.module.css"
import emptyChatImg from '@/static/images/icons/empty-chat.svg'
import { DialogBlockProps } from "../ChatPage.intefaces"
import { FeedbackMessageView } from "@/integration-api/server-rest-get-info"
import DialogTimeItem from "../DialogTimeItem/DialogTimeItem"
import { Ref, forwardRef, useContext, useEffect } from "react"
import { ChatContext } from "../ChatContext"

export interface dialogMockData {
  name?: string,
  message?: string,
  time?: string,
  incoming?: boolean,
}

const DialogBlock = <T extends FeedbackMessageView>({ messagesBlocks }: DialogBlockProps<T>) => {
  const { dialogBlockRef } = useContext(ChatContext);

  useEffect(() => {
    if(dialogBlockRef?.current && messagesBlocks.length) {
      dialogBlockRef.current.scrollTo({ top: dialogBlockRef.current.scrollHeight })
    }
  }, [dialogBlockRef?.current, messagesBlocks.length])

  return (
    <div className={s.wrap}>
      {messagesBlocks.length === 0 ?
        <div className={s.emptyChat}>
          <div className={s.emptyText}>
            У вас есть вопрос или предложение, возникла проблема или вам нужна информация – напишите нам
            {/* Пока здесь пусто, начните <br />диалог первым! */}
          </div>
          <img src={emptyChatImg} alt="empty chat" className={s.emptyChatImg} />
        </div> :
        <div className={s.chatWithMessages} ref={dialogBlockRef}>
          {messagesBlocks.map((entry, index) =>
            <DialogTimeItem<any>
              key={index}
              //ebal v rot ebanyi typescript
              //@ts-ignore
              messagesBlock={entry}
            />
          )}
        </div>
      }
    </div>
  )
}

export default DialogBlock;
