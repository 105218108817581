import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import Button from '@/components/Button/Button';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import { Link } from 'react-router-dom';
import lundaIllustration from '@/static/images/lunda-padel-logo.svg';
import styles from './UpdateRequiredPage.module.css';
import { getOSName } from '@/utils/utils';

export default function UpdateRequiredPage() {
  function calculateUpdateLink() {
    const osName = getOSName();
    if (osName === 'iOS') {
      return 'https://apps.apple.com/ru/app/lunda-padel/id6479701297';
    } else if(osName === 'Android') {
      return 'https://play.google.com/store/apps/details?id=lunda.padel.app&hl=ru&gl=US';
    } else {
      return 'https://lundapadel.ru';
    }
  }

  return (
    <BasePageWrapper contentWrapperClassName={styles['page-wrap']}>
      <img src={lundaIllustration} alt="lunda illustration" className={styles['illustration']} />

      <span className={styles.description}>Вышла новая версия приложения!</span>

      <OversideWrapper className={styles['button-wrap']}>
        <Link to={calculateUpdateLink()}>
          <Button>Обновить</Button>
        </Link>
      </OversideWrapper>
    </BasePageWrapper>
  )
}
