/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationReadAllParameter,
    NotificationReadAllParameterFromJSON,
    NotificationReadAllParameterFromJSONTyped,
    NotificationReadAllParameterToJSON,
} from './NotificationReadAllParameter';

/**
 * 
 * @export
 * @interface ReadAllNotificationRequest
 */
export interface ReadAllNotificationRequest {
    /**
     * 
     * @type {NotificationReadAllParameter}
     * @memberof ReadAllNotificationRequest
     */
    parameters?: NotificationReadAllParameter;
}

export function ReadAllNotificationRequestFromJSON(json: any): ReadAllNotificationRequest {
    return ReadAllNotificationRequestFromJSONTyped(json, false);
}

export function ReadAllNotificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadAllNotificationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : NotificationReadAllParameterFromJSON(json['parameters']),
    };
}

export function ReadAllNotificationRequestToJSON(value?: ReadAllNotificationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': NotificationReadAllParameterToJSON(value.parameters),
    };
}

