import { LocalDateTime } from "@/integration-api/server-rest-lundapadelApi"
import RestClient from "@/integration/RestClient"

export const feedbackSendMessage = (message?: string) => {
  return RestClient.mainApi.feedbackSendMessage({
    feedbackSendMessageRequest: {
      parameters: {
        message
      }
    }
  })
}

export const feedbackLoadListMessage = () => {
  return RestClient.mainApi.feedbackLoadListMessage({
    limit: 100,
    offset: 0
  })
}

export const feedbackRead = (lastFeedbackMessageDate: LocalDateTime) => {
  return RestClient.mainApi.feedbackRead({
    lastFeedbackMessageDate
  })
}
