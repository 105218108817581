import { TournamentStatus } from "@/integration-api/server-rest-lundapadelApi";
import styles from './TournamentTable.module.css';
import TournamentTableItemPreview from "../TournamentTableItem/TournametTableItemPreview";
import { useContext, useState } from "react";
import OversideWrapper from "@/components/OversideWrapper/OversideWrapper";
import Button, { ButtonVariants } from "@/components/Button/Button";
import { TournamentResultClassicContext, applyTableItemStyle } from "../../utils";
import { TournamentResultsAction, TournamentResultsActionParams } from "../../TournamentPage.interfaces";
import TournamentTableItemDraggable from "../TournamentTableItem/TournamentTableItemDraggable";

type TournamentTableProps = {
  tournamentStatus?: TournamentStatus; 
  handleButtonAction: (action: TournamentResultsAction, params?: TournamentResultsActionParams) => Promise<void>;
}

const TournamentTable = ({ tournamentStatus, handleButtonAction }: TournamentTableProps) => {
  const { isOwner, participants } = useContext(TournamentResultClassicContext);
  const [dropTarget, setDropTarget] = useState<number>();
  const [dragTarget, setDragTarget] = useState<number>();

  const renderParticipants = () => {
    return participants.map((participant, index) => (
      <TournamentTableItemDraggable
        key={participant.player?.uid}
        participant={participant}
        placement={index + 1}
        setDropTarget={(val: number | undefined) => setDropTarget(val)}
        setDragTarget={(val: number | undefined) => setDragTarget(val)}
        style={dropTarget && dragTarget ? applyTableItemStyle(dragTarget, dropTarget, index + 1) : undefined}
      />
    ))
  }

  return ( 
    <>
      {renderParticipants()}
      <TournamentTableItemPreview/>
      <div className={styles['buttons']}>
        <OversideWrapper>
          {tournamentStatus !== TournamentStatus.FINISH && isOwner ?
            <>            
              {tournamentStatus === TournamentStatus.START ?
                <Button 
                  variant={ButtonVariants.PRIMARY}
                  onClick={() => handleButtonAction('save', { status: TournamentStatus.AFTER_TOURNAMENT })}>
                  Отправить результат турнира
                </Button> :
                <Button 
                  variant={ButtonVariants.BLUE}
                  onClick={() => handleButtonAction('save', { status: TournamentStatus.AFTER_TOURNAMENT })}>
                  Отправить результат турнира
                </Button>
              }
            </> : null
          }
        </OversideWrapper>
      </div>
    </>
  );
}
 
export default TournamentTable;
