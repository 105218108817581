import styles from './RadioButton.module.css';
import React from 'react';

export type RadioButtonProps = {

} & React.AllHTMLAttributes<HTMLInputElement>;

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { label, ...inputProps } = props;

  return (
    <label className={styles.radioBtn}>
      <input className={styles.radioInput} type="radio" {...inputProps}  /> 
      <span className={styles.customRadioIndicator} />
      {label}
    </label>
  );
};

export default RadioButton;
