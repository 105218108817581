/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GameMatchResult,
    GameMatchResultFromJSON,
    GameMatchResultFromJSONTyped,
    GameMatchResultToJSON,
} from './GameMatchResult';
import {
    GameSetView,
    GameSetViewFromJSON,
    GameSetViewFromJSONTyped,
    GameSetViewToJSON,
} from './GameSetView';
import {
    GameTournamentKind,
    GameTournamentKindFromJSON,
    GameTournamentKindFromJSONTyped,
    GameTournamentKindToJSON,
} from './GameTournamentKind';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';
import {
    InformationMessage,
    InformationMessageFromJSON,
    InformationMessageFromJSONTyped,
    InformationMessageToJSON,
} from './InformationMessage';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';
import {
    PlayerProfileInfo,
    PlayerProfileInfoFromJSON,
    PlayerProfileInfoFromJSONTyped,
    PlayerProfileInfoToJSON,
} from './PlayerProfileInfo';

/**
 * 
 * @export
 * @interface GameElementInfo
 */
export interface GameElementInfo {
    /**
     * 
     * @type {string}
     * @memberof GameElementInfo
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof GameElementInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof GameElementInfo
     */
    clubName?: string;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof GameElementInfo
     */
    plannedDate?: LocalDateTime;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameElementInfo
     */
    player1?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameElementInfo
     */
    player2?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameElementInfo
     */
    player3?: PlayerProfileInfo;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameElementInfo
     */
    player4?: PlayerProfileInfo;
    /**
     * 
     * @type {boolean}
     * @memberof GameElementInfo
     */
    privateMatch?: boolean;
    /**
     * 
     * @type {Grade}
     * @memberof GameElementInfo
     */
    minGrade?: Grade;
    /**
     * 
     * @type {Grade}
     * @memberof GameElementInfo
     */
    maxGrade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof GameElementInfo
     */
    displayMinGrade?: string;
    /**
     * 
     * @type {string}
     * @memberof GameElementInfo
     */
    displayMaxGrade?: string;
    /**
     * 
     * @type {PlayerProfileInfo}
     * @memberof GameElementInfo
     */
    owner?: PlayerProfileInfo;
    /**
     * 
     * @type {GameMatchResult}
     * @memberof GameElementInfo
     */
    matchResult?: GameMatchResult;
    /**
     * 
     * @type {boolean}
     * @memberof GameElementInfo
     */
    ranking?: boolean;
    /**
     * 
     * @type {GameTournamentKind}
     * @memberof GameElementInfo
     */
    tournamentKind?: GameTournamentKind;
    /**
     * 
     * @type {string}
     * @memberof GameElementInfo
     */
    displayPlannedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof GameElementInfo
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof GameElementInfo
     */
    place?: number;
    /**
     * 
     * @type {Array<InformationMessage>}
     * @memberof GameElementInfo
     */
    messages?: Array<InformationMessage>;
    /**
     * 
     * @type {Array<GameSetView>}
     * @memberof GameElementInfo
     */
    gameSets?: Array<GameSetView>;
    /**
     * 
     * @type {Array<PlayerProfileInfo>}
     * @memberof GameElementInfo
     */
    members?: Array<PlayerProfileInfo>;
}

export function GameElementInfoFromJSON(json: any): GameElementInfo {
    return GameElementInfoFromJSONTyped(json, false);
}

export function GameElementInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameElementInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'clubName': !exists(json, 'clubName') ? undefined : json['clubName'],
        'plannedDate': !exists(json, 'plannedDate') ? undefined : LocalDateTimeFromJSON(json['plannedDate']),
        'player1': !exists(json, 'player1') ? undefined : PlayerProfileInfoFromJSON(json['player1']),
        'player2': !exists(json, 'player2') ? undefined : PlayerProfileInfoFromJSON(json['player2']),
        'player3': !exists(json, 'player3') ? undefined : PlayerProfileInfoFromJSON(json['player3']),
        'player4': !exists(json, 'player4') ? undefined : PlayerProfileInfoFromJSON(json['player4']),
        'privateMatch': !exists(json, 'privateMatch') ? undefined : json['privateMatch'],
        'minGrade': !exists(json, 'minGrade') ? undefined : GradeFromJSON(json['minGrade']),
        'maxGrade': !exists(json, 'maxGrade') ? undefined : GradeFromJSON(json['maxGrade']),
        'displayMinGrade': !exists(json, 'displayMinGrade') ? undefined : json['displayMinGrade'],
        'displayMaxGrade': !exists(json, 'displayMaxGrade') ? undefined : json['displayMaxGrade'],
        'owner': !exists(json, 'owner') ? undefined : PlayerProfileInfoFromJSON(json['owner']),
        'matchResult': !exists(json, 'matchResult') ? undefined : GameMatchResultFromJSON(json['matchResult']),
        'ranking': !exists(json, 'ranking') ? undefined : json['ranking'],
        'tournamentKind': !exists(json, 'tournamentKind') ? undefined : GameTournamentKindFromJSON(json['tournamentKind']),
        'displayPlannedDate': !exists(json, 'displayPlannedDate') ? undefined : json['displayPlannedDate'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(InformationMessageFromJSON)),
        'gameSets': !exists(json, 'gameSets') ? undefined : ((json['gameSets'] as Array<any>).map(GameSetViewFromJSON)),
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(PlayerProfileInfoFromJSON)),
    };
}

export function GameElementInfoToJSON(value?: GameElementInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'uid': value.uid,
        'clubName': value.clubName,
        'plannedDate': LocalDateTimeToJSON(value.plannedDate),
        'player1': PlayerProfileInfoToJSON(value.player1),
        'player2': PlayerProfileInfoToJSON(value.player2),
        'player3': PlayerProfileInfoToJSON(value.player3),
        'player4': PlayerProfileInfoToJSON(value.player4),
        'privateMatch': value.privateMatch,
        'minGrade': GradeToJSON(value.minGrade),
        'maxGrade': GradeToJSON(value.maxGrade),
        'displayMinGrade': value.displayMinGrade,
        'displayMaxGrade': value.displayMaxGrade,
        'owner': PlayerProfileInfoToJSON(value.owner),
        'matchResult': GameMatchResultToJSON(value.matchResult),
        'ranking': value.ranking,
        'tournamentKind': GameTournamentKindToJSON(value.tournamentKind),
        'displayPlannedDate': value.displayPlannedDate,
        'duration': value.duration,
        'place': value.place,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(InformationMessageToJSON)),
        'gameSets': value.gameSets === undefined ? undefined : ((value.gameSets as Array<any>).map(GameSetViewToJSON)),
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(PlayerProfileInfoToJSON)),
    };
}

