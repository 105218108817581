import { useCallback, useEffect, useRef, useState } from "react";
import { FetchErrorMessage } from "@/types/types";
import ChatPage from "@/components/Chat/ChatPage";
import { feedbackLoadListMessage, feedbackRead, feedbackSendMessage } from "./service";
import { FeedbackMessageAuthor, FeedbackMessageView, toLocalDateTime } from "@/integration-api/server-rest-lundapadelApi";
import { PopupMessage } from "@/components/PopupMessage/PopupMessage";

function ProfileFeedbackPage() {
  const [feedbackMessages, setFeedbackMessages] = useState<Array<FeedbackMessageView>>([]);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleSendMessage = useCallback((message: string) => {
    feedbackSendMessage(message)
      .then(() => {
        setFeedbackMessages(state => state.concat([{
          author: FeedbackMessageAuthor.PLAYER,
          message: message,
          read: false,
          timestamp: toLocalDateTime(new Date())
        }]))
      })
      .catch(err => {
        if(err instanceof Promise) {
          err.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })
  }, []);

  const loadMessages = () => {
    feedbackLoadListMessage()
    .then(({ result }) => {
      setFeedbackMessages(result?.infos ?? []);
      const filteredMessages = result?.infos?.filter(msg => msg.author === FeedbackMessageAuthor.ADMIN);
      const lastMsg = filteredMessages?.at(-1);
      if(!timer.current) {
        timer.current = setInterval(loadMessages, 60000)
      }
      if(lastMsg?.timestamp) {
        return feedbackRead(lastMsg.timestamp)
        // return feedbackRead(dateToUTC(lastMsg.timestamp))
      }
    })
    .catch(err => {
      if(timer.current) {
        clearInterval(timer.current);
      }
      if(err instanceof Promise) {
        err.then(err => {
          const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
          PopupMessage.open(userErrorMessage ?? errorMessage);
        })
      }
    })
  }

  useEffect(() => {
    loadMessages();
    return () => {
      if(timer.current) {
        clearInterval(timer.current);
      }
    }
  }, []);

  return (
    <>
      <ChatPage<FeedbackMessageView>
        header='Обратная связь'
        messageList={feedbackMessages}
        handleSendMessage={handleSendMessage}
      />
    </>
  );
}

export default ProfileFeedbackPage;
