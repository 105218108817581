/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvertisementView,
    AdvertisementViewFromJSON,
    AdvertisementViewFromJSONTyped,
    AdvertisementViewToJSON,
} from './AdvertisementView';

/**
 * 
 * @export
 * @interface LoadAdvertisementResponse
 */
export interface LoadAdvertisementResponse {
    /**
     * 
     * @type {AdvertisementView}
     * @memberof LoadAdvertisementResponse
     */
    result?: AdvertisementView;
    /**
     * 
     * @type {Array<object>}
     * @memberof LoadAdvertisementResponse
     */
    messages?: Array<object>;
}

export function LoadAdvertisementResponseFromJSON(json: any): LoadAdvertisementResponse {
    return LoadAdvertisementResponseFromJSONTyped(json, false);
}

export function LoadAdvertisementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadAdvertisementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : AdvertisementViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function LoadAdvertisementResponseToJSON(value?: LoadAdvertisementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': AdvertisementViewToJSON(value.result),
        'messages': value.messages,
    };
}

