/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileStatisticsParam
 */
export interface ProfileStatisticsParam {
    /**
     * 
     * @type {string}
     * @memberof ProfileStatisticsParam
     */
    profileUid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileStatisticsParam
     */
    playerId?: number | null;
}

export function ProfileStatisticsParamFromJSON(json: any): ProfileStatisticsParam {
    return ProfileStatisticsParamFromJSONTyped(json, false);
}

export function ProfileStatisticsParamFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileStatisticsParam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profileUid': !exists(json, 'profileUid') ? undefined : json['profileUid'],
        'playerId': !exists(json, 'playerId') ? undefined : json['playerId'],
    };
}

export function ProfileStatisticsParamToJSON(value?: ProfileStatisticsParam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profileUid': value.profileUid,
        'playerId': value.playerId,
    };
}

