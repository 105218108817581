import { AuthParameters, CaptchaDataLoadParam, SendCodeParameters } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export function sendCode(parameters: SendCodeParameters, answer: string, ticket: string, telegramSend: boolean) {
  return RestClient.profileApi.sendCode({
    sendCodeRequest: {
      parameters: {
        ...parameters,
        answer,
        ticket,
        telegramSend,
      },
    },
  });
}

export function authFetch(parameters: AuthParameters) {
  return RestClient.profileApi.auth({
    authRequest: {
      parameters,
    },
  });
}

export function fetchCaptcha(parameters: CaptchaDataLoadParam) {
  return RestClient.profileApi.loadCaptcha({
    loadCaptchaRequest: {
      parameters
    }
  });
}
