/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Color,
    ColorFromJSON,
    ColorFromJSONTyped,
    ColorToJSON,
} from './Color';
import {
    LocalDateTime,
    LocalDateTimeFromJSON,
    LocalDateTimeFromJSONTyped,
    LocalDateTimeToJSON,
} from './LocalDateTime';

/**
 * 
 * @export
 * @interface NewsItemView
 */
export interface NewsItemView {
    /**
     * 
     * @type {string}
     * @memberof NewsItemView
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsItemView
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsItemView
     */
    content?: string;
    /**
     * 
     * @type {Color}
     * @memberof NewsItemView
     */
    color?: Color;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof NewsItemView
     */
    timestamp?: LocalDateTime;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewsItemView
     */
    imagesUids?: Array<string>;
}

export function NewsItemViewFromJSON(json: any): NewsItemView {
    return NewsItemViewFromJSONTyped(json, false);
}

export function NewsItemViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'color': !exists(json, 'color') ? undefined : ColorFromJSON(json['color']),
        'timestamp': !exists(json, 'timestamp') ? undefined : LocalDateTimeFromJSON(json['timestamp']),
        'imagesUids': !exists(json, 'imagesUids') ? undefined : json['imagesUids'],
    };
}

export function NewsItemViewToJSON(value?: NewsItemView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'title': value.title,
        'content': value.content,
        'color': ColorToJSON(value.color),
        'timestamp': LocalDateTimeToJSON(value.timestamp),
        'imagesUids': value.imagesUids,
    };
}

