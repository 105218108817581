import Headling from '@/components/Headling/Headling';
import ClubItem from '../ClubItem/ClubItem';
import styles from './ClubsSection.module.css';
import { useCallback, useEffect, useState } from 'react';
import { ClubView } from '@/integration-api/server-rest-lundapadelApi';
import { clubLoadList } from './service';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FetchErrorMessage } from '@/types/types';
import DBManager from '@/utils/DBManager/DBManager';
import SeeMore from '@/components/SeeMore/SeeMore';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const ClubsSection = (): JSX.Element => {
  const [clubs, setClubs] = useState<Array<ClubView>>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadClubs = useCallback(async () => {
    setLoading(true)
    try {
      const { result } = await clubLoadList();
      if (result) {
        setClubs(result.views || []);
      }
    } catch (err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    } finally{
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      DBManager.create('clubs', clubs, 'clubsInfo')
      if (!clubs) {
        loadClubs();
      }
    } else {
      !clubs && DBManager.read('clubs', 'clubsInfo').then(clubs => setClubs(clubs)).catch((err) => console.log(err)).finally(()=> setLoading(false))
    }
  }, [clubs, loadClubs])

  return (
    <section className={styles['courts']}>
      <div className={styles['headling']}>
        <Headling appearence="big-normal">
          Клубы
        </Headling>
        <Link to={'/clubs'}>
          <SeeMore>Все</SeeMore>
        </Link>
      </div>
      <div className={styles['courts-wrapper']}>
        {loading && <Skeleton count={4} />}
        {clubs &&
          clubs.slice(0, 5).map((club) => (
            <Link to={`/clubs/${club.name}`} key={club.name}>
              <ClubItem club={club} />
            </Link>
          ))}
      </div>
    </section>
  );
};

export default ClubsSection;
