import styles from './DialogTimeItem.module.css';
import { DialogTimeItemProps } from '../ChatPage.intefaces';
import formatter from '@/utils/utils';
import DialogItem from '../DialogItem/DialogItem';
import dayjs from 'dayjs';
import { FeedbackMessageAuthor, FeedbackMessageView } from '@/integration-api/server-rest-lundapadelApi';

export const DialogTimeItem = <T extends FeedbackMessageView>({ messagesBlock }: DialogTimeItemProps<T>) => {

  return (
    <div className={styles['item-wrapper']}>
      <span className={styles['item-date']}>{formatter.relativeDate(messagesBlock[0] as string)}</span>
      <div className={styles['messages-wrapper']}>
        {messagesBlock[1].map((msg, index) => (
          <DialogItem
            key={index}
            message={msg.message}
            name={msg.author === FeedbackMessageAuthor.ADMIN ? 'Администрация' : undefined}
            time={`${dayjs(msg.timestamp).hour() < 10 ? `0${dayjs(msg.timestamp).hour()}` : dayjs(msg.timestamp).hour()}:${dayjs(msg.timestamp).minute() < 10 ? `0${dayjs(msg.timestamp).minute()}` : dayjs(msg.timestamp).minute()}`}
            incoming={msg.author === FeedbackMessageAuthor.ADMIN}
          />
        ))}
      </div>
    </div>
  );
}

export default DialogTimeItem;
