import styles from './RatingGrade.module.css';
import { EMPTY_STRING } from '@/utils/constants';

export const getAdditionalContentClass = ({
  isActive,
  isPrev,
  isNext,
}: {
  isActive: boolean;
  isNext: boolean;
  isPrev: boolean;
}) => {
  if (isActive) {
    return styles['swiper-slide-content--active'];
  }

  if (isPrev) {
    return styles['swiper-slide-content--prev'];
  }

  if (isNext) {
    return styles['swiper-slide-content--next'];
  }

  return EMPTY_STRING;
};
