/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentStatisticElementView,
    TournamentStatisticElementViewFromJSON,
    TournamentStatisticElementViewFromJSONTyped,
    TournamentStatisticElementViewToJSON,
} from './TournamentStatisticElementView';

/**
 * 
 * @export
 * @interface PlayerProfileStatisticsView
 */
export interface PlayerProfileStatisticsView {
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    friendsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    followersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    partnersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    matchesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    winsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    correctMatchesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    winsLastMatchCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    winsLastTwentySet?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    countLastTwentySet?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    tournamentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    gamesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerProfileStatisticsView
     */
    efficiency?: number;
    /**
     * 
     * @type {Array<TournamentStatisticElementView>}
     * @memberof PlayerProfileStatisticsView
     */
    tournaments?: Array<TournamentStatisticElementView>;
}

export function PlayerProfileStatisticsViewFromJSON(json: any): PlayerProfileStatisticsView {
    return PlayerProfileStatisticsViewFromJSONTyped(json, false);
}

export function PlayerProfileStatisticsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileStatisticsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'friendsCount': !exists(json, 'friendsCount') ? undefined : json['friendsCount'],
        'followersCount': !exists(json, 'followersCount') ? undefined : json['followersCount'],
        'partnersCount': !exists(json, 'partnersCount') ? undefined : json['partnersCount'],
        'matchesCount': !exists(json, 'matchesCount') ? undefined : json['matchesCount'],
        'winsCount': !exists(json, 'winsCount') ? undefined : json['winsCount'],
        'correctMatchesCount': !exists(json, 'correctMatchesCount') ? undefined : json['correctMatchesCount'],
        'winsLastMatchCount': !exists(json, 'winsLastMatchCount') ? undefined : json['winsLastMatchCount'],
        'winsLastTwentySet': !exists(json, 'winsLastTwentySet') ? undefined : json['winsLastTwentySet'],
        'countLastTwentySet': !exists(json, 'countLastTwentySet') ? undefined : json['countLastTwentySet'],
        'tournamentCount': !exists(json, 'tournamentCount') ? undefined : json['tournamentCount'],
        'gamesCount': !exists(json, 'gamesCount') ? undefined : json['gamesCount'],
        'efficiency': !exists(json, 'efficiency') ? undefined : json['efficiency'],
        'tournaments': !exists(json, 'tournaments') ? undefined : ((json['tournaments'] as Array<any>).map(TournamentStatisticElementViewFromJSON)),
    };
}

export function PlayerProfileStatisticsViewToJSON(value?: PlayerProfileStatisticsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'friendsCount': value.friendsCount,
        'followersCount': value.followersCount,
        'partnersCount': value.partnersCount,
        'matchesCount': value.matchesCount,
        'winsCount': value.winsCount,
        'correctMatchesCount': value.correctMatchesCount,
        'winsLastMatchCount': value.winsLastMatchCount,
        'winsLastTwentySet': value.winsLastTwentySet,
        'countLastTwentySet': value.countLastTwentySet,
        'tournamentCount': value.tournamentCount,
        'gamesCount': value.gamesCount,
        'efficiency': value.efficiency,
        'tournaments': value.tournaments === undefined ? undefined : ((value.tournaments as Array<any>).map(TournamentStatisticElementViewToJSON)),
    };
}

