import Header from "@/components/Header/Header";
import RatingInfoHeadling from "../components/RatingInfoHeadling/RatingInfoHeadling";
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import InnerPadding from "@/components/InnerPadding/InnerPadding";
import styles from './RatingInfoPage.module.css';
import defineLevel from '@/static/images/levelDefine.png';
import blueCheckMark from '@/static/images/icons/blue-checkmark-icon.svg';
import { useNavigate } from "react-router-dom";

const RatingInfoPage = (): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = () => {
    if(window.history.state.usr?.from === 'register') {
      navigate('/auth/register', { state: { step: 3 } });
    } else {
      navigate(-1);
    }
  }

  return (
    <BasePageWrapper>
      <Header handleClick={handleClick} className={styles['header']}>Информация о рейтинге</Header>
      <InnerPadding className={styles['content-wrapper']}>
        <main>
          <RatingInfoHeadling index={1}>Рейтинг в приложении</RatingInfoHeadling>
          <div className={styles['text-content']}>
            Это цифровое обозначение (2.1, 5.8 и т.д). Первичный рейтинг дается за прохождение опроса, для более точного определения внутриигрового рейтинга запишитесь на 10-ти минутную тренировку в наш клуб, это совершенно бесплатно!
          </div>
          <div className={styles['text-content']}>
            Рейтинг будет повышаться или понижаться в зависимости от выигранных или проигранных матчей.
          </div>
          <RatingInfoHeadling index={2}>Уровень игры</RatingInfoHeadling>
          <div className={styles['text-content']}>
            Это буквенное обозначение (A+, С и т.д.). Пользователь относит себя к той или иной группе в зависимости от своих умений и опыта.
          </div>
          <div className={styles['text-content']}>
            Учтите, что А - это игрок-профессионал, а D - игрок впервые держит ракетку. 
          </div>
          <div className={styles['text-content']} style={{marginBottom: '28px'}}>
            Определить уровень игрока может тренер клуба, тогда рейтинг считается подтвержденным и обозначается иконкой 
            <span>
              <img src={blueCheckMark} style={{ marginLeft: '4px'}} alt='телеграм'/>  
            </span>
          </div>
          <div className={styles['text-content']}>
            В какую группу себя отнести?
          </div>
          <img src={defineLevel} alt="уровни" style={{ width: '100%'}}/>
          <RatingInfoHeadling index={3}>Вложения</RatingInfoHeadling>
          <div style={{ marginBottom: '64px' }}>
            <div className={styles['text-content']}>
              Как будут выглядеть вышеописанные  рейтинги в приложении: 
            </div>
            <div className={styles['rating-struct']}></div>
          </div>
        </main>
      </InnerPadding>
    </BasePageWrapper>
  );
}

export default RatingInfoPage;
