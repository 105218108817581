import styles from './Tournament.module.css';
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { cancelTournament, entryTournament, loadTournament, loadTournamentRoundsPreview, loadTournamentStatistics, ownerKickPlayerTournament, rejectInviteForTournament, startTournament } from "./service";
import { Gender, PlayerProfileInfo, TicketType, TournamentKind, TournamentPlayerStatisticsInfo, TournamentRoundView, TournamentStatus, TournamentView } from "@/integration-api/server-rest-lundapadelApi";
import TournamentCard from "@/components/TournamentCard/TournamentCard";
import PageBackground from "@/components/PageBackground/PageBackground";
import bgImage from '@/static/images/tournament.png';
import Header from '@/components/Header/Header';
import { TournamentButtonAction, TournamentButtonParams, TournamentPageModalState, TournamentResultsTabsValues } from './TournamentPage.interfaces';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import { useAppSelector } from '@/hooks/hooks';
import Button, { ButtonVariants } from '@/components/Button/Button';
import joinIcon from '@/static/images/icons/join-tournament-icon.svg';
import TournamentParticipants from './components/TournamentParticipants/TournamentParticipants';
import { PuffLoader } from 'react-spinners';
import { FetchErrorMessage } from '@/types/types';
import pencilIcon from '@/static/images/icons/pencil-icon.svg';
import { shareTournament } from './components/TournamentTableItem/utils';
import TournamentTableItem from './components/TournamentTableItem/TournamentTableItem';
import TournamentResultsTabs from './components/TournamentResultsTabs/TournamentResultsTabs';
import TournamentStatistics from './components/TournamentStatistics/TournamentStatistics';
import TournamentRoundsPreview from './components/TournamentRoundsPreview/TournamentRoundsPreview';
import { useImmer } from 'use-immer';
import roundStyles from './components/TournamentRoundsPreview/TournamentRoundsPreview.module.css';
import EditGameIcon from '@/static/images/icons/EditGameIcon';
import ShareIcon from '@/static/images/icons/ShareIcon';
import EntityCalendarIcon from '@/static/images/icons/EntityCalendarIcon';
import { createImgSrc } from '@/utils/utils';
import DBManager from '@/utils/DBManager/DBManager';
import TournamentActionModal from './components/TournamentActionModal/TournamentActionModal';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import OwnerButton from './components/OwnerButton/OwnerButton';
import GamePlayerCarousel from '../game/components/GamePlayerCarousel/GamePlayerCarousel';
import { useWebsocketTicket } from '../game/GameStatusContent/utils';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

type TournamentPageState = {
  tournament: TournamentView;
  tournamentStatistic?: Array<TournamentPlayerStatisticsInfo>;
  rounds: Array<TournamentRoundView>;
  activeTab: TournamentResultsTabsValues;
  editMode: boolean;
}
const TournamentPage = () => {
  const current = useAppSelector(state => state.auth.currentProfile);
  const { id } = useParams();
  const navigate = useNavigate();

  const [tournamentState, setTournamentState] = useImmer<TournamentPageState>({
    tournament: {},
    rounds: [],
    activeTab: 'results',
    editMode: false,
  });

  const [tournamentModalsState, setTournamentModalsState] = useImmer<TournamentPageModalState>({
    cancelTournamentVisible: false,
    joinQueueVisible: false,
    kickPlayerVisible: false,
    leaveQueueVisible: false,
    leaveTournamentVisible: false,
    removeInviteVisible: false,
  })

  const [loading, setLoading] = useState(false);
  const webSocketURL = useWebsocketTicket(id, TicketType.TOURNAMENT);
  const [offset, setOffset] = useState(0);

  const buttonsRef = useRef<HTMLDivElement>(null);

  const handleButtonAction = useCallback(async (action: TournamentButtonAction, params?: TournamentButtonParams) => {
    try {      
      switch(action) {
        case 'start': {
          if(tournamentState.tournament?.status === TournamentStatus.PLANNED) {
            await startTournament({ tournamentUid: id });
          }
          navigate(`/tournament/${id}/results`);
          break;
        }
        case 'cancel': {
          await cancelTournament({
            tournamentUid: id,
          });
          setTournamentModalsState(state => { state.cancelTournamentVisible = false });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'join': {
          await entryTournament({
            tournamentUid: id,
            participation: true
          })
          setTournamentModalsState(state => { state.joinQueueVisible = false });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'leave': {
          await entryTournament({
            tournamentUid: id,
            participation: false
          })
          setTournamentModalsState(state => {
            state.leaveQueueVisible = false;
            state.leaveTournamentVisible = false;
          })
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        case 'kick': {
          const { result } = await ownerKickPlayerTournament({
            playerUid: params?.uid,
            tournamentUid: id
          })
          setTournamentModalsState(state => {
            state.kickPlayerVisible = false;
            state.playerForKick = undefined;
          });
          if(result && !webSocketURL) {
            setTournamentState(state => { state.tournament = result });
          }
          break;
        }
        case 'removeInvite': {
          await rejectInviteForTournament({
            playerUid: params?.uid,
            tournamentUid: id
          });
          setTournamentModalsState(state => {
            state.removeInviteVisible = false;
            state.playerForKick = undefined;
          });
          if(id && !webSocketURL) {
            const { result } = await loadTournament(id);
            if(result) {
              setTournamentState(state => { state.tournament = result });
            }
          }
          break;
        }
        default: 
          const _: never = action;
          throw new Error('not all actions are handled');
      }
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, [id, tournamentState.tournament]);

  const handleGoBack = useMemo(() => {
    if (
      window.location.search.includes('afterCreate') ||
      window.location.search.includes('redirect') ||
      window.history.length === 1
    ) {
      return () => navigate('/play');
    }
  }, [navigate]);

  const selectPlayerForKick = useCallback((player?: PlayerProfileInfo) => {
    setTournamentModalsState(state => {
      state.playerForKick = {
        displayName: player?.displayName,
        uid: player?.uid
      };
      state.kickPlayerVisible = true;
    })
  }, []);

  const isParticipatating = useMemo(() => {
    return !!tournamentState.tournament?.members?.find(member => member.player?.uid === current?.identity?.uid)
  }, [tournamentState.tournament?.members, current?.identity?.uid]);

  const isQueued = useMemo(() => {
    return !!tournamentState.tournament?.queue?.find(member => member.player?.uid === current?.identity?.uid);
  }, [tournamentState.tournament?.queue, current?.identity?.uid]);

  const isTournamentFull = useMemo(() => {
    return tournamentState.tournament?.members?.length === tournamentState.tournament?.playersLimit;
  }, [tournamentState.tournament?.members?.length, tournamentState.tournament?.playersLimit]);

  const renderTabContent = () => {
    if(tournamentState.tournament?.kind !== TournamentKind.CLASSIC) {
      switch(tournamentState.activeTab) {
        case 'results': {
          return (
            <div className={styles['table']}>
              {tournamentState.tournament?.members?.map?.((participant, index) => (
                <TournamentTableItem
                  key={participant.player?.uid}
                  participant={participant}
                  placement={index + 1}
                />
              ))}
            </div>
          )
        }
        case 'score': {
          return (
            <div className={roundStyles['wrapper']}>
              {tournamentState.rounds?.map((round, index) => (
                <TournamentRoundsPreview
                  key={index}
                  round={round}
                  index={index}
                />
              ))}
            </div>
          )
        }
        case 'statistic': {
          return (
            <TournamentStatistics
              tournamentStatistic={tournamentState.tournamentStatistic}
            />
          )
        }
        default: {
          const _: never = tournamentState.activeTab;
          throw new Error('failed');
        }
      }
    } else {
      return (
        <div className={styles['table']}>
          {tournamentState.tournament?.members?.map((participant, index) => (
            <TournamentTableItem
              key={participant.player?.uid}
              participant={participant}
              placement={index + 1}
            />
          ))}
        </div>
      )
    }
  }

  const renderCalendarButton = () => {
    if(Capacitor.isNativePlatform()){
      return (
        <div className={styles['header-button']} onClick={async () => {
          try {
            if(Capacitor.getPlatform() === 'ios') {
              await Browser.open({
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=TOURNAMENT&type=&inline=true`,
                presentationStyle: 'popover'
              });
            } else {
              const { path } = await Filesystem.downloadFile({
                path: 'calendar.ics',
                directory: Directory.Data,
                url: `${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=TOURNAMENT&type=&inline=true`,
              });
              if(path) {
                await FileOpener.open({
                  filePath: path
                });
              }
            }
          } catch(err) {
            PopupMessage.open('Произошла ошибка')
          }
        }}>
          <EntityCalendarIcon />
        </div>
      )
    } else {
      return (
        <div>
          <a className={styles['header-button']} href={`${createImgSrc.origin}/api/event/export?entityUid=${id}&entityType=TOURNAMENT&type=&inline=true`} download>
            <EntityCalendarIcon />
          </a> 
        </div>
      )
    }
  }

  const fetchTournament = () => {
    if(id) {
      Promise.all([
        loadTournament(id),
        loadTournamentStatistics(id),
        loadTournamentRoundsPreview(id)
      ])
        .then(([{ result }, { result: resultStats }, { result: resultRounds }]) => {
          setTournamentState(state => {
            state.tournament = result ?? {};
            state.tournamentStatistic = resultStats?.playersStatistics;
            state.rounds = resultRounds?.rounds ?? []
          });
          DBManager.create('tournaments', {
            tournament: result,
            rounds: resultRounds?.rounds,
            tournamentStatistic: resultStats?.playersStatistics
          }, id);
        })
        .catch(err => {
          if(err instanceof Promise) {
            err.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        })
        .finally(() => setLoading(false));
    }
  }
  
  useEffect(() => {
    if(id) {
      if(navigator.onLine) {
        setLoading(true);
        fetchTournament();
      } else {
        DBManager.read('tournaments', id)
          .then(tournamentInfo => {
            setTournamentState(state => {
              state.tournament = tournamentInfo?.tournament;
              state.rounds = tournamentInfo?.rounds;
              state.tournamentStatistic = tournamentInfo?.tournamentStatistic;
            });
          })
          .catch((err) => console.log(err));
      }
    }
  }, [id]);

  useEffect(() => {
    let ignoreWSConnection = false;
    const socket = webSocketURL ? new WebSocket(webSocketURL) : null;

    if (socket) {
      socket.onopen = (e) => {
        if (ignoreWSConnection) {
          socket?.close();
        } else {
          console.log('Websocket is open!');
        }
      };
    
      socket.onerror = (error) => {
        console.log(error);
      };

      socket.onmessage = ({ data }) => {
        const { eventType } = JSON.parse(data);
        if (eventType === 'UPDATED') {
          fetchTournament();
        }
      };
    }
  
    return () => {
      if (socket?.readyState === 1) {
        socket?.close();
      } else {
        ignoreWSConnection = true;
      }
    }
  }, [webSocketURL]);

  useEffect(() => {
    //для фейковых паддингов, чтобы кнопки не перекрывали контент
    setOffset(buttonsRef.current?.clientHeight ?? 0);
  }, [buttonsRef.current?.clientHeight, offset, tournamentState]);

  return ( 
    <BasePageWrapper>
      <TournamentActionModal
        type='cancel'
        visible={tournamentModalsState.cancelTournamentVisible}
        onClose={() => setTournamentModalsState(state => { state.cancelTournamentVisible = false })}
        handler={() => handleButtonAction('cancel')}
      />
      <TournamentActionModal
        type='leaveTournament'
        visible={tournamentModalsState.leaveTournamentVisible}
        onClose={() => setTournamentModalsState(state => { state.leaveTournamentVisible = false })}
        handler={() => handleButtonAction('leave')}
      />
      <TournamentActionModal
        type='leaveQueue'
        visible={tournamentModalsState.leaveQueueVisible}
        onClose={() => setTournamentModalsState(state => { state.leaveQueueVisible = false })}
        handler={() => handleButtonAction('leave')}
      />
      <TournamentActionModal
        type='joinQueue'
        visible={tournamentModalsState.joinQueueVisible}
        onClose={() => setTournamentModalsState(state => { state.joinQueueVisible = false })}
        handler={() => handleButtonAction('join')}
      />
      <TournamentActionModal
        type='kick'
        visible={tournamentModalsState.kickPlayerVisible}
        onClose={() => setTournamentModalsState(state => {
          state.playerForKick = undefined;
          state.kickPlayerVisible = false;
        })}
        handler={uid => handleButtonAction('kick', { uid })}
        playerData={tournamentModalsState.playerForKick}
      />
      <TournamentActionModal
        type='removeInvite'
        visible={tournamentModalsState.removeInviteVisible}
        onClose={() => setTournamentModalsState(state => {
          state.playerForKick = undefined;
          state.removeInviteVisible = false;
        })}
        handler={uid => handleButtonAction('removeInvite', { uid })}
        playerData={tournamentModalsState.playerForKick}
      />
      <PageBackground imgSrc={bgImage} />
      <Header
        handleClick={handleGoBack}
        className={styles['header']}
        additional={
          <div className={styles['header-buttons']}>
            {tournamentState.tournament?.status === TournamentStatus.PLANNED ?
              <>
                {renderCalendarButton()}
              </> : null
            }
            {tournamentState.tournament?.owner?.uid === current?.identity?.uid && [TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!) ?
              <div className={styles['share-button']} onClick={() => navigate(`/tournament/${id}/settings`)}>
                <EditGameIcon/>
              </div> : null
            }
            <div className={styles['share-button']} onClick={() => shareTournament(tournamentState.tournament, PopupMessage.open)}>
               <ShareIcon />
            </div>
          </div>
        }>
        Турнир
      </Header>
      <div className={styles['wrapper']}>
        {loading ?
          <PuffLoader color='#4EB857' cssOverride={{ margin: 'auto' }}/> :
          <>
            <div className={styles['padding-20']}>
              <TournamentCard tournament={tournamentState.tournament} />
            </div>
            {current.identity?.uid !== tournamentState.tournament?.owner?.uid && [TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!) ?
              <div className={styles['padding-20']} style={{ marginTop: '8px' }}>
                <OwnerButton
                  owner={tournamentState.tournament?.owner}
                  onClick={() => navigate(`/profile/${tournamentState.tournament?.owner?.uid}`)}
                />
              </div> : null
            }
            {current.identity?.uid === tournamentState.tournament?.owner?.uid && tournamentState.tournament?.status === TournamentStatus.PLANNED && tournamentState.tournament.privateTournament ?
              <div className={styles['padding-20']} style={{ marginTop: '8px' }}>
                <Button
                  variant={ButtonVariants.GOLD}
                  onClick={() => navigate(`/tournament/${id}/invitation`)}
                >
                  Пригласить игроков
                </Button>
              </div> : null
            }
            {tournamentState.tournament?.kind !== TournamentKind.CLASSIC && ![TournamentStatus.PLANNED, TournamentStatus.START, TournamentStatus.CANCEL].includes(tournamentState.tournament?.status!) ?
              <TournamentResultsTabs
                name='tournament-tabs'
                activeTab={tournamentState.activeTab}
                selectTab={e => setTournamentState(state => { state.activeTab = (e.target as HTMLInputElement).value as TournamentResultsTabsValues })}
                style={{ paddingBottom: tournamentState.activeTab === 'score' ? '28px' : '0' }}
              /> 
              : null
            }
            {[TournamentStatus.EDITABLE, TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.FINISH].includes(tournamentState.tournament?.status!) ?
              <>
                {renderTabContent()}
              </> :
              <div>
                {tournamentState.tournament?.members?.length ?
                  <TournamentParticipants
                    owner={tournamentState.tournament?.owner?.uid === current?.identity?.uid}
                    isOver={![TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!)}
                    editMode={navigator.onLine && tournamentState.editMode}
                    setEditMode={(value: boolean) => setTournamentState(state => { state.editMode = value })}
                    participants={tournamentState?.tournament?.members ?? []}
                    kickPlayer={(player?: PlayerProfileInfo) => selectPlayerForKick(player)}
                  /> : null
                }
                {tournamentState.tournament?.queue?.length ?
                  <TournamentParticipants
                    queue
                    editMode={tournamentState.editMode}
                    isOver={![TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!)}
                    setEditMode={(value: boolean) => setTournamentState(state => { state.editMode = value })}
                    owner={navigator.onLine && tournamentState.tournament?.owner?.uid === current?.identity?.uid}
                    participants={tournamentState?.tournament?.queue ?? []}
                    kickPlayer={(player?: PlayerProfileInfo) => selectPlayerForKick(player)}
                  /> : null
                }
                {tournamentState.tournament?.invitations?.length ?
                  <div className={styles['padding-20']}>
                    <GamePlayerCarousel
                      players={tournamentState.tournament?.invitations?.map(inv => ({ ...inv.player, inviteType: inv.sender?.uid === inv.player?.uid ? 'player' : 'onwer' } ?? {})) ?? []}
                      type='invintations'
                      editMode={tournamentState.editMode}
                      setEditMode={(value: boolean) => setTournamentState(state => { state.editMode = value })}
                      owner={(current?.identity?.uid === tournamentState.tournament?.owner?.uid) && tournamentState?.tournament?.status === TournamentStatus.PLANNED && navigator.onLine}
                      ownerUid={tournamentState.tournament?.owner?.uid}
                      playerHandler={(uid, displayName) => {
                        setTournamentModalsState(state => { 
                          state.removeInviteVisible = true;
                          state.playerForKick = { uid, displayName } 
                        }
                      )}}
                    />
                  </div> : null
                }
                {([TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!) && !isTournamentFull) ? !tournamentState.tournament?.genders?.includes(current?.gender!) && !tournamentState.editMode ?
                  <div className={styles['invalid-gender']}>
                    Вы не можете стать участником турнира, этот турнир для {tournamentState.tournament?.genders?.includes(Gender.MAN) ? 'мужчин' : 'женщин'}
                  </div> : !tournamentState.tournament?.available ?
                  <div className={styles['invalid-rating']}>
                    <span>
                      Вы не можете стать участником турнира, этот турнир для игроков уровня {tournamentState.tournament?.displayMinGrade === tournamentState.tournament?.displayMaxGrade ?
                      <>{tournamentState.tournament?.displayMinGrade}</> :
                      <>{tournamentState.tournament?.displayMinGrade}...{tournamentState.tournament?.displayMaxGrade}</>
                    }
                    </span>
                    <div>Ваш уровень: <span className={styles['invalid-rating-value']}>{current?.displayGrade} | {current?.displayRating}</span></div>
                  </div> : null : null
                }
              </div>
            }
            <div style={{ height: `calc(${(offset) + 28}px + env(safe-area-inset-bottom))` }}></div>
          </>
        }
      </div>
      <div className={styles['buttons']} ref={buttonsRef}>
        <OversideWrapper className={styles['buttons-wrapper']}>
          {[TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!) && tournamentState.tournament?.available && !tournamentState.editMode && tournamentState.tournament?.genders?.includes(current?.gender!) ?
            <>
              {(!isParticipatating && !isQueued) ? tournamentState.tournament?.members?.length !== tournamentState.tournament?.playersLimit ?
                <Button
                  variant={ButtonVariants.PRIMARY}
                  className={styles['button']}
                  onClick={() => handleButtonAction('join')}
                  disabled={!navigator.onLine}
                >
                  <img src={joinIcon} alt='иконка вступления'/>
                  <span>Участвовать в турнире</span>
                </Button> :
                <Button
                  variant={ButtonVariants.BLUE}
                  onClick={() => setTournamentModalsState(state => { state.joinQueueVisible = true })}
                  disabled={!navigator.onLine}
                >
                  Занять очередь в листе ожидания
                </Button> : null
              }
            </> : null
          }
          {[TournamentStatus.EDITABLE, TournamentStatus.AFTER_TOURNAMENT].includes(tournamentState.tournament?.status!) && isTournamentFull && !tournamentState.editMode && tournamentState.tournament?.owner?.uid === current?.identity?.uid ?
            <Button
              variant={ ButtonVariants.BLUE}
              onClick={() => navigate(`/tournament/${id}/results`)}
              disabled={!navigator.onLine}
            >
              <span className={styles['button-with-icon']}>
                <img src={pencilIcon} alt="иконка счета" />
                <span>Редактировать результат турнира</span>
              </span>
            </Button> : null
          }
          {tournamentState.tournament?.owner?.uid === current?.identity?.uid && [TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!) && !tournamentState.editMode && isTournamentFull ?
            <Button
              variant={ButtonVariants.PRIMARY}
              onClick={() => handleButtonAction('start')}
              className={styles['finish-button']}
              disabled={!navigator.onLine}
            >
              Начать турнир
            </Button> : null
          }
          {isParticipatating && !isQueued && tournamentState.tournament?.status === TournamentStatus.PLANNED && !tournamentState.editMode && tournamentState.tournament?.owner?.uid !== current?.identity?.uid ?
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setTournamentModalsState(state => { state.leaveTournamentVisible = true })}
              className={styles['finish-button']}
              disabled={!navigator.onLine}
            >
              Отменить участие
            </Button> : null
          }
          {!isParticipatating && isQueued && tournamentState.tournament?.status === TournamentStatus.PLANNED && !tournamentState.editMode && tournamentState.tournament?.owner?.uid !== current?.identity?.uid ?
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setTournamentModalsState(state => { state.leaveQueueVisible = true })}
              disabled={!navigator.onLine}
            >
              Покинуть лист ожидания
            </Button> : null
          }
          {[TournamentStatus.PLANNED, TournamentStatus.START].includes(tournamentState.tournament?.status!) && tournamentState.tournament?.owner?.uid === current?.identity?.uid && tournamentState.editMode ?
            <Button
              variant={ButtonVariants.PRIMARY}
              onClick={() => setTournamentState(state => { state.editMode = false })}
              disabled={!navigator.onLine}
            >
              Сохранить состав игроков
            </Button> : null
          }
          {tournamentState.tournament?.owner?.uid === current?.identity?.uid && ![TournamentStatus.AFTER_TOURNAMENT, TournamentStatus.CANCEL, TournamentStatus.FINISH].includes(tournamentState.tournament?.status ?? TournamentStatus.START) && !tournamentState.editMode ?
            <Button
              variant={ButtonVariants.CANCEL}
              bordered
              onClick={() => setTournamentModalsState(state => { state.cancelTournamentVisible = true })}
              className={styles['finish-button']}
              disabled={!navigator.onLine}
            >
              Отменить турнир
            </Button> : null
          }
        </OversideWrapper>
      </div>

    </BasePageWrapper>
  );
}
 
export default TournamentPage;
