import { useContext } from "react";
import { TournamentResultsNewContext } from "../utils";
import TournamentResultsLineups from "../TournamentResultsLineups/TournamentResultsLineups";
import TournamentResultsLineupScore from "../TournamentResultsLineupScore/TournamentResultsLineupScore";
import TournamentResultsRounds from "../TournamentResultsRounds/TournamentResultsRounds";
import TournamentResultsCourts from "../TournamentResultsCourts/TournamentResultsCourts";

const TournamentResultsNew = () => {
  const { step } = useContext(TournamentResultsNewContext);

  return ( 
    <>
      {step === 'courts' ? <TournamentResultsCourts /> : null}
      {step === 'lineups' ? <TournamentResultsLineups /> : null}
      {step === 'score' ? <TournamentResultsLineupScore /> : null}
      {step === 'rounds' ? <TournamentResultsRounds /> : null}
    </>
  );
}
 
export default TournamentResultsNew;
