type ArrowIcon = {
  stroke?: string;
}

const ArrowIcon = ({ stroke }: ArrowIcon) => {
  return ( 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10L12 15L17 10" stroke={stroke ?? "#C9C9C9"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
 
export default ArrowIcon;
