import { ConfirmationModalProps } from '@/components/ConfirmationModal/ConfirmationModal.interfaces';
import styles from './GameActionModal.module.css';
import { ModalsState } from '../../GameStatusContent/GameStatusContent.interfaces';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import { useCallback } from 'react';
import ModalBallIcon from '@/static/images/icons/ModalBallIcon';
import ModalArrowIcon from '@/static/images/icons/ModalArrowIcon';

type GameActionModalProps = {
  handler: (agr?: string) => Promise<void>;
  playerData?: {
    uid?: string;
    displayName?: string;
  }
  type: keyof Omit<ModalsState, 'playerData'>;
} & ConfirmationModalProps;

const GameActionModal = ({ handler, playerData, type, ...props }: GameActionModalProps) => {
  const renderModalContent = useCallback(() => {
    switch(type) {
      case 'removeInviteVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Вы уверены, что хотите удалить <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span> из списка приглашенных в этот матч?</span>
            </div>
            <div className={styles['description']}>
              Игрок не сможет присоединиться к игре по этому приглашению 
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Удалить игрока</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'kickPlayerVisible': {
        return (
          <>
            <div className={styles['question']}>
              <span>Вы уверены, что хотите удалить <span style={{ color: 'var(--blue)'}}>{playerData?.displayName}</span> из матча?</span>
            </div>
            <div className={styles['description']}>
              Этот игрок получит уведомление и его место будет свободно
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Удалить игрока</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'leaveGameVisible': {
        return (
          <>
            <div className={styles['question']}>
              Вы уверены, что хотите хотите покинуть игру?
            </div>
            <div className={styles['description']}>
              Если вы покинете место, остальные игроки получат уведомления 
            </div>
            <button className={styles['button']} onClick={() => handler?.(playerData?.uid)}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Покинуть игру</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      case 'cancelGameVisible': {
        return (
          <>
            <div className={styles['question']}>
              Вы уверены, что хотите отменить игру?
            </div>
            <div className={styles['description']}>
              Если вы отмените игру, остальные игроки получат уведомления 
            </div>
            <button className={styles['button']} onClick={() => handler?.()}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Отменить игру</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        )
      }
      default: {
        const _: never = type;
        throw new Error('not every type handeled')
      }
    }
  }, [type, handler, playerData?.displayName, playerData?.uid]);

  return (  
    <ConfirmationModal {...props}>
      {renderModalContent()}
    </ConfirmationModal>
  );
}
 
export default GameActionModal;
