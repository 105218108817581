import { MutableRefObject, useRef } from 'react';
import styles from './Swiper.module.css';
import { GradeDetailsInfo } from '@/integration-api/server-rest-lundapadelApi';
import { setPlayerListBlockerStatus } from '../RatingSection/utils';
import { setSwiperBlockerStatus } from './utils';

export const chldrenWidth = 95;
export const swiperBlockerElementID = 'swiper-blocker';

interface SwiperProps {
  onChange: (active: number) => void,
  grades: GradeDetailsInfo[],
  containerRef: MutableRefObject<HTMLDivElement | null>,
}

export default function Swiper({onChange, grades, containerRef}: SwiperProps) {
  const observerRef = useRef<IntersectionObserver>();
  const currentFocusIndex = useRef<number>(0);
  const scrollTimer = useRef<NodeJS.Timer>();
  const isUserSlide = useRef(false);
  const isScrollNow = useRef(false);

  const containerRefCallback = (node: HTMLDivElement | null) => {
    if (node) {
      containerRef.current = node;

      observerRef.current = new IntersectionObserver((intersections) => {
        intersections.filter((intersection) => intersection.isIntersecting).forEach((int) => {
          const element = (int.target as HTMLDivElement);
          // убираем активный класс у всех грейдов
          Array.from(element.parentElement?.children || []).forEach((elm) => elm.classList.remove(styles.swiperItemActive))
          // добавляем активный класс элементу
          element.classList.add(styles.swiperItemActive)
          currentFocusIndex.current = +element.dataset.grade!;
        })
      }, {root: node, threshold: 0, rootMargin: `0px -48% 0px -48%`})
    }
  }

  const containerScrollHandler = () => {
    isScrollNow.current = true;

    setPlayerListBlockerStatus('block') // устанавливаем блокировку списка игроков

    if (scrollTimer.current) {
      clearTimeout(scrollTimer.current)
    }
    
    scrollTimer.current = setTimeout(() => {
      isScrollNow.current = false;

      if (isUserSlide.current) {
        isUserSlide.current = false;
        onChange?.(currentFocusIndex.current);
      }

      setPlayerListBlockerStatus('unblock'); // снимаем блокировку свайпера
    }, 300)
  }

  const gradeItemClickHandler = (evt: React.MouseEvent, index: number) => {
    setSwiperBlockerStatus('block'); // устанавливаем блокировку свайпера
    
    evt.stopPropagation();
    if (!isScrollNow.current) {
      onChange?.(index)
      containerRef.current?.scrollTo({
        behavior: 'smooth',
        left: chldrenWidth * index + 1,
      })
    }

    setTimeout(() => {
      setSwiperBlockerStatus('unblock') // снимаем блокировку свайпера
    }, 555)
  }

  return (
    grades ? (
      <div className={styles.wrap}>
        <div>
          <div id={swiperBlockerElementID} className={styles.blocker} onClick={(e) => e.stopPropagation()}/>
        </div>
        <div className={styles.container}
          ref={containerRefCallback}
          onScroll={containerScrollHandler}
          onTouchMove={() => isUserSlide.current = true}
        >
          {grades?.map((grade, index) => (
            <span
              key={index}
              className={styles.swiperItem}
              data-grade={index}
              style={{
                width: chldrenWidth
              }}
              ref={(node) => {if (node && observerRef.current) observerRef.current.observe(node)}}
            >
              <span onClick={(e) => gradeItemClickHandler(e, index)} >{grade.displayGrade}</span>
            </span>
          ))}
        </div>
      </div>
    ) : null
  )
}
