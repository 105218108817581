/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsItemLoadParameters
 */
export interface NewsItemLoadParameters {
    /**
     * 
     * @type {string}
     * @memberof NewsItemLoadParameters
     */
    uid?: string;
}

export function NewsItemLoadParametersFromJSON(json: any): NewsItemLoadParameters {
    return NewsItemLoadParametersFromJSONTyped(json, false);
}

export function NewsItemLoadParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsItemLoadParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
    };
}

export function NewsItemLoadParametersToJSON(value?: NewsItemLoadParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
    };
}

