/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackSendParameters,
    FeedbackSendParametersFromJSON,
    FeedbackSendParametersFromJSONTyped,
    FeedbackSendParametersToJSON,
} from './FeedbackSendParameters';

/**
 * 
 * @export
 * @interface FeedbackSendRequest
 */
export interface FeedbackSendRequest {
    /**
     * 
     * @type {FeedbackSendParameters}
     * @memberof FeedbackSendRequest
     */
    parameters?: FeedbackSendParameters;
}

export function FeedbackSendRequestFromJSON(json: any): FeedbackSendRequest {
    return FeedbackSendRequestFromJSONTyped(json, false);
}

export function FeedbackSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : FeedbackSendParametersFromJSON(json['parameters']),
    };
}

export function FeedbackSendRequestToJSON(value?: FeedbackSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': FeedbackSendParametersToJSON(value.parameters),
    };
}

