import { FC, useCallback, useEffect, useState } from 'react';
import { NotificationsProps } from './Notifications.interfaces';
import styles from './Notifications.module.css';
import Header from '@/components/Header/Header';
import { notificationLoadList, readAllNotification, readNotification } from './service';
import { LundapadelMainApiNotificationLoadListRequest, NotificationInfo, toLocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import NotificationStatusWrapper from '../NotificationStatusWrapper/NotificationStatusWrapper';
import NotificationCard from '../NotificationCard/NotificationCard';
import InnerPadding from '@/components/InnerPadding/InnerPadding';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch';
import { FetchErrorMessage } from '@/types/types';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

const Notifications: FC<NotificationsProps> = (props): JSX.Element => {
  const { onCloseNotifications } = props;
  const [notifications, setNotifications] = useState<Array<NotificationInfo>>([]);
  const [reloadNotification, setReloadNotification] = useState(true);
  const [unread, setUnread] = useState(false);

  const loadNotificationHandle = useCallback(async (params: LundapadelMainApiNotificationLoadListRequest = { limit: 0, offset: 0 }) => {
    try {
      const { result } = await notificationLoadList(params);
      if (result) {
        setNotifications(result.infos ?? []);
      }
    } catch (err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const onReadNotification = useCallback(async (uid?: string) => {
    try {
      await readNotification(uid ? [uid] : []);
      setReloadNotification(true);
    } catch (err: unknown) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, []);

  const handleReadAll = useCallback(async () => {
    try {
      await readAllNotification(notifications?.[0].timestamp);
      setReloadNotification(true);
      setUnread(false);
    } catch(err) {
      if(err instanceof Promise) {
        const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = await err;
        PopupMessage.open(userErrorMessage ?? errorMessage);
      }
    }
  }, [notifications]);

  const toggleSwitch = async () => {
    setUnread(state => !state);
    await loadNotificationHandle({
      limit: 0,
      offset: 0,
      unread: !unread ? !unread : undefined
    });
  }

  useEffect(() => {
    if (reloadNotification) {
      loadNotificationHandle();
      setReloadNotification(false);
    }
  }, [reloadNotification]);

  return (
    <>
      <Header 
        handleClick={onCloseNotifications}
        additional={<span className={styles['read-all']} onClick={handleReadAll}>Прочитать все</span>}
      >
        Уведомления
      </Header>
      <InnerPadding className={styles['padding']}>
        <div className={styles['toggle']}>
          <span>Показать только непрочитанные</span>
          <ToggleSwitch id='content' onChange={toggleSwitch} checked={unread}/>
        </div>
      </InnerPadding>
      <div className={styles['wrapper']}>
        {notifications && notifications.length > 0 && (
          <NotificationStatusWrapper>
            {notifications.map((notification, index) => (
              <NotificationCard
                first={index === 0}
                key={notification.notificationUid}
                notification={notification}
                onReadNotification={!notification.read ? onReadNotification : undefined}
              />
            ))}
          </NotificationStatusWrapper>
        )}
      </div>      
    </>
  );
};

export default Notifications;
