import { Reference } from '@/integration-api/server-rest-lundapadelApi';

export const EMPTY_STRING = '';

export enum dayOfWeek {
  'MONDAY' = 'Понедельник',
  'TUESDAY' = 'Вторник',
  'WEDNESDAY' = 'Среда',
  'THURSDAY' = 'Четверг',
  'FRIDAY' = 'Пятница',
  'SATURDAY' = 'Суббота',
  'SUNDAY' = 'Воскресенье',
}

export enum shortNameDayOfWeek {
  'MONDAY' = 'Пн',
  'TUESDAY' = 'Вт',
  'WEDNESDAY' = 'Ср',
  'THURSDAY' = 'Чт',
  'FRIDAY' = 'Пт',
  'SATURDAY' = 'Сб',
  'SUNDAY' = 'Вс',
}
export const ratingMarks = ['D','D+','C','C+','B','B+','A','A+']

export const shortNameDayOfWeekArray = [
  'Вс',
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Сб',
];

export const nameMonthArray = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
export const shortNameMonthArray = [
  'янв',
  'фев',
  'март',
  'апр',
  'май',
  'июнь',
  'июль',
  'авг',
  'сен',
  'окт',
  'нояб',
  'дек',
];

export const clubList: Array<Reference> = [
  {
    uid: '0cb15df6-6e0d-4a0d-9cbd-848ac622ad76',
    type: 'com.gridnine.platform.lundapadel.model.club.Club',
    caption: 'Lunda Padel Фили',
  },
  {
    uid: '1bdfdeb0-34cb-4b4c-a9fa-21dc13f81bd4',
    type: 'com.gridnine.platform.lundapadel.model.club.Club',
    caption: 'Lunda Padel Речной',
  }
]
export const clubAddressesList: Array<any> = [
  {
    uid: '0cb15df6-6e0d-4a0d-9cbd-848ac622ad76',
    address: 'Москва, Большая Филевская, 20',
    displayName: 'Lunda Padel Фили',
  },
  {
    uid: '1bdfdeb0-34cb-4b4c-a9fa-21dc13f81bd4',
    address: 'Москва, Правобережная, 1Б, ТЦ Капитолий',
    displayName: 'Lunda Padel Речной',
  }
]

export const TIME_FORMAT_HH_MM = 'HH:mm';

export const DATE_FORMAT_WITH_DOTS = 'DD.MM.YYYY';

export const DATE_FORMAT_WITH_DOTS_SHORT = 'DD.MM.YY';

export const GRADE_ID_PREFIX = 'rating-group-';

export const uidRegExp = /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/;
