import { CityClubsGroupLoadListParameters, ClubLoadListParameters } from "@/integration-api/server-rest-lundapadelApi";
import RestClient from "@/integration/RestClient";

export const loadClubs = (parameters?: ClubLoadListParameters) => {
  return RestClient.clubApi.clubLoadList({
    clubLoadListRequest: {
      parameters: parameters ?? {}
    }
  })
}

export const cityClubsLoadList = (parameters: CityClubsGroupLoadListParameters) => {
  return RestClient.clubApi.cityClubsLoadList({
    cityClubsLoadListRequest: {
      parameters
    }
  })
}
