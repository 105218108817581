import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal";
import styles from './GameResultsModal.module.css';
import { ConfirmationModalProps } from "@/components/ConfirmationModal/ConfirmationModal.interfaces";
import { memo, useMemo } from "react";
import ModalBallIcon from "@/static/images/icons/ModalBallIcon";
import ModalArrowIcon from "@/static/images/icons/ModalArrowIcon";

type GameResultsModalProps = {
  type: 'thirdSet' | 'error';
} & ConfirmationModalProps

const GameResultsModal = ({ type, ...props }: GameResultsModalProps) => {
  const renderContent = useMemo(() => {
    switch(type) {
      case 'thirdSet': {
        return (
          <>
            <div className={styles['question']}>
              Добавление третьего сета
            </div>
            <div className={styles['description']}>
              Для добавления третьего сета по итогу первых двух должна быть ничья 
            </div>
            <button className={styles['button']} onClick={props.onClose}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                <span>Понятно</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      case 'error': {
        return (
          <>
            <div className={styles['question']}>
              Ошибка в заполнении матча
            </div>
            <div className={styles['description']}>
              Матч состоит из 2 или 3 сетов и не может закончиться ничьей. Сеты должны быть полноценными: до 6 побед или до 7 с тай-брейком 
            </div>
            <button className={styles['button']} onClick={props.onClose}>
              <div className={styles['button-inner']}>
                <ModalBallIcon/>
                  <span>Понятно</span>
                <ModalArrowIcon/>
              </div>
            </button>
          </>
        );
      }
      default: {
        const _: never = type;
        throw new Error('not every type handeled')
      }
    }
  }, [type, props.onClose]);

  return (  
    <ConfirmationModal {...props}>
      {renderContent}
    </ConfirmationModal>
  );
}
 
export default memo(GameResultsModal);
