/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentMemberView,
    TournamentMemberViewFromJSON,
    TournamentMemberViewFromJSONTyped,
    TournamentMemberViewToJSON,
} from './TournamentMemberView';

/**
 * 
 * @export
 * @interface TournamentTableLoadResult
 */
export interface TournamentTableLoadResult {
    /**
     * 
     * @type {string}
     * @memberof TournamentTableLoadResult
     */
    tournamentUid?: string;
    /**
     * 
     * @type {Array<TournamentMemberView>}
     * @memberof TournamentTableLoadResult
     */
    infos?: Array<TournamentMemberView>;
}

export function TournamentTableLoadResultFromJSON(json: any): TournamentTableLoadResult {
    return TournamentTableLoadResultFromJSONTyped(json, false);
}

export function TournamentTableLoadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentTableLoadResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentUid': !exists(json, 'tournamentUid') ? undefined : json['tournamentUid'],
        'infos': !exists(json, 'infos') ? undefined : ((json['infos'] as Array<any>).map(TournamentMemberViewFromJSON)),
    };
}

export function TournamentTableLoadResultToJSON(value?: TournamentTableLoadResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentUid': value.tournamentUid,
        'infos': value.infos === undefined ? undefined : ((value.infos as Array<any>).map(TournamentMemberViewToJSON)),
    };
}

