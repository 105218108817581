import styles from './GameMatchCard.module.css';
import { GameMatchCardProps } from './GameMatchCard.interfaces';
import { gameCardResultForProfile } from './utils';
import ProfileCard from '../ProfileCard/ProfileCard';
import RatingBage from '../Badge/RatingBage/RatingBage';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { Role } from '@/integration-api/server-rest-lundapadelApi';
import formatter from '@/utils/utils';
import MedalIconSmall from '@/static/images/icons/MedalIconSmall';

const GameMatchCard = ({ match, profileUid }: GameMatchCardProps) => {
  const { player1, player2, player3, player4, gameSets, plannedDate } = match;
  const relativeResult = gameCardResultForProfile(match, profileUid);

  const renderResultText = () => {
    switch(relativeResult) {
      case 'win': {
        return (
          <div className={styles['result']}>
            <span>Победа</span>
            <MedalIconSmall />
          </div>
        )
      }
      case 'loss': {
        return (
          <div className={styles['result']}>Поражение</div>
        )
      }
      case 'tie': {
        return (
          <div className={styles['result']}>Ничья</div>
        )
      }
      default: {
        return null;
      }
    }
  }

  const renderTeamScores = (team: 'score1' | 'score2') => {
    switch(team) {
      case 'score1' : {
        return gameSets?.map(({ score1, score2 }, index) => (
          <span key={index} className={styles[score1! >= score2! ? 'score-win' : 'score-loss']}>
            {score1}
          </span>
        ));
      }
      case 'score2': {
        return gameSets?.map(({ score1, score2 }, index) => (
          <span key={index} className={styles[score2! >= score1! ? 'score-win' : 'score-loss']}>
            {score2}
          </span>
        ));
      }
      default: {
        return null;
      }
    }
  }

  return (  
    <div className={`${styles['card']} ${styles[relativeResult]}`.trim()}>
      <div className={styles['lineup']}>
        <div className={styles['team']}>
          <ProfileCard
            player={player1}
            noPreferedSide
            fullName
            bage={[{
              element: <RatingBage rating={player1?.displayRating ?? ''}/>,
              position: 'top-right'
            }, {
              element: player1?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
          <ProfileCard
            player={player2}
            noPreferedSide
            fullName
            bage={[{
              element: <RatingBage rating={player2?.displayRating ?? ''}/>,
              position: 'top-right'
            }, {
              element: player2?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
        </div>
        <div className={styles['team']}>
          <ProfileCard
            player={player3}
            noPreferedSide
            fullName
            bage={[{
              element: <RatingBage rating={player3?.displayRating ?? ''}/>,
              position: 'top-right'
            }, {
              element: player3?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
          <ProfileCard
            player={player4}
            noPreferedSide
            fullName
            bage={[{
              element: <RatingBage rating={player4?.displayRating ?? ''}/>,
              position: 'top-right'
            }, {
              element: player4?.roles?.includes(Role.COACH) ? <CoachWhistleFilled/> : <></>,
              position: 'top-left'
            }]}
          />
        </div>
      </div>
      <div className={styles['score']}>
        <span className={styles['date']}>
          {formatter.relativeDate(plannedDate ?? '-')}
        </span>
        <div className={styles['score-team']}>
          {renderTeamScores('score1')}
        </div>
        {renderResultText()}
        <div className={styles['score-team']}>
          {renderTeamScores('score2')}
        </div>
      </div>
    </div>
  );
}
 
export default GameMatchCard;
