import { FC, MouseEvent } from "react";
import { ConfirmationModalProps } from "./ConfirmationModal.interfaces";
import styles from './ConfirmationModal.module.css';
import { createPortal } from "react-dom";
import closeIcon from '@/static/images/icons/close-modal-icon.svg';

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const { visible, onClose, children } = props;
  
  const clickHandler = (e: MouseEvent<HTMLDivElement>) => {
    if((e.target as HTMLDivElement).className?.includes?.('modal-wrapper')) {
      onClose();
    }
  }

  return (
    visible ?
      createPortal(
        <div className={styles['modal-wrapper']} onClick={clickHandler}>
          <div className={styles['content']}>
            {children}
            <img onClick={onClose} className={styles['close']} src={closeIcon} alt="иконка закрыть" />
          </div>
        </div>,
        document.body
      )
    : null
  );
}

export default ConfirmationModal;
