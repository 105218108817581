/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClubLoadListParameters
 */
export interface ClubLoadListParameters {
    /**
     * 
     * @type {boolean}
     * @memberof ClubLoadListParameters
     */
    fullInfo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClubLoadListParameters
     */
    favorite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClubLoadListParameters
     */
    textPattern?: string | null;
}

export function ClubLoadListParametersFromJSON(json: any): ClubLoadListParameters {
    return ClubLoadListParametersFromJSONTyped(json, false);
}

export function ClubLoadListParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClubLoadListParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullInfo': !exists(json, 'fullInfo') ? undefined : json['fullInfo'],
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
        'textPattern': !exists(json, 'textPattern') ? undefined : json['textPattern'],
    };
}

export function ClubLoadListParametersToJSON(value?: ClubLoadListParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullInfo': value.fullInfo,
        'favorite': value.favorite,
        'textPattern': value.textPattern,
    };
}

