/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeFromJSONTyped,
    GradeToJSON,
} from './Grade';

/**
 * 
 * @export
 * @interface GradeDetailsInfo
 */
export interface GradeDetailsInfo {
    /**
     * 
     * @type {Grade}
     * @memberof GradeDetailsInfo
     */
    grade?: Grade;
    /**
     * 
     * @type {string}
     * @memberof GradeDetailsInfo
     */
    displayGrade?: string;
    /**
     * 
     * @type {number}
     * @memberof GradeDetailsInfo
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof GradeDetailsInfo
     */
    description?: string;
}

export function GradeDetailsInfoFromJSON(json: any): GradeDetailsInfo {
    return GradeDetailsInfoFromJSONTyped(json, false);
}

export function GradeDetailsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradeDetailsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grade': !exists(json, 'grade') ? undefined : GradeFromJSON(json['grade']),
        'displayGrade': !exists(json, 'displayGrade') ? undefined : json['displayGrade'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function GradeDetailsInfoToJSON(value?: GradeDetailsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grade': GradeToJSON(value.grade),
        'displayGrade': value.displayGrade,
        'rating': value.rating,
        'description': value.description,
    };
}

