import { CityClubsGroupLoadListParameters } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export const clubLoadList = (fullInfo = false) => {
  return RestClient.clubApi
    .clubLoadList({
      clubLoadListRequest: {
        parameters: {
          fullInfo,
        },
      },
    });
};

export const cityClubsLoadList = (parameters: CityClubsGroupLoadListParameters) => {
  return RestClient.clubApi.cityClubsLoadList({
    cityClubsLoadListRequest: {
      parameters
    }
  });
}
