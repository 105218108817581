import { CurrentPlayerProfileView, PlayerProfileInfo, PlayerProfileView } from "@/integration-api/server-rest-lundapadelApi";
import { fileToDataUrl } from "@/pages/application/news/utils/utils";
import { createImgSrc } from "@/utils/utils";
import { useEffect, useState } from "react";

type ProfileType = PlayerProfileInfo | PlayerProfileView | CurrentPlayerProfileView;

function isPlayerProfileInfo(profile: ProfileType): profile is PlayerProfileInfo {
  return 'subscribed' in profile;
}

const loadAvatar = <T extends ProfileType>(profile: T) => {
  if(isPlayerProfileInfo(profile)) {
    const url = `${createImgSrc.origin}/attachment?size=128&ownerType=PLAYER_PROFILE&ownerUid=${profile.uid}&imageUid=${profile.avatarUid}`;
    if(url && !url.includes('=undefined')) {
      return url
    }
  } else {
    const url = `${createImgSrc.origin}/attachment?size=128&ownerType=PLAYER_PROFILE&ownerUid=${profile.identity?.uid}&imageUid=${profile.avatarUid}`;
    if(url && !url.includes('=undefined')) {
      return url
    }
  }
}

export function usePlayerAvatar<T extends ProfileType>(profile?: T): string {
  const [avatar, setAvatar] = useState<string>('');
  useEffect(() => {
    if(profile) {
      (async () => {
        try {
          const imageFile = await loadAvatar(profile);
          if(imageFile && typeof imageFile !== 'string') {
            const imageBase64 = await fileToDataUrl(imageFile);
            setAvatar(typeof imageBase64 === 'string' ? imageBase64 : '');
          } else {
            setAvatar(imageFile || '');
          }
        } catch(err) {
          console.log(err);
        }
      }
      )();
    }
  }, [profile]);

  return avatar;
}
