import HLWrapper from '@/components/HLWrapper/HLWrapper';
import styles from './RegisterStepTwo.module.css';
import HLine from '@/components/HLine/HLine';
import OversideWrapper from '@/components/OversideWrapper/OversideWrapper';
import Button from '@/components/Button/Button';
import { RegisterStepProps } from '../../helpers/interfaces';
import DatePicker from '@/components/Formik/DatePicker/DatePicker';
import {
  FitnessLevel,
  Gender,
  Grade,
  PlayedMatches,
  RacketSportExperience,
  toLocalDate,
} from '@/integration-api/server-rest-lundapadelApi';
import RadioButton from '@/components/Formik/RadioButton/RadioButton';
import { saveProfile } from '../../service';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FetchErrorMessage } from '@/types/types';
import GradeSwiper from '../Grade/Grade';
import Tooltip, { tooltipWrapID } from '@/components/Tooltip/Tooltip';
import FullscreenLoader from '@/components/FullscreenLoader/FullscreenLoader';
import { PopupMessage } from '@/components/PopupMessage/PopupMessage';

function RegisterStepTwo(props: RegisterStepProps) {
  const { formik, onSave, fieldsErrors, setSelectedDate, updateProfile } = props;
  const labels = ['D', 'D+', 'C', 'C+', 'B', 'B+', 'A']

  const [isLoad, setIsLoad] = useState(false);
  const [showGradeTooltip, setShowGradeTooltip] = useState(false);

  const handleContinueBtnClick = (e: React.MouseEvent) => {
    const { values, touched } = formik;
  
    if (!touched?.grade) { // пользователь должен изменить начальное значение грейда 
      setShowGradeTooltip(true);
      document.querySelector(`#${tooltipWrapID}`)?.scrollIntoView({behavior: 'smooth'});
      (formik).setTouched({
        ...formik.touched,
        grade: true,
      });

      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const fetchData = {
      ...values,
      birthday: toLocalDate(dayjs(formik.values.birthday)),
      tournamentParticipation : formik.values.tournamentParticipation === "" ? undefined :formik.values.tournamentParticipation 
    }
    if (values.birthday && values.experience && values.fitnessLevel && values.gender && values.grade) {
      setIsLoad(true);
      saveProfile(fetchData, 'Анкета')
        .then(() => {
          return saveProfile(fetchData, 'Регистрация')
        })
        .then(({ result }) => {
          updateProfile?.(result ?? {});
          sessionStorage.setItem('profileUid', result?.identity?.uid ?? '');
        })
        .then(() => {
          sessionStorage.removeItem('authData')
          onSave?.()
        })
        .catch(e => {
          if(e instanceof Promise) {
            e.then(err => {
              const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
              PopupMessage.open(userErrorMessage ?? errorMessage);
            })
          }
        })
        .finally(() => setIsLoad(false));
    }
  }

  useEffect(() => {
    const fetchData = {
      ...formik.values,
      birthday: toLocalDate(dayjs(formik.values.birthday)),
      tournamentParticipation : formik.values.tournamentParticipation === "" ? undefined :formik.values.tournamentParticipation 
    }
    saveProfile(fetchData, 'Анкета')
      .catch(e => {
        if(e instanceof Promise) {
          e.then(err => {
            const { userErrorMessage, errorMessage }: Awaited<FetchErrorMessage> = err;
            PopupMessage.open(userErrorMessage ?? errorMessage);
          })
        }
      })

      if (formik.touched.grade) setShowGradeTooltip(false);
  }, [formik.values])

  return (
    <div className={`${styles.container}`}>
      <div>
        <Tooltip visible={showGradeTooltip}>
          <GradeSwiper formik={formik} />
        </Tooltip>
        <div className={styles.radioContainer}>
          <h3 className={styles.radioContainerHeader}>Укажите ваш пол</h3>

          <RadioButton
            name="gender"
            onChange={formik.handleChange}
            defaultChecked={formik.values.gender === Gender.MAN ?? false}
            value={Gender.MAN}
            label="Мужской"
          />
          <RadioButton
            name="gender"
            onChange={formik.handleChange}
            defaultChecked={formik.values.gender === Gender.WOMAN ?? false}
            value={Gender.WOMAN}
            label="Женский"
          />
        </div>
        <HLine />
        <div className={styles.radioContainer}>
          <h3 className={styles.radioContainerHeader}>
            Играли ли вы в падел, теннис, сквош?
          </h3>
          <RadioButton
            name="experience"
            onChange={formik.handleChange}
            value={RacketSportExperience.NEVER}
            defaultChecked={formik.values.experience === RacketSportExperience.NEVER ?? false}
            label="Нет, никогда"
          />
          <RadioButton
            name="experience"
            onChange={formik.handleChange}
            value={RacketSportExperience.THIS_YEAR}
            defaultChecked={formik.values.experience === RacketSportExperience.THIS_YEAR ?? false}
            label="Да, в этом году"
          />
          <RadioButton
            name="experience"
            onChange={formik.handleChange}
            value={RacketSportExperience.YEAR_AGO}
            defaultChecked={formik.values.experience === RacketSportExperience.YEAR_AGO ?? false}
            label="Да, более года назад"
          />
          <RadioButton
            name="experience"
            onChange={formik.handleChange}
            value={RacketSportExperience.LONG_TIME_AGO}
            defaultChecked={formik.values.experience === RacketSportExperience.LONG_TIME_AGO ?? false}
            label="Да, более пяти лет назад"
          />
        </div>
        <HLine />
        <div className={styles.radioContainer}>
          <h3 className={styles.radioContainerHeader}>
            Сколько матчей вы сыграли за последние 6 месяцев?
          </h3>
          <RadioButton name="playedMatches" value={PlayedMatches.LOW_NUMBER} label="0-10" onChange={formik.handleChange} defaultChecked={formik.values.playedMatches === PlayedMatches.LOW_NUMBER} />
          <RadioButton name="playedMatches" value={PlayedMatches.AVERAGE_NUMBER} label="10-100" onChange={formik.handleChange} defaultChecked={formik.values.playedMatches === PlayedMatches.AVERAGE_NUMBER} />
          <RadioButton name="playedMatches" value={PlayedMatches.HIGH_NUMBER} label="100 и более" onChange={formik.handleChange} defaultChecked={formik.values.playedMatches === PlayedMatches.HIGH_NUMBER} />
        </div>
        <HLine />
        <div className={styles.radioContainer}>
          <h3 className={styles.radioContainerHeader}>
            Укажите вашу общую физическую подготовку
          </h3>
          <RadioButton
            name="fitnessLevel"
            onChange={formik.handleChange}
            defaultChecked={formik.values.fitnessLevel === FitnessLevel.LOW ?? true}
            value={FitnessLevel.LOW}
            label="Низкая"
          />
          <RadioButton
            name="fitnessLevel"
            onChange={formik.handleChange}
            defaultChecked={formik.values.fitnessLevel === FitnessLevel.GOOD ?? false}
            value={FitnessLevel.GOOD}
            label="Хорошая"
          />
          <RadioButton
            name="fitnessLevel"
            onChange={formik.handleChange}
            value={FitnessLevel.GREAT}
            defaultChecked={formik.values.fitnessLevel === FitnessLevel.GREAT ?? false}
            label="Отличная"
          />
        </div>
        <HLine />
        <div className={styles.radioContainer}>
          <h3 className={styles.radioContainerHeader}>
            В турнирах какого уровня вы принимали участие?
          </h3>
          <div className={styles.radioGrid}>
            <RadioButton
              name="tournamentParticipation"
              onChange={formik.handleChange}
              defaultChecked={!formik.values.tournamentParticipation ?? true}
              value={undefined}
              label="Не участвовал"
            />
            {labels.map((label, index: any) =>
              <RadioButton
                name="tournamentParticipation"
                onChange={formik.handleChange}
                defaultChecked={formik.values.tournamentParticipation === Grade[`_${index + 1}` as keyof typeof Grade] ?? true}
                value={Grade[`_${index + 1}` as keyof typeof Grade]}
                label={label}
              />
            )}
          </div>

        </div>
        <HLine />
        <h3 className={styles.header}>Дата рождения</h3>

        <div className={styles.dateContainer}>
          <HLWrapper fieldsErrors={fieldsErrors?.includes('birthday')}>
            <DatePicker title='Выберите дату рождения'
              {...formik.getFieldProps('birthday')}
              isBirthday
              formik={formik}
              setSelectedDate={setSelectedDate}
            />
          </HLWrapper>
        </div>
      </div>

      <OversideWrapper className={styles.submitBtn}>
        <Button onClick={handleContinueBtnClick} type='submit'>Продолжить</Button>
      </OversideWrapper>

      <FullscreenLoader loading={isLoad} />
    </div>
  );
}

export default RegisterStepTwo;
