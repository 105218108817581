import styles from "./NumberKeyboard.module.css";
import crossIcon from "@/static/images/icons/cross-icon.svg";
import delIcon from "@/static/images/icons/del-icon.svg";

interface NumberKeyboardInterface {
  onClick?: (value: string) => void;
}

function NumberKeyboard({ onClick }: NumberKeyboardInterface) {
  const handleKeyClick = (value: string) => {
    onClick?.(value);
  };

  return (
    <div className={styles.keyboard}>
      <span onClick={() => handleKeyClick("1")}>1</span>
      <span onClick={() => handleKeyClick("2")}>2</span>
      <span onClick={() => handleKeyClick("3")}>3</span>
      <span onClick={() => handleKeyClick("4")}>4</span>
      <span onClick={() => handleKeyClick("5")}>5</span>
      <span onClick={() => handleKeyClick("6")}>6</span>
      <span onClick={() => handleKeyClick("7")}>7</span>
      <span onClick={() => handleKeyClick("8")}>8</span>
      <span onClick={() => handleKeyClick("9")}>9</span>
      <span onClick={() => handleKeyClick("*")}>
        <img src={crossIcon} alt="Star" />
      </span>
      <span onClick={() => handleKeyClick("0")}>0</span>
      <span onClick={() => handleKeyClick("del")}>
        <img src={delIcon} alt="Delete" />
      </span>
    </div>
  );
}

export default NumberKeyboard;
