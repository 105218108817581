import Headling from '@/components/Headling/Headling';
import styles from './MatchDurationRadio.module.css';
import { durationBookingData, durationData } from '../SelectTime/helper';
import { InputHTMLAttributes } from 'react';

interface MatchDurationRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  durationValue: string;
  booking: boolean
}

const MatchDurationRadio = ({ durationValue, booking, ...props }: MatchDurationRadioProps) => {
  const durationArray = booking ? durationBookingData : durationData;
  return ( 
    <div className={styles['wrapper']}>
      <Headling appearence='medium-normal' className={styles['headling']}>
        Продолжительность (в чаcах)
      </Headling>
      <div className={styles['radio-wrapper']}>
        {durationArray.map(duration => (
          <label key={duration} htmlFor={`duration-${duration}`} style={{ flex: 1 }}>
            <input type="radio" id={`duration-${duration}`} name='duration' value={duration} checked={durationValue === duration} {...props} />
            <div className={styles['radio-item']}>{duration}</div>
          </label>
        ))}
      </div>
    </div>
   );
}
 
export default MatchDurationRadio;
