/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UIGameSettingsView,
    UIGameSettingsViewFromJSON,
    UIGameSettingsViewFromJSONTyped,
    UIGameSettingsViewToJSON,
} from './UIGameSettingsView';

/**
 * 
 * @export
 * @interface GetUIGameSettingsResponse
 */
export interface GetUIGameSettingsResponse {
    /**
     * 
     * @type {UIGameSettingsView}
     * @memberof GetUIGameSettingsResponse
     */
    result?: UIGameSettingsView;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetUIGameSettingsResponse
     */
    messages?: Array<object>;
}

export function GetUIGameSettingsResponseFromJSON(json: any): GetUIGameSettingsResponse {
    return GetUIGameSettingsResponseFromJSONTyped(json, false);
}

export function GetUIGameSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUIGameSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : UIGameSettingsViewFromJSON(json['result']),
        'messages': !exists(json, 'messages') ? undefined : json['messages'],
    };
}

export function GetUIGameSettingsResponseToJSON(value?: GetUIGameSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': UIGameSettingsViewToJSON(value.result),
        'messages': value.messages,
    };
}

