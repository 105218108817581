import { Gender, TournamentStatus } from "@/integration-api/server-rest-lundapadelApi";
import styles from './TournamentCard.module.css';
import { EMPTY_STRING } from "@/utils/constants";

export const cardBackground = (status?: TournamentStatus) => {
  switch(status) {
    case TournamentStatus.START:
      return styles['orange']
    case TournamentStatus.EDITABLE: 
    case TournamentStatus.AFTER_TOURNAMENT: 
      return styles['blue']
    default:
      return EMPTY_STRING;
  }
}

export const tournamentType = (genders?: Array<Gender>) => {
  if(genders?.includes(Gender.MAN) && genders.includes(Gender.WOMAN)) {
    return 'Смешанный турнир';
  } else if(genders?.includes(Gender.MAN)) {
    return 'Мужской турнир';
  } else if(genders?.includes(Gender.WOMAN)) {
    return 'Женский турнир';
  } else {
    return 'Турнир';
  }
}

export const eyeFill = (status?: TournamentStatus) => {
  switch(status) {
    case TournamentStatus.START:
      return '#722121';
    case TournamentStatus.CANCEL:
      return '#0F2B4B';
    default:
      return '#296AB5';
  }
}
