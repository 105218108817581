import styles from './PopupMessage.module.css';
import successIcon from '@/static/images/icons/succes-message-icon.svg';
import errorIcon from '@/static/images/icons/error-message-icon.svg';
import dangerIcon from '@/static/images/icons/danger-icon.svg';

export class PopupMessage {
  private static timer: NodeJS.Timeout | null = null;

  private static clear(): void {
    if(this.timer) {
      clearTimeout(this.timer);
    }
    const deleteingEl = document.querySelector('#popup');
    if(deleteingEl) {
      document.body.removeChild(deleteingEl);
    }
  }
  
  private static create(el: Element): void {
    document.body.prepend(el);
  }
  
  public static open(text: string, type: 'error' | 'no_internet' | 'success' = 'error'): void {
    this.clear();
    const popupEl = document.createElement('div');
    popupEl.id = `popup`;
    popupEl.classList.add(styles['wrapper']);
    popupEl.onclick = this.clear;

    switch(type) {
      case 'success': {
        const image = document.createElement('img');
        image.src = successIcon;
        image.alt = 'success icon';
        popupEl.appendChild(image);
        popupEl.style.backgroundColor = 'var(--green-lunda)';
        break;
      }
      case 'error': {
        const image = document.createElement('img');
        image.src = errorIcon;
        image.alt = 'error icon';
        popupEl.appendChild(image);
        popupEl.style.backgroundColor = 'var(--red)';
        break;
      }
      case 'no_internet': {
        const image = document.createElement('img');
        image.src = dangerIcon;
        image.alt = 'danger icon';
        popupEl.appendChild(image);
        popupEl.style.backgroundColor = 'var(--orange)';
        break;
      }
      default: {
        break;
      }
    }

    const textSpan = document.createElement('span');
    textSpan.textContent = text;
    popupEl.appendChild(textSpan);

    this.create(popupEl);

    this.timer = setTimeout(() => {
      if(this.timer) {
        this.clear();
      }
    }, 3000);
  }
}
