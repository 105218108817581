import { LocalDateTime, LundapadelMainApiNotificationLoadListRequest, toLocalDateTime } from '@/integration-api/server-rest-lundapadelApi';
import RestClient from '@/integration/RestClient';

export const notificationLoadList = (parameters: LundapadelMainApiNotificationLoadListRequest) => {
  return RestClient.mainApi.notificationLoadList(parameters);
};

export const readNotification = (notificationUids: Array<string>) => {
  return RestClient.mainApi.readNotification({
    readNotificationRequest: {
      parameters: {
        notificationUids
      },
    },
  });
};

export const readAllNotification = (dateLimit?: LocalDateTime) => {
  return RestClient.mainApi.readAllNotification({
    readAllNotificationRequest: {
      parameters: {
        dateLimit
      }
    }
  })
}

export const existNotification = () => {
  return RestClient.mainApi.existNotification();
}
