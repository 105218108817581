import { useCallback, useContext, useRef } from 'react';
import styles from './LineupScoresList.module.css';
import CrossIcon from '@/static/images/icons/CrossIcon';
import { LineupScoreContext } from '../../GameResults/utils';
import { v4 } from 'uuid';
import { ModifiedGameMatchView } from '../../GameResults/GameResultsPage.interfaces';
import LineupMatchesList from '../LineupMatchesList/LineupMatchesList';

const LineupMatchesScoresList = () => {
  const { matchScores, addMatchScore } = useContext(LineupScoreContext);

  const ref = useRef<HTMLDivElement>(null);

  const addMatch = useCallback(() => {
    const emptyMatch: ModifiedGameMatchView = {
      gameSets: [
        {
          score1: 0,
          score2: 0,
          uid: v4()
        },
        {
          score1: 0,
          score2: 0,
          uid: v4()
        },
      ],
      uid: v4()
    };
    addMatchScore?.(emptyMatch);
    scrollToEnd();
  }, [addMatchScore]);

  const renderMatchItems = () => {
    return matchScores.map((match, index) => (
      <LineupMatchesList
        key={match.uid}
        match={match}
        index={index}
      />
    ));
  }

  const scrollToEnd = () => {
    setTimeout(() => {
      if(ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }
    }, 100);
  };

  return (  
    <>
      <div className={styles['wrapper']}>
        {renderMatchItems()}
      </div>
      {matchScores.length ?
        <div className={styles['add-set']} onClick={addMatch}>
          <CrossIcon fill='var(--green-yellow)'/>
          <span>Добавить матч</span>
        </div> : 
        <div className={styles['add-set-wrapper']}>
          <div className={styles['add-set']} onClick={addMatch}>
            <CrossIcon fill='var(--green-yellow)'/>
            <span>Добавить матч</span>
          </div>
          <span className={styles['description']}>
            Матч состоит из 2 или 3 сетов и не может закончиться ничьей  
          </span>
        </div>
      }
      <div ref={ref}></div>
    </>
  );
}
 
export default LineupMatchesScoresList;
