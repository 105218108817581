import InnerPadding from '@/components/InnerPadding/InnerPadding';
import LineupScoreSwiper from '../LineupScoreSwiper/LineupScoreSwiper';
import styles from './LineupScoresListItem.module.css';
import TrashIcon from '@/static/images/icons/TrashIcon';
import { LineupScoreContext } from '../../GameResults/utils';
import { useContext } from 'react';
import { GameSetView } from '@/integration-api/server-rest-lundapadelApi';
import { LineupScoreTabsValues } from '../../GameResults/GameResultsPage.interfaces';

type LineupScoresListItemProps = {
  type: LineupScoreTabsValues;
  index: number;
  set: GameSetView;
  notDeletable?: boolean;
  matchIndex?: number;
}

const LineupScoresListItem = ({ index, set, type, matchIndex, notDeletable }: LineupScoresListItemProps) => {
  const { updateScores, deleteMatchSet, deleteScores } = useContext(LineupScoreContext);

  const handelDeletion = () => {
    if(type === 'match') {
      deleteMatchSet?.({ matchIndex });
    } else {
      deleteScores?.({ type: 'set', index });
    }
  }

  return ( 
    <InnerPadding>
      <div className={styles['wrapper']}>
        <div className={styles['title']}>
          <span>{index + 1} сет</span>
          {!notDeletable ?
            <div onClick={handelDeletion}>
              <TrashIcon/> 
            </div> : null
          }
        </div>
        <div className={styles['scores-wrapper']}>
          <LineupScoreSwiper
            type={type}
            index={index}
            matchIndex={matchIndex}
            team='score1' 
            initialIndex={set['score1']}
            limit={type === 'match' ? 10 : 53}
            updateScores={updateScores}
          />
          <span className={styles['divider']}>:</span>
          <LineupScoreSwiper
            type={type}
            index={index} 
            matchIndex={matchIndex}
            team='score2' 
            initialIndex={set['score2']}
            limit={type === 'match' ? 10 : 53}
            updateScores={updateScores}
          />
        </div>
      </div>
    </InnerPadding>
  );
}
 
export default LineupScoresListItem;
