/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import {
    TournamentKind,
    TournamentKindFromJSON,
    TournamentKindFromJSONTyped,
    TournamentKindToJSON,
} from './TournamentKind';

/**
 * 
 * @export
 * @interface TournamentStatisticElementView
 */
export interface TournamentStatisticElementView {
    /**
     * 
     * @type {string}
     * @memberof TournamentStatisticElementView
     */
    tournamentUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentStatisticElementView
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentStatisticElementView
     */
    displayDate?: string;
    /**
     * 
     * @type {TournamentKind}
     * @memberof TournamentStatisticElementView
     */
    kind?: TournamentKind;
    /**
     * 
     * @type {number}
     * @memberof TournamentStatisticElementView
     */
    place?: number;
    /**
     * 
     * @type {Array<Gender>}
     * @memberof TournamentStatisticElementView
     */
    gender?: Array<Gender>;
}

export function TournamentStatisticElementViewFromJSON(json: any): TournamentStatisticElementView {
    return TournamentStatisticElementViewFromJSONTyped(json, false);
}

export function TournamentStatisticElementViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentStatisticElementView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentUid': !exists(json, 'tournamentUid') ? undefined : json['tournamentUid'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'displayDate': !exists(json, 'displayDate') ? undefined : json['displayDate'],
        'kind': !exists(json, 'kind') ? undefined : TournamentKindFromJSON(json['kind']),
        'place': !exists(json, 'place') ? undefined : json['place'],
        'gender': !exists(json, 'gender') ? undefined : ((json['gender'] as Array<any>).map(GenderFromJSON)),
    };
}

export function TournamentStatisticElementViewToJSON(value?: TournamentStatisticElementView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentUid': value.tournamentUid,
        'title': value.title,
        'displayDate': value.displayDate,
        'kind': TournamentKindToJSON(value.kind),
        'place': value.place,
        'gender': value.gender === undefined ? undefined : ((value.gender as Array<any>).map(GenderToJSON)),
    };
}

