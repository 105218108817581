/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthStatus {
    SUCCESSFUL = 'SUCCESSFUL',
    REGISTRATION_REQUIRED = 'REGISTRATION_REQUIRED'
}

export function AuthStatusFromJSON(json: any): AuthStatus {
    return AuthStatusFromJSONTyped(json, false);
}

export function AuthStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthStatus {
    return json as AuthStatus;
}

export function AuthStatusToJSON(value?: AuthStatus | null): any {
    return value as any;
}

