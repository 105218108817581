import MyGamesTabs from "./components/MyGamesTabs/MyGamesTabs";
import BasePageWrapper from "@/components/BasePageWrapper/BasePageWrapper";
import Header from "@/components/Header/Header";
import { useNavigate } from "react-router-dom";

function MyGamesPage(): JSX.Element {
  const navigate = useNavigate();

  return (
    <BasePageWrapper showNavBar>
      <Header handleClick={() => navigate('/')}>Мои игры</Header>
      <MyGamesTabs />
    </BasePageWrapper>
  );
}

export default MyGamesPage;
