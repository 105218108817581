import Button from '@/components/Button/Button';
import styles from './SignInPage.module.css';

type PolicyProps = {
  onClose: () => void;
}

const Policy = ({ onClose }: PolicyProps) => {
  return ( 
    <div className={styles['policy']}>
      <div>
        <h1 className={styles['policy-h1']}>Политика конфиденциальности и безопасная передача данных</h1>
          <div>
            <h4>Термины и определения</h4>
            <div>
              <p className={styles['policy-p']}>Персональные данные — любая информация, относящаяся к прямо
                или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
                <p className={styles['policy-p']}>Обработка персональных данных — любое действие (операция) или
                    совокупность действий (операций), совершаемых с использованием средств автоматизации или
                    без использования таких средств с персональными данными, включая сбор, запись,
                    систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                    использование, передачу (распространение, предоставление, доступ), обезличивание,
                    блокирование, удаление, уничтожение персональных данных.</p>
                <p className={styles['policy-p']}>Общедоступные персональные данные — персональные данные, доступ
                    неограниченного круга лиц к которым предоставлен с согласия субъекта или на которые в
                    соответствии с федеральными законами не распространяется требование соблюдения
                    конфиденциальности.</p>
                <p className={styles['policy-p']}>Приложение — Программа для смартфонов
                    (как в целом, так и ее компоненты), являющаяся представленной в объективной форме
                    совокупностью данных и команд, в том числе исходного текста, базы данных, аудиовизуальных
                    произведений, включенных Разработчиком в состав указанной программы для смартфона,
                    а также любая документация по ее использованию.</p>
                <p className={styles['policy-p']}>Пользователь — физическое лицо, зарегистрировавшееся в приложении
                    Разработчика, а также лицо, акцептовавшее лицензионный договор Разработчика. </p>
                <p className={styles['policy-p']}>Согласие на обработку персональных данных — совершение конклюдентных
                    действий Пользователем, при осуществлении действий в приложении
                    (в том числе веб-версии <a href="#">https://app.lundapadel.ru/</a>)
                    Разработчика Lunda Padel в том числе,
                    но не ограничиваясь: регистрация в приложении, заполнение формы обратной связи
                    (запроса в техническую поддержку); а также акцепт Лицензионного договора Разработчика.</p>
            </div>

            <h4>1. Личная и конфиденциальная информация</h4><h4>Персональные данные и их защита</h4>
            <h5><a
                      href="#prt1" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt1">1. Состав
                  персональных данных</a></h5>
            <ol id="prt1" className="policy-list-style collapse show pt-3">
              <li>Разработчик вправе собирать и обрабатывать следующую информацию:
                <ul>
                  <li>Информация, которую Пользователь предоставляет нам самостоятельно. Пользователь
                      может предоставить личную информацию, заполняя формы в приложении и при регистрации
                      в Приложении, связываясь с нами по телефону, электронной почте или любым другим
                      способом. Эта информация включает в себя личные данные, указываемые при регистрации
                      в Приложении или в веб-версии, при заполнении формы для обратной связи, а также
                      в случаях, когда пользователь оповещает нас о неполадках или проблемах с
                      Приложением и/или веб-версией. Данные, которые предоставляет Пользователь, могут
                      включать в себя фамилию, имя, отчество, пол, номер телефона, адрес электронной
                      почты, почтовый адрес, адреса в социальных сетях и мессенджерах.
                  </li>
                  <li>Информация, которую Разработчик запрашивает у Пользователей. Каждый раз, когда
                      Пользователь посещает сайт, Разработчик вправе автоматически собирать следующую
                      информацию:
                    <ul>
                      <li>технические данные: IP-адрес, имя Пользователя, тип используемого
                          Пользователем браузера, часовой пояс пользователя, плагины и версии
                          браузера, операционная система;
                      </li>
                      <li>данные о посещении: полная информация о единообразном локаторе ресурса
                          (URL), статистика кликов при переходе/посещении/выходе из Приложения
                          (включая дату и время), тарифы, которые Пользователь просматривал или
                          искал, время отклика страницы, ошибки загрузки данных, продолжительность
                          пребывания на определенных страницах, прокручивания и движения мышью,
                          клики, методы, используемые для выхода из Приложения, а также номера
                          телефонов, которые использовались для связи.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>Cookie-файлы<p>Разработчик использует файлы cookie для того, чтобы отличать Пользователя
                  от других пользователей. Файлы cookie делают пользование веб-версией для Пользователя
                  более удобным, а также помогают Разработчику совершенствовать сайт.</p></li>
            </ol>
          </div>
          <div>
            <h5><a href="#prt2" className="text-color text-decoration-none policy-collapse-btn"
                        data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt2">2. Цели, для
                которых используются личные данные</a></h5>
            <div className="collapse show" id="prt2">
              <ol className="mt-4 policy-list-style">
                <li>Информация, предоставленная Пользователем, может быть использована в следующих
                    целях:
                  <ul>
                    <li>Для выполнения обязательств, предусмотренных договорами, заключенными с
                        Пользователями Приложения, а также с целью предоставления Пользователям
                        веб-версии или Приложения запрашиваемой информации и услуг.
                    </li>
                    <li>Для информирования Пользователей веб-версии и Приложения о других наших
                        продуктах и услугах.
                    </li>
                    <li>Для информирования Пользователей веб-версии и Приложения об изменениях,
                        связанных с Приложением и услугах Разработчика.
                    </li>
                    <li>С целью убедиться, что контент Приложения максимально удобен для Пользователя
                        и для операционной системы устройства Пользователя.
                    </li>
                  </ul>
                </li>
                <li>Информация, которую Разработчик запрашивает у Пользователей, Разработчик может
                    использовать в следующих целях:
                  <ul>
                    <li>Для администрирования Приложения и для совершения внутренних операций,
                        включая поиск и устранение неисправностей; для анализа данных, тестирования,
                        сбора статистических данных и для проведения опросов.
                    </li>
                    <li>Для улучшения Приложения и с целью удостовериться, что контент Приложения
                        максимально удобен для операционной системы устройства Пользователя.
                    </li>
                    <li>Для предоставления Пользователям доступа к интерактивным возможностям
                        Приложения в случае, если пользователь желает ими воспользоваться.
                    </li>
                    <li>Для обеспечения безопасности Приложения.</li>
                    <li>Для оценки эффективности рекламных материалов, которые размещены в Приложении,
                        а также для предоставления Пользователям важной рекламной информации.
                    </li>
                    <li>Для рекомендации Пользователям услуг, которые могли бы их заинтересовать.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          <div><h5><a href="#prt3" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt3">3. Порядок сбора,
              хранения, передачи и иных видов обработки персональных данных</a></h5>
            <div className="collapse show" id="prt3">
              <ol className="mt-4">
                <li>Все персональные данные получаются Разработчиком в электронном виде от самих
                    Пользователей веб-версии и Приложения.
                </li>
                <li>Персональные данные Пользователей хранятся исключительно на электронных носителях и
                    обрабатываются с использованием автоматизированных систем управления базами данных,
                    а также иных программных средств, за исключением случаев, когда неавтоматизированная
                    обработка персональных данных необходима в связи с исполнением требований законодательства.
                </li>
                <li>Разработчик проводит технические мероприятия, направленные на предотвращение
                    несанкционированного доступа к персональным данным и передачи их лицам, не имеющим
                    права доступа к такой информации; защитные инструменты настроены на своевременное
                    обнаружение фактов несанкционированного доступа к персональным данным; технические
                    средства автоматизированной обработки персональных данных изолированы в целях
                    недопущения воздействия на них, в результате которого может быть нарушено их
                    функционирование; Разработчик производит резервное копирование данных с тем, чтобы
                    иметь возможность незамедлительного восстановления персональных данных,
                    модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
                    Разработчик осуществляет постоянный контроль за обеспечением уровня защищенности
                    персональных данных.
                </li>
                <li>Разработчик вправе поручить обработку персональных данных Пользователей третьим лицам,
                    на основании заключаемого с этими лицами договора. Лица, осуществляющие обработку
                    персональных данных по поручению Разработчика, обязуются соблюдать принципы и правила
                    обработки и защиты персональных данных, предусмотренные Федеральным законом
                    № 152-ФЗ «О персональных данных». Для каждого лица определены перечень действий
                    (операций) с персональными данными, которые будут совершаться юридическим лицом,
                    осуществляющим обработку персональных данных, цели обработки, установлена обязанность
                    такого лица соблюдать конфиденциальность и обеспечивать безопасность персональных
                    данных при их обработке, а также указаны требования к защите обрабатываемых
                    персональных данных.
                </li>
                <li>В случаях, установленных законодательством Российской Федерации,
                    Разработчик вправе осуществлять передачу персональных данных Пользователей.
                </li>
                <li>Если персональные данные Пользователя возможно получить только у третьей стороны,
                    Разработчик должен быть уведомлен об этом заранее и от него должно быть получено
                    письменное согласие. Третье лицо, предоставляющее персональные данные Пользователя,
                    должно обладать согласием субъекта на передачу персональных данных Разработчику.
                    Разработчик обязан получить подтверждение от третьего лица, передающего персональные
                    данные Пользователя, о том, что персональные данные передаются с согласия Пользователя.
                    Разработчик обязан при взаимодействии с третьими лицами заключить с ними соглашение
                    о конфиденциальности информации, касающейся персональных данных Пользователей.
                </li>
                <li>Разработчик уничтожает либо обезличивает персональные данные по достижении целей
                    обработки или в случае утраты необходимости достижения целей обработки.
                </li>
                <li>Обработка персональных данных Пользователей без их согласия осуществляется в
                    следующих случаях:
                  <ul>
                    <li>персональные данные являются общедоступными;</li>
                    <li>по требованию полномочных государственных органов в случаях, предусмотренных
                        федеральным законом;
                    </li>
                    <li>обработка персональных данных осуществляется на основании действующего
                        законодательства;
                    </li>
                    <li>обработка персональных данных осуществляется для статистических целей при
                        условии обязательного обезличивания персональных данных.
                    </li>
                  </ul>
                </li>
                <li>Разработчик не имеет права получать и обрабатывать персональные данные Пользователя
                    о его расовой или национальной принадлежности, политических взглядах, религиозных
                    или философских убеждениях, состоянии здоровья, интимной жизни.
                </li>
                <li>При определении объема и содержания обрабатываемых персональных данных Разработчик
                    руководствуется Конституцией Российской Федерации, законом о персональных данных и
                    иными федеральными законами.
                </li>
              </ol>
            </div>
          </div>
          <div>
            <h5><a href="#prt4" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt4">4. Права и
              обязанности Разработчика</a></h5>
            <div className="collapse show" id="prt4">
              <ol className="mt-4">
                <li>Разработчик имеет право:
                  <ol>
                    <li value="1.1">Предоставлять персональные данные Пользователя третьим лицам,
                        если это предусмотрено действующим законодательством (налоговые и
                        правоохранительные органы, суды).
                    </li>
                    <li>Отказать в предоставлении персональных данных в случаях, предусмотренных
                        законодательством РФ.
                    </li>
                    <li>Использовать персональные данные Пользователя без его согласия в случаях,
                        предусмотренных законодательством РФ.
                    </li>
                    <li>на отзыв согласия на обработку персональных данных;</li>
                  </ol>
                </li>
                <li>В случае неисполнения положений настоящей Политики Разработчик несет ответственность
                    в соответствии действующим законодательством Российской Федерации.
                </li>
                <li>Разработчик не несет ответственность за любые потери, возникшие в результате
                    использования кем-либо пароля или учетной записи Пользователя без или с ведома последнего.
                </li>
                <li>Разработчик принимает все разумные и известные ему меры по защите пароля каждого
                    Пользователя, при этом ни один из сотрудников Разработчика не имеет права запрашивать
                    у Пользователя его пароль.
                </li>
              </ol>
            </div>
          </div>
          <div>
            <h5><a href="#prt5" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt5">5. Права и
              обязанности Пользователя</a></h5>
            <div className="collapse show" id="prt5">
              <ol className="mt-4">
                <li>Пользователь имеет право
                  <ul>
                    <li>Требовать уточнения своих персональных данных, их блокирования или уничтожения
                        в случае, если персональные данные являются неполными, устаревшими,
                        недостоверными, незаконно полученными или не являются необходимыми для
                        заявленной цели обработки, а также принимать предусмотренные законом меры по
                        защите своих прав.
                    </li>
                    <li>Требовать перечень своих персональных данных, обрабатываемых Разработчиком,
                        и источник их получения.
                    </li>
                    <li>Получать информацию о сроках обработки своих персональных данных,
                        в том числе о сроках их хранения.
                    </li>
                    <li>Требовать извещения всех лиц, которым ранее были сообщены неверные или
                        неполные его персональные данные, обо всех произведенных в них исключениях,
                        исправлениях или дополнениях.
                    </li>
                    <li>Обжаловать в уполномоченный орган по защите прав субъектов персональных
                        данных или в судебном порядке неправомерные действия или бездействия при
                        обработке его персональных данных.
                    </li>
                    <li>На защиту своих прав и законных интересов, в том числе на возмещение убытков
                        и (или) компенсацию морального вреда в судебном порядке.
                    </li>
                    <li>Отозвать свое согласие на обработку персональных данных.</li>
                  </ul>
                </li>
                <li>Пользователь обязан:
                  <ul>
                    <li>обеспечить конфиденциальность своего пароля и учетной записи. Пользователь
                        полностью ответственен за любые действия, совершенные им с использованием его
                        учетной записи, и/или действия, происходящие от его имени с использованием
                        его учетной записи.
                    </li>
                    <li>немедленно информировать Разработчика в случае любого несанкционированного
                        использования учетной записи Пользователя или о любых возникших с ней
                        проблемах безопасности.
                    </li>
                  </ul>
                </li>
                <li>Пользователь может быть привлечен к ответственности за ущерб, нанесенный Разработчику
                    или третьей стороне вследствие использования кем-либо учетной записи или пароля
                    Пользователя. Пользователь не имеет права пользоваться чьей-либо учетной записью без
                    письменного разрешения ее владельца.
                </li>
              </ol>
            </div>
          </div>
          <h4>2. Условия использования Приложения и его оплаты</h4>
          <div><h5><a href="#prt6" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt6">1. Условия
              использования и обслуживания Приложения</a></h5>
            <div className="collapse show" id="prt6">
              <ol className="mt-4">
                <li>Разработчик не владеет размещенными Пользователем в Приложении данными, текстовыми,
                    графическими и любыми другими материалами. Размещение в Приложении любых материалов
                    любым пользователем не меняет их правовой статус
                </li>
                <li>Вся информация, загружаемая Пользователями, по умолчанию приватна, конфиденциальна,
                    не подлежит свободному распространению вне рамок организованного в Приложении аккаунта
                    Пользователя в соответствии с установленными там правами доступа.
                </li>
                <li>Используя Приложение, Пользователь предоставляет Разработчику разрешение совершать
                    с загруженными Пользователем данными определенные действия, для которых используется
                    Приложение. Например, предоставляется разрешение создавать их резервную копию,
                    отправлять их по сети, показывать на мобильных устройствах Пользователя, предоставлять
                    для просмотра другим членам коллегиального органа в соответствии с предоставленными
                    им правами доступа, накапливать, анализировать, систематизировать и т. д. Некоторые
                    из этих операций могут потребовать передачи данных Пользователя партнерам Разработчика,
                    таким как оператор связи, картографический Приложение и другие, чтобы Приложение
                    могло осуществлять свою работу. Прежде чем это сделать, Разработчик обязан
                    удостовериться в том, что договоры с партнерами защищают Пользователей и их права.
                </li>
                <li>Пользователь не имеет права:
                  <ul>
                    <li>на страницах Приложения загружать, размещать, переносить или делать доступными
                        любые данные, которые имеют запрещенное, вредное, угрожающее, оскорбительное,
                        назойливое, клеветническое, вульгарное, непристойное, дискредитирующее,
                        навязчивое для других, ненавистническое, расистское, этнически враждебное
                        или нежелательное содержание;
                    </li>
                    <li>использовать Приложение, его материалы, услуги для преследования,
                        причинения беспокойства или вреда другим лицам;
                    </li>
                    <li>выдавать себя за другое физическое или юридическое лицо, включая, но не
                        ограничиваясь, официальными представителями Разработчика, администраторами
                        форумов, руководителями или владельцами, или подменять сведения о своем
                        статусе другой информацией, или собирать и хранить персональные данные других
                        пользователей в корыстных целях или для запрещенных действий;
                    </li>
                    <li>фальсифицировать заголовки или подменять идентификаторы для искажения
                        оригинальных данных, передаваемых через Приложение, или в материалах,
                        размещенных пользователями;
                    </li>
                    <li>загружать, размещать, перемещать или публиковать в Приложение любые данные,
                        на которые у Пользователя нет прав, приобретаемых на основании любых законов,
                        договоров или доверенностей (например, информацию для внутреннего использования,
                        частную или конфиденциальную информацию, полученную или раскрытую на условиях
                        трудовых договоров или соглашений о нераспространении);
                    </li>
                    <li>загружать, размещать, передавать через электронную почту, перемещать или
                        публиковать любые данные, нарушающие любые патенты, товарные знаки, коммерческие
                        секреты, авторские или интеллектуальные права любой независимой стороны;
                    </li>
                    <li>загружать, размещать, передавать через электронную почту, перемещать или
                        публиковать любые материалы, содержащие компьютерные вирусы или иной программный
                        код, файлы или программы, предназначенные для прерывания, повреждения или
                        ограничения функциональности любого программного обеспечения, оборудования
                        или телекоммуникационных систем;
                    </li>
                    <li>использовать Приложение любым способом, который может привести к повреждению,
                        отключению, перегрузкам или причинению вреда серверу, сетевым подключениям,
                        вызвать отказ от выполнения любых требований, процедур, политик или правил,
                        существующих в сетях, подключенных к Приложению Lunda Padel
                        и веб-версии <a
                                href="#">https://app.lundapadel.ru/</a>
                    </li>
                    <li>осуществлять любые попытки несанкционированного доступа к Приложениу, другим
                        учетным записям, компьютерным системам или сетям, подключенным к Приложениу,
                        в виде хакерских атак, подборов паролей или любым другим способом для получения
                        или попыток получения любых материалов или информации, которая намеренно не
                        доступна в Приложение;
                    </li>
                    <li>изменять, копировать, распространять, передавать, представлять, воспроизводить,
                        публиковать, лицензировать, создавать производные продукты, перемещать или
                        продавать программное обеспечение Приложения и решения организации
                        интерфейсов Приложения.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          <div><h5><a href="#prt7" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt7">2. Условия
              оплаты</a></h5>
            <div className="collapse show" id="prt7">
              <ol className="mt-4">
                <li>Пользователь имеет право использовать возможности Приложения в соответствии с
                    правилами и тарифами, опубликованными в приложении Разработчика.
                    При этом Пользователем приобретается именно право использования возможностей на
                    определенный срок (до определенной даты), которые пользователь может использовать
                    или не использовать по своему усмотрению. Если Пользователь не использует
                    приобретенную им возможность, он не имеет права требовать возврата денег в связи с
                    неиспользованием Приложения в оплаченном периоде, за исключением неработоспособности
                    Приложения в оплаченном периоде.
                </li>
                <li>
                    Если Приложение окажется неработоспособен по техническим причинам в оплаченный
                    Пользователем период, то Пользователь имеет право требовать продления своего статуса
                    оплаченного аккаунта на период неработоспособности Приложения.
                    Для этого Пользователю необходимо направить соответствующее заявление в адрес
                    Разработчика через форму обратной связи либо на адрес электронной почты
                    <a
                            href="mailto:support@lundapadel.ru">support@lundapadel.ru</a>.
                    При этом Пользователь должен быть уверен и иметь доказательства,
                    что причиной невозможности использования им Приложения явилась неработоспособность
                    Приложения, а не отсутствие у Пользователя доступа в Интернет, неисправность
                    оборудования или неподходящие настройки программного обеспечения Пользователя.

                </li>
              </ol>
            </div>
          </div>
          <div><h5><a href="#prt8" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt8">3. Ограничение
              ответственности</a></h5>
            <div className="collapse show" id="prt8">
              <ol className="mt-4">
                <li>Разработчик делает все возможное для соблюдения настоящей политики конфиденциальности,
                    однако не может гарантировать сохранность информации в случае воздействия факторов,
                    находящихся вне его влияния, результатом действия которых станет раскрытие информации.
                    Вся размещенная в Приложении Lunda Padel и в веб-версии
                    <a href="#">https://app.lundapadel.ru/</a>
                    информация представлена по принципу «как есть» без каких-либо гарантий. Разработчик
                    не несет ответственности за неблагоприятные последствия, а также за любые убытки,
                    причиненные вследствие ограничения доступа к приложению и URL веб-версии  или
                    вследствие посещения веб-версии и использования размещенной на нем информации.
                </li>
                <li>Пользователь понимает и соглашается с тем, что:
                  <ol className="list-unstyled">
                    <li>2.1 Пользователь использует Приложение исключительно на свой риск. Приложение
                        предоставляется без гарантии качества и по мере наличия. В наибольшей степени,
                        допускаемой применимым законодательством, Разработчик отказывается от всех
                        гарантий и условий в отношении оказываемых услуг, явных или подразумеваемых,
                        включая, без ограничений, подразумеваемые гарантии и условия пригодности для
                        конкретной цели и ненарушения прав третьих лиц.
                    </li>
                    <li>2.2 Разработчик не гарантирует, что:
                      <ul>
                        <li>настоящие Приложение удовлетворяет всем требованиям Пользователя;</li>
                        <li>работа Приложения будет бесперебойной, своевременной, безопасной и
                            безошибочной;
                        </li>
                        <li>все ошибки в программном обеспечении или Приложении будут исправлены.
                        </li>
                      </ul>
                    </li>
                    <li>2.3 Загрузка какого-либо материала с помощью настоящего Приложения
                        осуществляется по усмотрению Пользователя на его страх и риск, и Пользователь
                        несет полную ответственность за любой ущерб, нанесенный его смартфону или
                        другому устройству, или потерю данных в результате загрузки или использования
                        любого такого материала.
                    </li>
                    <li>2.4 Никакие сведения или информация, как устная, так и письменная, полученная
                        Пользователем от Приложения или с помощью настоящего Приложения, не создают
                        никаких гарантий, кроме прямо указанных в настоящей Политике конфиденциальности.
                    </li>
                    <li>2.5 Пользователь понимает и соглашается, что Разработчик, а также его
                        должностные лица, сотрудники, агенты и правопреемники не несут перед
                        Пользователями никакой ответственности за какие-либо прямые, косвенные, особые,
                        случайные, последующие убытки и убытки, определяемые особыми обстоятельствами
                        дела или присуждаемые в качестве наказания, включая среди прочего упущенную
                        выгоду, утрату репутации, возможности использования данных или страхового
                        покрытия и другие нематериальные убытки (даже если Разработчик был предупрежден
                        о возможности таких убытков), возникшие в результате:
                      <ul>
                        <li>использования или невозможности использования Приложения;</li>
                        <li>затрат на приобретение заменяющих услуг, потребность в которых возникла
                            в результате получения данных, информации или услуг либо получения
                            сообщений или выполнения транзакций с помощью или в рамках Приложения;
                        </li>
                        <li>несанкционированного доступа, повреждения, изменения или потери
                            содержимого, данных или переданных Пользователем материалов;
                        </li>
                        <li>заявлений или действий третьих лиц, осуществленных в рамках
                            Приложения или с его помощью, или третьих лиц, предоставляющих
                            какие-либо услуги в связи с работой Приложения;
                        </li>
                        <li>действий или бездействия Разработчика на основании учетных данных
                            Пользователя, внесенных в них изменений и уведомлений, полученных
                            под аккаунтом Пользователя;
                        </li>
                        <li>неспособности Пользователя обеспечить конфиденциальность паролей и
                            прав доступа к его учетным данным;
                        </li>
                        <li>действий или бездействия третьих лиц при использовании Приложения
                            или интеграции с ним или предложении продуктов;
                        </li>
                        <li>каких-либо рекламных материалов или приобретения или использования
                            Пользователем рекламируемых продуктов или услуг, или иных сторонних
                            продуктов или услуг;
                        </li>
                        <li>любых других обстоятельств, связанных с Приложением.</li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div><h5><a href="#prt9" className="text-color text-decoration-none policy-collapse-btn"
                      data-bs-toggle="collapse" aria-expanded="false" aria-controls="prt9">4. Заключительные
              положения</a></h5>
          <div className="collapse show" id="prt9">
            <ol className="mt-4">
              <li>Разработчик имеет право публиковать в приложении отзывы клиентов, которые могут
                  содержать данные, позволяющие идентифицировать Пользователя. Разработчик вправе
                  указать в отзыве без предварительного согласования с Клиентом следующие данные:
                  наименование бренда, адрес из Приложения в сети Интернет, логотип, сферу деятельности,
                  города и страны, в которых клиент оказывает услуги. Текст отзыва согласуется с клиентом.
                  Если Пользователь захочет удалить свой отзыв, изменить его или данные о компании,
                  он имеет право обратиться с запросом по адресу
                  <a href="mailto:support@lundapadel.ru">support@lundapadel.ru</a>.
              </li>
              <li>В настоящее время используется веб-версия мобильного приложения
                  <a href="#">https://app.lundapadel.ru/</a>
                  и Приложение Lunda Padel Пользователь признает и
                  соглашается с тем, что услуги могут все еще содержать ошибки программного обеспечения,
                  в них могут возникать сбои и отклонения от указанного или предназначенного для них
                  режима работы.
              </li>
              <li>Разработчик в любое время может пересмотреть данную Политику конфиденциальности,
                  опубликовав соответствующие обновления. Пользователь должен периодически посещать
                  эту страницу для ознакомления с текущей версией применимых к нему условий Политики
                  конфиденциальности.
              </li>
              <li>По вопросам, касающимся настоящей Политики конфиденциальности, просьба обращаться по
                  адресу <a href="mailto:support@lundapadel.ru">support@lundapadel.ru</a>.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Button onClick={onClose} className={styles['tos-button']}>Закрыть</Button>
    </div>
  );
}
 
export default Policy;
