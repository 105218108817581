import { ChangeEvent, HTMLAttributes } from 'react';
import styles from './LineupScoreTabs.module.css';
import { LineupScoreTabsValues } from '../../GameResults/GameResultsPage.interfaces';

type LineupScoreTabsProps = {
  activeTab: LineupScoreTabsValues;
  selectTab: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
} & HTMLAttributes<HTMLElement>

const LineupScoreTabs = ({ activeTab, name, selectTab, className, ...props }: LineupScoreTabsProps) => {
  return (  
    <nav {...props} className={`${className} ${styles['navbar']}`}>
      <label className={styles['navbar-item']}>
        <input checked={activeTab === 'match'} type='radio' name={name} style={{ display: 'none' }} value='match' onChange={selectTab}/>
        <span>Собрать матч</span>
      </label>
      <label className={styles['navbar-item']}>
        <input checked={activeTab === 'set'} type='radio' name={name} style={{ display: 'none' }} value='set' onChange={selectTab}/>
        <span>Тренировочные сеты</span>
      </label>
    </nav>
  );
}
 
export default LineupScoreTabs
