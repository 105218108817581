import { FC } from "react"
import { ItemType, PlayerItemProps } from "./PlayerItem.interfaces"
import styles from './PlayerItem.module.css';
import UserAvatar from "../UserAvatar/UserAvatar";
import signIcon from '@/static/images/icons/sign-icon.svg';
import { EMPTY_STRING } from "@/utils/constants";
import { useAppSelector } from "@/hooks/hooks";
import { useNavigate } from "react-router-dom";
import { usePlayerAvatar } from "@/hooks/usePlayerAvatar";
import { PlayerProfileInfo, Role } from "@/integration-api/server-rest-lundapadelApi";
import UserAvatarVerified from "../UserAvatarVerified/UserAvatarVerified";
import { UserAvatarBageType } from '../UserAvatar/UserAvatar.interfaces';
import CoachWhistleFilled from '@/static/images/icons/CoachWhistleFilled';
import { playerHasRole } from '@/utils/utils';

const PlayerItem: FC<PlayerItemProps> = (props): JSX.Element => {
  const { player, playerPlacement, type, withTopBorder = true, isActive = false, 
    isInvitation, isAlreadySelect, handleClick, addPlayer, customRating, updateSuggestions, updateFollow,  ...rest } = props;
  const current = useAppSelector(state => state.auth.currentProfile);
  const navigate = useNavigate();
  const avatar = usePlayerAvatar<PlayerProfileInfo>(player);

  const isYourself = (uid?: string) => {
    if(current && current.identity) {
      if(uid === current.identity.uid) {
        return true;
      }
    }
  }

  const handleAddClick = () => {
    handleClick?.(player);
    addPlayer?.(player);
  }

  const preferedSide = () => {
    if(!!player.leftSide === !!player.rightSide) {
      return 'Оба'
    } else if(player.leftSide) {
      return 'Левый'
    } else if(player.rightSide) {
      return 'Правый'
    } else {
      return null
    }
  }

  let userAvatarBadge: UserAvatarBageType[] = [];
  if (playerHasRole(player, Role.COACH)) userAvatarBadge.push({position: 'top-right', element: <CoachWhistleFilled />});
  if (!playerHasRole(player, Role.COACH) && player.gradeVerified) userAvatarBadge.push({position: 'top-right', element: <UserAvatarVerified />});

  return (
    <div className={`${isActive ? styles["item-wrapper--active"] : EMPTY_STRING} ${styles['item-wrapper']} ${withTopBorder ? styles["item-wrapper--bt"] : EMPTY_STRING}`.trim()}
    onClick={handleAddClick} {...rest}>
      {type === ItemType.RATING && playerPlacement? 
        <div className={styles['position']}>
          {playerPlacement}
        </div> : null
      }
      <div 
        className={styles['player']}
        onClick={type !== ItemType.SEARCH ? () => navigate(isYourself(player.uid) ? '/profile/me' : isInvitation ? '' : `/profile/${player.uid}`) : undefined}
      >
        <UserAvatar 
          className={styles['below-top-three']} 
          avatarUid={avatar} 
          nameInitials={player.nameInitials}
          badge={userAvatarBadge}
        />
        <div className={styles['info']}>
          <div className={styles['info-top']}>
            <span className={styles['name']}>{player.displayName?.substring(0, 25) || '-'}</span>
          </div>
          {type === ItemType.SEARCH && preferedSide() ? <span className={styles['team']}>{preferedSide()}</span> : null}
        </div>
      </div>
      <span className={styles['rating-value']}>
          {customRating || `${player.displayGrade || ''} | ${player.displayRating}`}
      </span>
      <div className={styles['actions']}>
        <img className={`${styles['actions-icon']} ${type === ItemType.SEARCH && isAlreadySelect && styles['actions-icon_visible']}`} src={signIcon} alt="иконка галочки" />
      </div>
    </div>
  );
}

export default PlayerItem;
