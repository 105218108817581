/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UIRatingSettingsView,
    UIRatingSettingsViewFromJSON,
    UIRatingSettingsViewFromJSONTyped,
    UIRatingSettingsViewToJSON,
} from './UIRatingSettingsView';

/**
 * 
 * @export
 * @interface SaveUIRatingSettingsRequest
 */
export interface SaveUIRatingSettingsRequest {
    /**
     * 
     * @type {UIRatingSettingsView}
     * @memberof SaveUIRatingSettingsRequest
     */
    parameters?: UIRatingSettingsView;
}

export function SaveUIRatingSettingsRequestFromJSON(json: any): SaveUIRatingSettingsRequest {
    return SaveUIRatingSettingsRequestFromJSONTyped(json, false);
}

export function SaveUIRatingSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveUIRatingSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : UIRatingSettingsViewFromJSON(json['parameters']),
    };
}

export function SaveUIRatingSettingsRequestToJSON(value?: SaveUIRatingSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': UIRatingSettingsViewToJSON(value.parameters),
    };
}

