import BasePageWrapper from '@/components/BasePageWrapper/BasePageWrapper';
import styles from './PlayPage.module.css';
import PlayPageContent from './PlayPageContent/PlayPageContent';

const PlayPage = () => {
  return (  
    <BasePageWrapper showNavBar className={styles['base-wrapper']}>
      <PlayPageContent />
    </BasePageWrapper>
  );
}

export default PlayPage;
