import styles from './TabsOutlined.module.css';

export interface TabsOutlinedProps {
  tabs: {label: string | React.ReactElement, value: string}[];
  activeTabKey: string;
  onTabClick: (key: string) => void;
}

const TabsOutlined: React.FC<TabsOutlinedProps> = (props) => {  
  const { activeTabKey, onTabClick, tabs } = props

  const calculateClassName = (tabKey: string) => {
    return tabKey === activeTabKey ? styles['tabs-item_active'] : styles['tabs-item'];
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <div
          className={calculateClassName(tab.value)}
          onClick={() => onTabClick(tab.value)}
          key={index + tab.value}
        >
          {tab.label}
        </div>
      )).reverse()}
    </div>
  );
}
 
export default TabsOutlined;
