/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ViewObjectIdentity,
    ViewObjectIdentityFromJSON,
    ViewObjectIdentityFromJSONTyped,
    ViewObjectIdentityToJSON,
} from './ViewObjectIdentity';

/**
 * 
 * @export
 * @interface PlayerProfileImageRemoveParameters
 */
export interface PlayerProfileImageRemoveParameters {
    /**
     * 
     * @type {ViewObjectIdentity}
     * @memberof PlayerProfileImageRemoveParameters
     */
    identity?: ViewObjectIdentity;
}

export function PlayerProfileImageRemoveParametersFromJSON(json: any): PlayerProfileImageRemoveParameters {
    return PlayerProfileImageRemoveParametersFromJSONTyped(json, false);
}

export function PlayerProfileImageRemoveParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerProfileImageRemoveParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': !exists(json, 'identity') ? undefined : ViewObjectIdentityFromJSON(json['identity']),
    };
}

export function PlayerProfileImageRemoveParametersToJSON(value?: PlayerProfileImageRemoveParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': ViewObjectIdentityToJSON(value.identity),
    };
}

