/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChatSilentModeParam,
    ChatSilentModeParamFromJSON,
    ChatSilentModeParamFromJSONTyped,
    ChatSilentModeParamToJSON,
} from './ChatSilentModeParam';

/**
 * 
 * @export
 * @interface ChatSilentModeRequest
 */
export interface ChatSilentModeRequest {
    /**
     * 
     * @type {ChatSilentModeParam}
     * @memberof ChatSilentModeRequest
     */
    parameters?: ChatSilentModeParam;
}

export function ChatSilentModeRequestFromJSON(json: any): ChatSilentModeRequest {
    return ChatSilentModeRequestFromJSONTyped(json, false);
}

export function ChatSilentModeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatSilentModeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : ChatSilentModeParamFromJSON(json['parameters']),
    };
}

export function ChatSilentModeRequestToJSON(value?: ChatSilentModeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': ChatSilentModeParamToJSON(value.parameters),
    };
}

