/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TournamentRoundInfo,
    TournamentRoundInfoFromJSON,
    TournamentRoundInfoFromJSONTyped,
    TournamentRoundInfoToJSON,
} from './TournamentRoundInfo';
import {
    TournamentStatus,
    TournamentStatusFromJSON,
    TournamentStatusFromJSONTyped,
    TournamentStatusToJSON,
} from './TournamentStatus';

/**
 * 
 * @export
 * @interface TournamentRoundLoadListResult
 */
export interface TournamentRoundLoadListResult {
    /**
     * 
     * @type {TournamentStatus}
     * @memberof TournamentRoundLoadListResult
     */
    tournamentStatus?: TournamentStatus;
    /**
     * 
     * @type {Array<TournamentRoundInfo>}
     * @memberof TournamentRoundLoadListResult
     */
    rounds?: Array<TournamentRoundInfo>;
}

export function TournamentRoundLoadListResultFromJSON(json: any): TournamentRoundLoadListResult {
    return TournamentRoundLoadListResultFromJSONTyped(json, false);
}

export function TournamentRoundLoadListResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentRoundLoadListResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tournamentStatus': !exists(json, 'tournamentStatus') ? undefined : TournamentStatusFromJSON(json['tournamentStatus']),
        'rounds': !exists(json, 'rounds') ? undefined : ((json['rounds'] as Array<any>).map(TournamentRoundInfoFromJSON)),
    };
}

export function TournamentRoundLoadListResultToJSON(value?: TournamentRoundLoadListResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tournamentStatus': TournamentStatusToJSON(value.tournamentStatus),
        'rounds': value.rounds === undefined ? undefined : ((value.rounds as Array<any>).map(TournamentRoundInfoToJSON)),
    };
}

