import {ClubView } from '@/integration-api/server-rest-lundapadelApi';
import styles from './HorizontalClubList.module.css';
import HorizontalClubListItem from '../HorizontalClubListItem/HorizontalClubListItem';
import { useMemo } from 'react';

type HorizontalClubListProps = {
  clubList: Array<ClubView>;
  handleClubSelection: (uid: string) => void; 
}

const HorizontalClubList = ({ clubList, handleClubSelection }: HorizontalClubListProps) => {
  const renderClubItems = useMemo(() => {
    const favoritesCount = clubList.reduce((acc: number, curr: ClubView) => curr.favorite ? ++acc : acc, 0);
    if(favoritesCount > 5) {
      return clubList.filter(club => club.favorite).map(club => (
        <HorizontalClubListItem
          key={club.reference?.uid}
          clubCity={club.mapAddress?.slice(0, 10) ?? '-'}
          clubName={club.name ?? '-'}
          onClick={() => handleClubSelection(club.reference?.uid ?? '')}
        />
      ))
    } else {
      return clubList.slice(0, 5).map(club => (
        <HorizontalClubListItem
          key={club.reference?.uid}
          clubCity={club.mapAddress && club.mapAddress.length > 17 ? `${club.mapAddress?.slice(0, 17)}...` : club.mapAddress}
          clubName={club.name ?? '-'}
          onClick={() => handleClubSelection(club.reference?.uid ?? '')}
        />
      ))
    }
  }, [clubList, handleClubSelection])

  return ( 
    <div className={styles['clubs-wrapper']}>
      <div className={styles['clubs-list']}>
        {renderClubItems}
      </div>
    </div>
  );
}
 
export default HorizontalClubList;
