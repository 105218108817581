/* tslint:disable */
/* eslint-disable */
/**
 * Lundapadel REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessageSendParameters
 */
export interface ChatMessageSendParameters {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageSendParameters
     */
    chatUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageSendParameters
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageSendParameters
     */
    opened?: boolean;
}

export function ChatMessageSendParametersFromJSON(json: any): ChatMessageSendParameters {
    return ChatMessageSendParametersFromJSONTyped(json, false);
}

export function ChatMessageSendParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageSendParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatUid': !exists(json, 'chatUid') ? undefined : json['chatUid'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'opened': !exists(json, 'opened') ? undefined : json['opened'],
    };
}

export function ChatMessageSendParametersToJSON(value?: ChatMessageSendParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatUid': value.chatUid,
        'message': value.message,
        'opened': value.opened,
    };
}

